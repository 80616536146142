<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>  

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-0 lg:p-4 text-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel class="h-auto relative text-left overflow-hidden transform transition-all my-0 lg:my-8 w-[500px] pb-10">
                            <div class="relative">
                                <div @click="closeThis" class="cursor-pointer group absolute -bottom-10 left-0 right-0 m-auto w-max flex space-x-0.5 justify-center">
                                    <XIcon class="w-6 h-6 text-white group-hover:text-gray-400 transition"/>
                                    <p class="shadow_drop-shadow-[0_0_4px_rgba(0,0,0,0.35)] text-white group-hover:text-gray-400 font-semibold transition">Close</p>
                                </div>
                                <div class="shadow-xl">
                                    <a :href="bannerLink" class="outline-0">
                                        <img
                                            :src="popup"
                                            alt="banner_photo"
                                            width="500"
                                            height="400"
                                            class="w-[500px] h-[400px] object-cover"
                                        >
                                    </a>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { useUserStore } from "@/store/user";

export default defineComponent({
    components: {
        Dialog, 
        DialogPanel,
        TransitionChild, 
        TransitionRoot,
        XIcon,
    },
    emits:[ 'close' ],
    props:{
        isVisible:{
            type:Boolean,
            default:false
        },
        bannerLink:{
            type:String,
            default:null
        },
        popup: {
            type: String,
            default: "/assets/images/home-banner.png",
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const userStore = useUserStore();

        const closeThis = async () => {
            open.value = false;
            await userStore.closeHomeBanner()
        };

        watch(
            () => props.isVisible,
            (bool) => open.value = bool, {
                immediate: true
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit('close');
            }
        );

        return {
            open,
            closeThis,
        }
    },
})
</script>
