<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="
						flex
						items-start
						lg:items-center
						justify-center
						min-h-[calc(100vh-72px)]
						lg:min-h-[calc(100vh-86px)]
					"
				>
					<div class="px-4 lg:px-0 w-full lg:w-[400px] mx-auto h-[calc(100vh-86px)] lg:h-auto">
						<div class="relative py-4 lg:py-0 h-full">
							<div class="relative">
								<div @click="() => router.push('/login-option')" class="relative pb-8 block lg:hidden">
									<ArrowLeftIcon class="cursor-pointer w-5 h-5 text-primary-500 absolute left-0" />
								</div>
							</div>
							<div>
								<h2 class="text-primary-900 font-semibold">Login Account</h2>
							</div>
							<div class="mt-8 space-y-2">
								<text-input type="text" placeholder="Enter phone or email" v-model="form.emailMobile" />
								<div class="relative">
									<text-input
										:type="showPassword ? 'text' : 'password'"
										placeholder="Password"
										v-model="form.password"
									/>
									<div
										@click="showPassword = !showPassword"
										class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center"
									>
										<template v-if="!showPassword">
											<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
										</template>
										<template v-else>
											<EyeOffIcon class="h-5 w-5 text-primary-500 bg-white" aria-hidden="true" />
										</template>
									</div>
								</div>
								<anchor-link @click="router.push('forgot-password')">
									<template #content>
										<span class="text-sm font-semibold text-primary-400"> Forgot Password? </span>
									</template>
								</anchor-link>
							</div>
							<div class="mt-0 lg:mt-3 text-right absolute lg:relative bottom-4 w-full">
								<button-component
									@click="submit"
									size="large"
									color="primary"
									customClass="w-full lg:w-auto"
								>
									<template #content>
										<template v-if="!loading">Login</template>
										<ion-spinner v-else></ion-spinner>
									</template>
								</button-component>
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import useModal from "@/composables/modal";
import useAuth from "@/composables/useAuth";
import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
import TextInput from "@/components/TextInput.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import { useApiStore } from "@/store/api";
import { useGuestStore } from "@/store/guest";

export default defineComponent({
	name: "LoginAccount",
	components: {
		IonPage,
		IonContent,
		IonSpinner,
		EyeIcon,
		EyeOffIcon,
		TopNavigation,
		BottomNavigation,
		ArrowLeftIcon,
		TextInput,
		ButtonComponent,
		AnchorLink,
	},
	setup() {
		const { setToken, setRefreshToken, fetchUserData } = useAuth();
		const modal = useModal();
		const router = useRouter();
		const route = useRoute();
		const apiStore = useApiStore();
		const guestStore = useGuestStore();

		const form = ref({
			emailMobile: route.query.credential,
			password: null,
		});

		const loading = ref(false);
		const errors = ref(null);
		const showPassword = ref(false);

		const submit = () => {
			window.axios
				.post(apiStore.route("login"), form.value)
				.then((result) => {
					loading.value = false;

					setToken("Bearer " + result.data.access_token, result.data.user);
					setRefreshToken(result.data.refresh_token);

					guestStore.syncToUser().then(() => {
						fetchUserData().then(() => {
							if (guestStore.isSynced) {
								router.replace("/branch-view");
							} else {
								router.replace("/home");
							}
						});
					});
				})
				.catch((error) => {
					errors.value = error.response.data.message;
					modal.error(errors.value);
				});
		};

		return {
			showPassword,
			router,
			form,
			loading,
			submit,
		};
	},
});
</script>
