<template>
	<ion-page>
		<top-navigation backButton backButtonHref="/menu"></top-navigation>

		<ion-content :fullscreen="true" v-if="userStore.userData">
			<div class="page-offset">
				<div class="h-full relative max-w-auto px-4 md:px-0 md:max-w-[408px] m-auto pt-10 pb-[54px] md:pb-[160px]">
					<div class="pb-8 border-b">
						<div>
							<h3 class="text-xl md:text-2xl font-semibold">Profile</h3>
						</div>

						<ion-row class="-mx-4 prf-view">
							<ion-col size="12" class="px-0">
								<img v-if="preview" :src="preview" class="mx-auto block w-16 h-16 rounded-full my-4" />
								<img v-else :src="form.image" class="mx-auto block w-16 h-16 rounded-full my-4" />
							</ion-col>
							<ion-col size="12" class="px-0 pb-0 pt-1 text-center">
								<label for="myfile" class="font-size--small text--primary"> Change Photo </label>
								<input @change="uploadProfile" type="file" id="myfile" name="avatar_path" hidden />
							</ion-col>
						</ion-row>

						<div class="mt-4 md:mt-6 space-y-2">
							<p class="text-sm text-primary-300 font-normal">Personal Information:</p>
							<text-input
								type="text"
								placeholder="First Name"
								v-model="form.first_name"
								name="first_name"
								:error="errors.first_name ? errors.first_name[0] : null" />
							<text-input
								type="text"
								placeholder="Last Name"
								v-model="form.last_name"
								name="last_name"
								:error="errors.last_name ? errors.last_name[0] : null" />
							<div>
								<select
									v-model="form.gender"
									name="gender"
									class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg placeholder:text-primary-100">
									<option selected disabled :value="null">Gender</option>
									<option value="Female">Female</option>
									<option value="Male">Male</option>
								</select>
								<p v-if="errors.gender" class="text-[10px] text-error mt-1">
									{{ errors.gender[0] }}
								</p>
							</div>
						</div>

						<div class="mt-4 space-y-2">
							<p class="text-primary-400 text-sm">Date of Birth:</p>
							<div class="flex items-start flex-col">
								<text-input
									type="date"
									placeholder="Birth Date"
									v-model="form.birthdate"
									:error="errors?.birthdate ? errors.birthdate[0] : null" />
							</div>
						</div>

						<div class="mt-4 space-y-2">
							<p class="text-sm text-primary-300 font-normal">Account Details:</p>
							<mobile-number
								v-model="form.mobile_number"
								:disabled="hasMobileNumber"
								:error="errors.mobile_number ? errors.mobile_number[0] : null" />
							<text-input
								type="email"
								placeholder="Email Address"
								name="email"
								v-model="form.email"
								:disabled="hasEmail"
								:error="errors.email ? errors.email[0] : null" />
						</div>
						<div class="flex flex-wrap justify-end space-x-1 md:space-x-4 mt-6">
							<button-component class="text-xs mt-1" color="red" @click="router.push('/delete-account')">
								<template #content> Delete Account </template>
							</button-component>
							<button-component class="text-xs mt-1" color="red" fill="outline" @click="router.push('/deactivate-account')">
								<template #content> Deactivate Account </template>
							</button-component>
							<button-component class="text-xs mt-1" color="primary" @click="trySavingProfile">
								<template #content> Save </template>
							</button-component>
						</div>
					</div>

					<div class="py-8 border-b">
						<ChangePasswordForm />
					</div>

					<div class="py-8 border-b">
						<div class="mb-6 flex justify-between items-center">
							<div class="w-fit">
								<h3 class="font-semibold">Address Book</h3>
							</div>
							<div @click="addNewAddress" class="add-new flex w-fit gap-2 items-center">
								<h6 class="font-semibold">Add New</h6>
								<ion-icon :src="chevronForward" class="w-4 h-4 text-black" />
							</div>
						</div>
						<div class="space-y-2">
							<template v-if="!addressLoading">
								<template v-if="addresses.length > 0">
									<ion-item-sliding
										v-for="(address, index) in addresses"
										:key="index"
										:ref="
											(el) => {
												addressSlides[index] = {
													slide: el,
													open: false,
												};
											}
										"
										class="w-full">
										<ion-item class="ion-no-padding frm-item-no-end pointer-events-auto" lines="none" mode="ios">
											<div class="border rounded-lg relative cursor-pointer w-full">
												<div class="card-wrapper p-4 relative">
													<div class="max-w-[302px]">
														<h6 class="font-semibold mb-2">
															{{ address.name }}
														</h6>
														<h6 class="mb-2 text-primary-400 line-clamp-2">
															{{ address.address }}
														</h6>
													</div>
													<ion-icon
														:src="ellipsisVertical"
														class="w-4 h-4 text-black absolute top-4 right-4 cursor-pointer"
														@click="openSlide(index)" />
												</div>
											</div>
										</ion-item>
										<ion-item-options>
											<ion-item-option @click="editAddress(address)" class="rounded-lg ml-2 bg-primary-100">
												<ion-icon src="/assets/icon/ic-edit.svg" />
											</ion-item-option>
											<ion-item-option class="rounded-lg ml-1 bg-error">
												<ion-icon @click="deleteAddress(address)" src="/assets/icon/ic-remove.svg" />
											</ion-item-option>
										</ion-item-options>
									</ion-item-sliding>
								</template>
								<p v-else class="text-sm text-gray-500">No address saved</p>
							</template>
							<ion-spinner v-else />
						</div>
					</div>
				</div>
			</div>
			<bottom-navigation />

			<!----------------- 
                TOASTS 
            ------------------>
			<toast-message :show="showToast" :message="'Your changes have been saved.'" @didDismiss="showToast = false" />

			<!-- SAVE PROFILE MODAL -->
			<generic-modal
				:is-visible="showProfileUpdateModal"
				@close="showProfileUpdateModal = false"
				:mobile-full-screen="true"
				:noCloseIcon="true">
				<template #content>
					<div class="absolute w-full top-6 z-50 lg:top-12">
						<div class="flex items-center justify-end">
							<XIcon
								@click="showProfileUpdateModal = false"
								class="cursor-pointer w-5 h-5 text-primary-500 absolute right-4 lg:right-12" />
						</div>
					</div>
					<div class="relative h-full">
						<div class="h-full w-full lg:w-[500px]">
							<div class="p-4 lg:p-12">
								<div class="flex items-center mb-5">
									<div
										class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
										<QuestionMarkCircleIcon class="h-6 w-6 text-black" aria-hidden="true" />
									</div>
									<p class="text-sm text-gray-700 ml-3">
										Are you sure you wish to update your profile? Please enter your password below to proceed.
									</p>
								</div>

								<label class="block text-sm font-medium text-gray-700"> Password </label>

								<div class="relative mb-3">
									<input
										:type="showPasswordValidate ? 'text' : 'password'"
										class="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-primary-50 placeholder:text-primary-100 rounded-lg"
										placeholder="Current Password"
										v-model="form.current_password" />
									<div
										@click="showPasswordValidate = !showPasswordValidate"
										class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]">
										<template v-if="!showPasswordValidate">
											<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
										</template>
										<template v-else>
											<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
										</template>
									</div>
								</div>

								<div class="block text-right">
									<button-component color="primary" @click="submit" :disabled="!form.current_password">
										<template #content> Proceed </template>
									</button-component>
								</div>
							</div>
						</div>
					</div>
				</template>
			</generic-modal>

			<!-- ADDRESS MODAL -->
			<generic-modal :is-visible="showAddressModal" @close="showAddressModal = false" :mobile-full-screen="true" :noCloseIcon="true">
				<template #content>
					<div class="absolute w-full top-6 z-50 lg:top-12">
						<div class="flex items-center justify-end">
							<XIcon
								@click="closeMapSelectionModal(false)"
								class="cursor-pointer w-5 h-5 text-primary-500 absolute right-4 lg:right-12" />
						</div>
					</div>
					<div class="relative h-full">
						<div class="h-full w-full lg:w-[500px]">
							<div class="p-4 lg:p-12">
								<address-book></address-book>
							</div>
						</div>
					</div>
				</template>
			</generic-modal>
		</ion-content>
	</ion-page>
</template>
<script type="text/javascript">
	import { defineComponent, ref, provide } from "vue";
	import { chevronForward, ellipsisVertical } from "ionicons/icons";
	import {
		IonPage,
		IonContent,
		IonRow,
		IonCol,
		IonItem,
		IonItemOptions,
		IonItemOption,
		IonItemSliding,
		IonIcon,
		loadingController,
		IonSpinner,
		onIonViewWillEnter,
	} from "@ionic/vue";
	import { XIcon, QuestionMarkCircleIcon } from "@heroicons/vue/outline";
	import { useApiStore } from "@/store/api";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import TextInput from "@/components/TextInput.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import ToastMessage from "@/components/ToastMessage.vue";
	import ChangePasswordForm from "./ChangePasswordForm.vue";
	import useModal from "@/composables/modal.js";
	import GenericModal from "@/components/GenericModal.vue";
	import MobileNumber from "@/components/MobileNumber.vue";
	import AddressBook from "../home/Components/AddressBook.vue";
	import { useAddressStore } from "@/store/address";
	import { useUserStore } from "@/store/user";
	import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
	import { useRouter } from "vue-router";
	import useLocation from "@/composables/useLocation";

	export default defineComponent({
		name: "EditProfile",
		components: {
			IonPage,
			IonContent,
			IonRow,
			IonCol,
			IonItem,
			IonItemOptions,
			IonItemOption,
			IonItemSliding,
			IonIcon,
			IonSpinner,
			TopNavigation,
			BottomNavigation,
			TextInput,
			ButtonComponent,
			ToastMessage,
			ChangePasswordForm,
			GenericModal,
			MobileNumber,
			XIcon,
			QuestionMarkCircleIcon,
			AddressBook,
			EyeIcon,
			EyeOffIcon,
		},
		setup() {
			const router = useRouter();
			const apiStore = useApiStore();
			const addressStore = useAddressStore();
			const userStore = useUserStore();
			const modal = useModal();
			const { clearValues } = useLocation();

			const showToast = ref(false);
			const showPasswordValidate = ref(false);
			const addressLoading = ref(true);
			const showAddressModal = ref(false);
			const showProfileUpdateModal = ref(false);

			const addresses = ref([]);
			const errors = ref([]);
			const form = ref({
				avatar: null,
				current_password: null,
				first_name: userStore.userData.first_name,
				last_name: userStore.userData.last_name,
				email: userStore.userData.email,
				birthdate: userStore.userData.birthdate,
				mobile_number: userStore.userData.mobile_number,
				gender: userStore.userData.gender,
				image: userStore.userData.avatar_path ?? userStore.userData.profile_photo_url,
			});

			const preview = ref(null);
			const hasEmail = ref(true);
			const hasMobileNumber = ref(true);

			const fetchUser = async () => {
				window.axios.get(apiStore.route("customer")).then((result) => {
					userStore.setUser(result.data.user);

					form.value.image = result.data.user.avatar_path ?? result.data.user.profile_photo_url;
					form.value.first_name = result.data.user.first_name;
					form.value.last_name = result.data.user.last_name;
					form.value.email = result.data.user.email;
					form.value.birthdate = result.data.user.birthdate;
					form.value.mobile_number = result.data.user.mobile_number;
					form.value.gender = result.data.user.gender;

					addresses.value = result.data.user.addresses;
					addressLoading.value = false;

					hasEmail.value = result.data.user.email !== null && result.data.user.email !== "";
					hasMobileNumber.value = result.data.user.mobile_number !== null && result.data.user.mobile_number !== "";
				});
			};

			const deleteAddress = (address) => {
				showLoading("Deleting Address ...");

				window.axios
					.delete(apiStore.route("deleteAddress", { id: address.id }))
					.then(() => {
						fetchAddresses();
						loadingController.dismiss();
						showToast.value = true;
					})
					.catch((error) => {
						loadingController.dismiss();
						modal.error(error.response.data.message);
					});
			};

			const editAddress = (address) => {
				addressStore.selectedAddress = address;
				addressStore.isAddressBook = true;
				addressStore.editAddress = true;
				showAddressModal.value = true;
			};

			const fetchAddresses = () => {
				addressLoading.value = true;

				window.axios
					.get(apiStore.route("fetchAddresses"))
					.then(({ data }) => {
						addresses.value = data.addresses;
						addressLoading.value = false;
					})
					.catch((error) => {
						modal.error(error.response.data.message);
					});
			};

			const closeMapSelectionModal = (fetchAddress) => {
				showAddressModal.value = false;
				if (fetchAddress) {
					fetchAddresses();
				}
			};

			const imageLoaded = (event) => {
				preview.value = event.target.result;
			};

			const uploadProfile = (event) => {
				const file = event.target.files.item(0);
				const reader = new FileReader();

				reader.addEventListener("load", imageLoaded);
				reader.readAsDataURL(file);

				form.value.avatar = file;
			};

			const submit = () => {
				showLoading("Verifying ...");

				window.axios
					.post(apiStore.route("profileUpdate"), form.value, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(() => {
						loadingController.dismiss();
						showToast.value = true;

						if (form.value.email) {
							hasEmail.value = true;
						}

						if (form.value.mobile_number) {
							hasMobileNumber.value = true;
						}

						fetchUser();
					})
					.catch((error) => {
						loadingController.dismiss();
						errors.value = error.response.data.errors;
						modal.error(error.response.data.message);
					});
			};

			const changeBirthdate = (value) => {
				let date = new Date(value.detail.value);
				form.value.birthdate = date instanceof Date && !isNaN(date) ? date.toISOString().split("T")[0] : null;
			};

			const showLoading = async (message = "Saving Changes ...") => {
				showProfileUpdateModal.value = false;

				const loading = await loadingController.create({
					message: message,
					mode: "ios",
				});

				loading.present();
			};

			const numberonly = (e) => {
				e = e ? e : window.event;
				let charCode = e.which ? e.which : e.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57)) {
					e.preventDefault();
				} else {
					return true;
				}
			};

			const addressSlides = ref([]);
			const openSlide = (index) => {
				if (addressSlides.value[index].open) {
					addressSlides.value[index].slide.$el.close();
					addressSlides.value[index].open = false;
				} else {
					addressSlides.value[index].slide.$el.open();
					addressSlides.value[index].open = true;
				}
			};

			const trySavingProfile = () => {
				if (userStore.userData.from_social_login) {
					submit();
				} else {
					showProfileUpdateModal.value = true;
				}
			};

			const addNewAddress = () => {
				clearValues();

				addressStore.selectedAddress = {};
				addressStore.isAddressBook = false;
				addressStore.editAddress = false;

				showAddressModal.value = true;
			};

			provide("map-selection-modal", {
				closeMapSelectionModal,
			});

			onIonViewWillEnter(() => {
				fetchUser();
			});

			return {
				router,
				numberonly,
				chevronForward,
				ellipsisVertical,
				showToast,
				showPasswordValidate,
				form,
				addresses,
				submit,
				errors,
				preview,
				uploadProfile,
				deleteAddress,
				editAddress,
				showAddressModal,
				showProfileUpdateModal,
				closeMapSelectionModal,
				addressLoading,
				addressSlides,
				openSlide,
				userStore,
				changeBirthdate,
				hasEmail,
				hasMobileNumber,
				trySavingProfile,
				addNewAddress,
			};
		},
	});
</script>
