<template>
	<div
		v-if="locationBar"
		class="flex flex-col lg:flex-row items-center justify-start gap-2 lg:gap-0 w-full lg:w-2/4">
		<select
			id="location"
			name="location"
			class="h-[53px] block w-full lg:w-auto pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-2 focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-lg lg:rounded-l-lg lg:rounded-r-none"
			v-model="orderOption">
			<option
				disabled
				value="">
				-- Order Option --
			</option>
			<option value="Delivery">Delivery</option>
			<option value="Pick-up">Pick-up</option>
		</select>
		<div class="relative flex items-center w-full">
			<div class="top-[3.4rem] lg:inset-y-0 flex lg:py-2 lg:px-2 w-full lg:w-auto bg-white order-btn">
				<button
					@click="orderNow"
					class="h-[53px] lg:h-auto w-full lg:w-auto inline-flex items-center justify-center text-sm bg-primary-500 hover:bg-primary-400 text-white font-semibold py-2 px-4 rounded-lg disabled:opacity-50 disabled:hover:bg-primary-500"
					:disabled="!orderOption">
					Order Now
				</button>
			</div>
		</div>
	</div>

	<div v-if="!locationBar">
		<button-component
			color="secondary"
			class="mt-11"
			@click="openBanner">
			<template #content>{{ `${contents ? contents.buttonText : "Read More"}` }}</template>
		</button-component>
	</div>

	<generic-modal
		:is-visible="showBannerModal"
		@close="showBannerModal = false"
		:mobile-full-screen="true">
		<template #content>
			<div class="relative h-full">
				<div class="w-full h-full lg:w-[500px] lg:h-auto relative px-4 py-6 lg:px-12">
					<div class="mt-4 mb-6">
						<img
							:src="`${contents.image !== '' ? renderCmsImage(contents.image) : '/assets/image/banner_img1.png'}`"
							class="object-contain block mx-auto"
							alt="banner" />
					</div>
					<div class="w-full mb-10">
						<h3 class="text-primary-900 font-semibold lg:text-2xl text-xl mb-1">
							{{ contents.title }}
						</h3>
						<p
							v-if="contents.validity"
							class="text-primary-200 text-xs mb-3">
							Valid until {{ contents.validity }}
						</p>
						<p class="text-primary-400">{{ contents.description }}</p>
					</div>
					<ion-button
						v-if="contents.is_external && contents.is_external == 1"
						class="mb-10 btn-primary"
						expand="block"
						:href="contents.link"
						target="_blank">
						{{ contents.buttonText }}
					</ion-button>
					<ion-button
						v-else
						class="mb-10 btn-primary"
						expand="block"
						@click="openTransaction(contents)">
						{{ contents.buttonText }}
					</ion-button>
				</div>
			</div>
		</template>
	</generic-modal>

	<generic-modal
		:no-close-icon="true"
		:is-visible="showTransactionModal"
		@close="showTransactionModal = false"
		:mobile-full-screen="true">
		<template #content>
			<div class="relative h-full">
				<div class="w-full h-full lg:w-[500px] lg:h-auto relative px-4 py-6 lg:px-12">
					<img
						src="/assets/icon/ic-arrow-back.svg"
						alt="back_icon"
						class="w-7 h-7 object-contain mb-8 hover:opacity-80 transition cursor-pointer"
						@click="openBanner" />
					<div class="w-full mb-8">
						<h3 class="text-primary-900 font-semibold lg:text-2xl mb-2 text-3xl">Select Transaction</h3>
					</div>
					<div class="flex flex-col space-y-2 mb-6">
						<div
							@click="selectMethod('Delivery')"
							class="flex items-center justify-between cursor-pointer border border-primary-50 rounded-xl p-6">
							<p class="font-semibold text-primary-500 mx-0">Delivery</p>
							<img
								src="/assets/icon/ic-delivery.svg"
								alt="icon"
								class="w-10 h-10 object-contain flex-shrink-0" />
						</div>

						<div
							@click="selectMethod('Pick-up')"
							class="flex items-center justify-between cursor-pointer border border-primary-50 rounded-xl p-6">
							<p class="font-semibold text-primary-500 mx-0">Pick-up</p>
							<img
								src="/assets/icon/ic-pickup.svg"
								alt="icon"
								class="w-10 h-10 object-contain flex-shrink-0" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</generic-modal>

	<!-- TERMS MODAL -->
	<generic-modal
		:is-visible="showTermsModal"
		@close="showTermsModal = false"
		:mobile-full-screen="true">
		<template #content>
			<div class="relative h-full">
				<div class="w-full h-full lg:w-[1054px] lg:h-auto relative px-4 py-6 lg:px-12">
					<div>
						<h3 class="text-primary-900 font-semibold text-2xl">
							{{ cmsContent ? getCmsContent("section1_pageTitle") || "Terms and Conditions" : "Terms and Conditions" }}
						</h3>

						<template v-if="cmsContent">
							<div
								v-html="getCmsContent('section1_description')"
								class="html-box h-[50vh] lg:h-80 overflow-auto space-y-6 pr-6 scrollbar-style mt-6"></div>
						</template>
						<template v-else>
							<div class="h-[50vh] lg:h-80 overflow-auto space-y-6 pr-6 scrollbar-style mt-6">
								<div>
									<p class="text-sm mb-2 font-semibold">1. ORDERING TIME, PICK-UP AND DELIVERY TRANSACTIONS</p>
									<p class="text-sm">
										Delivery and pick-up ordering start at 10:00 am until 7:00 pm. Limited deliveries are available per time slot. Delivery fees will apply. Some areas may not be covered by our delivery service. This order form is
										for same-day delivery/pick-up transactions only. *Delivery and pick-up schedule may vary depending on the branch*
									</p>
								</div>
								<div>
									<p class="text-sm mb-2 font-semibold">2. RAMEN, SIDE DISHES, RICE DISHES AND SET MEALS</p>
									<p class="text-sm">
										For delivery/pick-up transactions, we only offer ramen home kit, not yet ready to eat but comes with preparation guidelines. We don’t serve extra soup. Our ramen soup follows standard guidelines and we cannot
										accommodate “less sauce” requests. Rice and side dishes are ready to eat upon purchase. Set meals inclusions are ready to eat except the ramen which is ramen home kit.
									</p>
								</div>
								<div>
									<p class="text-sm mb-2 font-semibold">3. MODE OF PAYMENT AND DISCOUNTS</p>
									<p class="text-sm">
										We do accept Debit Card, Credit Card, GrabPay, and GCash as a mode of payment. The 20% discount is currently only available for food purchases paid in the stores. If you wish to avail of your senior citizen/PWD
										discount, you may order at any of our stores.
									</p>
								</div>
								<div>
									<p class="text-sm mb-2 font-semibold">4. FOOD CONSUMPTION</p>
									<p class="text-sm">
										A ramen home kit must be kept in the chiller if it won’t be consumed yet but it must be consumed within the day. All take-out / pick-up/delivery orders must be consumed within the day of purchase to avoid
										negative quality concerns. All our products are subject to availability. Some branches including the ones near you may or may not carry the products featured online. Images and packaging of featured products
										may not look identical to the products you receive at the branch.
									</p>
								</div>
								<div>
									<p class="text-sm mb-2 font-semibold">5. CANCELLATION</p>
									<p class="text-sm">
										Ramen Kuroda reserves the right to refuse any changes or cancellation in case the order has already been prepared or dispatched for delivery/pick-up prior to receipt of your request. This applies to both cash
										and cashless transactions.
									</p>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="pb-6 px-4 lg:px-12 flex flex-col lg:flex-row items-center justify-between absolute lg:relative bottom-0">
					<check-box
						id="terms"
						v-model="accept"
						label="I have read and agree to the above terms & conditions" />

					<div class="mt-4 lg:mt-0 w-full lg:w-auto">
						<button-component
							@click="openOrderModal"
							customClass="w-full lg:w-auto"
							color="primary"
							:disabled="!accept || loading">
							<template #content>
								<template v-if="!loading">Confirm</template>
								<ion-spinner v-else></ion-spinner>
							</template>
						</button-component>
					</div>
				</div>
			</div>
		</template>
	</generic-modal>

	<!-- LOGIN ALERT -->
	<generic-modal
		:no-close-icon="true"
		:is-visible="showLoginModal"
		@close="showLoginModal = false">
		<template #content>
			<div class="px-4 pb-4 lg:px-12 lg:pb-12 w-full lg:w-[407px] h-auto">
				<div class="space-y-2">
					<p class="text-base text-primary-900 font-bold">Login or Continue as a Guest to proceed</p>
					<p class="text-sm text-primary-400 font-normal">An account is needed to checkout an order.</p>
				</div>
				<div class="flex items-center justify-end gap-2 mt-8">
					<button-component
						@click="showLoginModal = false"
						color="secondary">
						<template #content>Cancel</template>
					</button-component>
					<button-component
						@click="redirect('login-option')"
						color="primary">
						<template #content>Okay</template>
					</button-component>
				</div>
			</div>
		</template>
	</generic-modal>

	<!-- ORDER MODAL -->
	<generic-modal
		:is-visible="showOrderModal"
		@close="
			{
				loading = false;
				showOrderModal = false;
			}
		"
		:mobile-full-screen="true"
		:noCloseIcon="true">
		<template #content>
			<div class="absolute w-full top-6 z-50 lg:top-12">
				<div class="flex items-center justify-between">
					<template v-if="step > 1 && orderOption !== 'Pick-up'">
						<ArrowLeftIcon
							@click="step--"
							class="cursor-pointer w-5 h-5 text-primary-500 absolute left-4 lg:left-12" />
					</template>

					<XIcon
						@click="showOrderModal = !showOrderModal"
						class="cursor-pointer w-5 h-5 text-primary-500 absolute right-4 lg:right-12" />
				</div>
			</div>
			<div class="relative h-full">
				<div class="h-full w-full lg:w-[500px]">
					<div class="p-4 lg:p-12">
						<recipient-index
							v-show="step === 1"
							@next="step++">
							<ArrowLeftIcon
								class="absolute top-0 left-4 lg:left-12 w-5 h-5 object-contain mb-8 hover:opacity-80 transition cursor-pointer"
								@click="openTerms" />
						</recipient-index>
						<order-address
							v-show="step === 2"
							@next="redirect('/branches')" />
					</div>
				</div>
			</div>
		</template>
	</generic-modal>
</template>
<script>
	import { defineComponent, onMounted, ref } from "vue";
	import { useRoute, useRouter } from "vue-router";
	import { XIcon, ArrowLeftIcon } from "@heroicons/vue/outline";
	import GenericModal from "@/components/GenericModal.vue";
	import RecipientIndex from "./RecipientIndex.vue";
	import OrderAddress from "./OrderAddress.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import CheckBox from "@/components/CheckBox.vue";
	import { useCartStore } from "@/store/cart";
	import { IonSpinner, IonButton } from "@ionic/vue";
	import useCms from "@/composables/useCms.js";
	import useLocalStorage from "@/composables/useLocalStorage.js";
	import { useGuestStore } from "@/store/guest";
	import { useUserStore } from "@/store/user";

	export default defineComponent({
		components: {
			GenericModal,
			XIcon,
			ArrowLeftIcon,
			RecipientIndex,
			OrderAddress,
			ButtonComponent,
			CheckBox,
			IonSpinner,
			IonButton,
		},
		props: {
			locationBar: {
				type: Boolean,
				default: true,
			},
			contents: {
				type: Object,
				default: () => ({}),
			},
		},
		setup() {
			const router = useRouter();
			const route = useRoute();
			const userStore = useUserStore();
			const guestStore = useGuestStore();
			const { localStorage } = useLocalStorage();
			const { cms, contentSlug, getCms, getCmsContent, renderCmsImage } = useCms();

			const cmsContent = ref(null);
			const loading = ref(false);
			const accept = ref(false);
			const showLoginModal = ref(false);
			const showTermsModal = ref(false);
			const showOrderModal = ref(false);
			const showBannerModal = ref(false);
			const showTransactionModal = ref(false);

			const cartStore = useCartStore();
			const orderOption = ref("Delivery");

			const recipientComplete = ref(false);

			const guestPreview = ref(true);

			const openOrderModal = () => {
				localStorage.value.get("authToken").then((value) => {
					if (value) {
						// fetch any updates from the user profile before proceeding
						loading.value = true;

						showTermsModal.value = false;
						showOrderModal.value = true;
					} else {
						if (route.query.guest && guestPreview.value) {
							// fetch any updates from the user profile before proceeding
							loading.value = true;

							showTermsModal.value = false;
							showOrderModal.value = true;
						} else {
							showLoginModal.value = true;
						}
					}
				});
			};

			const redirect = (url) => {
				showLoginModal.value = false;
				showTermsModal.value = false;
				showOrderModal.value = false;

				accept.value = false;
				loading.value = false;

				router.push(url);
			};

			const openBanner = () => {
				showBannerModal.value = true;
				showTransactionModal.value = false;
			};

			const openTransaction = async (banner) => {
				await userStore.setPromoBanner(banner);
				showBannerModal.value = false;
				showTransactionModal.value = true;
			};

			const selectMethod = (method) => {
				orderOption.value = method;
				orderNow();
			};

			const openTerms = () => {
				showTermsModal.value = true;
				showOrderModal.value = false;
			};

			const orderNow = () => {
				if (guestStore.isGuest) {
					guestStore.fulfillmentMethod = orderOption.value;

					if (!route.query.guest && guestPreview.value) {
						showLoginModal.value = true;
						return;
					}
				} else {
					cartStore.fulfillmentMethod = orderOption.value;
				}

				if (orderOption.value === "Pick-up") {
					step.value = 2;
				} else {
					step.value = 1;
				}

				showTermsModal.value = true;
				showTransactionModal.value = false;
			};

			const step = ref(1);

			onMounted(() => {
				guestStore.checkIfGuest((bool) => {
					guestPreview.value = bool;
				});

				contentSlug.value = "terms_and_conditions";
				getCms(contentSlug.value);
				cmsContent.value = cms._rawValue.content;
			});

			return {
				loading,
				accept,
				showTermsModal,
				showOrderModal,
				showLoginModal,
				openOrderModal,
				redirect,
				step,
				router,
				orderOption,
				recipientComplete,
				orderNow,
				cmsContent,
				getCmsContent,
				guestPreview,
				showBannerModal,
				openBanner,
				showTransactionModal,
				openTransaction,
				openTerms,
				renderCmsImage,
				selectMethod,
			};
		},
	});
</script>
