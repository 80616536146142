import Echo from "laravel-echo";

/* initialize pusher & echo */
window.Pusher = require("pusher-js");

/** Composables */
window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_MIX_PUSHER_HOST,
    wssHost: process.env.VUE_APP_MIX_PUSHER_HOST,
    wsPort: process.env.VUE_APP_MIX_PUSHER_PORT,
    wssPort: process.env.VUE_APP_MIX_PUSHER_PORT,
    forceTLS: process.env.VUE_APP_MIX_PUSHER_SCHEME === "https",
    cluster: process.env.VUE_APP_MIX_PUSHER_CLUSTER,
    authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                window.axios
                    .post(process.env.VUE_APP_API_URL + "/broadcasting/auth", {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then((response) => {
                        callback(null, response.data);
                    })
                    .catch((error) => {
                        callback(error);
                    });
            },
        };
    },
    encrypted: true,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
});

window.Echo.connector.pusher.connection.bind("connected", function () {
    window.axios.defaults.headers.common["X-Socket-Id"] =
        window.Echo.socketId();
});

window.Echo.connector.pusher.connection.bind("state_change", function (states) {
    if (states.current === "disconnected") {
        window.Echo.connector.pusher.connect();
    }
});