<template>
    <div>
        <div v-if="textarea" class="relative rounded-md">
            <textarea
                :id="id"
                rows="4"
                class="
                    shadow-sm
                    focus:ring-primary-500 focus:border-primary-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-lg
                    placeholder:text-primary-100
                "
                :placeholder="placeholder"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            ></textarea>
        </div>
        <div v-else class="relative rounded-md">
            <div
                v-if="leftIcon"
                class="
                    absolute
                    inset-y-0
                    left-0
                    pl-3
                    flex
                    items-center
                    pointer-events-none
                "
            >
                <img :src="leftIcon" class="h-5 w-5" />
            </div>
            <input
                :id="id"
                :name="id"
                :type="type"
                :placeholder="placeholder"
                :class="[rightIcon ? 'pr-10' : '', leftIcon ? 'pl-10' : '']"
                class="
                    shadow-sm
                    focus:ring-primary-500 focus:border-primary-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-lg
                    placeholder:text-primary-100
                "
                v-bind="$attrs"
                :value="modelValue"
                :maxlength="maxlength"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <div
                v-if="rightIcon"
                class="
                    absolute
                    inset-y-0
                    right-0
                    pr-3
                    flex
                    items-center
                    pointer-events-none
                "
            >
                <img :src="rightIcon" class="h-5 w-5" />
            </div>
        </div>
        <p class="text-[10px] text-primary-300 mt-1" v-if="helper">{{ helper }}</p>
        <p class="text-[10px] text-error mt-1" v-if="error">{{ error }}</p>
    </div>
</template>

<script>
export default {
    name: "text-input",
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: null,
        },
        helper: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        leftIcon: {
            type: String,
            default: null,
        },
        rightIcon: {
            type: String,
            default: null,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        maxlength: {
            type: [String, Number]
        }
    },
};
</script>