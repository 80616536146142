<template>
	<div v-if="!showAddressForm">
		<div
			v-if="!selectedLocation"
			class="w-full">
			<h3 class="text-primary-900 font-semibold text-2xl mb-2">{{ fulfillmentMethod }} to</h3>
			<p class="text-[#4D4D4D] text-sm">We need to know your location to validate the store near you.</p>
			<div class="mt-8 flex flex-col space-y-2">
				<div
					class="w-full border border-primary-50 cursor-pointer rounded-lg p-4"
					@click="(selectAddressBook = true), (selectedLocation = true)">
					<div class="flex items-center justify-between">
						<p class="text-sm font-semibold mx-0">Select from address book</p>
						<ChevronRightIcon class="w-6 h-6 text-black ml-2 flex-shrink-0" />
					</div>
				</div>
				<div
					class="w-full border border-primary-50 cursor-pointer rounded-lg p-4"
					@click="(selectManualLocation = true), (selectedLocation = true)">
					<div class="flex items-center justify-between">
						<p class="text-sm font-semibold mx-0">Set location manually</p>
						<ChevronRightIcon class="w-6 h-6 text-black ml-2 flex-shrink-0" />
					</div>
				</div>
			</div>
		</div>

		<div v-if="selectManualLocation">
			<div
				class="absolute top-0 bg-white z-[51]"
				@click="(selectManualLocation = false), (selectedLocation = false)">
				<ArrowLeftIcon class="cursor-pointer w-5 h-5 text-primary-500" />
			</div>
			<h3 class="text-primary-900 font-semibold text-2xl mb-2">Set Location Manually</h3>
			<p class="text-[#4D4D4D] text-sm">We need to know your location to validate the store near you.</p>
			<div class="relative">
				<text-input
					id="addressSearch"
					v-model="addressSearch"
					type="text"
					placeholder="Enter Address"
					@click="searching = true"
					class="mt-8 h-12" />
				<div
					v-if="searching"
					@click="
						{
							searching = false;
							locationState.completeAddress = null;
						}
					"
					class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
					<XCircleIcon
						class="h-6 w-6 text-primary-200 bg-white"
						aria-hidden="true" />
				</div>
			</div>
		</div>

		<div v-if="selectAddressBook">
			<div
				class="absolute top-0 bg-white z-[51]"
				@click="(selectAddressBook = false), (selectedLocation = false)">
				<ArrowLeftIcon class="cursor-pointer w-5 h-5 text-primary-500" />
			</div>
			<div
				class="w-full"
				v-if="!guestStore.isGuest">
				<h3 class="text-primary-900 font-semibold text-2xl mb-2">Address Book</h3>
				<p class="text-[#4D4D4D] text-sm">We need to know your location to validate the store near you.</p>
				<div class="max-h-[500px] lg:max-h-80 overflow-auto space-y-6 pr-2 scrollbar-style">
					<div class="space-y-2 mt-8">
						<template v-if="!loading">
							<address-card
								v-for="address in addresses"
								:key="address"
								:title="address.name"
								:address="address.address"
                                :isDefault="address.is_default"
								@click="setAddress(address)"
								:withRightArrow="true"
								:preSelected="cartStore.recipient?.address ? address.id === cartStore.recipient?.address?.id : address.is_default" />
						</template>
						<ion-spinner v-else />
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="selectManualLocation"
			class="mt-8"
			v-show="searching">
			<div class="max-h-[500px] lg:max-h-80 overflow-auto space-y-6 pr-2 scrollbar-style">
				<div
					v-for="(addressCandidate, index) in locationState.addressCandidates"
					:key="index"
					class="space-y-2 mt-2">
					<address-search-card
						:address="addressCandidate.formattedAddress"
						@click="selectAddress(addressCandidate)" />
				</div>
			</div>
		</div>
	</div>
	<template v-else>
		<map-selection
			@close="showAddressForm = false"
			@address:saved="(address) => setAddress(address)"></map-selection>
	</template>
</template>
<script>
	import { defineComponent, watch, ref, onMounted, computed } from "vue";
	import AddressCard from "@/components/AddressCard.vue";
	import AddressSearchCard from "./AddressSearchCard.vue";
	import TextInput from "@/components/TextInput.vue";
	import { XCircleIcon, ChevronRightIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
	import { useUserStore } from "@/store/user";
	import { useCartStore } from "@/store/cart";
	import { useGuestStore } from "@/store/guest";
	import MapSelection from "./MapSelection.vue";
	import useLocalStorage from "@/composables/useLocalStorage.js";
	import useLocation from "@/composables/useLocation";
	import { IonSpinner } from "@ionic/vue";
	import debounce from "lodash/debounce";

	export default defineComponent({
		components: {
			AddressCard,
			AddressSearchCard,
			TextInput,
			XCircleIcon,
			ChevronRightIcon,
			ArrowLeftIcon,
			MapSelection,
			IonSpinner,
		},
		props: {
			preventSaving: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const { localStorage } = useLocalStorage();
			const guestStore = useGuestStore();
			const userStore = useUserStore();
			const { locationState, setSelectedAddress, geocodePlace, googleSearch } = useLocation();

			const loading = ref(false);
			const searching = ref(false);
			const selectedLocation = ref(false);
			const selectCurrentLocation = ref(false);
			const selectAddressBook = ref(false);
			const selectManualLocation = ref(false);

			const fulfillmentMethod = computed(() => {
				return guestStore.isGuest ? guestStore.fulfillmentMethod : cartStore.fulfillmentMethod;
			});

			const addressSearch = ref(null);
			const searchAddress = ref(null);
			const addresses = ref(!guestStore.isGuest && userStore.userData ? userStore.userData.addresses : []);
			const showAddressForm = ref(false);

			watch(
				() => addressSearch.value,
				debounce((currentValue) => {
					if (currentValue) {
						googleSearch(currentValue);
					} else {
						locationState.addressCandidates = [];
					}

					if (!showAddressForm.value) {
						locationState.completeAddress = currentValue;
					}
				}, 700)
			);

			const cartStore = useCartStore();

			const setAddress = (address) => {
				cartStore.recipient.address = address;

				if (!guestStore.isGuest) {
					if (!props.preventSaving) {
						localStorage.value.set("cartFulfillment", cartStore.fulfillmentMethod);
						localStorage.value.set("setRecipient", cartStore.recipient);
					}
				} else {
					if (!props.preventSaving) {
						localStorage.value.set("guestRecipient", guestStore.recipient);
						localStorage.value.set("guestCartFulfillment", guestStore.fulfillmentMethod);
					}
				}

				emit("next");
			};

			const selectAddress = (addressCandidate) => {
				geocodePlace(addressCandidate.placeId).then((response) => {
					setSelectedAddress(response.results[0].geometry.location.lat(), response.results[0].geometry.location.lng(), addressCandidate.formattedAddress);
					showAddressForm.value = true;
				});
			};

			onMounted(() => {
				if (!guestStore.isGuest) {
					userStore.getUpdates().then(() => (loading.value = false));
				}
			});

			return {
				loading,
				searching,
				searchAddress,
				addresses,
				setAddress,
				showAddressForm,
				cartStore,
				locationState,
				selectAddress,
				userStore,
				guestStore,
				fulfillmentMethod,
				addressSearch,
				selectedLocation,
				selectCurrentLocation,
				selectAddressBook,
				selectManualLocation,
			};
		},
	});
</script>
