<template>
	<a
		@click="router.push(link)"
		class="group cursor-pointer fixed z-[1] bottom-16 right-12 flex items-center justify-between bg-white rounded px-4 py-4 min-w-[370px] max-w-[500px] shadow-[0_0_4px_4px_rgba(0,0,0,0.15)]">
		<div class="flex items-center space-x-3">
			<img
				src="/assets/icon/ic-order-status-signage.svg"
				class="object-contain w-[35px] h-[35px] flex-shrink-0"
				alt="icon" />
			<div class="w-full">
				<p class="text-primary-500 font-semibold">{{ title }}</p>
				<p class="text-primary-300 text-sm">{{ description }}</p>
			</div>
		</div>
		<ChevronRightIcon class="w-5 h-5 text-primary-500 ml-5 group-hover:text-primary-200 transition" />
	</a>
</template>
<script>
	import { defineComponent } from "vue";
	import { ChevronRightIcon } from "@heroicons/vue/solid";
	import { useRouter } from "vue-router";

	export default defineComponent({
		components: {
			ChevronRightIcon,
		},
		props: {
			title: {
				type: String,
				default: null,
			},
			description: {
				type: String,
				default: null,
			},
			link: {
				type: String,
				default: null,
			},
		},
		setup() {
			const router = useRouter();

			return {
				router,
			};
		},
	});
</script>
