<template>
	<anchor-link customClass="w-full">
		<template #content>
			<div class="border rounded-xl p-6 w-full">
				<div class="flex items-center justify-between">
					<div class="flex flex-col items-start">
						<p class="text-base font-bold text-primary-500 mx-0">
							{{ typeTitle }}
						</p>
						<p class="text-xs font-bold text-primary-500 mt-1 mx-0">
							{{ renderCartStatus }}
						</p>
						<p class="text-xs font-normal text-primary-200 mt-2 mx-0">
							{{ order.items.length }} Item(s), {{ typeTitle }}
							<span
								v-if="order.address"
								class="font-bold">
								{{ order.address.name }}
							</span>
						</p>
					</div>
					<img :src="typeIcon" />
				</div>
			</div>
		</template>
	</anchor-link>
</template>
<script>
	import { defineComponent, computed } from "vue";
	import AnchorLink from "@/components/AnchorLink.vue";

	export default defineComponent({
		components: {
			AnchorLink,
		},
		props: {
			order: {
				type: Object,
				required: true,
			},
		},
		setup(props) {
			const renderCartStatus = computed(() => {
				if (props.order.status === 0) {
					return "Order not place yet";
				} else if (props.order.status === 3) {
					return "Payment is being processed";
				} else {
					return "Order not place yet";
				}
			});

			const typeIcon = computed(() => {
				let icon;

				if (props.order.fulfillment_method == "Pick-up") {
					icon = "/assets/image/pickup.svg";
				}

				if (props.order.fulfillment_method == "Dine-in") {
					icon = "/assets/image/dinein.svg";
				}

				if (props.order.fulfillment_method == "Delivery") {
					icon = "/assets/image/delivery.svg";
				}

				return icon;
			});

			const typeTitle = computed(() => {
				let title;

				if (props.order.fulfillment_method == "Pick-up") {
					title = "Pick-up from";
				}

				if (props.order.fulfillment_method == "Dine-in") {
					title = "Dine-in at";
				}

				if (props.order.fulfillment_method == "Delivery") {
					title = "Delivery to";
				}

				return title;
			});

			return {
				renderCartStatus,
				typeIcon,
				typeTitle,
			};
		},
	});
</script>
