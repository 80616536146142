<template>
	<ion-page>
		<top-navigation @showTimeslot="showBranchTimeslot = true"></top-navigation>

		<ion-content :fullscreen="true">
			<div
				class="page-offset"
				v-if="branch">
				<div class="px-4 pb-4 lg:p-0">
					<fulfillment-information
						class="flex lg:hidden cursor-pointer mt-4"
						@showTimeslot="showBranchTimeslot = true" />
				</div>

				<section>
					<div class="relative w-full">
						<img
							:src="branch.image_path"
							class="w-full h-[188px] lg:h-[25rem] object-cover" />
						<div class="block lg:hidden absolute right-0 bottom-0 bg-white px-3 py-2 m-4 rounded-full shadow-lg">
							<p class="text-primary-900 text-xs font-bold">Call {{ branch.contact_number }}</p>
						</div>
					</div>
				</section>

				<section class="px-4 lg:px-20 mt-6">
					<div class="mt-6 space-y-2">
						<h3 class="text-2xl text-primary-900 font-bold">
							{{ branch.name }}
						</h3>
						<p class="text-sm text-primary-400">
							{{ branch.address }}
							<span class="text-primary-900 font-bold text-xs ml-4">{{ branch.distance.toFixed(2) }} km</span>
						</p>
					</div>

					<div class="flex items-start justify-between gap-16 my-6 lg:my-14">
						<div class="flex-1 w-[65vw]">
							<ion-spinner v-if="loading" />
							<template v-else>
								<!-- TABS -->
								<div class="border-b border-gray-200">
									<fun-tabs
										v-model="tab"
										active-color="#000000"
										line-width="auto"
										:line-height="2"
										class="frm-slider frm-slider__category overflow-auto">
										<fun-tab-item
											v-if="setBundles.length > 0"
											:name="0"
											title="PROMOS"
											class="text-sm mx-4 px-0 pt-3 cursor-pointer"
											:class="[tab == 0 ? 'font-semibold text-black' : 'text-gray-500']"
											@click="goto('setBundle', 0)" />

										<fun-tab-item
											v-if="bestSellers.length > 0"
											:name="setBundles.length > 0 ? 1 : 0"
											title="BEST SELLERS"
											class="text-sm mx-4 px-0 pt-3 cursor-pointer"
											:class="[tab == (setBundles.length > 0 ? 1 : 0) ? 'font-semibold text-black' : 'text-gray-500']"
											@click="goto('bestSeller', setBundles.length > 0 ? 1 : 0)" />

										<fun-tab-item
											:name="setBundles.length > 0 && bestSellers.length > 0 ? index + 2 : setBundles.length > 0 || bestSellers.length > 0 ? index + 1 : index"
											:title="category.name"
											v-for="(category, index) in categories"
											:key="category"
											class="text-sm mx-4 px-0 pt-3 cursor-pointer"
											:class="[tab == (setBundles.length > 0 && bestSellers.length > 0 ? index + 2 : setBundles.length > 0 || bestSellers.length > 0 ? index + 1 : index) ? 'font-semibold text-black' : 'text-gray-500']"
											@click="goto('category' + index, setBundles.length > 0 && bestSellers.length > 0 ? index + 2 : setBundles.length > 0 || bestSellers.length > 0 ? index + 1 : index)" />
									</fun-tabs>
								</div>

								<!-- SEARCH -->
								<div class="mt-6 w-[340px] ml-auto">
									<text-input
										id="search"
										type="search"
										placeholder="Search our menu"
										class="w-full h-12"
										v-model="search" />
								</div>

								<!-- PRODUCTS -->
								<div v-if="setBundles?.length > 0 || bestSellers.length > 0 || categories?.length > 0">
									<!-- Promos -->
									<div
										class="py-6 border-b"
										v-if="setBundles.length > 0"
										ref="setBundle">
										<h4 class="text-md text-primary-900 font-semibold mb-4">PROMOS</h4>
										<div class="grid grid-cols-2 gap-3 lg:gap-6">
											<template
												v-for="bundle in setBundles"
												:key="bundle">
												<bundle-item-card
													:bundle="bundle"
													@click="showSetBundle(bundle)" />
											</template>
										</div>
									</div>

									<!-- Best Sellers -->
									<div
										class="py-6 border-b"
										v-if="bestSellers.length > 0"
										ref="bestSeller">
										<h4 class="text-md text-primary-900 font-semibold mb-4">BEST SELLERS</h4>
										<div class="grid grid-cols-2 gap-3 lg:gap-6">
											<template
												v-for="product in bestSellers"
												:key="product">
												<product-item-card
													:product="product"
													@click="showProduct(product)" />
											</template>
										</div>
									</div>

									<!-- Categories -->
									<div
										class="py-6 border-b"
										v-for="(category, index) in categories"
										:key="category"
										:ref="'category' + index">
										<h4 class="text-md text-primary-900 font-semibold mb-4">
											{{ category.name }}
										</h4>

										<!-- SUBCATEGORIES -->
										<div
											v-for="subcategory in category.children"
											:key="subcategory"
											:ref="'category-subcategory-' + subcategory.id"
											class="mb-6">
											<h4 class="text-md text-primary-900 font-semibold mb-4 flex items-center">
												<img
													src="/assets/icon/ic-arrow-right.svg"
													alt="icon"
													class="w-2.5 h-2.5 mr-2.5" />

												{{ subcategory.name }}
											</h4>
											<div class="grid grid-cols-2 gap-3 lg:gap-6">
												<template
													v-for="product in subcategory.products"
													:key="product">
													<product-item-card
														:product="product"
														@click="showProduct(product)" />
												</template>
											</div>
										</div>

										<!-- OTHER PRODUCTS / MAIN PRODUCTS (if no subcategories) -->
										<h4
											class="text-md text-primary-900 font-semibold mb-4 flex items-center"
											v-if="category.children.length > 0 && category.products.length > 0">
											<img
												src="/assets/icon/ic-arrow-right.svg"
												alt="icon"
												class="w-2.5 h-2.5 mr-2.5" />

											Others
										</h4>
										<div class="grid grid-cols-2 gap-3 lg:gap-6">
											<template
												v-for="product in category.products"
												:key="product">
												<product-item-card
													:product="product"
													@click="showProduct(product)" />
											</template>
										</div>
									</div>
								</div>
								<div
									v-else
									class="mt-10">
									<p class="text-center">No products found.</p>
								</div>
							</template>
						</div>

						<!-- Summary -->
						<div
							class="flex-initial w-[35vw] hidden lg:block"
							ref="category">
							<h4 class="text-sm text-primary-900 font-bold">Orders</h4>
							<div class="flex flex-col gap-2 pb-6 border-b mt-2">
								<template v-if="cartStore.items.length > 0">
									<order-item-card
										v-for="item in cartStore.items"
										:key="item"
										:item="item"
										@update:quantity="(value) => (item.new_quantity = value)" />
								</template>
								<span
									class="text-sm text-primary-400"
									v-else>
									No items added yet.
								</span>
							</div>
							<div class="mt-6">
								<div class="pb-6 border-b space-y-2">
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">Subtotal</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.subtotal" />
										</p>
									</div>
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">VATable Sale</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.vatableSale" />
										</p>
									</div>
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">VAT (12%)</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.vat" />
										</p>
									</div>
									<div
										class="flex items-center justify-between"
										v-if="cartStore.serviceCharge > 0">
										<p class="text-sm text-primary-400 m-0">Service Charge</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.serviceCharge" />
										</p>
									</div>
								</div>
								<div
									class="mt-6"
									v-if="cartStore.items.length > 0 && !guestStore.isGuest">
									<button-component
										@click="router.push('checkout')"
										expand="block"
										color="primary">
										<template #content>Checkout</template>
									</button-component>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div class="fixed bottom-0 bg-white p-4 w-full block lg:hidden">
					<button-component
						@click="router.push('checkout')"
						expand="block"
						color="primary">
						<template #content>
							<div class="flex items-center justify-between w-full">
								<p class="text-sm text-white font-normal m-0">
									{{ cartStore.items.length }}
									{{ cartStore.items.length === 1 ? "item" : "items" }}
								</p>
								<p class="text-sm text-white font-bold m-0">
									Checkout (
									<render-price :value="cartStore.grandTotal" />
									)
								</p>
							</div>
						</template>
					</button-component>
				</div>
			</div>

			<set-bundle-modal
				v-if="setBundleDetail"
				:is-visible="showSetBundleModal"
				@close="
					{
						showSetBundleModal = false;
						setBundleDetail = null;
					}
				"
				:bundle="setBundleDetail"
				@add:order="addBundleToCart" />

			<product-modal
				v-if="productDetail"
				:is-visible="showProductModal"
				@close="closeProductModal()"
				:product="productDetail"
				@add:order="addToCart" />

			<bottom-navigation />

			<toast-message
				:show="showToast"
				:message="toastMessage"
				@didDismiss="showToast = false" />
		</ion-content>

		<branch-timeslots
			v-if="selectedBranch"
			:branch="selectedBranch"
			:is-visible="showBranchTimeslot"
			@close="showBranchTimeslot = false" />

		<generic-modal
			:no-close-icon="true"
			:is-visible="showLoginModal"
			@close="showLoginModal = false">
			<template #content>
				<div class="px-4 pb-4 lg:px-12 lg:pb-12 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold">You must login to proceed</p>
						<p class="text-sm text-primary-400 font-normal">An account is needed to checkout an order.</p>
					</div>
					<div class="flex items-center justify-end gap-2 mt-8">
						<button-component
							@click="showLoginModal = false"
							color="secondary">
							<template #content>Cancel</template>
						</button-component>
						<button-component
							@click="
								() => {
									router.push('login-option');
									showLoginModal = false;
								}
							"
							color="primary">
							<template #content>Okay</template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>

        <!-- PRODUCT FROM HOMEPAGE PROMO BANNER IS UNAVAILABLE MODAL -->
        <generic-modal :no-close-icon="true" :is-visible="unavailableModal" @close="unavailableModal = false">
			<template #content>
				<div class="px-4 pb-4 lg:px-12 lg:pb-12 w-full lg:w-[407px] h-auto">
					<img
						src="/assets/icon/ic-sad.svg"
						alt="sad_icon"
						class="w-8 h-8 object-contain mx-auto mb-3"
					>
					<div class="space-y-2 text-center">
						<p class="text-xl text-primary-900 font-semibold">Sorry</p>
						<p class="text-sm text-primary-400">The promotional product is not available on this branch for this transaction.</p>
					</div>
					<div class="w-full mt-8">
						<button-component @click="unavailableModal = false" color="primary" expand="block">
							<template #content> Okay </template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</ion-page>
</template>
<script>
	import { computed, defineComponent, onMounted, ref, watch } from "vue";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import OrderItemCard from "@/components/OrderItemCard.vue";
	import ProductItemCard from "./Components/ProductItemCard.vue";
	import BundleItemCard from "./Components/BundleItemCard.vue";
	import ProductModal from "./Components/ProductModal.vue";
	import SetBundleModal from "./Components/SetBundleModal.vue";
	import FulfillmentInformation from "@/components/FulfillmentInformation.vue";
	import { IonPage, IonContent, IonSpinner, modalController, onIonViewWillLeave } from "@ionic/vue";
	import { FunTabs, FunTabItem } from "fun-tab";
	import "fun-tab/dist/index.css";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import RenderPrice from "@/components/RenderPrice.vue";
	import ToastMessage from "@/components/ToastMessage.vue";
	import { useApiStore } from "@/store/api";
	import { useCartStore } from "@/store/cart";
	import useModal from "@/composables/modal";
	import useLocalStorage from "@/composables/useLocalStorage";
	import { useRouter } from "vue-router";
	import BranchTimeslots from "./Components/BranchTimeslots.vue";
	import { useGuestStore } from "@/store/guest";
	import GenericModal from "@/components/GenericModal.vue";
	import FreeItemModal from "@/components/FreeItemModal.vue";
	import TextInput from "@/components/TextInput.vue";
    import debounce from "lodash/debounce";
    import { useUserStore } from "@/store/user";

	export default defineComponent({
		name: "BranchView",
		components: {
			IonPage,
			IonContent,
			IonSpinner,
			TopNavigation,
			BottomNavigation,
			OrderItemCard,
			ProductItemCard,
			BundleItemCard,
			ProductModal,
			SetBundleModal,
			FulfillmentInformation,
			ButtonComponent,
			RenderPrice,
			ToastMessage,
			FunTabs,
			FunTabItem,
			BranchTimeslots,
			GenericModal,
			TextInput,
		},
		props: {
			showTimeSlotModal: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			goto(refName, index) {
				const [el] = this.$refs[refName];
				if (el) {
					el.scrollIntoView({ behavior: "smooth" });
					this.tab = index;
				}
			},
		},
		setup(props) {
			const router = useRouter();
			const apiStore = useApiStore();
            const userStore = useUserStore();
			const guestStore = useGuestStore();
			const cartStore = useCartStore();
			const modal = useModal();
			const { localStorage } = useLocalStorage();

			const branch = computed(() => {
				return guestStore.isGuest ? guestStore.branch : cartStore.branch;
			});

            const promoCode = computed(() => userStore.getPromoBanner?.code ?? null);

			const loading = ref(true);
			const productDetail = ref(null);
			const showProductModal = ref(false);

			const setBundleDetail = ref(null);
			const showSetBundleModal = ref(false);

			const showToast = ref(false);
			const toastMessage = ref(null);

			const setBundles = ref([]);
			const bestSellers = ref([]);
			const categories = ref([]);

			const tab = ref(0);
			const selectedBranch = ref(null);
			const showBranchTimeslot = ref(false);
			const showLoginModal = ref(false);
			const search = ref(null);
            const unavailableModal = ref(false);

			// cartStore.$subscribe(
			// 	(value) => {
			// 		if (value.events?.target?.branch) {
			// 			fetchCategories(value.events.target.branch);
			// 			selectedBranch.value = value.events.target.branch;
			// 		}
			// 	},
			// 	{ detached: true }
			// );

			const fetchCategories = (branch, search) => {
				localStorage.value.get("user").then((value) => {
					let route;

					if (value) {
						route = apiStore.route("productsWithCustomer");
					} else {
						route = apiStore.route("products");
					}

					window.axios
						.get(route, {
							params: {
								method: guestStore.isGuest ? guestStore.fulfillmentMethod : cartStore.fulfillmentMethod,
								branch: branch.id,
								query: search,
							},
						})
						.then(async(result) => {
							loading.value = false;

							setBundles.value = result.data.setBundles;
							bestSellers.value = result.data.bestSellers;
							categories.value = result.data.categories;

                            if (promoCode.value) {
                                await findPromoProduct(setBundles.value);
                                await findPromoProduct(bestSellers.value);
                                await findPromoProduct(categories.value);
                            }
						})
						.catch((error) => {
							loading.value = false;
							modal.error(error.response.data.message);
						});
				});
			};

			const showSetBundle = (bundle) => {
				setBundleDetail.value = bundle;
				showSetBundleModal.value = true;
			};

			const showProduct = (product) => {
				productDetail.value = product;
				showProductModal.value = true;
			};

            const findPromoProduct = async (items) => {
                if (!items.length) return;

                const product = items?.flatMap(item => item?.products).find(product => product?.code === promoCode.value);
                
                if (promoCode.value && !product) {
                    unavailableModal.value = true;
                }

                showProduct(product);
            }

            const closeProductModal = () => {
                showProductModal.value = false;
                productDetail.value = null;
                userStore.promoBanner = null;
            }

			const freeItemModal = async (freeItem) => {
				const modal = await modalController.create({
					component: FreeItemModal,
					canDismiss: true,
					backdropDismiss: true,
					cssClass: "gen-modal points",
					componentProps: {
						product: freeItem.get_product.name,
						minimum: freeItem.for_every_amount,
					},
				});

				return modal.present();
			};

			const addBundleToCart = (form) => {
				if (guestStore.isGuest) {
					showLoginModal.value = true;
				} else {
					window.axios
						.put(apiStore.route("cartAddBundle", { id: cartStore.id }), form)
						.then((result) => {
							// save to cart items
							if (result.data.new) {
								cartStore.items.push(result.data.item);
							} else {
								cartStore.items = cartStore.items.map((value) => {
									if (value.id === result.data.item.id) {
										value = result.data.item;
									}

									return value;
								});
							}

							// update active cart
							localStorage.value.set("activeCart", result.data.cart);

							showToast.value = true;
							toastMessage.value = "Order has been added.";

							const newFreeItems = cartStore.registerFreeItems(result.data.freeItems);

							newFreeItems.forEach((freeItem) => {
								freeItemModal(freeItem);
							});
						})
						.catch((error) => {
							modal.error(error.response.data.message);
						});
				}
			};

			const addToCart = (form) => {
				if (guestStore.isGuest) {
					showLoginModal.value = true;
				} else {
					window.axios
						.put(apiStore.route("cartAdd", { id: cartStore.id }), form)
						.then((result) => {
							// save to cart items
							if (result.data.new) {
								cartStore.items.push(result.data.item);
							} else {
								cartStore.items = cartStore.items.map((value) => {
									if (value.id === result.data.item.id) {
										value = result.data.item;
									}

									return value;
								});
							}

							// update active cart
							localStorage.value.set("activeCart", result.data.cart);

							showToast.value = true;
							toastMessage.value = "Order has been added.";
                            userStore.promoBanner = null;

							const newFreeItems = cartStore.registerFreeItems(result.data.freeItems);

							newFreeItems.forEach((freeItem) => {
								freeItemModal(freeItem);
							});
						})
						.catch((error) => {
							modal.error(error.response.data.message);
						});
				}
			};

			watch(
				search,
				debounce((newValue) => {
                    loading.value = true
					fetchCategories(branch.value, newValue);
				}, 700)
			);

			watch(branch, (value) => {
				if (value) {
					fetchCategories(value);
					selectedBranch.value = value;
					showBranchTimeslot.value = props.showTimeSlotModal;
				}
			});

			onMounted(() => {
				if (branch.value) {
                    selectedBranch.value = branch.value;
					showBranchTimeslot.value = props.showTimeSlotModal;

                    fetchCategories(branch.value);
				}
			});

            onIonViewWillLeave(() => {
                if (promoCode.value) {
                    userStore.promoBanner = null;
                }
            });

			return {
				guestStore,
				router,
				loading,
				tab,
				branch,
				showLoginModal,
				setBundles,
				bestSellers,
				categories,
				productDetail,
				showProductModal,
				showProduct,
				setBundleDetail,
				showSetBundleModal,
				showSetBundle,
				cartStore,
				addBundleToCart,
				addToCart,
				showToast,
				toastMessage,
				selectedBranch,
				showBranchTimeslot,
				search,
                closeProductModal,
                unavailableModal
			};
		},
	});
</script>
