<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true" class="ion-padding">
			<form ref="form" @submit.prevent="submit" class="h-full flex flex-wrap items-start pt-8">
				<ion-col size="8" class="px-8 mx-auto mt-5">
					<h3 class="font-semibold mb-2 mt-8">New Password</h3>
					<p class="text-sm text-primary-400 mb-8">Kindly provide your new password.</p>

					<div class="relative">
						<input
							v-model="new_password"
							:type="showNewPassword ? 'text' : 'password'"
							required
							name="new_password"
							placeholder="New Password"
							class="
								focus:ring-primary-500 focus:border-primary-500
								block
								w-full
								border-primary-50
								rounded-lg
								mb-2
								placeholder:text-primary-100
							"
						/>
						<div
							@click="showNewPassword = !showNewPassword"
							class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]"
						>
							<template v-if="!showNewPassword">
								<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
							</template>
							<template v-else>
								<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
							</template>
						</div>
					</div>

					<div class="relative">
						<input
							v-model="confirm_password"
							:type="showConfirmPassword ? 'text' : 'password'"
							required
							name="confirm_password"
							placeholder="Confirm Password"
							class="
								focus:ring-primary-500 focus:border-primary-500
								block
								w-full
								border-primary-50
								rounded-lg
								mb-2
								placeholder:text-primary-100
							"
						/>
						<div
							@click="showConfirmPassword = !showConfirmPassword"
							class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]"
						>
							<template v-if="!showConfirmPassword">
								<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
							</template>
							<template v-else>
								<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
							</template>
						</div>
					</div>
				</ion-col>
				<ion-col size="8" class="mt-auto text-center p-0 mx-auto">
					<ion-button type="submit" class="btn-primary" shape="round" size="large" expand="full">
						<div v-if="!loading">Submit</div>
						<ion-spinner v-else></ion-spinner>
					</ion-button>
				</ion-col>
			</form>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonCol, IonPage, IonContent, IonSpinner, modalController, IonButton } from "@ionic/vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import Modal from "@/components/Modal.vue";
import { ref } from "@vue/reactivity";
import { useUserStore } from "@/store/user";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";

export default {
	name: "ForgotPassword",
	components: {
		TopNavigation,
		IonContent,
		IonPage,
		IonSpinner,
		IonCol,
		IonButton,
		EyeIcon,
		EyeOffIcon,
	},

	setup() {
		const new_password = ref(null);
		const confirm_password = ref(null);
		const loading = ref(false);
		const errors = ref(null);
		const store = useUserStore();
		const userEmail = ref(store.userEmail);
		const message = ref(null);

		const showNewPassword = ref(false);
		const showConfirmPassword = ref(false);

		const errorModal = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Warning!",
					description: errors.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const openModal = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Success!",
					description: message.value,
					imageLink: "/assets/icons/ic-success.svg",
					imageWidth: "80",
					imageHeight: "78",
					buttonText: "Okay",
					buttonLink: "/login-option",
				},
			});

			return modal.present();
		};

		const submit = (event) => {
			loading.value = true;
			const form = event.target;
			const params = new FormData(form);
			params.append("email", userEmail.value);

			window.axios
				.post(process.env.VUE_APP_API_URL + "/api/reset-password/", params)
				.then((result) => {
					loading.value = false;
					message.value = result.data.message;
					openModal();
				})
				.catch((error) => {
					loading.value = false;
					errors.value = error.response.data.message;
					errorModal();
				});
		};

		return {
			new_password,
			confirm_password,
			loading,
			submit,
			showNewPassword,
			showConfirmPassword,
		};
	},
};
</script>