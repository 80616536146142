<template>
	<div
		class="fixed top-0 w-full lg:w-[25rem] bg-white h-[100dvh] z-[100] px-6 py-8 transition-all duration-500"
		:class="[open ? 'right-0' : '-right-[200%]']">
		<div class="relative h-full">
			<XIcon
				@click="closeThis"
				class="cursor-pointer absolute top-0 right-0 w-5 h-5" />

			<div class="pt-14">
				<h3 class="text-[28px] text-primary-900 font-bold">{{ cartStore.fulfillmentMethod }} Schedule</h3>
			</div>

			<div class="py-8 px-2 h-[60svh] overflow-y-auto scrollbar-style">
				<RadioGroup
					v-model="selectedSchedule"
					class="mt-2">
					<div class="grid grid-cols-2 gap-3">
						<RadioGroupOption
							as="template"
							v-for="timeslot in timeslots"
							:key="timeslot"
							:value="timeslot"
							:disabled="isPast(timeslot)"
							v-slot="{ active, checked }">
							<div
								:class="[
									!isPast(timeslot) ? 'cursor-pointer focus:outline-none' : 'opacity-25 pointer-events-none bg-primary-100 text-primary-800',
									active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
									checked || selectedSchedule
										? selectedSchedule.id === timeslot.id
											? 'bg-primary-500 border-transparent text-white hover:bg-primary-700'
											: 'bg-white border-gray-200 text-primary-400 hover:bg-gray-50'
										: 'bg-white border-gray-200 text-primary-400 hover:bg-gray-50',
									'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase flex-1 whitespace-nowrap',
								]">
								<RadioGroupLabel
									as="span"
									class="text-sm font-bold">
									{{ timeslot.start_time_formatted }} -
									{{ timeslot.end_time_formatted }}
								</RadioGroupLabel>
							</div>
						</RadioGroupOption>
					</div>
				</RadioGroup>
			</div>
			<div class="absolute bottom-0 w-full">
				<button-component
					@click="save"
					color="primary"
					expand="block"
					:disabled="!selectedSchedule">
					<template #content>
						<ion-spinner v-if="loading"></ion-spinner>
						<template v-else>Continue</template>
					</template>
				</button-component>
			</div>
		</div>
	</div>

	<div
		class="fixed inset-0 w-full h-full bg-primary-900/50 z-[90] transition-all duration-500"
		:class="[open ? 'block' : 'hidden']"
		@click="closeThis"></div>
</template>
<script>
	import { defineComponent, computed, onMounted, ref, watch } from "vue";
	import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
	import { XIcon } from "@heroicons/vue/solid";
	import { useRouter } from "vue-router";
	import { useCartStore } from "@/store/cart";
	import { useGuestStore } from "@/store/guest";
	import { useApiStore } from "@/store/api";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import { IonSpinner } from "@ionic/vue";
	import useModal from "@/composables/modal";
	import useLocalStorage from "@/composables/useLocalStorage";

	export default defineComponent({
		components: {
			XIcon,
			RadioGroup,
			RadioGroupLabel,
			RadioGroupOption,
			ButtonComponent,
			IonSpinner,
		},
		emits: ["close"],
		props: {
			branch: {
				type: Object,
				required: true,
			},
			isVisible: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const router = useRouter();
			const apiStore = useApiStore();
			const cartStore = useCartStore();
			const guestStore = useGuestStore();
			const modal = useModal();
			const { localStorage } = useLocalStorage();

			const loading = ref(false);
			const open = ref(false);

			const closeThis = () => {
				open.value = false;
			};

			const isPast = (timeslot) => {
				const now = new Date();
				const startDate = new Date();
				const endDate = new Date();

				const startObject = timeslot.start_time.split(":");
				const endObject = timeslot.end_time.split(":");

				// add 1 hour allowance
				now.setHours(now.getHours() + 1);

				startDate.setHours(startObject[0], startObject[1], startObject[2]);
				endDate.setHours(endObject[0], endObject[1], endObject[2]);

				if (timeslot.start_time === "23:00:00" && timeslot.end_time === "00:00:00") {
					endDate.setDate(endDate.getDate() + 1);
				}

				timeslot.isPast = endDate <= now;

				return timeslot.isPast;
			};

			watch(
				() => props.isVisible,
				(bool) => (open.value = bool),
				{
					immediate: true,
				}
			);

			watch(
				() => open.value,
				(bool) => {
					if (!bool) emit("close");
				}
			);

			const timeslots = ref(props.branch.timeslots);
			const selectedSchedule = ref(null);

			watch(
				() => selectedSchedule.value,
				(value) => {
					if (value) {
						if (isPast(value)) {
							selectedSchedule.value = timeslots.value.find((o) => o.isPast === false);
						}
					}
				}
			);

			const save = () => {
				if (guestStore.isGuest) {
					guestStore.branch = props.branch;
					guestStore.timeslot = selectedSchedule.value;

					localStorage.value.set("guestCartBranch", guestStore.branch);
					localStorage.value.set("guestCartTimeslot", guestStore.timeslot);

					router.push("/branch-view");
					closeThis();
				} else {
					loading.value = true;

					cartStore.branch = props.branch;
					cartStore.timeslot = selectedSchedule.value;

					const form = {
						fulfillment_method: cartStore.fulfillmentMethod,
						recipient: cartStore.recipient,
						branch: cartStore.branch.id,
						timeslot: cartStore.timeslot.id,
					};

					window.axios
						.put(apiStore.route("cartStore"), form)
						.then((result) => {
							loading.value = false;

							// save to localStorage the cart
							localStorage.value.set("activeCart", result.data.cart);
							cartStore.updateUserCart(result.data.cart);
							cartStore.fetchCart(result.data.cart);
                            
                            router.push("/branch-view");
							closeThis();
						})
						.catch((error) => {
							loading.value = false;
							modal.error(error.response.data.message);
						});
				}
			};

			const userTimeslot = computed(() => {
				if (guestStore.isGuest) {
					return guestStore.timeslot;
				} else {
					return cartStore.timeslot;
				}
			});

			const isCurrentBranch = () => {
				if (guestStore.isGuest) {
					return guestStore.branch && props.branch.id === guestStore.branch.id && guestStore.timeslot;
				} else {
					return cartStore.branch && props.branch.id === cartStore.branch.id && cartStore.timeslot;
				}
			};

			const setCurrentSchedule = () => {
				if (isCurrentBranch()) {
					if (isPast(userTimeslot.value) || timeslots.value.findIndex((obj) => obj.id === userTimeslot.value.id) === -1) {
						userTimeslot.value = null;
					} else {
						return userTimeslot.value;
					}
				}

				return timeslots.value.find((o) => o.isPast === false);
			};

			onMounted(() => {
				selectedSchedule.value = setCurrentSchedule();
			});

			return {
				loading,
				timeslots,
				selectedSchedule,
				open,
				closeThis,
				router,
				cartStore,
				isPast,
				save,
			};
		},
	});
</script>
