<template>
	<ion-page>
		<top-navigation :showButtons="false"></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="
						flex
						items-start
						lg:items-center
						justify-center
						min-h-[calc(100vh-72px)]
						lg:min-h-[calc(100vh-86px)]
					"
				>
					<div class="px-4 lg:px-0 w-full lg:w-[400px] mx-auto h-[calc(100vh-86px)] lg:h-auto">
						<div class="relative py-4 lg:py-0 h-full">
							<div class="relative">
								<div @click="() => router.push('/login-option')" class="relative pb-8 block lg:hidden">
									<XIcon class="cursor-pointer w-5 h-5 text-primary-500 absolute right-0" />
								</div>
							</div>
							<div>
								<h2 class="text-primary-900 font-semibold text-2xl lg:text-[28px]">
									Enter your credentials
								</h2>
								<p class="text-primary-900 text-sm">
									First, we need to verify if you have an existing account.
								</p>
							</div>
							<div class="mt-8">
								<text-input
									type="text"
									placeholder="Enter phone or email"
									v-model="credential"
									helper="912 456 789 or user@email.com"
								/>
							</div>
							<div class="mt-0 lg:mt-10 text-right absolute lg:relative bottom-4 w-full">
								<ion-button @click="validate" size="large" class="btn-primary w-full lg:w-auto">
									<template v-if="!loading"> Validate </template>
									<ion-spinner v-else></ion-spinner>
								</ion-button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import Modal from "@/components/Modal.vue";
import TextInput from "@/components/TextInput.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import { XIcon } from "@heroicons/vue/outline";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user";
import { useApiStore } from "@/store/api";
import { IonPage, IonContent, IonButton, IonSpinner, modalController } from "@ionic/vue";

export default defineComponent({
	name: "EnterCredentials",
	components: {
		IonPage,
		IonContent,
		IonButton,
		IonSpinner,
		TopNavigation,
		BottomNavigation,
		XIcon,
		TextInput,
	},
	setup() {
		const router = useRouter();
		const store = useUserStore();
		const loading = ref(false);
		const errors = ref();
		const apiStore = useApiStore();

		const credential = ref(null);

		const errorModal = async () => {
			loading.value = false;

			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Warning!",
					description: errors.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const validate = () => {
			loading.value = true;

			if (credential.value == null) {
				errors.value = "You must enter your email or mobile number";
				errorModal();
			} else {
				window.axios
					.post(apiStore.route("validate"), {
						val: credential.value,
					})
					.then((result) => {
						loading.value = false;

						if (result.data.value == 0) {
							store.setUserValidation(credential.value);
							router.replace("/create-account");
						} else {
							router.replace({
								path: "/login-account",
								query: {
									credential: credential.value,
								},
							});
						}
					})
					.catch((error) => {
						errors.value = error.response.data.errors.error[0];
						errorModal();
					});
			}
		};

		return {
			router,
			loading,
			credential,
			validate,
			errorModal,
		};
	},
});
</script>
