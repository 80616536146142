<template>
    <ion-page>

        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <ion-grid class="">
            
            <!-- Typography -->
            <ion-row>
                <ion-col size="12">
                    <ion-item-divider class="px-0">
                        <ion-label>
                            <h1 class="font-weight-extrabold text--primary">Typography | Font Face</h1>
                        </ion-label>
                    </ion-item-divider>
                </ion-col>
                <ion-col size="12" class="mb-8">
                    <h2 class="font-weight-bold">Inter</h2>
                    <div class="inlineBlock-parent">
                        <h1 class="align-b mr-2">H1</h1>
                        <h2 class="align-b mr-2">H2</h2>
                        <h3 class="align-b mr-2">H3</h3>
                        <h4 class="align-b mr-2">H4</h4>
                        <h5 class="align-b mr-2">H5</h5>
                        <h6 class="align-b mr-2">H6</h6>
                    </div>
                    <p class="font--21">
                        Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
                    </p>
                    <p>
                        Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
                    </p>
                    <p class="font--13">
                        Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
                    </p>
                    <p class="font--10">
                        Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
                    </p>
                    <small>
                        Small: Lorem ipsum dolor sit amet, consectetur adipisicing elit
                    </small>
                </ion-col>
                <ion-col size="12">
                    <ion-item-divider class="px-0">
                        <ion-label>
                            <h1 class="font-weight-extrabold text--primary">Typography | Font Size</h1>
                        </ion-label>
                    </ion-item-divider>
                </ion-col>
                <ion-col size="12" class="mb-8">
                    <h1 class="align-b mr-2 font-size--xx-large">XX-Large | 31px</h1>
                    <h1 class="align-b mr-2">H1 | 25px</h1>
                    <h2 class="align-b mr-2">H2 | 20px</h2>
                    <h3 class="align-b mr-2">H3 | 16px</h3>
                    <h4 class="align-b mr-2">H4 | 14px</h4>
                    <h5 class="align-b mr-2">H5 | 12px</h5>
                    <h6 class="align-b mr-2">H6 | 10px</h6>
                    <p class="mb-0 font--21">Paragraph | 21px</p>
                    <p class="mb-0">Paragraph | 16px</p>
                    <p class="mb-0 font--13">Paragraph 13px</p>
                    <p class="mb-0 font--10">Paragraph 10px</p>
                    <small>Small | 8px</small>
                </ion-col>
                <ion-col size="12">
                    <ion-item-divider class="px-0">
                        <ion-label>
                            <h1 class="font-weight-extrabold text--primary">Typography | Font Style</h1>
                        </ion-label>
                    </ion-item-divider>
                </ion-col>
                <ion-col size="12" class="mb-8">
                    <p class="font-weight-extrabold mb-2">Extra Bold</p>
                    <p class="font-weight-extrabold font-italic mb-2">
                        Extra Bold Italic
                    </p>
                    <p class="font-weight-bold mb-2">Bold</p>
                    <p class="font-weight-bold font-italic mb-2">Bold Italic</p>
                    <p class="font-weight-regular mb-2">Regular</p>
                    <p class="font-weight-regular font-italic mb-2">Regular Italic</p>
                </ion-col>
            </ion-row>

            <!-- Buttons -->
            <ion-row>
                <ion-col size="12">
                    <ion-item-divider class="px-0">
                        <ion-label>
                            <h1 class="font-weight-extrabold text--primary">Buttons</h1>
                        </ion-label>
                    </ion-item-divider>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="12">
                    <!-- Button Colors -->
                    <!-- 
                        Reference: https://www.figma.com/file/kk0NqxOChLOmHw1uHtxYhj/Ramen-Kuroda%3A-Mobile-App-v.1---April%2C-2022?node-id=410%3A1565
                    -->
                    <ion-button size="large" class="btn-primary">Primary</ion-button>
                    <ion-button size="large" class="btn-secondary">Secondary</ion-button>
                    <ion-button size="large" class="btn-secondary">Tertiary</ion-button>
                    <ion-button size="large" class="btn-blue">Blue</ion-button>
                    <ion-button size="large" class="btn-red">Red</ion-button>

                    <ion-button class="btn-primary" shape="round" size="large" fill="outline">Outline + Primary</ion-button>
                    <ion-button class="btn-secondary" shape="round" size="large" fill="outline">Outline + Secondary</ion-button>
                    <ion-button class="btn-secondary" shape="round" size="large" fill="outline">Outline + Tertiary</ion-button>
                </ion-col>
                
                <ion-col size="12" class="mb-8">

                    <ion-button class="btn-primary" size="large" shape="round" disabled>Disabled</ion-button>

                    <!-- Anchor -->
                    <ion-button shape="round" class="btn-link text--primary" href="#">Anchor Primary</ion-button>

                    <!-- Expand -->
                    <ion-button class="btn-primary" shape="round" size="large" expand="full">Full Button</ion-button>
                    <ion-button class="btn-primary" shape="round" size="large" expand="block">Block Button</ion-button>

                    <!-- Fill -->
                    <ion-button class="btn-primary" shape="round" size="large" expand="full" fill="outline">Outline + Full</ion-button>
                    <ion-button class="btn-secondary" shape="round" size="large" expand="block" fill="outline">Outline + Block</ion-button>

                    <!-- Icons -->
                    <ion-button shape="round" size="large">
                        <ion-icon slot="start" src="/assets/icons/ic-cart.svg" class="mr-3" name="cart"></ion-icon>
                        Left Icon
                    </ion-button>

                    <ion-button shape="round" size="large">
                        Right Icon
                        <ion-icon slot="end" src="/assets/icons/ic-cart.svg" class="ml-3" name="cart"></ion-icon>
                    </ion-button>

                    <ion-button shape="round" size="large" class="btn-tile">
                        <ion-icon slot="icon-only" src="/assets/icons/ic-cart.svg" name="cart"></ion-icon>
                    </ion-button>

                    <!-- Sizes -->
                    <ion-button shape="round" size="large">Large</ion-button>
                    <ion-button shape="round">Default</ion-button>
                    <ion-button shape="round" size="small">Small</ion-button>
                </ion-col>
            </ion-row>

            <!-- Forms -->
            <ion-row>
                <ion-col size="12">
                    <ion-item-divider class="px-0">
                        <ion-label>
                        <h1 class="font-weight-extrabold text--primary">Forms</h1>
                        </ion-label>
                    </ion-item-divider>
                </ion-col>
                <ion-col size="12" class="mt-4 mb-8">
                    <!-- <TextInput /> -->
                    <div class="mb-4">
                        <input type="email" name="email" id="email" class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg placeholder:text-primary-100" placeholder="you@example.com" />
                    </div>
                    <div class="mb-4 relative">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <img src="/assets/icon/favicon.png" width="20" alt="">
                        </div>
                        <input type="email" name="email" id="email" class="shadow-sm focus:ring-primary-500 pl-10 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg placeholder:text-primary-100" placeholder="you@example.com" />
                    </div>

                    <ion-item>
                        <ion-label>Toggle</ion-label>
                        <ion-toggle slot="end" checked></ion-toggle>
                    </ion-item>

                    <ion-item>
                        <ion-checkbox slot="start" checked mode="md"></ion-checkbox>
                        <ion-label>Checkbox</ion-label>
                    </ion-item>
                </ion-col>
            </ion-row>

            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import { defineComponent } from 'vue';
import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    IonLabel,
    IonButton,
    IonIcon
} from "@ionic/vue";
import TopNavigation from "@/partials/TopNavigation.vue";

export default defineComponent({
    name: 'style-sheet',
    components: {
        IonPage,
        IonContent,
        IonGrid,
        IonRow,
        IonCol,
        IonItemDivider,
        IonLabel,
        IonButton,
        IonIcon,
        TopNavigation
    },
});
</script>
