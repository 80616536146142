<template>
	<div
		class="rounded-lg border"
		:class="
			getBundleLowestStock === 0
				? 'pointer-events-none relative before:absolute before:block before:content before:inset-0 before:w-full before:h-full before:bg-neutral-500/40 before:z-50 before:rounded-lg'
				: 'cursor-pointer'
		"
	>
		<div class="flex flex-col lg:flex-row items-stretch h-full w-full">
			<div class="flex-none bg-primary-50 relative" v-if="bundle.image_path">
				<img :src="bundle.image_path" class="w-full lg:w-[146px] h-full object-cover" />
				<span
					class="absolute top-2 right-2 bg-error text-xs font-semibold px-3 py-1 rounded-3xl text-white"
					v-if="itemsPerProduct(bundle.id, 'SetBundle') > 0"
				>
					{{ itemsPerProduct(bundle.id, "SetBundle") }}
				</span>
			</div>
			<div class="relative flex-auto p-4 flex flex-col justify-between gap-6 w-full">
				<span
					class="absolute top-2 right-2 bg-error text-xs font-semibold px-3 py-1 rounded-3xl text-white"
					v-if="itemsPerProduct(bundle.id, 'SetBundle') > 0 && !bundle.image_path"
				>
					{{ itemsPerProduct(bundle.id, "SetBundle") }}
				</span>
				<div>
					<h4
						class="font-bold text-sm m-0 line-clamp-2"
						:class="getBundleLowestStock === 0 ? 'text-primary-200' : 'text-primary-500'"
					>
						{{ bundle.name }}
					</h4>
					<p
						class="text-xs m-0 line-clamp-2 mt-1"
						:class="getBundleLowestStock === 0 ? 'text-primary-100' : 'text-primary-300'"
					>
						{{ bundle.sections.map((section) => section.name).join(" + ") }}
					</p>
				</div>
				<div class="flex items-center justify-between">
					<h4
						class="flex-1 font-bold text-sm m-0"
						:class="getBundleLowestStock === 0 ? 'text-primary-200' : 'text-primary-500'"
					>
						<render-price :value="bundle.bundle_price" />
					</h4>
					<button-component
						customClass="icon-only"
						color="primary"
						:disabled="getBundleLowestStock === 0"
						v-if="getBundleLowestStock !== 0"
					>
						<template #content>
							<PlusIcon class="h-3 w-3 text-white" />
						</template>
					</button-component>
				</div>

				<p class="text-error font-bold text-sm m-0" v-if="getBundleLowestStock === 0">Not available</p>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent, computed } from "vue";
import { PlusIcon } from "@heroicons/vue/solid";
import RenderPrice from "@/components/RenderPrice.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useCartStore } from "@/store/cart";

export default defineComponent({
	components: {
		PlusIcon,
		RenderPrice,
		ButtonComponent,
	},
	props: {
		bundle: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const cartStore = useCartStore();

		const getBundleLowestStock = computed(() => {
			let lowestStock = null;

			props.bundle.sections.forEach((section) => {
				section.options.forEach((option) => {
					if (lowestStock === null || lowestStock > option.product.inventories[0].stock) {
						lowestStock = option.product.inventories[0].stock;
					}
				});
			});

			return lowestStock;
		});

		return {
			productPrice: cartStore.productPrice,
			itemsPerProduct: cartStore.itemsPerProduct,
			getBundleLowestStock,
		};
	},
});
</script>
