<template>
	<div class="flex items-center">
		<button
			class="
				bg-primary-500
				rounded-lg
				text-white text-center
				w-8
				h-8
				disabled:bg-white
				disabled:text-primary-100
				disabled:border
				disabled:border-solid
				disabled:border-primary-50
			"
			@click="
				() => {
					if (quantity > 0) {
						quantity--;
						$emit('update:modelValue', quantity);
					}
				}
			"
			:disabled="quantity <= 0"
		>
			<ion-icon :src="removeOutline" class="p-1 w-6 h-6"></ion-icon>
		</button>
		<input
			type="tel"
			class="border-0 w-12 text-center text-sm font-semibold focus:ring-0 p-0"
			readonly
			v-model="quantity"
			min="0"
			step="1"
			:max="max"
		/>
		<button
			class="
				bg-primary-500
				rounded-lg
				text-white text-center
				w-8
				h-8
				disabled:bg-white
				disabled:text-primary-100
				disabled:border
				disabled:border-solid
				disabled:border-primary-50
			"
			@click="
				() => {
					if (quantity < max) {
						quantity++;
						$emit('update:modelValue', quantity);
					}
				}
			"
			:disabled="quantity === max || disabled"
		>
			<ion-icon :src="addOutline" class="p-1 w-6 h-6"></ion-icon>
		</button>
	</div>
</template>
<script>
import { addOutline, removeOutline } from "ionicons/icons";
import { ref } from "@vue/reactivity";
import { IonIcon } from "@ionic/vue";

export default {
	name: "QuantitySelectorComponent",
	components: {
		IonIcon,
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			required: true,
			default: 20,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const quantity = ref(props.value);

		return {
			addOutline,
			removeOutline,
			quantity,
		};
	},
};
</script>