<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10">
			<TransitionChild
				as="template"
				enter="ease-in-out duration-500"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in-out duration-500"
				leave-from="opacity-100"
				leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 overflow-hidden">
				<div class="absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<TransitionChild
							as="template"
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enter-from="translate-x-full"
							enter-to="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leave-from="translate-x-0"
							leave-to="translate-x-full">
							<DialogPanel class="pointer-events-auto w-[348px]">
								<div class="flex h-full items-start flex-wrap w-full overflow-y-scroll bg-white py-6 shadow-xl">
									<div class="relative w-full px-12">
										<div class="flex items-center justify-between mb-8">
											<div>
												<!-- <div class="bg-primary-500 rounded-3xl px-3 py-1 flex items-center justify-center">
                                                <img src="/assets/icon/ic-star.svg" class="w-3.5 h-3.5 mr-2">
                                                <p class="text-success text-xs font-semibold">RK Prime Member</p>
                                            </div> -->
											</div>
											<button class="border-0 outline-none">
												<ion-icon :src="closeOutline" class="w-6 h-6" @click="closeThis"></ion-icon>
											</button>
										</div>
										<!-- Replace with your content -->
										<!-- <div class="mb-6">
                                        <anchor-link @click="redirect('prime-membership')">
                                            <template #content>
                                                <span class="text-sm font-semibold text-primary-500">RK Prime Membership</span>
                                            </template>
                                        </anchor-link>
                                    </div> -->
										<div class="mb-6">
											<anchor-link @click="redirect('wallet')">
												<template #content>
													<span class="text-sm font-semibold text-primary-500">RK Wallet</span>
												</template>
											</anchor-link>
										</div>
										<div class="mb-6">
											<anchor-link @click="redirect('points')">
												<template #content>
													<span class="text-sm font-semibold text-primary-500">RK Points</span>
												</template>
											</anchor-link>
										</div>
										<div class="mb-6 flex items-center justify-between">
											<anchor-link @click="redirect('order-history')">
												<template #content>
													<span class="text-sm font-semibold text-primary-500">Order History</span>
												</template>
											</anchor-link>
                                            <span v-if="toRateCount !== 0" class="w-6 h-6 bg-red-600 text-white rounded-full flex items-center justify-center text-xs mx-0 font-semibold cursor-default">{{ toRateCount }}</span>
										</div>
										<div class="mb-6">
											<anchor-link @click="redirect('edit-profile')">
												<template #content>
													<span class="text-sm font-semibold text-primary-500">Profile</span>
												</template>
											</anchor-link>
										</div>
										<div class="mb-12">
											<anchor-link @click="redirect('help-center')">
												<template #content>
													<span class="text-sm font-semibold text-primary-500">Help Center</span>
												</template>
											</anchor-link>
										</div>
										<div class="mb-6">
											<anchor-link @click="logout">
												<template #content>
													<span class="text-sm font-semibold text-error">Sign out</span>
												</template>
											</anchor-link>
										</div>
										<!-- /End replace -->
									</div>
									<div class="mt-auto px-12">
										<img src="/assets/image/rk-logo-1.png" alt="logo" width="48" class="mb-4" />
										<p class="font-semibold">More good things for you in our app!</p>
										<div class="flex mt-4">
											<a href="https://play.google.com/store/apps/details?id=com.praxxys.ramenkuroda" target="_blank">
												<img src="/assets/image/google-play-btn.png" alt="googleplay" width="120" class="mr-3" />
											</a>
											<a href="https://apps.apple.com/ph/app/ramen-kuroda-ph-app/id1631474055" target="_blank">
												<img src="/assets/image/app-store-btn.png" alt="appstore" width="120" />
											</a>
										</div>
									</div>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
	import { defineComponent, ref, watch } from "vue";
	import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
	import { IonIcon } from "@ionic/vue";
	import { closeOutline } from "ionicons/icons";
	import { useRouter } from "vue-router";
	import useAuth from "@/composables/useAuth.js";
	import AnchorLink from "@/components/AnchorLink.vue";

	export default defineComponent({
		components: {
			Dialog,
			DialogPanel,
			TransitionChild,
			TransitionRoot,
			IonIcon,
			AnchorLink,
		},
		emits: ["close"],
		props: {
			isVisible: {
				type: Boolean,
				default: false,
			},
            toRateCount: {
                type: Number, 
                default: 0
            }
		},
		setup(props, { emit }) {
			const router = useRouter();
			const open = ref(false);
			const { logout } = useAuth();

			const closeThis = () => {
				open.value = false;
			};

			const redirect = (link) => {
				closeThis();
				router.push(link);
			};

			watch(
				() => props.isVisible,
				(bool) => (open.value = bool),
				{
					immediate: true,
				}
			);

			watch(
				() => open.value,
				(bool) => {
					if (!bool) emit("close");
				}
			);

			return {
				open,
				closeThis,
				closeOutline,
				router,
				redirect,
				logout,
			};
		},
	});
</script>
