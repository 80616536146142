<template>
	<div class="p-4 lg:p-10 w-full lg:w-[450px] overflow-auto h-full">
		<div class="w-full h-0 pt-[100%] relative mb-6">
			<div
				class="absolute top-0 left-0 h-full bg-cover w-full bg-no-repeat bg-center rounded-xl"
				:style="`background-image: url('${item.image_path}')`"></div>
		</div>
		<h4 class="font-semibold mb-4">{{ item.name }}</h4>
		<p class="font-semibold text-sm mb-4">{{ transactions }}</p>

		<template v-if="item.type === 'bundle'">
			<div class="relative flex space-x-2 mb-3">
				<div class="flex-shrink-0">
					<ion-icon
						:src="checkmarkCircle"
						class="w-4 h-4"></ion-icon>
				</div>
				<div class="flex-1 min-w-0">
					<p class="text-sm text-primary-400">
						For only
						<span class="font-bold">
							<render-price :value="item.bundle_price" />
						</span>
					</p>
				</div>
			</div>
			<div class="relative flex space-x-2 mb-3">
				<div class="flex-shrink-0">
					<ion-icon
						:src="checkmarkCircle"
						class="w-4 h-4"></ion-icon>
				</div>
				<div class="flex-1 min-w-0">
					<p class="text-sm text-primary-400">
						Choice of
						<template
							v-for="(section, index) in item.sections"
							:key="index">
							<b>
								{{ converter.toWords(section.is_single ? 1 : section.max) }}
								({{ section.is_single ? 1 : section.max }})
							</b>
							{{ section.name + (index !== item.sections.length - 1 ? " + " : ".") }}
						</template>
					</p>

					<!-- <p class="text-sm text-primary-400" v-for="section in item.sections" :key="section">
						<b>{{ section.name }}</b>
						&#183; Choice of
						<span v-html="section.options.map((option) => option.product.name).join(', ')" />
					</p> -->
				</div>
			</div>
		</template>

		<template v-else-if="item.type === 'free_item'">
			<div class="relative flex space-x-2 mb-3">
				<div class="flex-shrink-0">
					<ion-icon
						:src="checkmarkCircle"
						class="w-4 h-4" />
				</div>
				<div class="flex-1 min-w-0">
					<p class="text-sm text-primary-400">
						<b>Free 1</b>
						order of {{ item.get_product?.name }} for
						<template v-if="item.requirement_type === 'item'">
							<template v-if="item.participating_product === 1">all products</template>
							<template v-else-if="item.participating_product === 2">each products under category: {{ item.categories.map((category) => category.name).join(", ") }}</template>
							<template v-else-if="item.participating_product === 3">each: {{ item.products.map((product) => product.name).join(", ") }}</template>
						</template>
						<template v-else-if="item.requirement_type === 'amount'">
							orders worth
							<render-price :value="item.for_every_amount" />
						</template>
					</p>
				</div>
			</div>
		</template>

		<div class="relative flex space-x-2 mb-3">
			<div class="flex-shrink-0">
				<ion-icon
					:src="checkmarkCircle"
					class="w-4 h-4" />
			</div>
			<div class="flex-1 min-w-0">
				<p class="text-sm text-primary-400">
					<template v-if="item.for_male_only && item.for_female_only">All Customers</template>
					<template v-else-if="item.for_male_only">All Male Customers</template>
					<template v-else-if="item.for_female_only">All Female Customers</template>
				</p>
			</div>
		</div>

		<div class="relative flex space-x-2 mb-3">
			<div class="flex-shrink-0">
				<ion-icon
					:src="checkmarkCircle"
					class="w-4 h-4" />
			</div>
			<div class="flex-1 min-w-0">
				<p class="text-sm text-primary-400">Applicable for customers under tier: {{ tierLevels }}</p>
			</div>
		</div>

		<div class="relative flex space-x-2 mb-3">
			<div class="flex-shrink-0">
				<ion-icon
					:src="checkmarkCircle"
					class="w-4 h-4"></ion-icon>
			</div>
			<div class="flex-1 min-w-0">
				<p class="text-sm text-primary-400">
					<template v-if="item.start_datetime && item.end_datetime">Available on {{ item.start_datetime_formatted }} to {{ item.end_datetime_formatted }} only.</template>
					<template v-else>Available anytime.</template>
				</p>
			</div>
		</div>

		<div>
			<div class="border-t border-primary-50 my-6"></div>
			<template v-if="item.branches.length !== 0">
				<p class="font-semibold mb-4">List of Branches</p>
				<div
					class="relative flex items-start space-x-4 mb-3"
					v-for="(branch, index) in item.branches"
					:key="index">
					<div class="flex-shrink-0">
						<img
							class="h-[64px] w-[64px] rounded-lg"
							:src="branch.branch.image_path"
							alt="" />
					</div>
					<div class="flex-1 min-w-0">
						<p class="text-sm font-semibold text-primary-500 truncate mb-2">
							{{ branch.branch.name }}
						</p>
						<p class="text-xs text-primary-200 line-clamp-2">
							{{ branch.branch.address }}
						</p>
					</div>
				</div>
			</template>
			<template v-else>
				<p class="font-semibold mb-4">Applies to all Branches</p>
			</template>
		</div>
	</div>
</template>
<script>
	import { defineComponent, computed } from "vue";
	import { IonIcon } from "@ionic/vue";
	import { checkmarkCircle } from "ionicons/icons";
	import RenderPrice from "@/components/RenderPrice.vue";
	import converter from "number-to-words";

	export default defineComponent({
		components: {
			IonIcon,
			RenderPrice,
		},
		props: {
			item: {
				type: Object,
				required: true,
			},
		},
		setup(props) {
			const transactions = computed(() => {
				let list = [];

				if (props.item.for_delivery) {
					list.push("Delivery");
				}

				if (props.item.for_pick_up) {
					list.push("Pick-up");
				}

				if (props.item.for_dine_in) {
					list.push("Dine-in");
				}

				const last = list.pop();

				return (list.length ? list.join(", ") + " & " + last : last) + " transaction only";
			});

			const tierLevels = computed(() => {
				const list = [];

				if (props.item.for_regular) {
					list.push("Regular");
				}

				if (props.item.for_silver) {
					list.push("Silver");
				}

				if (props.item.for_gold) {
					list.push("Gold");
				}

				if (props.item.for_platinum) {
					list.push("Platinum");
				}

				if (props.item.for_diamond) {
					list.push("Diamond");
				}

				return list.join(", ");
			});

			return {
				transactions,
				tierLevels,
				checkmarkCircle,
				converter,
			};
		},
	});
</script>
