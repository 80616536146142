<template>
	<ion-page>
		<top-navigation backButton backButtonHref="/menu"></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="h-full relative max-w-auto px-4 md:px-0 md:max-w-[408px] m-auto pt-10 pb-[54px] md:pb-[160px]">
					<div class="pb-8">
						<ArrowLeftIcon
							class="w-7 h-7 mb-4 md:mb-8 cursor-pointer"
							@click="router.push('/edit-profile')" />
						<div>
							<h3 class="text-xl md:text-2xl font-semibold mb-8">Delete Account</h3>
						</div>

						<ion-row class="">
							<ion-col size="12" class="px-0">
								<template v-if="!showPasswordConfirmation">
									<div class="">
										<p class="font-semibold mb-2">
											You are about to permanently delete your account.
										</p>
										<p class="mb-4">
											If you delete your account, you won't be able to retrieve all your
											information. Account deletion is irreversible.
										</p>
										<ul class="list-disc pl-5 mb-8">
											<li>You won’t be able to use your points accumulated.</li>
											<li>
												You won’t be able to return or refund items ordered by that account.
											</li>
											<li>You won’t be able to use your RK Wallet balance.</li>
										</ul>
									</div>
									<div class="block text-right">
										<button-component color="red" expand="block" @click="proceedAnyway">
											<template #content>
												<ion-spinner v-if="loading" />
												<template v-else> Proceed Anyway </template>
											</template>
										</button-component>
									</div>
									<p class="text-xs mt-6 md:mt-8">
										Please be advised that Ramen Kuroda is legally required or entitled to retain
										some types of data such as order history. We do this in line with the applicable
										laws including for tax and accounting and fraud preventing purposes.
									</p>
								</template>

								<template v-else>
									<template v-if="userStore.userData.has_password">
										<p class="mb-2">Input your password to proceed.</p>
										<div class="relative mb-2">
											<input
												:type="showPassword1 ? 'text' : 'password'"
												class="focus:ring-primary-500 focus:border-primary-500 block w-full border-primary-50 placeholder:text-primary-100 rounded-lg h-12"
												placeholder="Current Password"
												v-model="password" />
											<div
												@click="showPassword1 = !showPassword1"
												class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-12">
												<template v-if="!showPassword1">
													<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
												</template>
												<template v-else>
													<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
												</template>
											</div>
										</div>
										<div class="relative mb-8">
											<input
												:type="showPassword2 ? 'text' : 'password'"
												class="focus:ring-primary-500 focus:border-primary-500 block w-full border-primary-50 placeholder:text-primary-100 rounded-lg h-12"
												placeholder="Confirm Password"
												v-model="passwordConfirmation" />
											<div
												@click="showPassword2 = !showPassword2"
												class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-12">
												<template v-if="!showPassword2">
													<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
												</template>
												<template v-else>
													<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
												</template>
											</div>
										</div>
										<div class="block text-right">
											<button-component color="red" expand="block" @click="proceedDelete">
												<template #content> Delete My Account </template>
											</button-component>
										</div>
									</template>
									<template v-else>
										<div class="h-full flex flex-wrap items-start">
											<SingleSignOn :singleSignOn="true" :type="'delete'" />
										</div>
									</template>
								</template>
							</ion-col>
						</ion-row>
					</div>
				</div>
			</div>
			<bottom-navigation />
		</ion-content>

		<GenericModal :isVisible="deleteAccount" noCloseIcon>
			<template #content>
				<div class="text-center p-12 pt-0">
					<template v-if="!success">
						<ion-spinner name="circular"></ion-spinner>
						<p class="font-semibold mt-6">Deleting your account...</p>
					</template>
					<template v-else>
						<img src="/assets/icon/ic-check.svg" alt="icon" class="mx-auto mb-6" />
						<p class="font-semibold mb-2">Account Deleted</p>
						<p class="text-sm">You will be redirected to login screen.</p>
					</template>
				</div>
			</template>
		</GenericModal>
	</ion-page>
</template>
<script type="text/javascript">
import { defineComponent, ref } from "vue";
import { IonPage, IonContent, IonRow, IonCol, IonSpinner, onIonViewWillEnter } from "@ionic/vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import GenericModal from "@/components/GenericModal.vue";
import { EyeIcon, EyeOffIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";
import { useApiStore } from "@/store/api";
import useAuth from "@/composables/useAuth.js";
import useModal from "@/composables/modal";
import { useUserStore } from "@/store/user";
import SingleSignOn from "@/components/SingleSignOn.vue";

export default defineComponent({
	name: "DeleteAccount",
	components: {
		IonPage,
		IonContent,
		IonRow,
		IonCol,
		IonSpinner,
		TopNavigation,
		BottomNavigation,
		ButtonComponent,
		GenericModal,
		EyeIcon,
		EyeOffIcon,
		ArrowLeftIcon,
		SingleSignOn,
	},
	setup() {
		const router = useRouter();
		const apiStore = useApiStore();
		const { logout } = useAuth();
		const modal = useModal();
		const userStore = useUserStore();
		const loading = ref(false);

		const showPassword1 = ref(false);
		const showPassword2 = ref(false);
		const showPasswordConfirmation = ref(false);

		const password = ref(null);
		const passwordConfirmation = ref(null);

		const deleteAccount = ref(false);
		const success = ref(false);

		const proceedDelete = () => {
			deleteAccount.value = true;

			window.axios
				.patch(apiStore.route("profileDelete"), {
					password: password.value,
					password_confirmation: passwordConfirmation.value,
				})
				.then(() => {
					success.value = true;

					setTimeout(() => {
						logout();
					}, 2000);
				})
				.catch((error) => {
					deleteAccount.value = false;

					modal.error(error.response.data.message);
				});
		};

		const proceedAnyway = () => {
			if (!userStore.userData.has_password) {
				sendOtp();
			} else {
				showPasswordConfirmation.value = true;
			}
		};

		const sendOtp = () => {
			loading.value = true;
			window.axios
				.post(apiStore.route("validateSingleSignOn"))
				.then((result) => {
					console.log(result);
					loading.value = false;
					showPasswordConfirmation.value = true;
					userStore.setUserValidation(userStore.userData.email);
					userStore.setUserEmail(userStore.userData.email);
				})
				.catch((error) => {
					console.log(error);
					loading.value = false;
					modal.error(error.response.data.message);
				});
		};

		onIonViewWillEnter(() => (showPasswordConfirmation.value = false));

		return {
			router,
			showPasswordConfirmation,
			showPassword1,
			showPassword2,
			password,
			passwordConfirmation,
			deleteAccount,
			proceedDelete,
			success,
			userStore,
			loading,
			proceedAnyway,
		};
	},
});
</script>
