<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-[999]">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="
                        fixed
                        inset-0
                        bg-gray-500 bg-opacity-75
                        transition-opacity
                    "
                />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        min-h-full
                        text-center
                        sm:p-0
                    "
                    :class="[mobileFullScreen == true ? 'p-0 lg:p-4' : 'p-4']"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="
                                relative
                                bg-white
                                sm:rounded-lg
                                text-left
                                overflow-hidden
                                shadow-xl
                                transform
                                transition-all
                                my-0
                                lg:my-8
                                w-full
                                md:w-auto
                            "
                            :class="[
                                mobileFullScreen == true
                                    ? 'h-screen lg:h-auto'
                                    : 'h-auto',
                            ]"
                        >
                            <div
                                v-if="!noCloseIcon"
                                class="
                                    cursor-pointer
                                    absolute
                                    top-4
                                    z-50
                                    right-4
                                    lg:top-8 lg:right-12
                                "
                            >
                                <XIcon
                                    @click="closeThis"
                                    class="w-5 h-5 text-primary-500"
                                />
                            </div>
                            <div class="pt-10 h-full">
                                <slot name="content"></slot>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default defineComponent({
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        XIcon,
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        noCloseIcon: {
            type: Boolean,
            default: false,
        },
        mobileFullScreen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const open = ref(false);

        const closeThis = () => {
            open.value = false;
        };

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool),
            {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        return {
            open,
            closeThis,
        };
    },
});
</script>
