<template>
	<div class="space-y-2 cursor-pointer">
		<div class="block">
			<img
				:src="item.image_path"
				class="w-full h-auto lg:w-72 lg:h-72 object-cover rounded-xl" />
		</div>
		<div class="space-y-2 text-left">
			<h4 class="text-primary-900 font-medium text-sm">{{ item.name }}</h4>
			<p class="text-primary-200 text-xs">{{ item.end_datetime ? "Until " + item.end_datetime_formatted : "Valid anytime" }}</p>
		</div>
	</div>
</template>
<script>
	import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			item: {
				type: Object,
				required: true,
			},
		},
	});
</script>
