<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="
						flex
						items-center
						justify-center
						min-h-[calc(100vh-72px)]
						lg:min-h-[calc(100vh-80px)]
					"
				>
					<div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
						<div class="space-y-4">
							<h3
								class="
									font-bold
									text-[28px] text-primary-900 text-center
								"
							>
								Topup Success!
							</h3>
							<p class="text-primary-400 text-center">
								Your topup was successfully. For more details,
								tap the button below to view your orders.
							</p>
						</div>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent } from "vue";
import {
	IonPage,
	IonContent,
	onIonViewWillEnter,
	modalController,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import PointsModal from "@/components/PointsModal.vue";

export default defineComponent({
	components: {
		IonContent,
		IonPage,
	},
	setup() {
		const route = useRoute();

		const successModal = async () => {
			const modal = await modalController.create({
				component: PointsModal,
				canDismiss: true,
				backdropDismiss: true,
				cssClass: "gen-modal points",
				componentProps: {
					points: route.query.points,
					availablePoints: route.query.available,
				},
			});

			return modal.present();
		};

		onIonViewWillEnter(() => {
			if (route.query.points != 0) {
				successModal();
			}
		});
	},
});
</script>
