import { ref, watch } from "vue";
import { useApiStore } from '@/store/api.js';

export default function useCms() {

    const store = useApiStore();

    const cms = ref({});
    const contentSlug = ref(null);

    const getCms = (slug) => {
        const content = store.cms || [];
        cms.value = Object.keys(content).length > 0 ? content.find(x => x.slug == slug) : {};
    }

    const getCmsContent = (key) => {
        if(!Object.keys(cms.value).length) return;
        return Object.keys(cms.value).length > 0 ? cms.value.content[key] : null;
    }

    const getCmsImage = (key) => {
        if(!Object.keys(cms.value).length) return;
        return Object.keys(cms.value).length > 0 ? cms.value.formatted_storage_path + '/' + cms.value.content[key] : null;
    }

    const renderCmsImage = (key) => {
        if(!Object.keys(cms.value).length) return;
        return key ? cms.value.formatted_storage_path + '/' + key : null;
    }

    watch(
        () => store.cms,
        (items) => {
            if(items.length) getCms(contentSlug.value);
        }
    )

    return {
        contentSlug,
        getCms,
        getCmsContent,
        getCmsImage,
        renderCmsImage,
        cms
    }

}