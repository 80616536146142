import { useUserStore } from "@/store/user";
import { useCartStore } from "@/store/cart";
import useModal from "./modal.js";
import useLocalStorage from "./useLocalStorage.js";
import { ref } from "vue";

export default function useAuth() {
	const userStore = useUserStore();
	const cartStore = useCartStore();
	const modal = useModal();
	const errors = ref([]);
	const { localStorage } = useLocalStorage();

	const assignToken = async () => {
		return localStorage.value
			.get("authToken")
			.then((token) => {
				window.axios.defaults.headers.common["Authorization"] = token;
				userStore.token = token;

				return true;
			})
			.catch((error) => {
				errors.value = error.response.data.errors.message;
				modal.error(errors.value);
			});
	};

	const assignUser = async () => {
		return localStorage.value
			.get("user")
			.then((user) => {
				userStore.userData = user;

				return true;
			})
			.catch((error) => {
				errors.value = error.response.data.errors.message;
				modal.error(errors.value);
			});
	};

	const setToken = (token, user) => {
		window.axios.defaults.headers.common["Authorization"] = token;

		localStorage.value.set("authToken", token);
		localStorage.value.set("user", user);

		userStore.token = token;
		userStore.userData = user;
	};

	const setRefreshToken = (token) => {
		localStorage.value.set("authRefreshToken", token);
	};

	const logout = () => {
		clearStorage();
		// Redirect to the home page
		window.location.href = "/";
	};

	const clearStorage = () => {
		localStorage.value.delete("authToken");
		localStorage.value.delete("authRefreshToken");
		localStorage.value.delete("user");
		localStorage.value.delete("cartFulfillment");
		localStorage.value.delete("setRecipient");
		localStorage.value.delete("activeCart");
		localStorage.value.delete("guestCartBranch");
		localStorage.value.delete("guestRecipient");
		localStorage.value.delete("guestCartTimeslot");
		localStorage.value.delete("guestCartFulfillment");
        localStorage.value.delete("isHomeBannerClosed");

		userStore.token = null;
		userStore.userData = null;
	};

	const fetchUserData = async () => {
		// get updates from user
		if (await localStorage.value.get("authToken")) {
			await cartStore.fetchCarts();
			await cartStore.fetchCartRecipient();
			await cartStore.fetchActiveCart();

			// get updates from user
			userStore.getUpdates();
		}

		return true;
	};

	return {
		setToken: setToken,
		setRefreshToken: setRefreshToken,
		assignToken: assignToken,
		assignUser: assignUser,
		logout: logout,
		fetchUserData: fetchUserData,
		clearStorage: clearStorage,
	};
}
