<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="
						flex
						items-center
						justify-center
						min-h-[calc(100vh-72px)]
						lg:min-h-[calc(100vh-80px)]
					"
				>
					<div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
						<div class="space-y-4">
							<h3
								class="
									font-bold
									text-[28px] text-primary-900 text-center
								"
							>
								Topup Success!
							</h3>
							<p class="text-primary-400 text-center">
								Your topup was successfully. For more details,
								tap the button below to view your topup history.
							</p>
						</div>
						<div class="mt-10 space-y-2">
							<button-component
								@click="router.replace('home')"
								expand="block"
								color="white"
							>
								<template #content> Back to Home </template>
							</button-component>
							<button-component
								@click="router.replace('wallet')"
								expand="block"
								color="primary"
							>
								<template #content> Confirm </template>
							</button-component>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useRouter } from "vue-router";
import {
	IonPage,
	IonContent,
	onIonViewWillEnter,
	modalController,
} from "@ionic/vue";
import PointsModal from "@/components/PointsModal.vue";
import useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
	components: {
		TopNavigation,
		BottomNavigation,
		ButtonComponent,
		IonContent,
		IonPage,
	},
	setup() {
		const router = useRouter();
		const { localStorage } = useLocalStorage();

		const successModal = async (value, points) => {
			const modal = await modalController.create({
				component: PointsModal,
				canDismiss: true,
				backdropDismiss: true,
				cssClass: "gen-modal points",
				componentProps: {
					points: value,
					availablePoints: points,
				},
			});

			modal.onDidDismiss().then(() => {
				localStorage.value.delete("pointsToEarn");
				localStorage.value.delete("availablePoints");
			});

			return modal.present();
		};

		onIonViewWillEnter(() => {
			localStorage.value.get("pointsToEarn").then((value) => {
				if (value) {
					localStorage.value.get("availablePoints").then((points) => {
						successModal(value, points);
					});
				}
			});
		});

		return {
			router,
		};
	},
});
</script>
