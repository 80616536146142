import { defineStore } from "pinia";
import { useApiStore } from "./api";
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useUserStore = defineStore({
	id: "user",
	state: () => {
		return {
			token: null,
			pushToken: null,
			auth: false,
			userData: null,
			userEmail: null,
			userRecipient: null,
			userMapAddress: null,
			userCard: null,
			orderMethod: null,
			userTotalPoints: null,
			carts: [],
			activeOrders: [],
			userValidation: null,
			userSubscription: null,

			pointsToEarn: 0,
			availablePoints: 0,
			viewOrder: null,

            isHomeBannerClosed: false,
            promoBanner: {},
		};
	},
	getters: {
        getPromoBanner: (state) => state.promoBanner,
	},
	actions: {
		setUser(value) {
			const { localStorage } = useLocalStorage();

			this.userData = value;
			localStorage.value.set("user", value);
		},
		clearAuth() {
			this.auth = false;
			this.token = null;
		},
		setUserValidation(value) {
			const { localStorage } = useLocalStorage();
			this.userValidation = value;
			localStorage.value.set("userValidation", value);
		},
		setUserEmail(value) {
			const { localStorage } = useLocalStorage();
			this.userEmail = value;
			localStorage.value.set("userEmail", value);
		},
		async getUserValidation() {
			const { localStorage } = useLocalStorage();
			const value = await localStorage.value.get("userValidation");

			this.userValidation = value;
			return true;
		},
		async getUserEmail() {
			const { localStorage } = useLocalStorage();
			const value = await localStorage.value.get("userEmail");

			this.userEmail = value;
			return true;
		},
		async getUpdates() {
			const apiStore = useApiStore();
			const { localStorage } = useLocalStorage();

			window.axios.get(apiStore.route("customer")).then((result) => {
				localStorage.value.set("user", result.data.user);
				this.userData = result.data.user;
			});
		},
        async closeHomeBanner() {
            const { localStorage } = useLocalStorage();
            this.isHomeBannerClosed = true;

            localStorage.value.set("isHomeBannerClosed", true);
        },
        async getHomeBannerState() {
			const { localStorage } = useLocalStorage();
			const value = await localStorage.value.get("isHomeBannerClosed");

			this.isHomeBannerClosed = value;
			return true;
		},
        async setPromoBanner(value) {
            this.promoBanner = value;
        }
	},
});
