<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="flex flex-col lg:flex-row items-start px-4 lg:px-12 py-10 lg:py-14 divide-x divide-[#e5e7eb]">
					<div class="w-full lg:w-[500px] lg:pr-20 flex-shrink-0">
						<div class="w-full mb-8 flex justify-between items-center">
							<back-button />

							<div class="ml-6 w-full relative flex justify-end">
								<img
									src="/assets/icon/ic-search.svg"
									alt="search_icon"
									class="w-8 h-8 object-contain cursor-pointer mx-0"
									@click="initialSearchTrigger" />
								<input
									id="search"
									name="search"
									placeholder="Search..."
									class="w-full h-12 rounded-lg bg-primary-50 p-3 absolute -top-1.5 focus:ring-primary-500 outline-primary-500 hidden text-xs placeholder-primary-200" />
							</div>
						</div>
						<div class="space-y-2">
							<h3 class="text-2xl lg:text-[28px] font-semibold">Your orders</h3>
						</div>
						<div class="mt-11 w-full">
							<div class="w-full bg-primary-50 inline-flex items-center justify-start rounded-3xl p-1">
								<div
									@click="tab = 'active'"
									:class="{
										'bg-primary-600 text-white': tab === 'active',
									}"
									class="cursor-pointer border rounded-3xl py-2 px-3 flex items-center justify-center sm:flex-1 w-40 transition-all duration-500">
									Active Order
								</div>
								<div
									@click="tab = 'draft'"
									:class="{
										'bg-primary-600 text-white': tab === 'draft',
									}"
									class="cursor-pointer border rounded-3xl py-2 px-3 flex items-center justify-center sm:flex-1 w-40 transition-all duration-500">
									Draft Order
								</div>
							</div>
						</div>

						<div class="w-full mx-auto min-h-[60vh] max-h-[60vh] overflow-y-auto mt-10">
							<template v-if="tab === 'active'">
								<div
									v-if="loading"
									class="text-center">
									<ion-spinner />
								</div>
								<template v-else>
									<template v-if="orders.length === 0">
										<div class="text-center">
											<p class="text-sm text-primary-900">No data to display.</p>
										</div>
									</template>
									<div
										v-else
										class="space-y-2">
										<template
											v-for="order in userStore.activeOrders"
											:key="order">
											<active-order
												@click="orderView = order"
												:order="order"
												:class="{
													'border border-primary-400 rounded-xl': orderView?.id === order.id && tab === 'active',
												}" />
										</template>
									</div>
								</template>
							</template>

							<template v-if="tab === 'draft'">
								<div
									v-if="loading"
									class="text-center">
									<ion-spinner></ion-spinner>
								</div>
								<template v-else>
									<template v-if="userStore.carts.length === 0">
										<div class="text-center">
											<p class="text-sm text-primary-900">No data to display.</p>
										</div>
									</template>
									<div
										class="space-y-2"
										v-else>
										<template
											v-for="cart in userStore.carts"
											:key="cart">
											<draft-order
												@click="orderView = cart"
												:order="cart"
												:class="{
													'border border-primary-400 rounded-xl': orderView?.id === cart.id && tab === 'draft',
												}" />
										</template>
									</div>
								</template>
							</template>
						</div>
					</div>

					<div class="w-full min-h-[60vh]">
						<div class="max-w-none lg:max-w-[408px] mx-auto hidden lg:block">
							<template v-if="orderView">
								<template v-if="tab === 'active'">
									<order-view
										:order="orderView"
										:allowCancel="true"
										@showTimeline="showOrderTimeline = true" />
								</template>
								<template v-if="tab === 'draft'">
									<order-view
										:order="orderView"
										:isDraftOrder="orderView.status === 0"
										:isPaymentProcessing="orderView.status === 3"
										@order:deleted="orderDeleted" />
								</template>
							</template>
						</div>
					</div>
				</div>
			</div>
			<bottom-navigation></bottom-navigation>
		</ion-content>

		<!-------------- 
            MODALS 
        --------------->

		<!-- Order View - Mobile Responsive -->
		<div class="block lg:hidden">
			<side-menu
				v-if="orderView && tab === 'draft'"
				:is-visible="orderView && tab === 'draft'"
				@close="orderView = !orderView">
				<template #content>
					<order-view
						:order="orderView"
						:isDraftOrder="orderView.status === 0"
						:isPaymentProcessing="orderView.status === 3"
						@order:deleted="orderDeleted" />
				</template>
			</side-menu>

			<side-menu
				v-if="orderView && tab === 'active'"
				:is-visible="orderView && tab === 'active'"
				@close="orderView = !orderView">
				<template #content>
					<order-view
						:order="orderView"
						@showTimeline="showOrderTimeline = true" />
				</template>
			</side-menu>
		</div>
		<!--  -->

		<!-- Order Status Timeline -->
		<side-menu
			v-if="orderView"
			title="Order Status"
			:is-visible="showOrderTimeline"
			@close="showOrderTimeline = false">
			<template #content>
				<div
					class="bg-primary-50 rounded-xl w-full p-6"
					v-if="orderView.grab_orders.length > 0">
					<div class="flex items-start justify-between">
						<div class="space-y-1">
							<h5 class="text-primary-900 font-bold">
								{{ orderView.grab_orders.at(-1).delivery_id }}
							</h5>
							<p class="text-xs text-primary-400">Tracking Number</p>
						</div>

						<span
							class="text-sm text-primary-500 m-0 font-semibold cursor-pointer"
							v-if="orderView.grab_orders.at(-1).share_link"
							@click="
								() => {
									showTrackingView = !showTrackingView;
									showTrackingViewUrl = orderView.grab_orders.at(-1).share_link;
								}
							">
							{{ showTrackingView ? "Hide" : "Show" }}
						</span>
					</div>
				</div>

				<div
					class="bg-primary-50 rounded-xl w-full p-6"
					v-if="orderView.lalamove_orders.length > 0">
					<div class="flex items-start justify-between">
						<div class="space-y-1">
							<h4 class="text-xl text-primary-900 font-bold">
								{{ orderView.lalamove_orders.at(-1).order_id }}
							</h4>
							<p class="text-xs text-primary-400">Tracking Number</p>
						</div>

						<span
							class="text-sm text-primary-500 m-0 font-semibold cursor-pointer"
							v-if="orderView.lalamove_orders.at(-1).share_link"
							@click="
								() => {
									showTrackingView = !showTrackingView;
									showTrackingViewUrl = orderView.lalamove_orders.at(-1).share_link;
								}
							">
							{{ showTrackingView ? "Hide" : "Show" }}
						</span>
					</div>
				</div>

				<div class="mt-4">
					<iframe
						v-if="showTrackingView"
						class="w-full"
						style="height: 560px"
						:src="showTrackingViewUrl" />

					<div
						class="mt-6"
						v-else>
						<ol class="relative border-l border-primary-100 dark:border-gray-700 ml-28">
							<li
								class="mb-10 ml-4"
								v-for="log in orderView.order_history"
								:key="log">
								<div class="absolute w-3 h-3 bg-primary-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
								<div class="absolute -left-20 text-right">
									<p class="text-primary-200 text-xs">
										{{ log.date }}
									</p>
									<p class="text-primary-200 text-xs">
										{{ log.time }}
									</p>
								</div>
								<div>
									<h4 class="text-primary-900 text-sm font-bold">
										{{ log.status }}
									</h4>
									<p class="text-primary-200 text-xs">
										{{ log.text }}
									</p>
								</div>
							</li>
						</ol>
					</div>
				</div>
			</template>
		</side-menu>
		<toast-message
			:show="showToast"
			:message="toastMessage"
			@didDismiss="showToast = false" />
	</ion-page>
</template>
<script>
	import { defineComponent, ref, watch, nextTick } from "vue";
	import { IonPage, IonContent, IonSpinner, onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
	import { useRouter } from "vue-router";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import ActiveOrder from "./Components/ActiveOrder.vue";
	import DraftOrder from "./Components/DraftOrder.vue";
	import OrderView from "@/components/OrderView.vue";
	import SideMenu from "@/components/SideMenu.vue";
	import BackButton from "@/components/BackButton.vue";
	import { useApiStore } from "@/store/api";
	import { useUserStore } from "@/store/user";
	import { useOrderStore } from "@/store/order";
	import { useCartStore } from "@/store/cart";
	import ToastMessage from "@/components/ToastMessage.vue";

	export default defineComponent({
		name: "OrdersIndex",
		components: {
			IonPage,
			IonContent,
			TopNavigation,
			BottomNavigation,
			ActiveOrder,
			DraftOrder,
			OrderView,
			SideMenu,
			IonSpinner,
			ToastMessage,
			BackButton,
		},
		setup() {
			const apiStore = useApiStore();
			const userStore = useUserStore();
			const orderStore = useOrderStore();
			const cartStore = useCartStore();
			const router = useRouter();

			const showToast = ref(false);
			const showTrackingView = ref(false);
			const showTrackingViewUrl = ref("");
			const toastMessage = ref(null);

			const loading = ref(false);
			const tab = ref("active");

			const showOrderTimeline = ref(false);
			const showOrderViewMbl = ref(false);
			const orders = ref([]);
			const orderView = ref(null);

			const initialSearchTrigger = async () => {
				await nextTick();
				document.getElementById("search").classList.remove("hidden");
				document.getElementById("search").focus();
			};

			watch(
				() => tab.value,
				() => {
					orderView.value = null;
				}
			);

			const fetchRecords = async () => {
				await window.axios
					.post(apiStore.route("orders"), {
						type: "active",
					})
					.then((result) => {
						orders.value = result.data.orders;

						if (orders.value.length > 0) {
							orderView.value = orders.value[0];
						}
					});

				await cartStore.fetchCarts();
				loading.value = false;
			};

			const orderDeleted = async (message) => {
				orderView.value = null;
				toastMessage.value = message;
				showToast.value = true;

				await cartStore.fetchCarts();
			};

			onIonViewWillEnter(() => {
				loading.value = true;
				fetchRecords();
				orderStore.fetchActive();
			});

			onIonViewWillLeave(() => {
				orderView.value = null;
			});

			return {
				userStore,
				tab,
				loading,
				showOrderTimeline,
				router,
				orders,
				orderView,
				orderDeleted,
				toastMessage,
				showToast,
				showTrackingView,
				showTrackingViewUrl,
				showOrderViewMbl,
				initialSearchTrigger,
			};
		},
	});
</script>
