<template>
    <ion-page>
        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="page-offset">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        min-h-[calc(100vh-72px)]
                        lg:min-h-[calc(100vh-80px)]
                    "
                >
                    <div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
                        <div class="space-y-4">
                            <h3
                                class="
                                    font-bold
                                    text-[28px] text-primary-900 text-center
                                "
                            >
                                Success!
                            </h3>
                            <p class="text-primary-400 text-center">
                                Subscription successful. Please wait while we process your payment.
                            </p>
                        </div>
                        <div class="mt-10 space-y-2">
                            <button-component
                                @click="createSubscription"
                                expand="block"
                                color="primary"
                            >
                                <template #content> Continue </template>
                            </button-component>
                        </div>
                    </div>
                </div>
            </div>

            <bottom-navigation />
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useRouter } from "vue-router";
import { IonPage, IonContent } from "@ionic/vue";
import useLocalStorage from "@/composables/useLocalStorage.js";
// import Modal from "@/components/Modal.vue";

export default defineComponent({
    components: {
        TopNavigation,
        BottomNavigation,
        ButtonComponent,
        IonContent,
        IonPage,
    },
    setup() {
        const router = useRouter();
        const { localStorage } = useLocalStorage();
        const customerId = ref(null);
        const cardTokenId = ref(null);

        const createSubscription = () => {
            router.push({ name: 'Home' });
        }

        onMounted(() => {
			localStorage.value.get("setUserSubscription").then((value) => {
				if (value) {
					customerId.value = value.customerId;
					cardTokenId.value = value.cardTokenId;
				}
			});
		});

        return {
            router,
            createSubscription
        };
    },
});
</script>
