<template>
	<div class="ion-padding px-11 py-6">
		<ion-row>
			<ion-col size="12" class="p-0 text-center flex flex-col items-center justify-center">
				<CheckCircleIcon class="w-10 h-10 mb-8" />

				<h4 class="text-primary-900 font-semibold mb-4">Free {{ product }}!</h4>
				<p class="text-sm text-primary-400 mb-10 w-3/4">
					You successfully avail our promo for every subtotal worth <render-price :value="minimum" />!
				</p>
				<a class="text-sm font-semibold cursor-pointer" @click="close">
					{{ buttonText }}
				</a>
			</ion-col>
		</ion-row>
	</div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { modalController, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";
import RenderPrice from "@/components/RenderPrice.vue";

export default defineComponent({
	name: "free-item-modal",
	props: {
		product: {
			type: String,
			default: "Free Item!",
		},
		minimum: {
			type: String,
			default: null,
		},
		buttonText: {
			type: String,
			default: "Okay",
		},
	},
	components: {
		IonRow,
		IonCol,
		CheckCircleIcon,
		RenderPrice,
	},
	setup() {
		const close = () => {
			modalController.dismiss();
		};

		return {
			close,
		};
	},
});
</script>