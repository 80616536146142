<template>
    <div class="ion-padding p-6" :class="customClass">
        <!-- <img class="mx-auto" :width="imageWidth" :height="imageHeight" :src="imageLink" alt="Icon"> -->
        <p class="font-semibold text-primary-900">{{ title }}</p>
        <p class="mt-2 mb-4" style="color: #000">
            {{ description }}
        </p>
        <template v-if="cancelButton || buttonLink">
            <div class="flex items-start w-full">
                <div class="w-6/12 pr-1" v-if="cancelButton">
                    <ion-button
                        class="btn-tertiary m-0"
                        size="large"
                        :expand="expand"
                        @click="closeModal"
                    >
                        Cancel
                    </ion-button>
                </div>
                <div
                    v-show="!closeModalButton"
                    :class="cancelButton ? 'w-6/12 pl-1' : 'w-full'"
                >
                    <ion-button
                        :href="buttonLink"
                        :class="
                            cancelButton ? 'btn-primary' : 'btn-primary btn-min'
                        "
                        size="large"
                        :expand="expand"
                    >
                        {{ buttonText }}
                    </ion-button>
                </div>
            </div>
        </template>
        <template v-if="buttonText && !buttonLink">
            <div
                v-show="!closeModalButton"
                :class="cancelButton ? 'w-6/12 pl-1' : 'w-full'"
            >
                <ion-button
                    @click="closeModal"
                    :class="
                        cancelButton ? 'btn-primary' : 'btn-primary btn-min'
                    "
                    size="large"
                    :expand="expand"
                >
                    {{ buttonText }}
                </ion-button>
            </div>
        </template>
    </div>
</template>

<script>
import { IonButton, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "modal-generic",
    props: {
        customClass: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        buttonLink: {
            type: String,
            default: null,
        },
        expand: {
            type: String,
            default: null,
        },
        imageLink: {
            type: String,
            default: null,
        },
        imageWidth: {
            type: String,
            default: null,
        },
        imageHeight: {
            type: String,
            default: null,
        },
        cancelButton: {
            type: Boolean,
            default: false,
        },
        cancelButtonText: {
            type: String,
            default: "Cancel",
        },
        closeModalButton: {
            type: Boolean,
            default: false,
        },
        confirmLink: { type: Function },
    },
    data() {
        return {
            content: "Content",
        };
    },
    methods: {
        async closeModal() {
            await modalController.dismiss();
        },
    },
    components: { IonButton },
});
</script>
