<template>
    <ion-button
        fill="clear"
        class="btn-link btn-no-padding ion-no-margin"
        :class="customClass"
    >
        <slot name="content"></slot>
    </ion-button>
</template>
<script>
import { IonButton } from "@ionic/vue";

export default {
    components: {
        IonButton,
    },
    props: {
        customClass: {
            type: String,
            default: null,
        },
    },
};
</script>