<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="min-h-[calc(100vh-72px)] lg:min-h-[calc(100vh-80px)] relative">
					<div class="px-4 lg:px-12 py-10 lg:py-14 h-full items-start flex flex-col lg:flex-row">
						<div class="w-full lg:w-[500px] lg:pr-20 flex-shrink-0 min-h-[60vh]">
							<div class="w-full lg:w-[500px] lg:pr-20 flex-shrink-0 mb-8 flex justify-between items-center">
                                <div 
                                    v-if="showSearch"
                                    @click="{
                                        showSearch = false;
                                        search = null;
                                    }"
                                    class="mx-0 cursor-pointer flex-shrink-0"
                                >
                                    <img 
                                        src="/assets/icon/ic-arrow-back.svg"
                                        alt="back_arrow_icon"
                                        class="w-8 h-8 object-contain hover:opacity-80 transition"
                                    >
                                </div>
								<back-button v-else />

								<div class="ml-6 w-full relative flex justify-end">
									<img 
										src="/assets/icon/ic-search.svg"
										alt="search_icon"
										class="w-8 h-8 object-contain cursor-pointer mx-0"
										@click="showSearch = true"
									>
									<input
                                        v-if="showSearch"
                                        v-model="search"
										id="search"
										name="search"
										placeholder="Search..."
										class="w-full h-12 rounded-lg bg-primary-50 p-3 absolute -top-1.5 focus:ring-primary-500 outline-primary-500 text-xs placeholder-primary-200"
									>
								</div>
							</div>
							<div class="ordr-hstry__header mb-4 lg:mb-10">
								<p class="text-2xl lg:text-[28px] font-semibold">Order History</p>
							</div>

							<div v-if="loading" class="text-center">
								<ion-spinner></ion-spinner>
							</div>
							<div v-else class="w-full">
								<template v-for="(order, index) in orders" :key="index">
									<order-history-list
										@click="viewOrder(order, order.id)"
										:order="order"
										:class="{
                                            'border border-primary-100': selected == order.id,
										}"
									></order-history-list>
								</template>
                                <template v-if="orders.length < 1">
                                    <p class="text-center">No orders found.</p>
                                </template>
							</div>
						</div>
						<div
							class="w-full absolute lg:relative inset-0 mt-6 lg:mt-0 z-[999] h-full translate-x-full lg:translate-x-0 transition-all duration-500 bg-white border-0 lg:border-l min-h-[60vh]"
							ref="orderview"
						>
							<div class="px-4 block lg:hidden" @click="goBack">
								<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 512 512">
									<path
										fill="none"
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="48"
										d="M244 400L100 256l144-144M120 256h292"
									/>
								</svg>
							</div>
							<div class="max-w-none lg:max-w-[408px] mx-auto bg-white p-4 lg:p-0 h-auto">
								<order-view v-if="orderView" :order="orderView" :allow-cancel="false" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>

<script>
import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { useRouter } from "vue-router";
import { ref, onMounted, watch } from "vue";
import debounce from "lodash/debounce";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import OrderHistoryList from "@/components/OrderHistoryList.vue";
import BackButton from "@/components/BackButton.vue";
import OrderView from "@/components/OrderView.vue";
import { useApiStore } from "@/store/api";
import { useUserStore } from "@/store/user";

export default {
	name: "OrderHistory",
	components: {
		TopNavigation,
		BottomNavigation,
		IonContent,
		IonPage,
		IonSpinner,
		OrderHistoryList,
		OrderView,
		BackButton,
	},
	setup() {
		const apiStore = useApiStore();
		const userStore = useUserStore();
		const loading = ref(true);
		const router = useRouter();
		const orders = ref([]);
		const orderView = ref(null);
		const selected = ref(null);
        const search = ref(null);
        const showSearch = ref(false);

		const windowWidth = window.matchMedia("(max-width: 1024px)");
		const orderview = ref(null);

		const viewOrder = (order, id) => {
			orderView.value = order;
			selected.value = id;

			if (windowWidth.matches) {
				orderview.value.classList.remove("translate-x-full");
				orderview.value.classList.add("translate-x-0");
			}
		};

		const goBack = () => {
			if (windowWidth.matches) {
				orderview.value.classList.add("translate-x-full");
				orderview.value.classList.remove("translate-x-0");
			}
		};

        const fetchOrders = (search = null) => {
            window.axios.post(process.env.VUE_APP_API_URL + "/api/orders/fetch", { search }).then((result) => {
				loading.value = false;
				orders.value = result.data.orders;
			});
        }

        watch(
            search,
            debounce((newValue) => {
                fetchOrders(newValue);
            }, 1000)
        );

		onMounted(() => {
            fetchOrders();

			if (userStore.viewOrder !== null) {
				window.axios
					.post(apiStore.route("orderView"), {
						id: userStore.viewOrder,
					})
					.then((response) => {
						viewOrder(response.data.order, response.data.order.id);
					});
			}
		});

		return {
			loading,
			chevronForward,
			router,
			// tabLink,
			orders,
			viewOrder,
			orderView,
			selected,
			goBack,
			orderview,
            search,
            showSearch
		};
	},
};
</script>