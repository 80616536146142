<template>
    
    <p class="m-0 text-sm" :class="[statusColor, customClass]">{{orderStatus}}</p>

</template>
<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
    props: {
        orderStatus: {
            type: String
        },
        customClass: {
            type: String
        }
    },
    setup(props){
        const statusColor = computed(() => {
            return {
                'Completed': 'text--success',
                'Delivering': 'text--delivering',
                'Cancelled': 'text--cancelled',
                'Confirmed': 'text--confirmed',
                'Received': 'text--received',
                'Prepared': 'text--prepared',
                'Preparing': 'text--preparing'
            }[props.orderStatus]
        })

        return {
            statusColor
        }
    }
})
</script>
