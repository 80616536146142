<template>
	<div>
		<div
			@click="$emit('showTimeline')"
			class="cursor-pointer rounded-lg p-4 bg-primary-800 mb-4"
			v-if="!isDraftOrder">
			<div class="flex items-center justify-between">
				<p class="m-0 text-sm text-warning">
					{{ order.order_history[0].status }}
				</p>
				<p class="m-0 text-sm text-primary-50">#{{ order.id }}</p>
			</div>
			<template v-if="order.latest_time">
				<h4 class="mt-2 text-white font-semibold">
					{{ order.latest_time }}
				</h4>
				<p class="text-primary-50 text-xs">Status Updated Time</p>
			</template>
			<template v-if="isPaymentProcessing">
				<h4 class="mt-2 text-white font-semibold">Payment Processing ...</h4>
			</template>
		</div>

		<div class="mt-6">
			<template v-if="order.address">
				<div class="flex items-center justify-between mb-3">
					<p class="m-0 font-semibold">
						<template v-if="order.fulfillment_method === 'Delivery'">Deliver to:</template>
						<template v-else-if="order.fulfillment_method === 'Pick-up'">Pick-up near:</template>
					</p>
					<p
						v-if="order.created_at_formatted"
						class="m-0 text-xs text-primary-200">
						{{ order.created_at_formatted }}
					</p>
				</div>
				<div class="space-y-2">
					<address-card
						:title="order.address.name"
						:address="order.address.address" />
					<address-card
						v-if="order.recipient_first_name && order.recipient_last_name"
						:title="order.recipient_first_name + ' ' + order.recipient_last_name"
						:address="order.recipient_mobile_number" />
				</div>
			</template>

			<template v-if="order.branch_table">
				<div class="flex items-center justify-between mt-6 mb-3">
					<p class="m-0 font-semibold">
						<span>Contactless Dining</span>
					</p>
					<p class="m-0 text-xs text-primary-200">
						{{ order.created_at_formatted }}
					</p>
				</div>

				<div class="border border-primary-50 rounded-xl p-4 mb-2">
					<p class="text-sm font-semibold text-primary-500 mb-1">
						{{ order.branch.name }}
					</p>
					<p class="text-xs text-primary-400">
						{{ order.branch_table.table_number }}
					</p>
				</div>
			</template>
		</div>

		<div class="mt-6">
			<p class="m-0 font-semibold">Orders</p>
			<div
				class="mt-2 space-y-2"
				v-if="order.items.length > 0">
				<template
					v-for="item in order.items"
					:key="item">
					<order-product-card :item="item" />
				</template>
			</div>
			<p
				class="text-sm text-primary-900"
				v-else>
				No data to display.
			</p>
		</div>

		<div
			class="mt-6"
			v-if="!isDraftOrder">
			<div
				class="flex justify-between mb-2"
				v-if="order.include_freebies">
				<p class="text-primary-400 m-0">RK Prime Freebies</p>
				<p class="text-primary-900 font-bold m-0">Yes</p>
			</div>

			<div class="flex justify-between mb-2">
				<p class="text-primary-400 m-0">Subtotal</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.subtotal" />
				</p>
			</div>
			<div class="flex justify-between mb-2">
				<p class="text-primary-400 m-0">VATable Sale</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.vatable_sales" />
				</p>
			</div>
			<div class="flex justify-between mb-2">
				<p class="text-primary-400 m-0">VAT (12%)</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.vat" />
				</p>
			</div>
			<div
				class="flex justify-between mb-2"
				v-if="order.service_charge > 0">
				<p class="text-primary-400 m-0">Service Charge</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.service_charge" />
				</p>
			</div>
			<div
				class="flex justify-between mb-2"
				v-if="order.voucher_applied > 0">
				<p class="text-primary-400 m-0">Voucher Discount</p>
				<p class="text-primary-900 font-bold m-0">
					-
					<render-price :value="order.voucher_applied" />
				</p>
			</div>
			<div
				class="flex justify-between mb-2"
				v-if="order.points_applied > 0">
				<p class="text-primary-400 m-0">Points Applied</p>
				<p class="text-primary-900 font-bold m-0">
					-
					<render-price :value="order.points_applied" />
				</p>
			</div>
			<div
				class="flex justify-between mb-2"
				v-if="order.delivery_fee > 0">
				<p class="text-primary-400 m-0">Delivery Fee</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.delivery_fee" />
				</p>
			</div>
			<div class="flex justify-between mb-2">
				<p class="text-primary-400 m-0">Total</p>
				<p class="text-primary-900 font-bold m-0">
					<render-price :value="order.total" />
				</p>
			</div>
		</div>

		<div
			class="border-t border-primary-50 pt-6 mt-6"
			v-if="order.payment">
			<p class="font-semibold">Payment</p>
			<p class="text-sm">
				{{ order.payment.method }}
				<template v-if="order.payment_source">({{ order.payment_source }})</template>
			</p>
		</div>

		<div class="mt-8 flex items-center justify-end gap-4">
			<button-component
                v-if="!order.rated_at && (order.order_status === 'Completed')"
				color="primary"
				fill="outline"
				class="w-1/2"
				@click="showRatingForm()"
            >
				<template #content>Rate</template>
			</button-component>
            <button-component
                v-else-if="order.rated_at && (order.order_status === 'Completed')"
				color="primary"
				fill="outline"
				class="w-1/2"
				@click="viewRating()"
            >
				<template #content>View Rating</template>
			</button-component>
			<button-component
				v-if="order.completed_at_formatted && order.fulfillment_method !== 'Dine-in'"
				color="primary"
				@click="reorder"
				class="w-1/2">
				<template #content>Order Again</template>
			</button-component>

			<template v-if="isDraftOrder">
				<button-component
					color="primary"
					fill="outline"
					@click="edit"
					class="w-1/2">
					<template #content>Edit</template>
				</button-component>

				<button-component
					color="red"
					fill="outline"
					@click="showDeleteConfirmationModal = true"
					class="w-1/2">
					<template #content>Delete Order</template>
				</button-component>
			</template>

			<button-component
				v-if="order.can_cancel && allowCancel"
				@click="cancelOrder(order.id)"
				color="red"
				:disabled="!order.can_cancel"
				class="w-1/2">
				<template #content>Cancel Order</template>
			</button-component>
		</div>
	</div>

	<!--------------------------- 
        MODALS
    ----------------------------->

	<!-- Cancellation Menu -->
	<Teleport to="body">
		<side-menu
			title="Order Cancellation"
			description="We’re sorry for the inconvience. Tell us your concerns to improve our service."
			:is-visible="showCancelOrder"
			@close="showCancelOrder = false">
			<template #content>
				<div class="space-y-6 pl-3">
					<radio-group
						:options="cancelReason"
						name="cancel-reason"
						v-model="selectedReason"></radio-group>
				</div>
			</template>
			<template #button>
				<button-component
					@click="showCancelConfirmationModal = true"
					color="red"
					expand="block">
					<template #content>Continue</template>
				</button-component>
			</template>
		</side-menu>
	</Teleport>

	<!-- Cancel Order Confirmation Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="showDeleteConfirmationModal"
			@close="showDeleteConfirmationModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-6 lg:pb-6 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold">Are you sure?</p>
						<p class="text-sm text-primary-400 font-normal">You won't be able to retrieve your drafted order.</p>
					</div>
					<div class="flex items-center justify-end gap-2 mt-8">
						<button-component
							@click="showDeleteConfirmationModal = false"
							color="secondary">
							<template #content>No, I'm not</template>
						</button-component>
						<button-component
							@click="destroyOrder"
							color="red">
							<template #content>Yes, I'm Sure</template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</Teleport>

	<!-- Cancel Order Confirmation Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="showCancelConfirmationModal"
			@close="showCancelConfirmationModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-6 lg:pb-6 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold">Are you sure?</p>
					</div>
					<div class="flex items-center justify-end gap-2 mt-8">
						<button-component
							@click="showCancelConfirmationModal = false"
							color="secondary">
							<template #content>No, I'm not</template>
						</button-component>
						<button-component
							@click="confirmCancellation"
							color="red">
							<template #content>Yes, I'm Sure</template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</Teleport>

	<!-- Order Cannot be cancelled Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="showNoCancelModal"
			@close="showNoCancelModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-12 lg:pb-6 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold text-center">
							Sorry! we are now preparing your
							<br />
							order and it can no longer be
							<br />
							cancelled.
						</p>
					</div>
					<div class="flex items-center justify-center gap-2 mt-8 mb-4">
						<anchor-link @click="showNoCancelModal = false">
							<template #content>
								<span class="text-primary-500 text-sm">Okay</span>
							</template>
						</anchor-link>
					</div>
				</div>
			</template>
		</generic-modal>
	</Teleport>

	<!-- Rate Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="showRatingFormModal"
			@close="showRatingFormModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-12 pb-12 w-full lg:w-[500px] h-auto">
					<template v-if="!loading">
                        <div class="flex items-center justify-between mb-2">
                            <h3 class="m-0 font-semibold">Rate your meal</h3>
                            <XIcon
                                @click="showRatingFormModal = false"
                                class="cursor-pointer w-5 h-5" />
                        </div>
                        <p class="text-sm text-primary-400">Let us know what your experience was like.</p>
                        <div class="mt-8 border rounded-[10px] py-5 px-4 space-y-5">
                            <div class="flex items-center justify-between" v-for="item in form.rating" :key="item.rating_criteria" >
                                <p class="text-sm font-semibold m-0">{{ item.description }}</p>
                                <div class="ml-auto">
                                    <star-rating v-model="item.value" />
                                </div>
                            </div>
                        </div>
                        <div class="w-full mt-8">
                            <button-component
                                color="primary"
                                expand="block"
                                @click="submitRating()">
                                <template #content>Submit Review</template>
                            </button-component>
                        </div>
                    </template>
                    <template v-else>
                        <ion-spinner></ion-spinner>
                    </template>
				</div>
			</template>
		</generic-modal>
	</Teleport>

    <!-- View Rating Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="viewOrderRateModal"
			@close="viewOrderRateModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-12 pb-12 w-full lg:w-[500px] h-auto">
					<template v-if="!loading">
                        <div class="flex items-center justify-between mb-2">
                            <h3 class="m-0 font-semibold">Rate your meal</h3>
                            <XIcon
                                @click="viewOrderRateModal = false"
                                class="cursor-pointer w-5 h-5" />
                        </div>
                        <p class="text-sm text-primary-400">Let us know what your experience was like.</p>
                        <div class="mt-8 border rounded-[10px] py-5 px-4 space-y-5">
                            <div class="flex items-center justify-between" v-for="item in form.rating" :key="item.rating_criteria" >
                                <p class="text-sm font-semibold m-0">{{ item.description }}</p>
                                <div class="ml-auto">
                                    <star-rating v-model="item.value" :readonly="true" />
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 border rounded-[10px] py-5 px-4 space-y-5">
                            <p class="text-sm font-semibold mb-5">Wanna give a more detailed review?</p>
                            <p class="text-sm text-primary-400 mb-5">Visit our Facebook page and share your experience with other customers!</p>
                            <ion-button
                                class="btn-secondary--lighter"
                                expand="block"
                                :href="link"
                                target="_blank"
                            >
                                    <img
                                        src="/assets/icon/ic-facebook-logo.svg"
                                        alt="fb-icon"
                                        class="m-0 mr-1.5" 
                                    />
                                    Visit us on Facebook
                            </ion-button>
                        </div>
                        <div class="w-full mt-8">
                            <button-component
                                color="primary"
                                expand="block"
                                @click="viewOrderRateModal = false"
                            >
                                <template #content>Return to Order History</template>
                            </button-component>
                        </div>
                    </template>
                    <template v-else>
                        <ion-spinner></ion-spinner>
                    </template>
				</div>
			</template>
		</generic-modal>
	</Teleport>

	<!-- Rate Successful Modal -->
	<Teleport to="body">
		<generic-modal
			:is-visible="showRateSuccessModal"
			@close="showRateSuccessModal = false"
			:noCloseIcon="true"
        >
			<template #content>
				<div class="px-12 pb-12 w-full lg:w-[500px] h-auto">
					<div class="flex items-center space-x-1.5 mb-6">
						<CheckCircleIcon class="w-6 h-6 text-success" />
						<h3 class="font-semibold">Review submitted!</h3>
					</div>

					<p class="mb-4 text-primary-400 text-sm">Thank you for taking the time to leave a review.</p>
					<p class="mb-6 text-primary-400 text-sm">We will use this information to improve our services and provide better experience in your next meal with us.</p>
					<div class="mt-6 border rounded-[10px] py-5 px-4 space-y-5">
						<p class="text-sm font-semibold mb-5">Wanna give a more detailed review?</p>
						<p class="text-sm text-primary-400 mb-5">Visit our Facebook page and share your experience with other customers!</p>
                        <ion-button
                            class="btn-secondary--lighter"
                            expand="block"
                            :href="link"
                            target="_blank"
                        >
                                <img
                                    src="/assets/icon/ic-facebook-logo.svg"
                                    alt="fb-icon"
                                    class="m-0 mr-1.5" 
                                />
                                Visit us on Facebook
                        </ion-button>
					</div>
					<div class="w-full mt-8">
						<button-component
							color="primary"
							expand="block"
							fill="outline"
							@click="router.go()"
                        >
							<template #content>Return to Order History</template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</Teleport>
</template>
<script>
	import { defineComponent, ref, reactive } from "vue";
	import { useRouter } from "vue-router";
	import AddressCard from "@/components/AddressCard.vue";
	import OrderProductCard from "@/components/OrderProductCard.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import RenderPrice from "@/components/RenderPrice.vue";
	import StarRating from "@/components/StarRating.vue";
	import GenericModal from "@/components/GenericModal.vue";
	import AnchorLink from "@/components/AnchorLink.vue";
	import SideMenu from "@/components/SideMenu.vue";
	import RadioGroup from "@/components/RadioGroup.vue";
	import { useCartStore } from "@/store/cart";
	import useModal from "@/composables/modal";
	import useFormat from "@/composables/useFormat";
	import { XIcon, CheckCircleIcon } from "@heroicons/vue/solid";
	import useLocalStorage from "@/composables/useLocalStorage.js";
    import { useApiStore } from "@/store/api";
    import { IonSpinner, IonButton } from "@ionic/vue";
    import useCms from "@/composables/useCms";

	export default defineComponent({
		components: {
			AddressCard,
			OrderProductCard,
			ButtonComponent,
			RenderPrice,
			GenericModal,
			AnchorLink,
			SideMenu,
			RadioGroup,
			StarRating,
			XIcon,
			CheckCircleIcon,
            IonSpinner,
            IonButton
		},
		emits: ["showTimeline", "order:deleted"],
		props: {
			isDraftOrder: {
				type: Boolean,
				default: false,
			},
			order: {
				type: Object,
				required: true,
			},
			allowCancel: {
				type: Boolean,
				default: false,
			},
			isPaymentProcessing: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const router = useRouter();
			const cartStore = useCartStore();
            const apiStore = useApiStore();
			const modal = useModal();
			const { moneyFormat } = useFormat();
			const { localStorage } = useLocalStorage();
            const { contentSlug, getCms, getCmsContent } = useCms();

			const showNoCancelModal = ref(false);
			const showRatingFormModal = ref(false);
			const showRateSuccessModal = ref(false);
			const showCancelOrder = ref(false);
			const showCancelConfirmationModal = ref(false);
			const showDeleteConfirmationModal = ref(false);
			const selectedReason = ref("Change Order");
			const cancelOrderId = ref(null);
            const loading = ref(true);
            const criteria = ref([]);
            const link = ref(null);
            const viewOrderRateModal = ref(false);
            const ratings = ref([]);

            const form = reactive({
                rating: [],
            });

			const cancelReason = [
				{
					id: "changeOrder",
					label: "Change Order",
					value: "Change Order",
				},
				{
					id: "incorrectInformation",
					label: "Incorrect contact information",
					value: "Incorrect contact information",
				},
				{
					id: "duplicateOrder",
					label: "Duplicate Order",
					value: "Duplicate Order",
				},
				{
					id: "other",
					label: "Other",
					value: "Other",
				},
			];

			const cancelOrder = (id) => {
				cancelOrderId.value = id;
				showCancelOrder.value = true;
			};

			const confirmCancellation = () => {
				window.axios
					.post(process.env.VUE_APP_API_URL + "/api/orders/cancel", {
						orderId: cancelOrderId.value,
						cancel_reason: selectedReason.value,
					})
					.then(() => {
						showCancelConfirmationModal.value = false;
						showCancelOrder.value = false;
						router.push("order-cancelled");
					})
					.catch(() => {
						showNoCancelModal.value = true;
					});
			};

			const typeTitle = () => {
				if (props.order.fulfillment_method == "Pick-up") {
					return "Pick-up from";
				}

				if (props.order.fulfillment_method == "Dine-in") {
					return "Dine-in at";
				}

				if (props.order.fulfillment_method == "Delivery") {
					return "Delivery to";
				}
			};

			const reorder = () => {
				window.axios
					.get(process.env.VUE_APP_API_URL + "/api/cart/reorder/" + props.order.id)
					.then((result) => {
						localStorage.value.set("activeCart", result.data.cart);
						localStorage.value.set("setRecipient", result.data.cart);
						cartStore.updateUserCart(result.data.cart);
						cartStore.fetchCart(result.data.cart);
						cartStore.fetchActiveCart();

						const timeSlotValue = ref(isPast(result.data.cart.timeslot));

						router.push({
							name: "BranchView",
							params: { showTimeSlotModal: timeSlotValue.value === false },
						});
					})
					.catch((error) => {
						modal.error(error.response.data.message);
					});
			};

			const isPast = (timeslot) => {
				const now = new Date();
				const startDate = new Date();
				const endDate = new Date();

				const startObject = timeslot.start_time.split(":");
				const endObject = timeslot.end_time.split(":");

				now.setHours(now.getHours() + 1);

				startDate.setHours(startObject[0], startObject[1], startObject[2]);
				endDate.setHours(endObject[0], endObject[1], endObject[2]);

				if (timeslot.start_time === "23:00:00" && timeslot.end_time === "00:00:00") {
					endDate.setDate(endDate.getDate() + 1);
				}

				timeslot.isPast = endDate < now || startDate < now;

				return timeslot.isPast;
			};

			const edit = async () => {
				await cartStore.edit(props.order.id);
				router.push("/branch-view");
			};

			const destroyOrder = () => {
				showDeleteConfirmationModal.value = false;

				cartStore.delete(
					props.order.id,
					() => {
						emit("order:deleted", "Draft order has been deleted.");
					},
					(error) => {
						modal.error(error.response.data.message);
					}
				);
			};

            const fetchCriteria = async () => {
                window.axios
                    .get(apiStore.route("ordersRatingCriteria", { order: props.order.id }))
                    .then(({ data }) => {
                        loading.value = false;
                        criteria.value = data.criteria;

                        form.rating = criteria.value?.map((item) => ({
                            description: item.description,
                            rating_criteria: item.value,
                            value: 5
                        }));
                    })
                    .catch((error) => {
                        console.log(error);
                        loading.value = false;
                        modal.error(error.response.data.message);
                    });
            }

            const showRatingForm = async () => {
                await fetchCriteria();
                showRatingFormModal.value = true;
            }

            const submitRating = () => {
                window.axios
                    .post(apiStore.route("submitRating", {
                        order: props.order.id,
                    }), form)
                    .then(async() => {
                        showRatingFormModal.value = false;
                        showRateSuccessModal.value = true;
                        fetchCms();
                    })
                    .catch((error) => {
                        console.log(error);
                        modal.error(error.response.data.message);
                    })
            }

            const fetchCms = async () => {
                contentSlug.value = "mobile_rating_page";
                getCms(contentSlug.value);
                link.value = getCmsContent("frame1_link");
            };

            const fetchRating = async () => {
                window.axios
                    .get(apiStore.route("viewRating", { order: props.order.id }))
                    .then(({ data }) => {
                        loading.value = false;
                        ratings.value = data.ratings;

                        form.rating = ratings.value?.map((item) => ({
                            description: item.criteria_name,
                            value: item.value
                        }));
                    })
                    .catch((error) => {
                        console.log(error);
                        loading.value = false;
                        modal.error(error.response.data.message);
                    });
            }

            const viewRating = async () => {
                await fetchRating();
                await fetchCms();
                viewOrderRateModal.value = true;
            }

			return {
				showNoCancelModal,
				showCancelConfirmationModal,
				showDeleteConfirmationModal,
				showRatingFormModal,
                showRatingForm,
				showRateSuccessModal,
				showCancelOrder,
				selectedReason,
				cancelReason,
				cancelOrder,
				confirmCancellation,
				router,
				typeTitle,
				edit,
				destroyOrder,
				moneyFormat,
				reorder,
                criteria,
                loading,
                form,
                submitRating,
                link,
                viewRating,
                viewOrderRateModal,
                fetchRating,
			};
		},
	});
</script>
