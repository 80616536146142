<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="flex items-start lg:items-center justify-center min-h-[calc(100vh-86px)]">
					<div class="px-4 lg:px-0 w-full lg:w-[400px] mx-auto h-[calc(100vh-86px)] lg:h-auto pt-10 lg:pt-0">
						<div class="relative h-full">
							<div class="text-center">
								<h2 class="text-primary-900 font-semibold">Confirm OTP</h2>
								<p class="text-primary-900 text-sm">
									Enter OTP we just sent to
									<span class="font-bold">{{ userStore.userValidation }}</span>
								</p>
							</div>
							<div class="mt-8 w-80 mx-auto flex items-center justify-center gap-3">
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp1"
									@keyup="moveFocus($event, $refs.otp2.$el, '')"
									v-model="otpNum.num1"
								/>
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp2"
									@keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)"
									v-model="otpNum.num2"
								/>
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp3"
									@keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)"
									v-model="otpNum.num3"
								/>
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp4"
									@keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
									v-model="otpNum.num4"
								/>
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp5"
									@keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
									v-model="otpNum.num5"
								/>
								<ion-input
									type="tel"
									class="otp-input"
									placeholder="•"
									maxlength="1"
									autocomplete="off"
									ref="otp6"
									@keyup="moveFocus($event, '', $refs.otp5.$el)"
									v-model="otpNum.num6"
								/>
							</div>
							<div class="mt-8 space-y-2 text-center">
								<p class="text-primary-400 text-sm">Didn't get a code?</p>
								<p class="font-bold text-primary-100 text-sm" v-if="countDown > 0">
									Resend in {{ countDown }}s
								</p>
								<a v-else>
									<anchor-link @click="resendOtp">
										<template #content>
											<span class="text-sm font-semibold text-primary-400"> Resend </span>
										</template>
									</anchor-link>
								</a>
							</div>
							<div class="mt-10 text-center lg:relative absolute bottom-4 w-full">
								<button-component @click="validate" color="primary" customClass="w-full lg:w-auto">
									<template #content>
										<template v-if="!loading">Confirm</template>
										<ion-spinner v-else></ion-spinner>
									</template>
								</button-component>
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import Modal from "@/components/Modal.vue";
import { useRouter } from "vue-router";
import useLocalStorage from "@/composables/useLocalStorage.js";
import { onMounted } from "@vue/runtime-core";
import { IonPage, IonContent, IonInput, IonSpinner, modalController } from "@ionic/vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import useAuth from "@/composables/useAuth";
import { useUserStore } from "@/store/user";
import { useGuestStore } from "@/store/guest";
import { useApiStore } from "@/store/api";
import RegistrationPointsModal from "@/components/RegistrationPointsModal.vue";

export default defineComponent({
	name: "OtpVerification",
	components: {
		IonPage,
		IonContent,
		IonInput,
		IonSpinner,
		TopNavigation,
		BottomNavigation,
		ButtonComponent,
		AnchorLink,
	},
	ionViewDidEnter() {
		setTimeout(() => {
			this.$refs.otp1.$el.setFocus();
		}, 500);
	},
	setup() {
		const { setToken, setRefreshToken, fetchUserData } = useAuth();
		const { localStorage } = useLocalStorage();
		const userStore = useUserStore();
		const apiStore = useApiStore();
		const guestStore = useGuestStore();
		const loading = ref(false);
		const countDown = ref(120);
		const message = ref(null);
		const router = useRouter();
		const title = ref(null);

		const errorModal = async () => {
			loading.value = false;

			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Warning!",
					description: "Failed to register. Please try again later.",
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const successModal = async (points, redirectRoute) => {
			if (points === 0) {
				router.replace(redirectRoute);
			} else {
				const modal = await modalController.create({
					component: RegistrationPointsModal,
					canDismiss: true,
					backdropDismiss: false,
					cssClass: "gen-modal points",
					componentProps: {
						buttonLink: redirectRoute,
						points: points,
					},
				});

				modal.onDidDismiss(() => {
					router.replace(redirectRoute);
				});

				return modal.present();
			}
		};

		const otpNum = [
			{ num1: ref(null) },
			{ num2: ref(null) },
			{ num3: ref(null) },
			{ num4: ref(null) },
			{ num5: ref(null) },
			{ num6: ref(null) },
		];

		const responseModal = async () => {
			loading.value = false;

			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: title.value,
					description: message.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const validate = () => {
			const otpVal = `${otpNum.num1}${otpNum.num2}${otpNum.num3}${otpNum.num4}${otpNum.num5}${otpNum.num6}`;

			const data = {
				otp: otpVal,
				value: userStore.userValidation,
			};

			window.axios
				.post(apiStore.route("validateOtp"), data)
				.then(() => {
					localStorage.value.get("pendingRegisterDetails").then((values) => {
						window.axios
							.post(apiStore.route("register"), values)
							.then((result) => {
								loading.value = false;
								message.value = result.data.message;

								setToken("Bearer " + result.data.access_token, result.data.user);
								setRefreshToken(result.data.refresh_token);

								guestStore.syncToUser().then(() => {
									fetchUserData().then(() => {
										if (guestStore.isSynced) {
											successModal(result.data.points_earned, "/branch-view");
										} else {
											successModal(result.data.points_earned, "/home");
										}
									});
								});
							})
							.catch(() => {
								errorModal();
							});
					});
				})
				.catch((error) => {
					title.value = "Warning!";
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const resendOtp = () => {
			window.axios
				.post(apiStore.route("resendOtp"), {
					val: userStore.userValidation,
				})
				.then((result) => {
					title.value = "Success!";
					message.value = result.data.message;

					countDown.value = 120;
					countDownTimer();
					responseModal();
				})
				.catch((error) => {
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const moveFocus = (event, nextElement, previousElement) => {
			if (event.keyCode == 8 && previousElement) {
				previousElement.setFocus();
			} else if (event.keyCode >= 48 && event.keyCode <= 57) {
				if (nextElement) {
					nextElement.setFocus();
				}
			} else {
				event.path[0].value = "";
			}
		};

		const countDownTimer = () => {
			if (countDown.value > 0) {
				setTimeout(() => {
					countDown.value -= 1;
					countDownTimer();
				}, 1000);
			}
		};

		onMounted(() => {
			countDownTimer();
			userStore.getUserValidation();
			userStore.getUserEmail();
		});

		return {
			loading,
			otpNum,
			router,
			validate,
			resendOtp,
			moveFocus,
			countDown,
			userStore,
		};
	},
});
</script>