import { modalController } from "@ionic/vue";
import Modal from "@/components/Modal.vue";
import { reactive } from "vue";

const modalState = reactive({
	open: false,
});

export default function useModal() {
	const error = async (errors, callback = null) => {
		const modal = await modalController.create({
			component: Modal,
			cssClass: "gen-modal z-[9999]",
			componentProps: {
				title: "Warning!",
				description: errors,
				imageLink: "/assets/icons/ic-warning.svg",
				imageWidth: "80",
				imageHeight: "78",
			},
		});

		if (callback != null) {
			modal.onDidDismiss().then(() => {
				callback();
			});
		}

		return modal.present();
	};

	const success = async (message, callback = null) => {
		const modal = await modalController.create({
			component: Modal,
			cssClass: "gen-modal z-[9999]",
			componentProps: {
				title: "Success!",
				description: message,
				imageLink: "/assets/icons/ic-success.svg",
				imageWidth: "80",
				imageHeight: "78",
			},
		});

		if (callback != null) {
			modal.onDidDismiss().then(() => {
				callback();
			});
		}

		return modal.present();
	};

	return {
		error: error,
		success: success,
		modalState,
	};
}
