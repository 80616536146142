<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div
					class="
						flex
						items-start
						lg:items-center
						justify-center
						min-h-[calc(100vh-72px)]
						lg:min-h-[calc(100vh-86px)]
					"
				>
					<div class="px-4 lg:px-0 w-full lg:w-[400px] mx-auto h-[calc(100vh-86px)] lg:h-auto">
						<div class="relative py-4 lg:py-0 h-full">
							<div class="relative">
								<div @click="() => router.push('/login-account')" class="relative pb-8 block lg:hidden">
									<XIcon class="cursor-pointer w-5 h-5 text-primary-500 absolute right-0" />
								</div>
							</div>
							<div>
								<h2 class="text-primary-900 font-semibold">Reset Password</h2>
								<p class="text-primary-900 text-sm">
									We will send instructions on how to reset your password.
								</p>
							</div>
							<div class="mt-8">
								<text-input
									v-model="email"
									name="email"
									type="email"
									placeholder="Email Address"
								></text-input>
							</div>
							<div class="mt-0 lg:mt-10 text-right absolute lg:relative bottom-4 w-full">
								<button-component
									@click="submit()"
									customClass="w-full lg:w-auto"
									color="primary"
									loading
								>
									<template #content>
										<template v-if="!loading">Confirm</template>
										<ion-spinner v-else></ion-spinner>
									</template>
								</button-component>
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { XIcon } from "@heroicons/vue/outline";
import { IonPage, IonContent, IonSpinner, modalController } from "@ionic/vue";

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import TextInput from "@/components/TextInput.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useUserStore } from "@/store/user";
import Modal from "@/components/Modal.vue";

export default defineComponent({
	name: "EnterCredentials",
	components: {
		IonPage,
		IonContent,
		IonSpinner,
		TopNavigation,
		BottomNavigation,
		XIcon,
		TextInput,
		ButtonComponent,
	},
	setup() {
		const router = useRouter();
		const store = useUserStore();
		const loading = ref(false);
		const errors = ref();
		const email = ref(null);

		const errorModal = async () => {
			loading.value = false;

			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Warning!",
					description: errors.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const submit = () => {
			loading.value = true;

			const params = {
				email: email.value,
			};

			window.axios
				.post(process.env.VUE_APP_API_URL + "/api/forgot-password/", params)
				.then(() => {
					loading.value = false;
					store.setUserEmail(email.value);
					store.setUserValidation(email.value);
					router.push("/email-otp");
				})
				.catch((error) => {
					loading.value = false;
					errors.value = error.response.data.message;
					errorModal();
				});
		};

		return {
			router,
			email,
			submit,
			loading,
		};
	},
});
</script>
