<template>
	<ion-app>
		<ion-router-outlet />
	</ion-app>
</template>

<script type="text/javascript">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { useApiStore } from "@/store/api.js";
import { Geolocation } from "@capacitor/geolocation";

import useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
	},

	setup() {
		const store = useApiStore();
		const { localStorage } = useLocalStorage();

		const interval = 2000;

		const fetch = async () => {
			window.axios.post(process.env.VUE_APP_API_URL + "/api/config/fetch").then((result) => {
				store.cms = result.data.cms;
				localStorage.value.set("cms", result.data.cms);
			});
		};

		const checkLocationPermissions = () => {
			setInterval(() => {
				Geolocation.checkPermissions();
			}, interval);
		};

		onMounted(() => {
			checkLocationPermissions();
			fetch();
		});

		return {
			localStorage,
		};
	},
});
</script>