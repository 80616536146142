<template>
    <div class="cursor-pointer rounded-lg" :class="{ 'opacity-50': disabled }">
        <!-- :class="branch.remarks ? 'opacity-30' : 'opacity-100'" -->
        <div class="relative w-full h-40 rounded-t-lg">
            <img :src="branch.image_path" class="w-full h-full object-cover rounded-t-lg" />
            <div class="absolute right-0 bottom-0 bg-white px-3 py-2 m-4 rounded-full shadow-lg" @click="dial">
                <p class="text-primary-900 text-xs font-bold">
                    Call {{ branch.contact_number }}
                </p>
            </div>
        </div>
        <div class="border rounded-b-lg p-4 space-y-2">
            <div class="flex items-center justify-between">
                <p class="font-bold text-primary-500 text-sm m-0">
                    {{ branch.name }}
                </p>
                <p class="text-primary-400 text-xs m-0">
                    {{ branch.distance.toFixed(2) }} km
                </p>
            </div>
            <div class="bg-error/10 p-1 px-2 rounded mt-2" v-if="branch.remarks">
                <p class="text-error text-[10px] font-semibold">
                    {{ branch.remarks }}
                </p>
            </div>
            <p class="line-clamp-2 text-primary-200 text-xs">
                {{ branch.address }}
            </p>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        branch: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const dial = () => {
            window.open("tel:" + props.branch.contact_number, "_blank");
        };

        return {
            dial,
        };
    },
});
</script>
