<template>
	<side-menu :title="title" :is-visible="open" @close="open = false">
		<template #content>
			<template v-if="step === 1">
				<div>
					<!-- <item-link
                        v-if="withWallet"
                        @click="setMethod('RK Wallet')"
                        title="RK Wallet"
                        description="Pay using your RK Wallet"
                    /> -->
					<!-- <template v-if="withWallet"> -->
					<item-link
						@click="step++"
						title="Debit/Credit"
						description="Pay using your Mastercard, Visa and more"
					/>
					<!-- <item-link
                            @click="setMethod('GCash')"
                            title="GCash"
                            description="Pay using your GCash App"
                        />
                        <item-link
                            @click="setMethod('GrabPay')"
                            title="GrabPay"
                            description="Pay using your Grab App"
                        />
                    </template> -->
				</div>
			</template>
			<template v-if="step === 2">
				<div class="space-y-8">
					<div class="space-y-2">
						<text-input v-model="form.cardNumber" placeholder="Card Number" />

						<div class="flex items-start gap-2">
							<text-input v-model="form.expMonth" placeholder="Expiry Month" helper="MM" />

							<text-input v-model="form.expYear" placeholder="Expiry Year" helper="YYYY" />
							<text-input v-model="form.cvv" placeholder="CVV" :rightIcon="informationCircle" />
						</div>
					</div>
					<button-component color="primary" expand="block" @click="activateMembership()" :disabled="loading">
						<template #content>
							<ion-spinner v-if="loading" />
							<template v-else> Activate Membership </template>
						</template>
					</button-component>
				</div>
			</template>
		</template>
	</side-menu>
</template>
<script>
import { computed, defineComponent, ref, watch } from "vue";
import { informationCircle } from "ionicons/icons";
import SideMenu from "@/components/SideMenu.vue";
import ItemLink from "@/components/ItemLink.vue";
import { modalController, IonSpinner } from "@ionic/vue";
// import RadioLargeCard from "@/components/RadioLargeCard.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import TextInput from "@/components/TextInput.vue";
// import CheckBox from "@/components/CheckBox.vue";
// import SelectMenu from "@/components/SelectMenu.vue";
// import { useUserStore } from "@/store/user";
import useLocalStorage from "@/composables/useLocalStorage.js";
import Modal from "@/components/Modal.vue";
import { useRouter } from "vue-router";

export default defineComponent({
	components: {
		SideMenu,
		ItemLink,
		// RadioLargeCard,
		ButtonComponent,
		TextInput,
		IonSpinner,
		// CheckBox,
		// SelectMenu,
	},
	emits: ["close", "payment:set", "card:set"],
	props: {
		withWallet: {
			type: Boolean,
			default: true,
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const { localStorage } = useLocalStorage();
		// const store = useUserStore();
		const cardSelected = ref(null);
		const open = ref(false);
		const step = ref(1);
		const loading = ref(false);
		const router = useRouter();

		const closeThis = () => {
			open.value = false;
		};

		const form = ref({
			cardNumber: null,
			expMonth: null,
			expYear: null,
			cvv: null,
		});

		const message = ref(null);
		const modalTitle = ref(null);

		const responseModal = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: modalTitle.value,
					description: message.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
					buttonText: "Okay",
				},
			});

			return modal.present();
		};

		const title = computed(() => {
			if (step.value === 1) {
				return "Choose Payment";
			} else if (step.value === 2) {
				return "Debit/Credit";
			} else {
				return "Add New Card";
			}
		});

		watch(
			() => props.isVisible,
			(bool) => (open.value = bool),
			{
				immediate: true,
			}
		);

		watch(
			() => open.value,
			(bool) => {
				if (!bool) {
					emit("close");
				} else {
					if (cardSelected.value && step.value != 2) {
						cardSelected.value = null;
					}

					step.value = 1;
				}
			}
		);

		const setMethod = (method) => {
			emit("payment:set", method);
			open.value = false;
		};

		const selectCard = () => {
			emit("card:set", cardSelected.value);
			setMethod("Debit/Credit");
		};

		const activateMembership = () => {
			const data = {
				card_number: form.value.cardNumber,
				expiration_month: form.value.expMonth,
				expiration_year: form.value.expYear,
				cvv: form.value.cvv,
				is_mobile: "Web",
			};

			if (form.value.cardNumber && form.value.expMonth && form.value.expYear) {
				if (form.value.cardNumber.length < 12 || form.value.cardNumber.length > 19) {
					modalTitle.value = "Warning!";
					message.value = "Please input valid 12-19 card number.";
					responseModal();

					return false;
				}

				if (form.value.expMonth < 1 || form.value.expMonth > 12 || form.value.expMonth.length != 2) {
					modalTitle.value = "Warning!";
					message.value = "Please input valid month (MM).";
					responseModal();

					return false;
				}

				if (form.value.expYear.length != 4) {
					modalTitle.value = "Warning!";
					message.value = "Please input valid year (YYYY).";
					responseModal();

					return false;
				}

				if (form.value.cvv.length != 3) {
					modalTitle.value = "Warning!";
					message.value = "Please input 3-digit CVC.";
					responseModal();

					return false;
				}

				loading.value = true;

				window.axios
					.post(process.env.VUE_APP_API_URL + "/api/subscribe", data)
					.then((result) => {
						loading.value = false;

						// clear inputs
						form.value.cardNumber = null;
						form.value.expMonth = null;
						form.value.expYear = null;
						form.value.cvv = null;

						if (result.data.redirect) {
							window.location.href = result.data.result.response.verificationUrl;
							localStorage.value.set("setUserSubscription", result.data.result.response);
						} else {
							open.value = false;
							router.push("subscription-success");
						}
					})
					.catch((error) => {
						loading.value = false;
                        modalTitle.value = "Warning!";
                        message.value = error.response.data.message;
                        responseModal();
					});
			} else {
				modalTitle.value = "Warning!";
				message.value = "Please input all fields";
				responseModal();
			}
		};

		return {
			open,
			closeThis,
			cardSelected,
			step,
			title,
			informationCircle,
			setMethod,
			selectCard,
			form,
			activateMembership,
			loading,
		};
	},
});
</script>
