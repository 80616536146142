<template>
	<anchor-link customClass="w-full">
		<template #content>
			<div class="cursor-pointer flex flex-col border rounded-xl justify-between p-4 w-full">
				<div class="flex w-full items-center justify-between mb-1">
					<p class="m-0 text-sm font-semibold text-primary-900">
						<span class="gen-badge mr-1.5"></span>
						#{{ order.id }}
					</p>
					<p class="m-0 text-sm text-green-600 font-normal">{{ order.order_status }}</p>
				</div>
				<div class="flex w-full items-center justify-between mb-2 font-normal">
					<p class="m-0 text-xs text-primary-200">
						{{ order.address?.name ?? order.branch?.name }}
					</p>
					<p class="m-0 text-xs text-primary-200">{{ order.fulfillment_method }}</p>
				</div>
				<div class="flex w-full items-center justify-between">
					<div class="relative flex items-center space-x-1">
						<template
							v-for="(item, index) in order.items.slice(0, 3)"
							:key="index">
							<div class="flex-shrink-0 w-7 h-7 rounded bg-primary-50">
								<img
									class="h-full w-full object-cover rounded"
									:src="item.product.image_path" />
							</div>
						</template>
						<template v-if="order.items.length > 3">
							<div class="flex-shrink-0 flex items-center justify-center w-7 h-7 rounded bg-primary-50 p-1 text-center">
								<span class="text-xs text-primary-200">{{ order.items.length - 3 }}</span>
							</div>
						</template>
					</div>
					<p class="m-0 text-sm font-bold text-primary-900">
						<render-price :value="order.total"></render-price>
					</p>
				</div>
				<div class="border-0 border-t w-full mt-3">
					<div class="flex items-center justify-between mt-3 font-normal">
						<p class="m-0 text-xs text-primary-200">
							{{ order.order_date }}
						</p>
						<p class="m-0 text-xs text-primary-200">{{ new Date(order.created_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) }}</p>
					</div>
				</div>
			</div>
		</template>
	</anchor-link>
</template>
<script>
	import { defineComponent } from "vue";
	import AnchorLink from "@/components/AnchorLink.vue";
	import RenderPrice from "@/components/RenderPrice.vue";

	export default defineComponent({
		components: {
			AnchorLink,
			RenderPrice,
		},
		props: {
			order: {
				type: Object,
				required: true,
			},
		},
	});
</script>
