<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true">
			<div class="page-offset notification_con">
				<div class="min-h-[calc(100vh-72px)] lg:min-h-[calc(100vh-80px)] relative">
					<div class="px-4 lg:px-12 py-10 lg:py-14 h-full items-start flex flex-col lg:flex-row">
						<div class="w-full lg:w-[500px] lg:pr-20 flex-shrink-0 min-h-[60vh]">
							<div class="w-full mb-8 flex justify-between items-center">
								<back-button />
							</div>
							<div class="flex items-center justify-between mb-4">
								<h3 class="m-0 text-2xl lg:text-[28px] font-semibold">Notifications</h3>
								<p
									@click="markAllAsRead()"
									class="m-0 cursor-pointer text-primary-500 hover:text-primary-400 transition text-xs font-semibold">
									Mark all as read
								</p>
							</div>
							<div class="w-full">
								<ion-segment
									scrollable
									class="mb-6 border-b tab"
									v-model="activeTab"
									@ionChange="activeTab = $event.target.value"
									color="rk-primary">
									<ion-segment-button :value="0">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === 0 ? 'font-semibold text-black' : 'text-gray-500']">
											All
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.ANNOUNCEMENTS">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.ANNOUNCEMENTS ? 'font-semibold text-black' : 'text-gray-500']">
											Announcements
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.PROMOS">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.PROMOS ? 'font-semibold text-black' : 'text-gray-500']">
											Promos
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.NEWSLETTER">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.NEWSLETTER ? 'font-semibold text-black' : 'text-gray-500']">
											Newsletter
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.UPDATES">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.UPDATES ? 'font-semibold text-black' : 'text-gray-500']">
											Updates
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.RK_GIFTS">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.RK_GIFTS ? 'font-semibold text-black' : 'text-gray-500']">
											RK Gifts
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.RK_POINTS">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.RK_POINTS ? 'font-semibold text-black' : 'text-gray-500']">
											RK Points
										</p>
									</ion-segment-button>
									<ion-segment-button :value="Types.RK_PRIME">
										<p
											class="normal-case text-sm"
											:class="[parseInt(activeTab) === Types.RK_PRIME ? 'font-semibold text-black' : 'text-gray-500']">
											RK Prime
										</p>
									</ion-segment-button>
								</ion-segment>

								<div
									v-if="loading"
									class="text-center mt-4">
									<ion-spinner />
								</div>
								<div
									v-else
									class="w-full space-y-2 mt-4">
									<template v-if="notifications.length > 0">
										<notification-card
											v-for="item in notifications"
											:key="item"
											:title="item.data?.title"
											:date="item.created_at_formatted"
											:description="item.data?.message"
											@click="viewNotification(item)"
											:class="{ 'border-primary-500': selected === item.id }"
											:isNew="!item.read_at" />
									</template>
									<p
										v-else
										class="text-center">
										No data to display.
									</p>
								</div>
							</div>
						</div>
						<div
							class="pl-14 w-full absolute lg:relative inset-0 mt-6 lg:mt-0 z-[999] h-full translate-x-full lg:translate-x-0 transition-all duration-500 bg-white border-0 lg:border-l min-h-[60vh]"
							ref="notificationContainer">
							<div
								class="px-4 block lg:hidden relative z-[999]"
								@click="goBack">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="w-6 h-6"
									viewBox="0 0 512 512">
									<path
										fill="none"
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="48"
										d="M244 400L100 256l144-144M120 256h292" />
								</svg>
							</div>
							<div class="max-w-none lg:max-w-[1024px] mx-auto bg-white p-4 lg:p-0 h-auto">
								<notification-article-card
									v-if="notificationView"
									:item="notificationView" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<bottom-navigation></bottom-navigation>
		</ion-content>
	</ion-page>
</template>
<script>
	import { defineComponent, ref, computed } from "vue";
	import { IonPage, IonContent, modalController, onIonViewWillEnter, IonSpinner, IonSegment, IonSegmentButton } from "@ionic/vue";
	import { useRouter } from "vue-router";
	// import { FunTabs, FunTabItem } from "fun-tab";
	import "fun-tab/dist/index.css";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import NotificationCard from "./Components/NotificationCard.vue";
	import NotificationArticleCard from "./Components/NotificationArticleCard.vue";
	import BackButton from "@/components/BackButton.vue";
	import Modal from "@/components/Modal.vue";
	import useModal from "@/composables/modal";
	import { useApiStore } from "@/store/api";

	export default defineComponent({
		components: {
			IonPage,
			IonContent,
			TopNavigation,
			BottomNavigation,
			NotificationArticleCard,
			NotificationCard,
			BackButton,
			// FunTabs,
			// FunTabItem,
			IonSpinner,
			IonSegment,
			IonSegmentButton,
		},
		setup() {
			const router = useRouter();
			const modal = useModal();
			const apiStore = useApiStore();

			const loading = ref(true);
			const activeTab = ref(0);
			const items = ref([]);
			const notificationView = ref(null);
			const notificationContainer = ref(null);
			const windowWidth = window.matchMedia("(max-width: 1024px)");
			const selected = ref(null);

			const Types = {
				ANNOUNCEMENTS: 1,
				PROMOS: 2,
				NEWSLETTER: 3,
				UPDATES: 4,
				RK_GIFTS: 5,
				RK_POINTS: 6,
				RK_PRIME: 7,
			};

			const notifications = computed(() => {
				const tab = parseInt(activeTab.value);

				const filteredItems = items.value.filter((item) => {
					const type = parseInt(item.data?.type);
					return !tab || type === tab;
				});

				return tab ? filteredItems : items.value;
			});

			const success = async () => {
				const modal = await modalController.create({
					component: Modal,
					cssClass: "gen-modal",
					componentProps: {
						title: "Success!",
						description: "All notifications have been marked as read.",
						imageLink: "/assets/icons/ic-warning.svg",
						imageWidth: "80",
						imageHeight: "78",
						buttonText: "Okay",
					},
				});

				return modal.present();
			};

			const fetchNotifications = async () => {
				window.axios
					.get(apiStore.route("fetchNotifications"))
					.then(({ data }) => {
						loading.value = false;
						items.value = data.notifications;
					})
					.catch((error) => {
						console.log(error);
						loading.value = false;
						modal.error(error.response.data.message);
					});
			};

			const markAllAsRead = () => {
				window.axios
					.post(apiStore.route("markAllAsRead"))
					.then(async () => {
						await success();
						await fetchNotifications();
					})
					.catch((error) => {
						console.log(error);
						loading.value = false;
						modal.error(error.response.data.message);
					});
			};

			const goBack = () => {
				if (windowWidth.matches) {
					notificationContainer.value.classList.add("translate-x-full");
					notificationContainer.value.classList.remove("translate-x-0");
				}
			};

			const viewNotification = (item) => {
				notificationView.value = item;
				selected.value = item.id;
				markAsRead(item);
				console.log("item", item);
				console.log("notificationView.value", notificationView.value);

				if (windowWidth.matches) {
					notificationContainer.value.classList.remove("translate-x-full");
					notificationContainer.value.classList.add("translate-x-0");
				}
			};

			const markAsRead = (item) => {
				loading.value = true;
				window.axios
					.post(apiStore.route("markAsRead", { notification: item.id }))
					.then(async () => {
						await fetchNotifications();
						loading.value = false;
					})
					.catch((error) => {
						console.log(error);
						loading.value = false;
						modal.error(error.response.data.message);
					});
			};

			onIonViewWillEnter(() => {
				fetchNotifications();
			});

			return {
				Types,
				router,
				activeTab,
				notifications,
				markAllAsRead,
				loading,
				notificationView,
				viewNotification,
				goBack,
				notificationContainer,
				selected,
			};
		},
	});
</script>
