<template>
	<div class="ion-padding px-11 py-6">
		<ion-row>
			<ion-col size="12" class="p-0 text-center">
				<img
					src="/assets/icon/ic-modal-gold.svg"
					alt="gold"
					class="w-full block mb-6"
				/>
				<h4 class="text-primary-900 font-semibold mb-4">
					{{ title }}
				</h4>
				<p class="text-sm text-primary-400 mb-6">
					You earned
					<span class="font-semibold"> {{ points }} points. </span>
				</p>
				<a
					class="text-sm font-semibold cursor-pointer"
					@click="redirect"
					v-if="buttonLink"
				>
					{{ buttonText }}
				</a>
			</ion-col>
		</ion-row>
	</div>
</template>

<script>
import { modalController, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "registration-points-modal",
	props: {
		title: {
			type: String,
			default: "Account created!",
		},
		buttonLink: {
			type: String,
			default: null,
		},
		buttonText: {
			type: String,
			default: "Okay",
		},

		points: {
			type: [String, Number],
		},
	},
	components: {
		IonRow,
		IonCol,
	},
	setup(props) {
		const router = useRouter();
		const redirect = async () => {
			await modalController.dismiss();
			router.replace(props.buttonLink);
		};

		return {
			redirect,
		};
	},
});
</script>