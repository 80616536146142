<template>
	<ion-page>
		<top-navigation :showButtons="false"></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="relative py-4 lg:py-0 px-4">
					<div @click="() => router.push('/home')" class="relative pb-8 block lg:hidden">
						<XIcon class="cursor-pointer w-5 h-5 text-primary-500 absolute right-0" />
					</div>
				</div>
				<div class="flex items-center justify-center min-h-[calc(100vh-72px)] lg:min-h-[calc(100vh-86px)] -mt-12 lg:mt-0">
					<div class="px-4 lg:px-0 w-full lg:w-80 mx-auto">
						<h2 class="text-primary-900 font-semibold text-2xl lg:text-[28px]">Welcome to<br />Ramen Kuroda!</h2>
						<div class="mt-8 space-y-4">
							<button-component color="blue" expand="block" @click="facebookSignin" v-if="apiStore?.settings?.withFacebookLogin">
								<template #content> Continue with Facebook </template>
							</button-component>
							<button-component color="red" expand="block" @click="googleSignin" v-if="apiStore?.settings?.withGoogleLogin">
								<template #content> Continue with Google </template>
							</button-component>

							<p
								class="text-xs text-primary-900 text-center"
								v-if="apiStore?.settings?.withFacebookLogin || apiStore?.settings?.withGoogleLogin">
								or continue with
							</p>

							<button-component @click="router.push('validation')" color="primary" expand="block">
								<template #content> Email/Mobile Number </template>
							</button-component>
							<p
								class="text-sm text-primary-900 text-center font-semibold pt-6 cursor-pointer"
								@click="
									router.push({
										path: '/home',
										query: {
											guest: 1,
										},
									})
								">
								Continue as Guest
							</p>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
	import { defineComponent } from "vue";
	import { XIcon } from "@heroicons/vue/outline";
	import { useRouter } from "vue-router";
	import { IonPage, IonContent, loadingController, modalController, onIonViewWillEnter } from "@ionic/vue";
	import { FacebookLogin } from "@capacitor-community/facebook-login";
	import { useApiStore } from "@/store/api";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import useModal from "@/composables/modal";
	import useAuth from "@/composables/useAuth";
	import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
	import RegistrationPointsModal from "@/components/RegistrationPointsModal.vue";

	export default defineComponent({
		name: "LoginOption",
		components: {
			IonPage,
			IonContent,
			TopNavigation,
			BottomNavigation,
			XIcon,
			ButtonComponent,
		},
		setup() {
			const router = useRouter();
			const modal = useModal();
			const apiStore = useApiStore();
			const { setToken, setRefreshToken, fetchUserData } = useAuth();

			const showLoading = async (message = "Requesting ...") => {
				const loading = await loadingController.create({
					message: message,
					mode: "ios",
				});

				loading.present();
			};

			const facebookSignin = async () => {
				FacebookLogin.logout();

				FacebookLogin.login({
					permissions: ["email", "public_profile"],
				})
					.then((response) => {
						if (response?.accessToken) {
							showLoading("Verifying ...");

							// get the profile in the Facebook Graph API: https://developers.facebook.com/docs/graph-api/reference/user
							FacebookLogin.getProfile({
								fields: ["email", "first_name", "last_name"],
							})
								.then((profileResult) => {
									successLogin(apiStore.route("loginFacebookVerify"), {
										facebook: profileResult,
									});
								})
								.catch((error) => {
									loadingController.dismiss();
									modal.error(error.response.data.message);
								});
						} else {
							modal.error("Failed to authenticate account using Facebook.");
						}
					})
					.catch(() => {
						modal.error("Failed to authenticate account using Facebook.");
					});
			};

			const googleSignin = async () => {
				GoogleAuth.signOut();

				GoogleAuth.signIn()
					.then((response) => {
						if (response?.authentication) {
							showLoading("Verifying ...");

							successLogin(apiStore.route("loginGoogleVerify"), {
								google: response,
							});
						} else {
							modal.error("Failed to authenticate account using Google.");
						}
					})
					.catch(() => {
						modal.error("Failed to authenticate account using Google.");
					});
			};

			const successModal = async (points) => {
				if (points === 0) {
					router.replace("/edit-profile");
				} else {
					const modal = await modalController.create({
						component: RegistrationPointsModal,
						canDismiss: true,
						backdropDismiss: false,
						cssClass: "gen-modal points",
						componentProps: {
							buttonLink: "/edit-profile",
							points: points,
						},
					});

					modal.onDidDismiss().then(() => {
						router.replace("/edit-profile");
					});

					return modal.present();
				}
			};

			const successLogin = (url, params) => {
				window.axios
					.post(url, params)
					.then((loginResult) => {
						setToken("Bearer " + loginResult.data.access_token, loginResult.data.user);
						setRefreshToken(loginResult.data.refresh_token);

						fetchUserData().then(() => {
							loadingController.dismiss();

							if (loginResult.data.social_register) {
								successModal(loginResult.data.points_earned);
							} else {
								router.replace("/home");
							}
						});
					})
					.catch((error) => {
						loadingController.dismiss();
						modal.error(error.response.data.message);
					});
			};

			onIonViewWillEnter(() => {
				FacebookLogin.initialize({
					appId: process.env.VUE_APP_FACEBOOK_APP_ID,
				});

				GoogleAuth.initialize({
					clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
					scopes: ["profile", "email"],
					grantOfflineAccess: true,
				});
			});

			return {
				router,
				facebookSignin,
				googleSignin,
				apiStore,
			};
		},
	});
</script>
