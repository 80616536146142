<template>
    <div class="cursor-pointer flex flex-col space-y-2 justify-between p-4 border rounded-lg mb-2">
        <div class="flex items-center justify-between">
            <p class="m-0 text-xs text-primary-400">{{ generateDescription(item) }}</p>
            <p class="m-0 text-xs text-primary-400">{{ item.created_at_formatted }}</p>
        </div>
        <div class="flex items-center justify-between">
            <p class="m-0 text-sm text-primary-500 font-semibold">{{ generateLabel(item) }}</p>
            <div class="space-y-2 text-right">
                <p
                    v-if="item.transaction_type === 'added'"
                    class="text-sm font-semibold text-success">
                    +
                    <render-price :value="item.amount" />
                </p>
                <p
                    v-if="item.transaction_type === 'refunded'"
                    class="text-sm font-semibold text-success">
                    +
                    <render-price :value="item.amount" />
                </p>
                <p
                    v-if="item.transaction_type === 'deducted'"
                    class="text-sm font-semibold text-error">
                    -
                    <render-price :value="item.amount" />
                </p>
                <p
                    v-if="item.transaction_type === 'reset'"
                    class="text-sm font-semibold text-blue-400">
                    <render-price :value="item.amount" />
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import _ from "lodash";
import RenderPrice from "@/components/RenderPrice.vue";

export default defineComponent({
    components: {
        RenderPrice
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    setup() {
        const generateDescription = (log) => {
            if (log.transaction_type === "added") {
                if (log.source_name === "Gift") {
                    return "Received gift from";
                } else {
                    if (log.source?.payment) {
                        return "Top up (" + identifyPaymentMethod(log.source?.payment?.method) + ") ";
                    } else {
                        if (_.startCase(log.source_type.split("\\").pop()) === "Order") {
                            return "Order Refund ";
                        } else {
                            return "Top up ";
                        }
                    }
                }
            } else {
                return _.startCase(log.source_type.split("\\").pop()) + " Payment ";
            }
        };

        const generateLabel = (log) => {
            if (log.source_name === "Gift") {
                return log.source?.sender?.name;
            } else {
                return log.source_name + " #" + log.source_id;
            }
        };

        const identifyPaymentMethod = (payment) => {
            return payment == "Card" ? "Debit/Credit" : payment;
        };

        return {
            generateDescription,
            generateLabel,
        }
    },
})
</script>
