import { defineStore } from "pinia";
import { useUserStore } from "./user";
import { useApiStore } from "./api";
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useOrderStore = defineStore({
	id: "order",
	state: () => {
		return {
			orders: [],
			data: {},
			items: [],
			toRateCount: 0,
		};
	},
	getters: {
		getToRateCount: (state) => state.toRateCount,
	},
	actions: {
		async fetchActive() {
			const { localStorage } = useLocalStorage();

			if (await localStorage.value.get("authToken")) {
				const userStore = useUserStore();
				const apiStore = useApiStore();

				return window.axios.get(apiStore.route("ordersActive")).then((result) => {
					userStore.activeOrders = result.data.orders;

					return true;
				});
			}
		},
        async fetchToRateCount() {
            const { localStorage } = useLocalStorage();
    
            if (await localStorage.value.get("authToken")) {
                const apiStore = useApiStore();
    
                return window.axios
                    .get(apiStore.route("ordersToRate"))
                    .then(({ data }) => {
                        this.toRateCount = data.count;
                    });
            }
        },
	},
});
