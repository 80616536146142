<template>
	<ion-col size="12" class="p-0 text-center">
		<h3 class="font-semibold mb-2">Confirm OTP</h3>
		<p class="text-sm text-primary-400 mb-14">
			Enter OTP we just sent to
			<span class="font-bold">{{ userStore.userValidation }}</span>
		</p>
		<form action="#" class="mb-8">
			<div class="w-full mx-auto">
				<div class="flex flex-row -mx-1">
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num1"
							ref="otp1"
							@keyup="moveFocus($event, $refs.otp2.$el, '')"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num2"
							ref="otp2"
							@keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num3"
							ref="otp3"
							@keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num4"
							ref="otp4"
							@keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num5"
							ref="otp5"
							@keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
					<div class="relative flex justify-center px-1">
						<ion-input
							v-model="otpNum.num6"
							ref="otp6"
							placeholder="•"
							class="otp otp-input"
							maxlength="1"
							inputmode="numeric"
							autocomplete="off">
						</ion-input>
						<span class="underline"></span>
					</div>
				</div>
			</div>
		</form>
		<p class="text-sm text-primary-400 mb-2">Didn’t get a code?</p>
		<template v-if="countDown !== 0">
			<p class="text-sm font-semibold text-primary-100">Resend in {{ countDown }}s</p>
		</template>
		<template v-else>
			<p @click="resendOtp" class="text-sm font-semibold text-primary-500">Resend</p>
		</template>
	</ion-col>
	<ion-col size="12" class="mt-auto text-center p-0">
		<ion-button
			@click="validate"
			class="btn-primary"
			:class="singleSignOn ? 'btn-red' : ''"
			shape="round"
			size="large"
			expand="full">
			<div v-if="!loading">Confirm</div>
			<ion-spinner v-else></ion-spinner>
		</ion-button>
	</ion-col>
</template>

<script>
import { IonInput, IonCol, IonButton, IonSpinner, modalController, onIonViewWillEnter } from "@ionic/vue";
import Modal from "@/components/Modal.vue";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useAuth from "@/composables/useAuth";
import { useUserStore } from "@/store/user";
import { useApiStore } from "@/store/api";
import { onMounted } from "@vue/runtime-core";

export default {
	name: "OTPPage",
	components: {
		IonInput,
		IonCol,
		IonButton,
		IonSpinner,
		// TextInput
	},
	props: {
		validateUrl: {
			type: String,
		},
		resendUrl: {
			type: String,
		},
		singleSignOn: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: null,
		},
	},
	setup(props) {
		const { logout } = useAuth();
		const userStore = useUserStore();
		const apiStore = useApiStore();
		const loading = ref(false);
		const countDown = ref(120);
		const message = ref(null);
		const router = useRouter();
		const title = ref(null);

		const otpNum = [
			{ num1: ref(null) },
			{ num2: ref(null) },
			{ num3: ref(null) },
			{ num4: ref(null) },
			{ num5: ref(null) },
			{ num6: ref(null) },
		];

		const responseModal = async () => {
			loading.value = false;

			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: title.value,
					description: message.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
					buttonText: "Okay",
				},
			});

			return modal.present();
		};

		const validate = () => {
			console.log("validate");

			const otpVal = `${otpNum.num1}${otpNum.num2}${otpNum.num3}${otpNum.num4}${otpNum.num5}${otpNum.num6}`;

			const data = {
				otp: otpVal,
				value: userStore.userValidation,
			};

			console.log(data);
			if (loading.value) {
				return;
			}
			loading.value = true;
			window.axios
				.post(apiStore.route("validateOtp"), data)
				.then(() => {
					singleSignOnOtp();
				})
				.catch((error) => {
					console.log(error);
					loading.value = false;
					title.value = "Warning!";
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const singleSignOnOtp = () => {
			if (props.type === "delete") {
				deleteAccount();
			} else if (props.type === "deactivated") {
				deactivateAccount();
			}
		};

		const deleteAccount = () => {
			window.axios
				.patch(apiStore.route("singleSignOnProfileDelete"))
				.then(() => {
					loading.value = false;

					setTimeout(() => {
						logout();
					}, 2000);
				})
				.catch((error) => {
					loading.value = false;
					console.log(error);
					title.value = "Warning!";
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const deactivateAccount = () => {
			window.axios
				.patch(apiStore.route("singleSignOnProfileDeactivate"))
				.then(() => {
					loading.value = false;

					setTimeout(() => {
						logout();
					}, 2000);
				})
				.catch((error) => {
					loading.value = false;
					console.log(error);
					title.value = "Warning!";
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const resendOtp = () => {
			if (loading.value) {
				return;
			}
			loading.value = false;
			window.axios
				.post(apiStore.route("resendOtp"), {
					val: userStore.userValidation,
				})
				.then((result) => {
					console.log(result);
					title.value = "Success!";
					message.value = result.data.message;
					loading.value = true;
					countDown.value = 120;
					countDownTimer();
					responseModal();
				})
				.catch((error) => {
					console.log(error);
					loading.value = false;
					message.value = error.response.data.message;
					responseModal();
				});
		};

		const moveFocus = (event, nextElement, previousElement) => {
			if (event.keyCode == 8 && previousElement) {
				previousElement.setFocus();
			} else if (event.keyCode >= 48 && event.keyCode <= 57) {
				if (nextElement) {
					nextElement.setFocus();
				}
			} else {
				event.path[0].value = "";
			}
		};

		const countDownTimer = () => {
			if (countDown.value > 0) {
				setTimeout(() => {
					countDown.value -= 1;
					countDownTimer();
				}, 1000);
			}
		};

		onIonViewWillEnter(() => {
			countDownTimer();
			userStore.getUserValidation();
			userStore.getUserEmail();
		});

		onMounted(() => {
			countDownTimer();
			userStore.getUserValidation();
			userStore.getUserEmail();
		});

		return {
			loading,
			otpNum,
			router,
			validate,
			resendOtp,
			moveFocus,
			countDown,
			userStore,
		};
	},
};
</script>
