<template>
    <anchor-link
        customClass="w-full"
        :class="border ? 'border rounded px-4' : ''"
    >
        <template #content>
            <div
                class="
                    flex
                    justify-between
                    py-4
                    last:border-0
                    cursor-pointer
                    w-full
                "
            >
                <div class="text-left">
                    <p
                        class="text-sm font-semibold text-primary-900"
                        :class="description ? 'mb-2' : ''"
                    >
                        {{ title }}
                    </p>
                    <p class="text-xs text-primary-400">{{ description }}</p>
                </div>
                <ion-icon
                    :src="chevronForward"
                    class="w-4 h-4 text-black"
                ></ion-icon>
            </div>
        </template>
    </anchor-link>
</template>
<script>
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";

import AnchorLink from "@/components/AnchorLink.vue";

export default defineComponent({
    components: {
        IonIcon,
        AnchorLink,
    },
    props: {
        border: {
            type: Boolean,
            default: false,
        },
        title: {
            required: true,
        },
        description: {
            type: String,
        },
    },
    setup() {
        return {
            chevronForward,
        };
    },
});
</script>
