<template>
	<div
		class="rounded-lg border"
		:class="getProductStock().stock === 0 ? 'pointer-events-none relative before:absolute before:block before:content before:inset-0 before:w-full before:h-full before:bg-neutral-500/40 before:z-50 before:rounded-lg' : 'cursor-pointer'">
		<div class="flex flex-col lg:flex-row items-stretch h-full w-full">
			<div
				class="flex-none bg-primary-50 relative"
				v-if="product.image_path">
				<img
					:src="product.image_path"
					class="w-full lg:w-[146px] h-full object-cover" />
				<span
					class="absolute top-2 right-2 bg-error text-xs font-semibold px-3 py-1 rounded-3xl text-white"
					v-if="itemsPerProduct(product.id, 'Product') > 0">
					{{ itemsPerProduct(product.id, "Product") }}
				</span>
			</div>
			<div
				class="relative flex-auto p-4 flex flex-col justify-between w-full"
				:class="{ 'gap-6': getProductStock().stock !== 0 }">
				<span
					class="absolute top-2 right-2 bg-error text-xs font-semibold px-3 py-1 rounded-3xl text-white"
					v-if="itemsPerProduct(product.id, 'Product') > 0 && !product.image_path">
					{{ itemsPerProduct(product.id, "Product") }}
				</span>
				<div>
					<h4
						class="font-bold text-sm m-0 line-clamp-2"
						:class="getProductStock().stock === 0 ? 'text-primary-200' : 'text-primary-500'">
						{{ product.name }}
					</h4>
					<p
						class="text-xs m-0 line-clamp-2 mt-1"
						:class="getProductStock().stock === 0 ? 'text-primary-100' : 'text-primary-300'">
						{{ product.description }}
					</p>

					<!-- Free Items -->
					<div
						v-if="product.free_items.length > 0 || product.applied_free_items.length > 0"
						class="mt-3">
						<!-- Product Specific -->
						<template
							v-for="freeItem in product.free_items"
							:key="freeItem">
							<template v-if="freeItem.get_product?.inventories[0]">
								<p
									class="text-xs font-semibold m-0 line-clamp-2 text-error"
									v-if="freeItem.get_product.inventories[0].stock > 0">
									*Free {{ freeItem.get_product.name }}
								</p>
							</template>
						</template>

						<!-- Category Specific & ALL -->
						<template
							v-for="freeItem in product.applied_free_items"
							:key="freeItem">
							<template v-if="freeItem.get_product?.inventories[0]">
								<p
									class="text-xs font-semibold m-0 line-clamp-2 text-error"
									v-if="freeItem.get_product.inventories[0].stock > 0">
									*Free {{ freeItem.get_product.name }}
								</p>
							</template>
						</template>
					</div>
				</div>
				<div class="flex items-center justify-between">
					<h4
						class="flex-1 font-bold text-sm m-0"
						:class="getProductStock().stock === 0 ? 'text-primary-200' : 'text-primary-500'">
						<render-price
							:value="productPrice(product)"
							:slashed="product.applied_item_discounts.length > 0 && product.applied_item_discounts.find((obj) => obj !== undefined) !== null"
							:old-price="product.price" />
					</h4>
					<button-component
						customClass="icon-only"
						color="primary"
						v-if="getProductStock().stock !== 0">
						<template #content>
							<PlusIcon class="h-3 w-3 text-white" />
						</template>
					</button-component>
				</div>

				<p
					class="text-error font-bold text-sm m-0"
					v-if="getProductStock().stock === 0">
					Not available
				</p>
			</div>
		</div>
	</div>
</template>
<script>
	import { defineComponent, onMounted, ref } from "vue";
	import { PlusIcon } from "@heroicons/vue/solid";
	import RenderPrice from "@/components/RenderPrice.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import { useCartStore } from "@/store/cart";

	export default defineComponent({
		components: {
			PlusIcon,
			RenderPrice,
			ButtonComponent,
		},
		props: {
			product: {
				type: Object,
				required: true,
			},
		},
		setup(props) {
			const cartStore = useCartStore();
			const inventories = ref([]);

			const getProductStock = () => {
				return (inventories.value = props.product.inventories[0] ?? {
					stock: 0,
				});
			};

			onMounted(() => {
				getProductStock();
			});

			return {
				productPrice: cartStore.productPrice,
				itemsPerProduct: cartStore.itemsPerProduct,
				getProductStock,
			};
		},
	});
</script>
