<template>
    <anchor-link :custom-class="'w-full ion-text-wrap text-left'">
        <template #content>
            <div class="border-b py-6 w-full">
                <div class="flex items-start justify-start gap-3">
                    <LocationMarkerIcon class="w-4 h-4 text-primary-500" />
                    <p
                        class="
                            font-normal
                            text-primary-500 text-sm
                            line-clamp-2
                            w-72
                            m-0
                        "
                    >
                        {{ address }}
                    </p>
                </div>
            </div>
        </template>
    </anchor-link>
</template>
<script>
import { defineComponent } from "vue";
import { LocationMarkerIcon } from "@heroicons/vue/solid";

import AnchorLink from "@/components/AnchorLink.vue";

export default defineComponent({
    components: {
        LocationMarkerIcon,
        AnchorLink,
    },
    props: {
        address: {
            type: String,
            default: null,
        },
    },
    setup() {},
});
</script>
