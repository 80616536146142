<template>
	<div>
		<div class="mb-6">
			<h3 class="font-semibold">Password</h3>
		</div>
		<div class="space-y-2">
			<!-- Current Password -->
			<div class="relative" v-if="userStore.userData.has_password">
				<input
					:type="showCurrentPassword ? 'text' : 'password'"
					class="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-primary-50 placeholder:text-primary-100 rounded-lg"
					placeholder="Current Password"
					v-model="form.currentPassword" />
				<div
					@click="showCurrentPassword = !showCurrentPassword"
					class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]">
					<template v-if="!showCurrentPassword">
						<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
					<template v-else>
						<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
				</div>
				<p class="text-[10px] text-error mt-1">
					{{ errors?.currentPassword ? errors.currentPassword[0] : null }}
				</p>
			</div>

			<!-- New Password -->
			<div class="relative">
				<input
					:type="showNewPassword ? 'text' : 'password'"
					class="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-primary-50 placeholder:text-primary-100 rounded-lg"
					placeholder="New Password"
					v-model="form.newPassword" />
				<div @click="showNewPassword = !showNewPassword" class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]">
					<template v-if="!showNewPassword">
						<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
					<template v-else>
						<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
				</div>
				<p class="text-[10px] text-error mt-1">
					{{ errors?.newPassword ? errors.newPassword[0] : null }}
				</p>
			</div>

			<!-- Confirm New Password -->
			<div class="relative">
				<input
					:type="showConfirmPassword ? 'text' : 'password'"
					class="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-primary-50 placeholder:text-primary-100 rounded-lg"
					placeholder="Confirm New Password"
					v-model="form.confirmPassword" />
				<div
					@click="showConfirmPassword = !showConfirmPassword"
					class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center h-[38px]">
					<template v-if="!showConfirmPassword">
						<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
					<template v-else>
						<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
					</template>
				</div>
			</div>
			<p class="text-[10px] text-error mt-1">
				{{ errors?.confirmPassword ? errors.confirmPassword[0] : null }}
			</p>
		</div>

		<div class="flex justify-end mt-6">
			<button-component color="primary" @click="submit">
				<template #content> Save </template>
			</button-component>
		</div>
	</div>

	<!----------------- 
        TOASTS 
    ------------------>

	<toast-message :show="showToast" :message="message" @didDismiss="showToast = false" />
</template>
<script>
import { defineComponent, ref } from "vue";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import { loadingController, modalController } from "@ionic/vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import ToastMessage from "@/components/ToastMessage.vue";
import Modal from "@/components/Modal.vue";
import { useUserStore } from "@/store/user";

export default defineComponent({
	components: {
		EyeIcon,
		EyeOffIcon,
		ButtonComponent,
		ToastMessage,
	},
	setup() {
		const showCurrentPassword = ref(false);
		const showNewPassword = ref(false);
		const showConfirmPassword = ref(false);
		const userStore = useUserStore();

		const showToast = ref(false);
		const message = ref(null);

		const errors = ref([]);
		const form = ref({
			currentPassword: null,
			newPassword: null,
			confirmPassword: null,
		});

		const errorModal = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: "Warning!",
					description: message.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
				},
			});

			return modal.present();
		};

		const showLoading = async () => {
			const loading = await loadingController.create({
				message: "Saving New Password ...",
				mode: "ios",
			});
			loading.present();
		};

		const submit = () => {
			showLoading();

			window.axios
				.post(process.env.VUE_APP_API_URL + "/api/profile/change-password", form.value)
				.then((result) => {
					loadingController.dismiss();
					message.value = result.data.message;
					showToast.value = true;

					errors.value = null;
					form.value.currentPassword = null;
					form.value.newPassword = null;
					form.value.confirmPassword = null;

					userStore.userData.has_password = true;
				})
				.catch((error) => {
					message.value = error.response.data.message;
					errors.value = error.response.data.errors;
					loadingController.dismiss();

					errorModal();
				});
		};

		return {
			userStore,
			showCurrentPassword,
			showNewPassword,
			showConfirmPassword,
			form,
			submit,
			errors,
			message,
			showToast,
		};
	},
});
</script>
