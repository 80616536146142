<template>
	<div class="p-4 border rounded-lg">
		<div class="flex flex-col items-start gap-2 pb-4 border-b">
			<div class="flex items-start gap-2" v-if="item.product">
				<img
					:src="item.product.image_path"
					class="w-20 h-20 object-cover rounded-lg"
					v-if="item.product.image_path"
				/>
				<div>
					<h4 class="font-bold text-sm m-0">{{ item.product.name }}</h4>
					<p
						class="text-primary-300 text-xs m-0 flex flex-col items-start text-left"
						v-if="item.addons.length > 0"
					>
						<span v-for="addon in item.addons" :key="addon" class="m-0">
							{{ addon.quantity }}x
							{{ addon.option.product ? addon.option.product.name : addon.option.name }}
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="flex items-center justify-between pt-4">
			<h4 class="flex-1 font-bold text-sm m-0">
				<render-price :value="getTotal()"></render-price>
			</h4>
			<div class="flex-1 flex items-center gap-2 justify-end">
				<div class="w-8 h-6 bg-primary-500 text-white p-1 rounded-md text-center flex items-center">
					<p class="text-sm mx-auto my-0">{{ item.quantity }}</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";

import RenderPrice from "@/components/RenderPrice.vue";

export default defineComponent({
	components: {
		RenderPrice,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const getTotal = () => {
			let total = parseFloat(props.item.price);

			if (props.item.add_options_total) {
				total += parseFloat(props.item.addons_total);
			}

			return total;
		};

		return {
			getTotal,
		};
	},
});
</script>
