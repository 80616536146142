<template>
	<template v-if="group.is_required && group.is_single">
		<div class="flex items-center h-6">
			<input
				:value="option.id"
				@change.prevent="(event) => update(event)"
				:id="`option-${group.id}-${index}`"
				aria-describedby="small-description"
				:name="`option-${group.id}`"
				:type="group.is_single ? 'radio' : 'checkbox'"
				:checked="group.is_single ? index === 0 : false"
				class="
					focus:ring-0 focus:ring-transparent
					h-4
					w-4
					text-primary-500
					checked:bg-primary-500 checked:border-primary-500
					focus:outline-none
					border-primary-100
					bg-primary-100
				"
				:disabled="disabled && modelValue !== 1"
			/>
			<div class="ml-3 text-sm">
				<label
					:for="`option-${group.id}-${index}`"
					:class="[disabled && modelValue !== 1 ? 'text-gray-300' : 'text-primary-500']"
				>
					{{ product.name }}
					<template v-if="product.price > 0 && showPrice">
						(<render-price :value="product.price" />)
					</template>
				</label>
			</div>
		</div>
	</template>
	<template v-else>
		<div class="flex items-center justify-between mb-3">
			<div class="text-sm font-medium m-0">
                <div class="group flex relative">
                    <div class="flex flex-row">
                        {{ product.name }}
                        <template v-if="product.price > 0 && showPrice">
                            (<render-price :value="product.price" />)
                        </template>

                        <!-- <InformationCircleIcon class="w-5 h-5 ml-2" v-if="option.quantity && option.quantity > 1" /> -->
                    </div>
					<!-- <span
						class="
							group-hover:opacity-100
							transition-opacity
							bg-primary-500
							px-1
							text-sm text-white
							rounded-md
							absolute
                            -right-2.5
                            -top-5
							opacity-0
						"
						v-if="option.quantity && option.quantity > 1"
					>
                        {{ option.quantity }} qty
					</span> -->
				</div>
			</div>
			<div class="flex items-center">
				<quantity-selector
					:value="value"
					:max="group.is_single ? 1 : group.max"
					:disabled="disabled"
					@update:modelValue="
						(newValue) => {
							value = newValue;
							$emit('update:modelValue', newValue);
							$emit('update:price', value * product.price);
						}
					"
				/>
			</div>
		</div>
	</template>
</template>
<script>
import { defineComponent, ref } from "vue";
import RenderPrice from "@/components/RenderPrice.vue";
import QuantitySelector from "@/components/QuantitySelector.vue";
// import { InformationCircleIcon } from "@heroicons/vue/solid";

export default defineComponent({
	components: {
		RenderPrice,
		QuantitySelector,
        // InformationCircleIcon,
	},
	props: {
		index: {
			type: Number,
			required: true,
		},
		group: {
			type: Object,
			required: true,
		},
		product: {
			type: Object,
			required: true,
		},
		option: {
			type: Object,
			required: true,
		},
		modelValue: {
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showPrice: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, { emit }) {
		const value = ref(props.modelValue);

		const update = (event) => {
			if (!props.disabled) {
				emit("update:modelValue", props.group.is_single ? props.option.id : event.target.checked ? 1 : 0);
			} else {
				emit("update:modelValue", 0);
				value.value = 0;
			}

			emit("update:price", event.target.checked ? props.product.price : 0);
		};

		return {
			value,
			update,
		};
	},
});
</script>
