<template>
    
    <div class="cursor-pointer flex flex-col justify-between p-4 border rounded-lg mb-2">
        <div class="flex items-center justify-between mb-1">
            <p class="m-0 text-sm font-semibold">
                <span
                    v-if="!order.rated_at && (order.order_status === 'Completed')"
                    class="gen-badge mr-1.5"
                ></span>    
                #{{ order.id }}
            </p>
            <order-status
                :orderStatus="order.order_status"
            ></order-status>
        </div>
        <div class="flex items-center justify-between mb-2">
            <p class="m-0 text-xs text-primary-200" v-if="order.address">{{ order.address.name }}</p>
            <p class="m-0 text-xs text-primary-200">{{ order.fulfillment_method }}</p>
        </div>
        <div class="flex items-center justify-between">
            <div class="relative flex items-center space-x-1">
                <template v-for="(item, index) in order.items.slice(0, 3)" :key="index">
                    <div class="flex-shrink-0 w-7 h-7 rounded bg-primary-50">
                        <img class="h-full w-full object-cover rounded" :src="item.product.image_path" />
                    </div>
                </template>
                <template v-if="order.items.length > 3">
                    <div class="flex-shrink-0 flex items-center justify-center w-7 h-7 rounded bg-primary-50 p-1 text-center">
                        <span class="text-xs text-primary-200">{{ order.items.length }}+</span>
                    </div>
                </template>
            </div>
            <p class="m-0 text-sm font-bold">
                <render-price
                    :value="order.total"
                ></render-price>
            </p>
        </div>
        <div class="border-0 border-t mt-3">
            <div class="flex items-center justify-between mt-3">
                <p class="m-0 text-xs text-primary-200" v-if="order.order_status === 'Cancelled'">{{ order.cancelled_at_formatted }}</p>
                <p class="m-0 text-xs text-primary-200">{{ order.order_time }}</p>
            </div>
        </div>
    </div>

</template>
<script>
import { defineComponent } from 'vue'
import OrderStatus from '@/components/OrderStatus.vue';
import RenderPrice from '@/components/RenderPrice.vue';

export default defineComponent({
    components: {
        OrderStatus,
        RenderPrice,
    },  
    props: {
        order: {
            type: Object,
            required: true
        }
    }
})
</script>
