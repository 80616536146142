import { useGuestStore } from '@/store/guest';

export default function useGuest() {
    const guestStore = useGuestStore();

    const fetchGuestData = async () => {
        // get updates from user
        if (guestStore.isGuest) {
            await guestStore.fetchCartFulfillment();
            await guestStore.fetchCartRecipient();
            await guestStore.fetchCartBranch();
            await guestStore.fetchCartTimeslot();
            await guestStore.fetchCartBranchTable();
        }

        return true;
    }

    return {
        fetchGuestData: fetchGuestData,
    }
}
