<template>
	<header
		class="absolute top-0 px-4 lg:px-12 py-4 w-full z-50 transition-all duration-300"
		:class="{ 'bg-white shadow-md': !isHomepage }">
		<div class="flex items-center justify-between">
			<div class="flex items-center justify-start gap-20">
				<template v-if="cmsContent">
					<img
						:src="renderCmsImage(getCmsContent('generic_logo')) != null ? renderCmsImage(getCmsContent('generic_logo')) : '/assets/image/rk-logo.png'"
						width="138"
						height="48"
						class="cursor-pointer w-[105px] lg:w-[138px] h-auto transition-all duration-300"
						:class="{
							invert: !isScrolled && isHomepage,
						}"
						@click="router.push('/home')" />
				</template>
				<template v-else>
					<img
						:src="'/assets/image/rk-logo.png'"
						width="138"
						height="48"
						class="cursor-pointer w-[105px] lg:w-[138px] h-auto transition-all duration-300"
						:class="{
							invert: !isScrolled && isHomepage,
						}"
						@click="router.push('/home')" />
				</template>
				<input
					v-if="isHomepage && isScrolled"
					type="text"
					class="hidden bg-primary-50 shadow-sm focus:ring-primary-500 focus:border-primary-500 w-[524px] h-[48px] placeholder:text-primary-100 text-sm border-primary-50 rounded-lg p-3"
					placeholder="Enter Address" />

				<fulfillment-information
					v-if="isBranches"
					class="hidden lg:flex cursor-pointer"
					@click="$emit('showTimeslot')" />
			</div>
			<div class="flex items-center justify-end">
				<template v-if="showButtons">
					<ion-button
						v-if="!isAuthenticated"
						@click="router.push('/login-option')"
						size="large"
						class="transition-all duration-300 btn-primary"
						:class="{
							'btn-white': !isScrolled && isHomepage,
						}">
						Login
					</ion-button>

					<template v-else>
						<div class="flex items-center justify-center gap-5">
							<ion-button
								fill="clear"
								class="block btn-link btn-no-padding transition-all duration-300"
								@click="router.push('orders')">
								<span
									class="absolute top-0 right-0 w-[10px] h-[10px] block rounded-full bg-error"
									v-if="userStore.carts.length > 0"></span>
								<svg
									class="h-6 w-6"
									width="18"
									height="20"
									viewBox="0 0 18 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										:fill="!isScrolled && isHomepage ? '#ffffff' : '#0F0A09'"
										class="transition-all duration-500"
										d="M15.75 4H13.5C13.5 1.79 11.4862 0 9 0C6.51375 0 4.5 1.79 4.5 4H2.25C1.0125 4 0 4.9 0 6V18C0 19.1 1.0125 20 2.25 20H15.75C16.9875 20 18 19.1 18 18V6C18 4.9 16.9875 4 15.75 4ZM6.75 8C6.75 8.55 6.24375 9 5.625 9C5.00625 9 4.5 8.55 4.5 8V6H6.75V8ZM9 2C10.2375 2 11.25 2.9 11.25 4H6.75C6.75 2.9 7.7625 2 9 2ZM13.5 8C13.5 8.55 12.9937 9 12.375 9C11.7563 9 11.25 8.55 11.25 8V6H13.5V8Z" />
								</svg>
							</ion-button>
							<ion-button
								fill="clear"
								class="block btn-link btn-no-padding transition-all duration-300 relative"
								@click="router.push('notification')">
								<svg
									width="22"
									height="22"
									viewBox="0 0 22 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M3.7929 7.77111C3.7929 5.79542 4.57773 3.90066 5.97475 2.50364C7.37178 1.10662 9.26654 0.321777 11.2422 0.321777C13.2179 0.321777 15.1127 1.10662 16.5097 2.50364C17.9067 3.90066 18.6916 5.79542 18.6916 7.77111V8.59881C18.6916 10.9418 19.5744 13.0761 21.029 14.6907C21.1196 14.7911 21.1842 14.9122 21.2172 15.0434C21.2502 15.1746 21.2505 15.3118 21.2182 15.4431C21.1858 15.5745 21.1218 15.6959 21.0317 15.7967C20.9416 15.8976 20.8281 15.9748 20.7012 16.0217C18.9973 16.6507 17.2138 17.1142 15.3697 17.3934C15.4112 17.9608 15.3353 18.5307 15.1467 19.0675C14.9581 19.6042 14.6608 20.0963 14.2735 20.513C13.8862 20.9298 13.4171 21.2621 12.8955 21.4894C12.374 21.7167 11.8112 21.834 11.2422 21.834C10.6733 21.834 10.1105 21.7167 9.58893 21.4894C9.06738 21.2621 8.59828 20.9298 8.21095 20.513C7.82362 20.0963 7.52636 19.6042 7.33775 19.0675C7.14914 18.5307 7.07323 17.9608 7.11475 17.3934C5.29562 17.1178 3.50926 16.6578 1.78323 16.0206C1.65646 15.9738 1.54308 15.8966 1.45299 15.7959C1.3629 15.6952 1.29883 15.574 1.26639 15.4428C1.23395 15.3116 1.23412 15.1745 1.26689 15.0434C1.29965 14.9123 1.36402 14.7912 1.45436 14.6907C2.96277 13.0206 3.79629 10.8493 3.7929 8.59881V7.77111ZM8.76132 17.5932C8.74721 17.9278 8.80092 18.2618 8.91923 18.5751C9.03755 18.8884 9.21801 19.1745 9.44978 19.4163C9.68154 19.658 9.95981 19.8504 10.2679 19.9818C10.5759 20.1132 10.9073 20.181 11.2422 20.181C11.5771 20.181 11.9086 20.1132 12.2166 19.9818C12.5246 19.8504 12.8029 19.658 13.0347 19.4163C13.2664 19.1745 13.4469 18.8884 13.5652 18.5751C13.6835 18.2618 13.7372 17.9278 13.7231 17.5932C12.0725 17.7419 10.4119 17.7419 8.76132 17.5932Z"
										class="transition-all duration-500"
										:fill="!isScrolled && isHomepage ? '#ffffff' : '#0F0A09'" />
								</svg>
								<span
									class="absolute top-0 right-0 w-[10px] h-[10px] block rounded-full bg-error"
									v-if="userStore.userData?.unread_notifications_count > 0"></span>
							</ion-button>

							<ion-button
								fill="clear"
								class="btn-link btn-no-padding"
								@click="showMainMenu = true">
								<img
									class="h-8 w-8 rounded-full border-solid border-2 border-white object-cover"
									:src="userStore.userData.avatar_path ?? '/assets/image/default-account.png'" />
							</ion-button>
						</div>
					</template>
				</template>
			</div>
		</div>

		<main-menu
			:is-visible="showMainMenu"
			:to-rate-count="toRateCount"
			@close="showMainMenu = false"></main-menu>

		<order-status-signage
			v-if="router.currentRoute.value.name != 'OrdersIndex' && userStore.activeOrders.length > 0"
			:title="userStore.activeOrders.length > 1 ? `You have ${userStore.activeOrders.length} active orders` : userStore.activeOrders[0].order_history[0]?.text"
			:description="userStore.activeOrders.length > 1 ? 'Click here to learn more' : userStore.activeOrders[0].branch?.name"
			link="/orders" />
	</header>
</template>

<script>
	import { defineComponent, onMounted, computed, ref } from "vue";
	import { useRouter } from "vue-router";
	import { IonButton } from "@ionic/vue";
	import { useUserStore } from "@/store/user";
	import { useOrderStore } from "@/store/order";
	import MainMenu from "@/components/MainMenu.vue";
	import FulfillmentInformation from "@/components/FulfillmentInformation.vue";
	import useCms from "@/composables/useCms";
	import OrderStatusSignage from "@/components/OrderStatusSignage.vue";

	export default defineComponent({
		components: {
			IonButton,
			MainMenu,
			FulfillmentInformation,
			OrderStatusSignage,
		},
		emit: ["showTimeslot"],
		props: {
			isScrolled: {
				type: Boolean,
				default: false,
			},
			showButtons: {
				type: Boolean,
				default: true,
			},
		},
		setup() {
			/*--------------*
			 * IMPORTS
			 *--------------*/
			const router = useRouter();
			const userStore = useUserStore();
			const orderStore = useOrderStore();
			const { cms, contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();

			/*--------------*
			 * REFS
			 *--------------*/
			const isHomepage = ref(false);
			const isBranches = ref(false);
			const cmsContent = ref(null);
			const showMainMenu = ref(false);

			/*--------------*
			 * COMPUTED
			 *--------------*/
			const isAuthenticated = computed(() => userStore.userData !== null);
			const toRateCount = computed(() => orderStore.getToRateCount ?? 0);

			/*--------------*
			 * METHODS
			 *--------------*/

			const getContentSlug = () => {
				contentSlug.value = "header_footer";
				getCms(contentSlug.value);
				cmsContent.value = cms._rawValue.content;
			};

			const currentUrl = () => {
				if (router.currentRoute.value.name === "Home") {
					isHomepage.value = true;
				}

				if (router.currentRoute.value.name === "BranchesIndex" || router.currentRoute.value.name === "BranchView" || router.currentRoute.value.name === "CheckoutPage") {
					isBranches.value = true;
				}
			};

			const listenOrders = () => {
				window.Echo.private(`customer.${userStore.userData?.id}.orders`)
					.stopListening("OrderStatusUpdatedEvent")
					.stopListening("OrderCompletedEvent")
					.listen("OrderStatusUpdatedEvent", (data) => {
						const order = userStore.activeOrders.find((o) => o.id === data.id);

						if (order) {
							order.order_history.unshift(data.update_message);
							order.latest_time = data.latest_time;
							order.order_status = data.order_status;
						}
					})
					.listen("OrderCompletedEvent", (data) => {
						userStore.activeOrders = userStore.activeOrders.filter((o) => o.id !== data.id);
						orderStore.fetchActive();
					});
			};

			onMounted(() => {
				currentUrl();
				getContentSlug();

				if (userStore.userData) {
					orderStore.fetchToRateCount();
					orderStore.fetchActive();
					listenOrders();
				}
			});

			return {
				showMainMenu,
				isHomepage,
				isBranches,
				isAuthenticated,
				router,
				userStore,
				getCmsContent,
				getCmsImage,
				renderCmsImage,
				cmsContent,
				toRateCount,
			};
		},
	});
</script>
