<template>
	<div class="block w-full lg:relative absolute top-0 left-0 z-10 h-full bg-white lg:p-0 py-8 px-4 max-w-none lg:max-w-[408px] mx-auto">
		<p class="text-2xl font-semibold mt-5 mb-6">{{ generateDescription(item) }}</p>
		<div class="rounded-lg p-4 border border-primary-50 mb-4 flex flex-col space-y-1">
			<div class="flex items-center justify-between">
				<img
					:src="item.source?.sender?.profile_photo_url ?? item.customer?.profile_photo_url"
					class="w-8 h-8 rounded-md" />
				<p class="m-0 text-sm text-success">{{ `Completed` }}</p>
			</div>
			<div class="flex items-center justify-between">
				<p class="m-0">{{ item.source?.sender?.name ?? generateLabel(item) }}</p>
				<p
					v-if="item.transaction_type === 'added' || item.transaction_type === 'refunded'"
					class="font-bold text-xl m-0">
					+
					<render-price :value="item.amount" />
				</p>
				<p
					v-if="item.transaction_type === 'deducted'"
					class="font-bold text-xl m-0">
					-
					<render-price :value="item.amount" />
				</p>
				<p
					v-if="item.transaction_type === 'reset'"
					class="font-bold text-xl m-0">
					<render-price :value="item.amount" />
				</p>
			</div>
			<div class="flex items-center justify-between">
				<p class="m-0 text-primary-400 text-xs">Date and Time</p>
				<p class="m-0 text-primary-400 text-xs">{{ item.created_at_formatted }}</p>
			</div>
			<div class="flex items-center justify-between">
				<p class="m-0 text-primary-400 text-xs">Reference Number</p>
				<p class="m-0 text-primary-400 text-xs">{{ item.source?.reference_number ?? item.source_id }}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import { defineComponent } from "vue";
	import _ from "lodash";
	import RenderPrice from "@/components/RenderPrice.vue";

	export default defineComponent({
		components: {
			RenderPrice,
		},
		props: {
			item: {
				type: Object,
				default: () => ({}),
			},
		},
		setup() {
			const generateDescription = (log) => {
				if (log.transaction_type === "added") {
					if (log.source_name === "Gift") {
						return "Received gift from";
					} else {
						if (log.source?.payment) {
							return "Top up (" + identifyPaymentMethod(log.source?.payment?.method) + ") ";
						} else {
							if (_.startCase(log.source_type.split("\\").pop()) === "Order") {
								return "Order Refund ";
							} else {
								return "Top up ";
							}
						}
					}
				} else {
					return _.startCase(log.source_type.split("\\").pop()) + " Payment ";
				}
			};

			const generateLabel = (log) => {
				if (log.source_name === "Gift") {
					return log.source?.sender?.name;
				} else {
					return log.source_name + " #" + log.source_id;
				}
			};

			const identifyPaymentMethod = (payment) => {
				return payment == "Card" ? "Debit/Credit" : payment;
			};

			return {
				generateDescription,
				generateLabel,
			};
		},
	});
</script>
