<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="flex items-center justify-center min-h-[calc(100vh-72px)] lg:min-h-[calc(100vh-80px)]">
					<div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
						<div class="space-y-4">
							<h3 class="font-bold text-[28px] text-primary-900 text-center">Voucher Payment Failed!</h3>
							<p class="text-primary-400 text-center">This is to confirm your payment has failed. We are very sorry for the inconvenience. Please try again later or use a different payment method.</p>
						</div>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
	import { defineComponent } from "vue";
	import { IonPage, IonContent } from "@ionic/vue";

	export default defineComponent({
		components: {
			IonContent,
			IonPage,
		},
	});
</script>
