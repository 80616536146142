<template>
    <div class="ion-padding px-11 py-6">
        <ion-row>
            <ion-col size="12" class="p-0 text-center">
                <img
                    src="/assets/icon/ic-modal-gold.svg"
                    alt="gold"
                    class="w-full block mb-6"
                />
                <h4 class="text-primary-900 font-semibold mb-4">
                    {{ points }} points will be added!
                </h4>
                <p class="text-sm text-primary-400 mb-6">
                    A total of
                    <span class="font-semibold"
                        >{{ availablePoints }} available points.</span
                    >
                    This will be credited shortly.
                </p>
                <a
                    class="text-sm font-semibold cursor-pointer"
                    @click="redirect"
                    v-if="buttonLink"
                >
                    {{ buttonText }}
                </a>
            </ion-col>
        </ion-row>
    </div>
</template>

<script>
import { modalController, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "points-modal",
    props: {
        title: {
            type: String,
            default: "Super Modal",
        },
        content: {
            type: String,
            default: null,
        },
        buttonLink: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: "Okay",
        },

        points: {
            type: [String, Number],
        },

        availablePoints: {
            type: [String, Number],
        },
    },
    components: {
        IonRow,
        IonCol,
    },
    setup(props) {
        const router = useRouter();
        const redirect = async () => {
            await modalController.dismiss();
            router.replace(props.buttonLink);
        };

        return {
            redirect,
            //   timeOutline
        };
    },
});
</script>