import { createRouter, createWebHistory } from "@ionic/vue-router";
import useLocalStorage from "@/composables/useLocalStorage.js";

const { localStorage } = useLocalStorage();

//  Auth
import LoginOption from "@/views/auth/LoginOption.vue";
import Validation from "@/views/auth/Validation.vue";
import LoginAccount from "@/views/auth/LoginAccount.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import EmailOtp from "@/views/auth/EmailOtp.vue";
import CreateAccount from "@/views/auth/CreateAccount.vue";
import OtpVerification from "@/views/auth/OtpVerification.vue";

//  Pages
import PrimeMembership from "@/views/membership/PrimeMembership.vue";
import SubscriptionSuccess from "@/views/membership/Components/SubscriptionSuccess.vue";
import SubscriptionFailed from "@/views/membership/Components/SubscriptionFail.vue";
import SubscriptionCancel from "@/views/membership/Components/SubscriptionCancel.vue";
import MobileSubscriptionSuccess from "@/views/membership/Components/MobileSubscriptionSuccess.vue";
import MobileSubscriptionFail from "@/views/membership/Components/MobileSubscriptionFail.vue";
import MobileSubscriptionCancel from "@/views/membership/Components/MobileSubscriptionCancel.vue";
import OrdersIndex from "@/views/orders/OrdersIndex.vue";
import OrderCancelled from "@/views/orders/OrderCancelled.vue";
import OrderHistory from "@/views/order-history/OrderHistory.vue";
import OrderDetail from "@/views/order-history/OrderDetail.vue";
import Points from "@/views/points/Points.vue";
import PointSetting from "@/views/checkout/PointSetting.vue";
import Help from "@/views/help/Help.vue";
import HomePage from "@/views/home/Home.vue";
import BranchesIndex from "@/views/branches/BranchesIndex.vue";
import BranchView from "@/views/branches/BranchView.vue";
import CheckoutPage from "@/views/checkout/CheckoutPage.vue";
import OrderSuccess from "@/views/checkout/OrderSuccess.vue";
import OrderFail from "@/views/checkout/OrderFail.vue";
import OrderMobileSuccess from "@/views/checkout/OrderMobileSuccess.vue";
import OrderMobileFail from "@/views/checkout/OrderMobileFail.vue";
import NotificationIndex from "@/views/notification/NotificationIndex.vue";
import EditProfile from "@/views/profile/EditProfile.vue";
import DeleteAccount from "@/views/profile/DeleteAccount.vue";
import DeactivateAccount from "@/views/profile/DeactivateAccount.vue";
import WalletPage from "@/views/rk-wallet/WalletPage.vue";
import WalletViewPage from "@/views/rk-wallet/WalletViewPage.vue";
import TopupSuccess from "@/views/rk-wallet/TopupSuccess.vue";
import TopupFail from "@/views/rk-wallet/TopupFail.vue";
import TopupMobileSuccess from "@/views/rk-wallet/TopupMobileSuccess.vue";
import TopupMobileFail from "@/views/rk-wallet/TopupMobileFail.vue";
import RKVoucherMobileSuccess from "@/views/rk-gifts/RKVoucherMobileSuccess.vue";
import RKVoucherMobileFail from "@/views/rk-gifts/RKVoucherMobileFail.vue";
import RKCreditMobileSuccess from "@/views/rk-wallet/RKCreditMobileSuccess.vue";
import RKCreditMobileFail from "@/views/rk-wallet/RKCreditMobileFail.vue";
import Stylesheet from "@/views/Stylesheet.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";

import MaintenancePage from "@/views/MaintenancePage.vue";

const routes = [
	{
		path: "/",
		redirect: "/login-option",
	},

	//  Auth
	{
		path: "/login-option",
		name: "LoginOption",
		component: LoginOption,
		meta: { withoutAuth: true },
	},
	{
		path: "/validation",
		name: "Validation",
		component: Validation,
		meta: { withoutAuth: true },
	},
	{
		path: "/login-account",
		name: "LoginAccount",
		component: LoginAccount,
		meta: { withoutAuth: true },
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
		meta: { withoutAuth: true },
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: ResetPassword,
		meta: { withoutAuth: true },
	},
	{
		path: "/email-otp",
		name: "EmailOtp",
		component: EmailOtp,
		meta: { withoutAuth: true },
	},
	{
		path: "/create-account",
		name: "CreateAccount",
		component: CreateAccount,
		meta: { withoutAuth: true },
	},
	{
		path: "/otp-verification",
		name: "OtpVerification",
		component: OtpVerification,
		meta: { withoutAuth: true },
	},

	//  Pages
	{
		path: "/edit-profile",
		name: "EditProfile",
		component: EditProfile,
		meta: { requiresAuth: true },
	},
	{
		path: "/delete-account",
		name: "DeleteAccount",
		component: DeleteAccount,
		meta: { requiresAuth: true },
	},
	{
		path: "/deactivate-account",
		name: "DeactivateAccount",
		component: DeactivateAccount,
		meta: { requiresAuth: true },
	},
	{
		path: "/order-detail",
		name: "OrderDetail",
		component: OrderDetail,
		meta: { requiresAuth: true },
	},
	{
		path: "/prime-membership",
		name: "PrimeMembership",
		component: PrimeMembership,
		meta: { requiresAuth: true },
	},
	{
		path: "/subscription-success",
		name: "SubscriptionSuccess",
		component: SubscriptionSuccess,
		// meta: { requiresAuth: true }
	},
	{
		path: "/subscription-failed",
		name: "SubscriptionFailed",
		component: SubscriptionFailed,
		// meta: { requiresAuth: true }
	},
	{
		path: "/subscription-cancel",
		name: "SubscriptionCancel",
		component: SubscriptionCancel,
		// meta: { requiresAuth: true }
	},
	{
		path: "/mobile-subscription-success",
		name: "MobileSubscriptionSuccess",
		component: MobileSubscriptionSuccess,
		// meta: { requiresAuth: true }
	},
	{
		path: "/mobile-subscription-fail",
		name: "MobileSubscriptionFail",
		component: MobileSubscriptionFail,
		// meta: { requiresAuth: true }
	},
	{
		path: "/mobile-subscription-cancel",
		name: "MobileSubscriptionCancel",
		component: MobileSubscriptionCancel,
		// meta: { requiresAuth: true }
	},
	{
		path: "/notification",
		name: "NotificationIndex",
		component: NotificationIndex,
		meta: { requiresAuth: true },
	},
	{
		path: "/points",
		name: "Points",
		component: Points,
		meta: { requiresAuth: true },
	},
	{
		path: "/point-setting",
		name: "PointSetting",
		component: PointSetting,
	},
	{
		path: "/order-history",
		name: "OrderHistory",
		component: OrderHistory,
		meta: { requiresAuth: true },
	},
	{
		path: "/orders",
		name: "OrdersIndex",
		component: OrdersIndex,
		meta: { requiresAuth: true },
	},
	{
		path: "/order-cancelled",
		name: "OrderCancelled",
		component: OrderCancelled,
	},
	{
		path: "/help-center",
		name: "Help",
		component: Help,
		meta: { requiresAuth: true },
	},
	{
		path: "/home",
		name: "Home",
		component: HomePage,
		meta: {
			guestPreview: true,
		},
	},
	{
		path: "/branches",
		name: "BranchesIndex",
		component: BranchesIndex,
		meta: {
			requiresAuth: true,
			requiresRecipient: true,
			guestPreview: true,
		},
	},
	{
		path: "/branch-view",
		name: "BranchView",
		component: BranchView,
		props: true,
		meta: {
			requiresAuth: true,
			requiresRecipient: true,
			requiresCart: true,
			guestPreview: true,
		},
	},
	{
		path: "/wallet",
		name: "Wallet",
		component: WalletPage,
		meta: { requiresAuth: true },
	},
	{
		path: "/wallet-view",
		name: "WalletViewPage",
		component: WalletViewPage,
		meta: { requiresAuth: true },
	},
	{
		path: "/checkout",
		name: "CheckoutPage",
		component: CheckoutPage,
		meta: {
			requiresAuth: true,
			requiresRecipient: true,
			requiresCart: true,
		},
	},
	{
		path: "/order-success",
		name: "OrderSuccess",
		component: OrderSuccess,
		// meta: { requiresAuth: true },
	},

	{
		path: "/order-failed",
		name: "OrderFail",
		component: OrderFail,
		// meta: { requiresAuth: true },
	},
	{
		path: "/topup-success",
		name: "TopupSuccess",
		component: TopupSuccess,
		// meta: { requiresAuth: true },
	},

	{
		path: "/topup-failed",
		name: "TopupFail",
		component: TopupFail,
		// meta: { requiresAuth: true },
	},

	{
		path: "/mobile-order-success",
		name: "OrderMobileSuccess",
		component: OrderMobileSuccess,
	},

	{
		path: "/mobile-order-failed",
		name: "OrderMobileFail",
		component: OrderMobileFail,
	},
	{
		path: "/mobile-topup-success",
		name: "TopupMobileSuccess",
		component: TopupMobileSuccess,
	},

	{
		path: "/mobile-topup-failed",
		name: "TopupMobileFail",
		component: TopupMobileFail,
	},

	{
		path: "/mobile-rk-voucher-success",
		name: "RKVoucherMobileSuccess",
		component: RKVoucherMobileSuccess,
	},

	{
		path: "/mobile-rk-voucher-failed",
		name: "RKVoucherMobileFail",
		component: RKVoucherMobileFail,
	},

	{
		path: "/mobile-rk-credit-success",
		name: "RKCreditMobileSuccess",
		component: RKCreditMobileSuccess,
	},

	{
		path: "/mobile-rk-credit-failed",
		name: "RKCreditMobileFail",
		component: RKCreditMobileFail,
	},

	{
		path: "/stylesheet",
		name: "Stylesheet",
		component: Stylesheet,
		meta: { requiresAuth: true },
	},

	{
		path: "/terms-and-conditions",
		name: "Terms",
		component: Terms,
	},

	{
		path: "/privacy-policy",
		name: "Privacy",
		component: Privacy,
	},

	{
		path: "/maintenance-page",
		name: "MaintenancePage",
		component: MaintenancePage,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	let redirected = false;

	if (to.meta) {
		if (to.meta.withoutAuth) {
			if (await localStorage.value.get("authToken")) {
				redirected = true;

				next({
					path: "/home",
				});
			}
		}

		if (to.meta.requiresAuth || to.meta.guestPreview) {
			// this route requires auth, check if logged in
			// if not, redirect to login page.
			if (!(await localStorage.value.get("authToken")) && !to.meta.guestPreview) {
				redirected = true;

				next({
					path: "/login-option",
				});
			}
		}

		if (to.meta.requiresRecipient && !to.meta.guestPreview) {
			if (!(await localStorage.value.get("setRecipient"))) {
				redirected = true;

				next({
					path: "/home",
				});
			}
		} else if (to.meta.requiresRecipient && to.meta.guestPreview) {
			if (!(await localStorage.value.get("authToken"))) {
				if (!(await localStorage.value.get("guestRecipient"))) {
					redirected = true;

					next({
						path: "/home",
					});
				}
			}
		}

		if (to.meta.requiresCart && !to.meta.guestPreview) {
			if (!(await localStorage.value.get("activeCart"))) {
				redirected = true;

				next({
					path: "/home",
				});
			}
		} else if (to.meta.requiresCart && to.meta.guestPreview) {
			if (!(await localStorage.value.get("authToken"))) {
				if (!(await localStorage.value.get("guestCartFulfillment")) || !(await localStorage.value.get("guestCartBranch")) || !(await localStorage.value.get("guestCartTimeslot"))) {
					redirected = true;

					next({
						path: "/home",
					});
				}
			}
		}
	}

	if (!redirected) {
		next(); // make sure to always call next()!
	}
});

export default router;
