<template>
    
    <div 
        class="fixed top-0 w-full lg:w-[25rem] bg-white h-screen z-[100] px-6 py-8 transition-all duration-500"
        :class="[ open ? 'right-0' : '-right-[200%]']"
    >
        <div class="relative h-full overflow-auto">
            <XIcon
                @click="closeThis"
                class="cursor-pointer absolute top-0 right-0 w-5 h-5"
            />
            <div class="pt-14 space-y-2">
                <h3 class="text-[28px] text-primary-900 font-bold">{{title}}</h3>
                <p v-if="description" class="text-sm text-primary-400">{{description}}</p>
            </div>
            <div class="pt-8">
                <slot name="content"></slot>
            </div>
            <div class="absolute bottom-0 w-full">
                <slot name="button"></slot>
            </div>
        </div>
    </div>

    <div 
        class="fixed inset-0 w-full h-full bg-primary-900/50 z-[90] transition-all duration-500"
        :class="[ open ? 'block' : 'hidden' ]"
        @click="closeThis"
    ></div>

</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import { XIcon } from '@heroicons/vue/solid'

export default defineComponent({
    components: {
        XIcon
    },
    emits: ['close'],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        description: {
            type: String
        },
    },
    setup(props, {emit}) {
        const open = ref(false)
        
        const closeThis = () => {
            open.value = false
        }

        watch(
            () => props.isVisible,
            (bool) => open.value = bool, {
                immediate: true
            }
        )

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit('close');
            }
        )

        return {
            open,
            closeThis
        }
    },
})
</script>
