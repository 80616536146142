<template>
	<footer class="bg-primary-800 px-4 py-8 lg:px-12 lg:py-20">
		<div class="flex flex-col lg:flex-row items-start justify-between">
			<div class="pb-6 lg:pb-0">
				<template v-if="cmsContent">
					<img
						:src="renderCmsImage(getCmsContent('generic_logo')) !== null ? renderCmsImage(getCmsContent('generic_logo')) : '/assets/image/rk-logo.png'"
						width="138"
						height="48"
						class="w-[138px] h-auto invert" />
				</template>
				<template v-else>
					<img
						src="/assets/image/rk-logo.png"
						width="138"
						height="48"
						class="w-[138px] h-auto invert" />
				</template>
				<div class="flex items-center justify-start gap-4 mt-8">
					<ion-button
						shape="round"
						class="btn-link btn-no-padding">
						<img src="/assets/icon/playstore-btn.svg" />
					</ion-button>
					<ion-button
						shape="round"
						class="btn-link btn-no-padding">
						<img src="/assets/icon/appstore-btn.svg" />
					</ion-button>
				</div>
			</div>
			<div class="flex flex-col lg:flex-row items-start gap-0 lg:gap-32 w-full lg:w-auto">
				<div class="flex flex-col items-start justify-start gap-4 py-6 lg:py-0 border-y lg:border-0 border-primary-500 w-full lg:w-auto">
					<p class="text-xs text-primary-400 font-bold m-0">Orders</p>
					<a
						@click="router.push('order-history')"
						class="cursor-pointer text-white text-base font-semibold m-0">
						Track Order
					</a>
				</div>
				<div class="flex flex-col items-start justify-start gap-4 py-6 lg:py-0 border-b lg:border-0 border-primary-500 w-full lg:w-auto">
					<p class="text-xs text-primary-400 font-bold m-0">Legal</p>
					<a
						@click="router.push('terms-and-conditions')"
						class="cursor-pointer text-white text-base font-semibold m-0">
						Terms and Conditions
					</a>
					<a
						@click="router.push('privacy-policy')"
						class="cursor-pointer text-white text-base font-semibold m-0">
						Privacy Policy
					</a>
				</div>
			</div>
		</div>
		<div class="w-full mt-10">
			<div class="flex items-center justify-start gap-4">
				<template v-if="cmsContent">
					<template
						v-for="(item, index) in getCmsContent('footer_social_accts')"
						:key="index">
						<a
							class="mx-0"
							:href="item.link"
							target="_blank">
							<img
								:src="[item.icon !== '' ? renderCmsImage(item.icon) : '/assets/icon/ic-fb.svg']"
								class="w-6 h-6" />
						</a>
					</template>
				</template>
				<template v-else>
					<a
						class="mx-0"
						href="https://www.facebook.com/kuroda.ph/menu/"
						target="_blank">
						<img
							src="/assets/icon/ic-fb.svg"
							class="w-6 h-6" />
					</a>
					<a
						class="mx-0"
						href="https://www.instagram.com/ramenkurodaph/?hl=en"
						target="_blank">
						<img
							src="/assets/icon/ic-ig.svg"
							class="w-6 h-6" />
					</a>
				</template>
			</div>
			<template v-if="cmsContent">
				<p class="text-white text-sm mt-6">
					{{ getCmsContent("footer_copyrightText") !== null ? getCmsContent("footer_copyrightText") : "© " + new Date().getFullYear() + " Ramen Kuroda" }}
				</p>
			</template>
			<template v-else>
				<p class="text-white text-sm mt-6">© {{ new Date().getFullYear() }} Ramen Kuroda</p>
			</template>
		</div>
	</footer>
</template>
<script>
	import { defineComponent, onBeforeMount, ref } from "vue";
	import { useRouter } from "vue-router";
	import { IonButton } from "@ionic/vue";

	import useCms from "@/composables/useCms";

	export default defineComponent({
		components: {
			IonButton,
		},
		setup() {
			const router = useRouter();
			const { cms, contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();
			const cmsContent = ref(null);

			const getContentSlug = () => {
				contentSlug.value = "header_footer";
				getCms(contentSlug.value);
				cmsContent.value = cms._rawValue.content;
			};

			onBeforeMount(() => {
				getContentSlug();
			});

			return {
				router,
				contentSlug,
				getCms,
				getCmsContent,
				getCmsImage,
				renderCmsImage,
				cmsContent,
			};
		},
	});
</script>
