<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content>
            <div class="page-offset">
                <div
                    class="
                        max-w-none
                        md:max-w-[504px]
                        m-auto
                        py-[54px]
                        md:py-10
                    "
                >
                    <div class="min-h-screen px-6 md:px-12">
                        <div class="help-header mb-8">
                            <div class="help-title mb-2">
                                <template v-if="cmsContent">
                                    <h2
                                        class="
                                            font-semibold
                                            text-2xl
                                            md:text-[28px]
                                        "
                                    >
                                        {{ getCmsContent('section1_pageTitle') || 'Help Center' }}
                                    </h2>
                                </template>
                                <template v-else>
                                    <h2 class="font-semibold text-2xl md:text-[28px]">Help Center</h2>
                                </template>
                            </div>

                            <template v-if="cmsContent">
                                <div v-html="getCmsContent('section1_description')" class="html-box"></div>
                            </template>
                            <div v-else class="help-desc">
                                <h6>General Questions</h6>
                            </div>

                        </div>
                        <ion-accordion-group class="flex flex-col gap-2">
                            <template v-if="cmsContent">
                                <template v-for="(content, index) in getCmsContent('section1_faqs')" :key="index">
                                    <ion-accordion class="accord-item" :value="index">
                                        <ion-item class="accord-header" slot="header">
                                            <ion-label
                                                class="accord-label font-semibold"
                                            >
                                                {{ content.question }}
                                            </ion-label>
                                        </ion-item>
                                        <div class="px-4 pt-2" slot="content">
                                            <div v-html="content.answer" class="html-box"></div>
                                        </div>
                                    </ion-accordion>
                                </template>
                            </template>
                            <template v-else>
                                <ion-accordion class="accord-item" value="first">
                                    <ion-item class="accord-header" slot="header">
                                        <ion-label
                                            class="accord-label font-semibold"
                                        >
                                            What is the Ramen Kuroda App?
                                        </ion-label>
                                    </ion-item>
                                    <div class="px-4 pt-2" slot="content">
                                        <p
                                            class="
                                                text-[#4D4D4D]
                                                leading-normal
                                                font--15
                                            "
                                        >
                                            The Ramen Kuroda app provides you
                                            conveniently ordering your favorite real
                                            Japanese dishes. It also has exclusive
                                            deals and discounts.
                                        </p>
                                    </div>
                                </ion-accordion>
                                <ion-accordion class="accord-item" value="second">
                                    <ion-item class="accord-header" slot="header">
                                        <ion-label
                                            class="accord-label font-semibold"
                                        >
                                            How do I use the Ramen Kuroda app?
                                        </ion-label>
                                    </ion-item>
                                    <div class="px-4 pt-2" slot="content">
                                        <p
                                            class="
                                                text-[#4D4D4D]
                                                leading-normal
                                                font--15
                                            "
                                        >
                                            Step 1: Download the Ramen Kuroda app at
                                            Google Play Store or App Store
                                        </p>
                                        <p
                                            class="
                                                text-[#4D4D4D]
                                                leading-normal
                                                font--15
                                            "
                                        >
                                            Step 2: Register an account
                                        </p>
                                        <p
                                            class="
                                                text-[#4D4D4D]
                                                leading-normal
                                                font--15
                                            "
                                        >
                                            Step 3: Order your favorite real
                                            Japanese dishes for contactless dining,
                                            pick-up and delivery transactions.
                                        </p>
                                    </div>
                                </ion-accordion>
                                <ion-accordion class="accord-item" value="third">
                                    <ion-item class="accord-header" slot="header">
                                        <ion-label
                                            class="accord-label font-semibold"
                                        >
                                            Are Senior Citizen, PWD and other
                                            discounts applicable on this app?
                                        </ion-label>
                                    </ion-item>
                                    <div class="px-4 pt-2" slot="content">
                                        <p
                                            class="
                                                text-[#4D4D4D]
                                                leading-normal
                                                font--15
                                            "
                                        >
                                            We regret to inform you that Senior
                                            Citizen, PWD and other discounts are
                                            currently not available in our
                                            application. If you wish to avail the
                                            said discounts, you may visit our
                                            nearest branch and order manually.
                                        </p>
                                    </div>
                                </ion-accordion>
                            </template>
                        </ion-accordion-group>
                    </div>
                </div>
            </div>
            <bottom-navigation />
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import useCms from "@/composables/useCms.js";

export default defineComponent({
    name: "help-center",
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
        IonAccordion,
        IonAccordionGroup,
        IonItem,
        IonLabel,
    },
    setup() {
        const { cms, contentSlug, getCms, getCmsContent } = useCms();
        const cmsContent = ref(null);

        onMounted(() => {
            contentSlug.value = 'help_center';
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        })

        return {
            getCmsContent,
            cmsContent
        }
    }
});
</script>
