import { defineStore } from "pinia";
import useLocalStorage from "@/composables/useLocalStorage.js";
import { useCartStore } from "./cart";
import { useApiStore } from "./api";
import useAuth from "@/composables/useAuth.js";

export const useGuestStore = defineStore({
	id: "guest",
	state: () => {
		return {
			isGuest: false,
			isSynced: false, // if true, after logging in, the user should be redirected to products
			fulfillmentMethod: null,
			recipient: {
				type: "other",
				firstName: null,
				lastName: null,
				mobileNumber: null,
				email: null,
				address: {
					latitude: null,
					longitude: null,
					fullAddress: null,
					name: null,
					details: null,
					isDefault: false,
				},
			},
			branch: null, // branch model
			timeslot: null, // timeslot model
			branchTableOption: null, // branch table option model
			headCount: 0,
		};
	},
	getters: {},
	actions: {
		async checkIfGuest(callback = null) {
			const { localStorage } = useLocalStorage();
			const { clearStorage } = useAuth();
			const apiStore = useApiStore();

			await localStorage.value.get("authToken").then((value) => {
				if (value) {
					return window.axios
						.post(apiStore.route("authCheck"), {})
						.then(() => {
							this.isGuest = false;

							if (callback) {
								callback(this.isGuest);
							}
						})
						.catch(() => {
							// clear localStorage as the token is already invalid or doesn't belong to the current user
							clearStorage();

							this.isGuest = true;

							if (callback) {
								callback(this.isGuest);
							}
						});
				} else {
					this.isGuest = true;

					if (callback) {
						callback(this.isGuest);
					}
				}
			});
		},
		async fetchCartFulfillment() {
			const { localStorage } = useLocalStorage();
			const cartStore = useCartStore();

			await localStorage.value.get("guestCartFulfillment").then((value) => {
				this.fulfillmentMethod = value;
				cartStore.fulfillmentMethod = value;
			});
		},
		async fetchCartRecipient() {
			const { localStorage } = useLocalStorage();
			await localStorage.value.get("guestRecipient").then((value) => {
				if (value) {
					this.recipient = value;

					if (typeof this.recipient.address === "undefined") {
						this.recipient.address = {};
					}
				} else {
					this.recipient = {};
				}
			});
		},
		async fetchCartBranch() {
			const { localStorage } = useLocalStorage();
			const cartStore = useCartStore();

			await localStorage.value.get("guestCartBranch").then((value) => {
				this.branch = value;
				cartStore.branch = value;
			});
		},
		async fetchCartTimeslot() {
			const { localStorage } = useLocalStorage();
			const cartStore = useCartStore();

			await localStorage.value.get("guestCartTimeslot").then((value) => {
				this.timeslot = value;
				cartStore.timeslot = value;
			});
		},
		async fetchCartBranchTable() {
			const { localStorage } = useLocalStorage();
			const cartStore = useCartStore();

			await localStorage.value.get("guestCartBranchTableOption").then((value) => {
				this.branchTableOption = value;
				cartStore.branchTableOption = value;
			});

			await localStorage.value.get("guestCartDiners").then((value) => {
				this.headCount = value;
				cartStore.headCount = value;
			});
		},
		async syncToUser() {
			if (this.fulfillmentMethod && this.branch) {
				const { localStorage } = useLocalStorage();
				const cartStore = useCartStore();
				const apiStore = useApiStore();

				return window.axios
					.put(apiStore.route("guestSync"), {
						fulfillmentMethod: this.fulfillmentMethod,
						recipient: this.recipient,
						branch: this.branch.id,
						timeslot: this.timeslot?.id,
						table: this.branchTableOption?.id,
						headCount: this.headCount,
					})
					.then((result) => {
						cartStore.fetchCarts().then(() => {
							cartStore.edit(result.data.id).then(() => {
								localStorage.value.delete("guestCartFulfillment");
								localStorage.value.delete("guestRecipient");
								localStorage.value.delete("guestCartBranch");
								localStorage.value.delete("guestCartTimeslot");
								localStorage.value.delete("guestCartBranchTableOption");
								localStorage.value.delete("guestCartDiners");

								this.$reset();
								this.isSynced = true;

								return true;
							});
						});
					});
			} else {
				return true;
			}
		},
	},
});
