<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10">
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed z-10 inset-0 overflow-y-auto">
				<div class="flex items-end sm:items-center justify-center min-h-full p-0 lg:p-4 text-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							class="
								relative
								bg-white
								rounded-lg
								text-left
								overflow-hidden
								shadow-xl
								transform
								transition-all
								my-0
								lg:my-8
								w-[500px]
							"
						>
							<div class="h-full overflow-auto">
								<ArrowLeftIcon
									@click="closeThis"
									class="cursor-pointer w-5 h-5 absolute left-6 top-6"
								/>
								<div class="h-80 lg:h-auto">
									<img
										:src="bundle.image_path"
										class="
											w-auto
											h-full
											lg:w-full lg:h-auto lg:max-w-[500px] lg:max-h-[250px]
											mx-auto
											object-cover
										"
									/>
								</div>
								<div
									class="
										py-6
										px-4
										lg:px-12
										rounded-t-3xl
										lg:rounded-t-none
										bg-white
										-mt-5
										lg:mt-0
										relative
										z-10
									"
								>
									<div class="space-y-2 mt-6">
										<h3 class="text-primary-900 text-2xl font-bold">
											{{ bundle.name }}
										</h3>
										<p class="text-sm text-primary-200">
											{{ bundle.sections.map((section) => section.name).join(" + ") }}
										</p>
									</div>
									<div class="flex items-center justify-between py-4 border-b">
										<h4 class="flex-1 text-primary-900 text-2xl font-bold m-0">
											<render-price :value="bundle.bundle_price" />
										</h4>
										<div class="flex-1 flex items-center gap-2 justify-end">
											<quantity-selector
												:value="form.quantity"
												@update:modelValue="(value) => (form.quantity = value)"
											/>
										</div>
									</div>

									<!-- Add ons -->
									<div class="py-6 border-b" v-for="section in bundle.sections" :key="section">
										<p class="text-base text-primary-900 font-bold mb-4">
											{{ section.name }}
											<span class="ml-1 font-normal text-primary-300 text-xs">
												{{ sectionMinMax(section) }}
											</span>
										</p>
										<div class="mb-3" v-for="(option, index) in section.options" :key="option">
											<template v-if="form.options[section.id] !== undefined">
												<template v-if="section.is_single && section.is_required">
													<add-on
														:index="index"
														:group="section"
														:product="option.product"
														:option="option"
														:showPrice="false"
														v-model="form.options[section.id]"
														@update:modelValue="
															(value) => {
																form.options[section.id] = value;
																isDisabled(section);
															}
														"
														:disabled="section.is_disabled"
													/>
												</template>
												<template v-else>
													<add-on
														:index="index"
														:group="section"
														:product="option.product"
														:option="option"
														:showPrice="false"
														v-model="form.options[section.id][option.id]"
														@update:modelValue="
															(value) => {
																form.options[section.id][option.id] = value;
																isDisabled(section);
															}
														"
														:disabled="section.is_disabled"
													/>
												</template>
											</template>
										</div>
									</div>

									<div class="py-6 sm:border-b mb-20 sm:mb-0">
										<div class="space-y-2 my-2">
											<p class="text-base text-primary-900 font-bold">Special Instructions</p>
											<p class="text-xs text-primary-900">
												Please take note that some requests may not be accomodated.
											</p>
										</div>
										<textarea
											v-model="form.special_instructions"
											rows="4"
											class="
												shadow-sm
												focus:ring-primary-500 focus:border-primary-500
												block
												w-full
												sm:text-sm
												border-gray-300
												rounded-lg
												placeholder:text-primary-100
												min-h-[75px]
												max-h-[250px]
											"
											placeholder="Do you have any request or allergies that need to avoid?"
										/>
									</div>
									<div
										class="
											sm:pt-6
											flex
											items-center
											justify-between
											p-4
											bg-white
											fixed
											bottom-0
											sm:relative
											w-full
											left-0
											border-t
											sm:border-0
										"
									>
										<div class="space-y-2">
											<p class="text-primary-100 text-xs font-bold">Grand Total</p>
											<p class="text-base text-primary-900 font-bold">
												<render-price :value="subtotal" />
											</p>
										</div>
										<button-component @click="addItem" color="primary" :disabled="!allowToAdd">
											<template #content> Add to Order </template>
										</button-component>
									</div>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
<script>
import { computed, defineComponent, ref, watch } from "vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import RenderPrice from "@/components/RenderPrice.vue";
import QuantitySelector from "@/components/QuantitySelector.vue";
import AddOn from "@/components/AddOn.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default defineComponent({
	components: {
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
		ArrowLeftIcon,
		ButtonComponent,
		RenderPrice,
		QuantitySelector,
		AddOn,
	},
	emits: ["close", "add:order"],
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		bundle: {
			type: Object,
			required: true,
		},
	},
	setup(props, { emit }) {
		const open = ref(false);
		const subtotal = ref(0);
		const form = ref({
			bundle: props.bundle.id,
			quantity: 0,
			special_instructions: null,
			options: {},
		});

		const allowToAdd = computed(() => {
			let allowed = true;

			props.bundle.sections.map((section) => {
				if (section.is_single && section.is_required) {
					if (form.value.options[section.id] === 0 || form.value.options[section.id] === null) {
						allowed = false;
					}
				} else {
					if (section.is_required) {
						const sum = Object.values(form.value.options[section.id]).reduce((accumulator, value) => {
							return accumulator + value;
						}, 0);

						if (sum < section.min) {
							allowed = false;
						}
					}
				}
			});

			return form.value.quantity != 0 && allowed;
		});

		watch(
			() => subtotal.value,
			() => computeSubtotal()
		);

		const computeSubtotal = () => {
			subtotal.value = props.bundle.bundle_price * form.value.quantity;
		};

		const closeThis = () => {
			open.value = false;
		};

		const addItem = () => {
			emit("add:order", form.value);
			closeThis();
		};

		watch(
			() => props.isVisible,
			(bool) => {
				open.value = bool;
			},
			{
				immediate: true,
			}
		);

		watch(
			() => open.value,
			(bool) => {
				if (!bool) {
					emit("close");
				} else {
					form.value.quantity = 0;
					form.value.special_instructions = null;
					form.value.options = {};

					props.bundle.sections.map((section) => {
						section.is_disabled = false;

						form.value.options[section.id] = {};

						section.options.map((option, index) => {
							if (section.is_single && section.is_required) {
								if (index === 0) {
									form.value.options[section.id] = option.id;
								}
							} else {
								form.value.options[section.id][option.id] = 0;
							}
						});
					});
				}
			},
			{
				immediate: true,
			}
		);

		watch(
			() => form.value.quantity,
			(value) => {
				subtotal.value = props.bundle.bundle_price * value;
			}
		);

		const isDisabled = (section) => {
			if (typeof form.value.options[section.id] === "object") {
				section.is_disabled =
					Object.values(form.value.options[section.id]).reduce((a, b) => a + b) ===
					(section.is_single ? 1 : section.max);
			} else {
				section.is_disabled = false;
			}
		};

		const sectionMinMax = (section) => {
			let text = "(" + (section.is_required ? "Required" : "Optional") + ", ";

			if (section.min) text += "Min: " + section.min + ", ";
			text += "Max: " + (section.is_single ? 1 : section.max);
			text += ")";

			return text;
		};

		return {
			open,
			closeThis,
			addItem,
			subtotal,
			form,
			allowToAdd,
			isDisabled,
			sectionMinMax,
		};
	},
});
</script>
