<template>
	<ion-page>
		<top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="page-offset">
                <div class="px-4 lg:px-12 my-8 py-8 w-full">
                    <div class="text-center py-8">
                        <template v-if="cmsContent">
                            <p class="text-primary-900 font-semibold text-underline">{{ getCmsContent('section1_pageTitle') || 'Privacy Policy'}}</p>
                        </template>
                        <template v-else>
                            <p class="text-primary-900 font-semibold text-underline">Privacy Policy</p>
                        </template>
                    </div>

                    <template v-if="cmsContent">
                        <div v-html="getCmsContent('section1_description')" class="html-box"></div>
                    </template>
                    <template v-else>
                        <h3 class="text-primary-900 font-semibold text-2xl">
                            DATA PRIVACY STATEMENT:
                        </h3>
                        <div
                            class="
                                mt-6
                            "
                        >
                            <div class="mb-4">
                                <p class="text-sm">
                                    MEAPECA ENTERPRISES, INC. (“COMPANY”) recognizes its responsibilities under Republic Act No. 
                                    10173, otherwise known as the Data Privacy Act of 2012 (“DPA”, for brevity), 
                                    with respect to the data it collects, records, organizes, updates, uses, consolidates or destroys from 
                                    its customers. The personal data obtained from its customers is entered and stored within the COMPANY’s 
                                    database system and will only be accessed by the authorized personnel of the COMPANY, which has instituted 
                                    appropriate organizational, technical and physical security measures to ensure the protection of its customers’ 
                                    personal data.
                                </p>
                            </div>
                            <div>
                                <p class="text-sm mb-2">
                                    Furthermore, the information collected and stored in the COMPANY’s database system shall only be used for the 
                                    following purposes:
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    1. Verifying the identity and performing customer due diligence;
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    2. Processing and reporting of documents related to the transactions under certain conditions 
                                    as required by law;
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    3. Meeting the COMPANY’s legal and compliance obligations arising from regulation on money laundering, 
                                    terrorist financing, fraud, exchange of information and other application laws;
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    4. Enforcing or defending the remedies or rights of the COMPANY in respect of the customer’s account;
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    5. Announcements/promotions of events, programs, courses, and other activities offered/organized by 
                                    the COMPANY and/or its partners; and
                                </p>
                            </div>
                            <div class="ml-3 mb-2">
                                <p class="text-sm">
                                    6. Activities pertaining to establishing relations with the COMPANY’s customers.
                                </p>
                            </div>

							<div class="mt-6">
								<p class="text-sm">
									COMPANY endeavors not to disclose the personal information of customers without
									their consent and shall retain this information over a period of five (5) years for
									the effective implementation and management of its database system.
								</p>
							</div>
						</div>
					</template>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { IonPage, IonContent } from "@ionic/vue";

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import useCms from "@/composables/useCms.js";

export default defineComponent({
    name: "LoginOption",
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
    },
    setup() {
        const router = useRouter();
        const { cms, contentSlug, getCms, getCmsContent } = useCms();
        const cmsContent = ref(null);

        onMounted(() => {
            contentSlug.value = 'privacy_policy';
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        })

        return {
            router,
            getCmsContent,
            cmsContent
        };
    },
});
</script>
