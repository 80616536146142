<template>
	<a
		@click="backButton"
		class="mx-0 cursor-pointer flex-shrink-0">
		<img
			src="/assets/icon/ic-arrow-back.svg"
			alt="back_arrow_icon"
			class="w-8 h-8 object-contain hover:opacity-80 transition" />
	</a>
</template>
<script>
	import { defineComponent } from "vue";
	import { useRouter } from "vue-router";

	export default defineComponent({
		name: "BackButton",
		components: {},
		setup() {
			const router = useRouter();

			const backButton = () => {
				if (document.referrer.indexOf(process.env.VUE_APP_ORIGIN_URL) >= 0) {
					router.go(-1);
				} else {
					window.location.href = router.resolve({ name: "Home" }).href;
				}
			};

			return {
				router,
				backButton,
			};
		},
	});
</script>
