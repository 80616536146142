<template>
    <div
        v-for="(option, index) in options"
        :key="index"
        class="flex items-center"
    >
        <input
            :id="option.id"
            :name="name"
            type="radio"
            class="
                h-4
                w-4
                text-primary-600
                border-gray-300
            "
            :class="[modelValue === option.value ? 'ring-primary-500' : 'ring-0']"
            @change="$emit('update:modelValue', option.value)"
            :checked="modelValue === option.value"
        />
        <label :for="option.id" class="ml-3 block text-sm text-gray-700">
            {{ option.label }}
        </label>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        options: {
            type: Array,
        },
        name: {
            type: String,
        },
    },
});
</script>
