<template>
	<div
		@click="$emit('showTimeslot')"
		class="rounded-full px-5 py-2 bg-primary-50 flex items-center justify-between gap-2"
		v-if="fulfillment"
	>
		<p class="text-primary-200 text-sm m-0">{{ fulfillment }} to</p>
		<p class="text-primary-900 font-bold text-sm m-0" v-if="recipient">
			<template v-if="recipient.address">
				{{ recipient.address.name }}

				<template v-if="timeslot">
					<span class="text-primary-200 text-sm">•</span>
					{{ timeslot.start_time_formatted }} -
					{{ timeslot.end_time_formatted }}
				</template>
			</template>
		</p>
	</div>
</template>
<script>
import { computed, defineComponent } from "vue";
import { useCartStore } from "@/store/cart";
import { useGuestStore } from "@/store/guest";

export default defineComponent({
	emit: ["showTimeslot"],
	setup() {
		const cartStore = useCartStore();
		const guestStore = useGuestStore();

		const fulfillment = computed(() => {
			return guestStore.isGuest ? guestStore.fulfillmentMethod : cartStore.fulfillmentMethod;
		});

		const recipient = computed(() => {
			return guestStore.isGuest ? guestStore.recipient : cartStore.recipient;
		});

		const timeslot = computed(() => {
			return guestStore.isGuest ? guestStore.timeslot : cartStore.timeslot;
		});

		return {
			cartStore,
			fulfillment,
			recipient,
			timeslot,
		};
	},
});
</script>
