<template>
    <div 
        v-if="length > 0" 
        class="rate"
    >
        <svg
            class="absolute w-0 h-0"
            width="0"
            height="0"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <symbol 
                    id="icon-star-full" 
                    viewBox="0 0 32 32"
                >
                    <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798z" />
                </symbol>
            </defs>
        </svg>
        <input 
            type="hidden" 
            :name="name" 
            v-model="rate" 
        >
        <template v-for="num in length" :key="num">
            <button
                type="button"
                :class="{'rate_star': true, 'hover': num <= over, 'filled': (num <= rate || isFilled(num))}"
                @click="setRate(num)"
                @mouseover="onOver(num)"
                @mouseout="onOut(num)"
            >
                <svg
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <use :xlink:href="`#icon-star-full`"></use>
                </svg>
            </button>
        </template>
    </div>
</template>
<script type="text/javascript">
import { defineComponent } from 'vue';
import { ref, watch, onBeforeMount } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: [Number, String], 
            default: 0
        },
        value: {
            type: [Number, String], 
            default: 0
        },
        name: {
            type: String, 
            default: 'rate'
        },
        length: {
            type: Number,
            default: 5,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, { emit }) {
        const over = ref(0)
        const rate = ref(0)

        const convertValue = (value) => {
            if (value >= props.length) {
                value = props.length
            } else if (value < 0) {
                value = 0
            }
            return value
        }

        const setRate = (index) => {
            if (props.readonly) return false;
            rate.value = index
            emit('update:modelValue', rate.value)
        }

        const onOver = (index) =>{ 
            if (!props.readonly) {
                over.value = index 
            }
        }

        const onOut = () => { 
            if (!props.readonly) {
                over.value = rate.value 
            }
        }
        const isFilled = (index) => { 
            return index <= over.value 
        }

        watch(
            () => props.modelValue,
            (newValue) => {
                rate.value = convertValue(newValue);
                over.value = convertValue(newValue);
            }
        )

        onBeforeMount(() => {
            let val = props.value || props.modelValue

            if (props.modelValue >= props.length) {
                val = props.length
            } else if (props.modelValue < 0) {
                val = 0
            }
            
            rate.value = convertValue(val)
            over.value = convertValue(val)
        })

        return {
            setRate,
            isFilled,
            onOver,
            onOut,
        }
    }
});
</script>