<template>
    <div class="relative flex items-center">
        <div class="flex items-center h-5">
            <input
                :id="id"
                type="checkbox"
                class="
                    cursor-pointer
                    focus:ring-primary-500
                    h-4
                    w-4
                    text-primary-600
                    border-gray-300
                    rounded
                "
                @change="$emit('update:modelValue', $event.target.checked)"
            />
        </div>
        <div class="ml-3 text-sm">
            <label
                :for="id"
                class="cursor-pointer font-semibold text-primary-900 text-sm"
                >{{ label }}</label
            >
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        id: {
            type: String,
        },
        label: {
            type: String,
        },
    },
});
</script>
