<template>
    <div
        class="cursor-pointer rounded-xl border p-5 w-full"
    >
        <div class="flex items-center justify-between w-full mb-1">
            <p class="text-base font-semibold text-primary-500 mx-0 flex">
                <span
                    v-if="isNew"
                    class="gen-badge mr-2"></span>
                {{ title }}
            </p>
            <p class="m-0 text-primary-200 text-xs">{{ date }}</p>
        </div>
        <p class="m-0 text-primary-200 text-sm min-h-[42px]">{{ description }}</p>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            default: null
        },
        date: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        isNew: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        
    },
})
</script>
