<template>
	<anchor-link :custom-class="'w-full ion-text-wrap text-left'">
		<template #content>
			<div
				class="p-4 rounded-lg w-full"
				:class="[preSelected ? 'border-2 border-black' : 'border']">
				<div class="flex items-center justify-between">
					<p class="text-primary-500 text-sm font-bold m-0 flex items-center">
						{{ title }}

						<span
							class="px-3 py-1 text-primary-900 text-xs font-semibold bg-primary-50 rounded-full ml-6"
							v-if="isDefault">
							Default
						</span>
					</p>
					<div class="flex items-center">
						<ion-icon
							:src="checkmarkCircle"
							v-if="preSelected"
							class="w-5 h-5 text-primary-500" />

						<ChevronRightIcon
							v-else-if="withRightArrow"
							class="w-5 h-5 text-primary-500 ml-2" />
					</div>
				</div>
				<p class="font-normal text-sm text-primary-400 mt-2 line-clamp-2 w-72 m-0">
					{{ address }}
				</p>
			</div>
		</template>
	</anchor-link>
</template>
<script>
	import { defineComponent } from "vue";
	import { ChevronRightIcon } from "@heroicons/vue/solid";
	import AnchorLink from "@/components/AnchorLink.vue";
	import { IonIcon } from "@ionic/vue";
	import { checkmarkCircle } from "ionicons/icons";

	export default defineComponent({
		components: {
			ChevronRightIcon,
			AnchorLink,
			IonIcon,
		},
		props: {
			title: {
				type: String,
				default: null,
			},
			address: {
				type: String,
				default: null,
			},
			withRightArrow: {
				type: Boolean,
				default: false,
			},
			preSelected: {
				type: Boolean,
				default: false,
			},
			isDefault: {
				type: Boolean,
				default: false,
			},
		},
		setup() {
			return {
				checkmarkCircle,
			};
		},
	});
</script>
