<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="px-4 lg:px-12 my-8 py-8 w-full">
					<h3 class="text-primary-900 font-semibold text-2xl">
						{{
							cmsContent
								? getCmsContent("section1_pageTitle") || "Terms and Conditions"
								: "Terms and Conditions"
						}}
					</h3>

					<template v-if="cmsContent">
						<div v-html="getCmsContent('section1_description')" class="html-box mt-6"></div>
					</template>
					<template v-else>
						<div class="mt-6">
							<div>
								<p class="text-sm mb-2 font-semibold">
									1. ORDERING TIME, PICK-UP AND DELIVERY TRANSACTIONS
								</p>
								<p class="text-sm">
									Delivery and pick-up ordering start at 10:00 am until 7:00 pm. Limited deliveries
									are available per time slot. Delivery fees will apply. Some areas may not be covered
									by our delivery service. This order form is for same-day delivery/pick-up
									transactions only. <br />*Delivery and pick-up schedule may vary depending on the
									branch*
								</p>
							</div>
							<div>
								<p class="text-sm mb-2 font-semibold mt-6">
									2. RAMEN, SIDE DISHES, RICE DISHES AND SET MEALS
								</p>
								<p class="text-sm">
									For delivery/pick-up transactions, we only offer ramen home kit, not yet ready to
									eat but comes with preparation guidelines. We don’t serve extra soup. Our ramen soup
									follows standard guidelines and we cannot accommodate “less sauce” requests. Rice
									and side dishes are ready to eat upon purchase. Set meals inclusions are ready to
									eat except the ramen which is ramen home kit.
								</p>
							</div>
							<div class="ml-3">
								<p class="text-sm mb-2 font-semibold mt-6">2. MODE OF PAYMENT AND DISCOUNTS</p>
								<p class="text-sm">
									We do accept Debit Card, Credit Card, GrabPay, and GCash as a mode of payment. The
									20% discount is currently only available for food purchases paid in the stores. If
									you wish to avail of your senior citizen/PWD discount, you may order at any of our
									stores.
								</p>
							</div>
							<div class="ml-3">
								<p class="text-sm mb-2 font-semibold mt-6">3. FOOD CONSUMPTION</p>
								<p class="text-sm">
									A ramen home kit must be kept in the chiller if it won’t be consumed yet but it must
									be consumed within the day. All take-out / pick-up/delivery orders must be consumed
									within the day of purchase to avoid negative quality concerns. All our products are
									subject to availability. Some branches including the ones near you may or may not
									carry the products featured online. Images and packaging of featured products may
									not look identical to the products you receive at the branch.
								</p>
							</div>

							<div class="mt-6">
								<p class="text-sm">
									<span class="font-semibold">CANCELLATION:</span> Ramen Kuroda reserves the right to
									refuse any changes or cancellation in case the order has already been prepared or
									dispatched for delivery/pick-up prior to receipt of your request. This applies to
									both cash and cashless transactions.
								</p>
							</div>
						</div>
					</template>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { IonPage, IonContent } from "@ionic/vue";

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import useCms from "@/composables/useCms.js";

export default defineComponent({
	name: "LoginOption",
	components: {
		IonPage,
		IonContent,
		TopNavigation,
		BottomNavigation,
	},
	setup() {
		const router = useRouter();
		const { cms, contentSlug, getCms, getCmsContent } = useCms();
		const cmsContent = ref(null);

		onMounted(() => {
			contentSlug.value = "terms_and_conditions";
			getCms(contentSlug.value);
			cmsContent.value = cms._rawValue.content;
		});

		return {
			router,
			getCmsContent,
			cmsContent,
		};
	},
});
</script>
