<template>
	<div class="p-4 border rounded-lg">
		<div class="flex flex-col gap-2 pb-4 border-b">
			<div
				class="flex items-start gap-2"
				v-if="orderItem.product">
				<img
					:src="orderItem.product.image_path"
					class="w-20 h-20 object-cover rounded-lg"
					v-if="orderItem.product.image_path" />
				<div>
					<h4 class="font-bold text-sm m-0">
						{{ orderItem.product.name }}
					</h4>

					<!-- Free Items -->
					<div
						v-if="orderItem.product.free_items?.length > 0 || orderItem.product.applied_free_items?.length > 0"
						class="mt-3">
						<!-- Product Specific -->
						<template
							v-for="freeItem in orderItem.product.free_items"
							:key="freeItem">
							<template v-if="freeItem.get_product?.inventories[0]">
								<p
									class="text-xs text-error font-semibold"
									v-if="freeItem.get_product.inventories[0].stock > 0">
									*Free {{ freeItem.get_product.name }}
								</p>
							</template>
						</template>

						<!-- Category Specific & ALL -->
						<template
							v-for="freeItem in orderItem.product.applied_free_items"
							:key="freeItem">
							<template v-if="freeItem.get_product?.inventories[0]">
								<p
									class="text-xs text-error font-semibold"
									v-if="freeItem.get_product.inventories[0].stock > 0">
									*Free {{ freeItem.get_product.name }}
								</p>
							</template>
						</template>
					</div>

					<!-- Options -->
					<p
						class="text-primary-300 text-xs m-0 flex flex-col items-start text-left"
						v-if="orderItem.options.length > 0">
						<span
							class="m-0"
							v-for="itemOption in orderItem.options"
							:key="itemOption">
							{{ itemOption.quantity * orderItem.quantity }}x -
							{{ itemOption.option.product ? itemOption.option.product.name : itemOption.option.name }}
							<!-- <template v-if="itemOption.option.quantity && itemOption.option.quantity > 1"> ({{ itemOption.option.quantity }} qty) </template> -->
						</span>
					</p>

					<!-- Instructions -->
					<p
						class="text-sm text-gray-600 truncate underline cursor-pointer"
						v-if="orderItem.special_instructions"
						@click="orderItem.show_special_instructions = !orderItem.show_special_instructions">
						<template v-if="!orderItem.show_special_instructions">View more</template>
						<template v-else>View less</template>
					</p>
				</div>
			</div>
			<div
				class="bg-gray-50 p-4 rounded-lg mb-2 w-100"
				v-if="orderItem.show_special_instructions">
				<p class="text-sm text-gray-600">Special Instructions:</p>
				<p class="text-sm text-gray-600">
					{{ orderItem.special_instructions }}
				</p>
			</div>
		</div>

		<div class="flex items-center justify-between pt-4">
			<h4 class="flex-1 font-bold text-sm m-0">
				<render-price :value="getTotal()" />
			</h4>
			<div class="flex-1 flex items-center gap-2 justify-end">
				<quantity-selector
					:value="orderItem.quantity"
					:max="orderItem.product.inventories?.[0].stock"
					@update:modelValue="(value) => $emit('update:quantity', value)" />
				<button-component
					v-if="orderItem.new_quantity != null && orderItem.quantity != orderItem.new_quantity"
					@click="updateQuantity"
					customClass="icon-only"
					expand="block"
					:color="orderItem.new_quantity > 0 ? 'primary' : 'red'"
					size="default"
					:disabled="updatingQuantity">
					<template #content>
						<CheckIcon
							class="w-5 h-5"
							v-if="orderItem.new_quantity > 0" />
						<TrashIcon
							class="w-5 h-5"
							v-else />
					</template>
				</button-component>
			</div>
		</div>
	</div>

	<toast-message
		:show="showToast"
		:message="toastMessage"
		@didDismiss="showToast = false" />
</template>
<script>
	import { defineComponent, ref } from "vue";
	import { modalController } from "@ionic/vue";
	import QuantitySelector from "@/components/QuantitySelector.vue";
	import RenderPrice from "@/components/RenderPrice.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import ToastMessage from "@/components/ToastMessage.vue";
	import FreeItemModal from "@/components/FreeItemModal.vue";
	import { CheckIcon, TrashIcon } from "@heroicons/vue/solid";
	import { useCartStore } from "@/store/cart";
	import useModal from "@/composables/modal";

	export default defineComponent({
		components: {
			QuantitySelector,
			RenderPrice,
			ButtonComponent,
			ToastMessage,
			CheckIcon,
			TrashIcon,
		},
		emits: ["new:quantity", "update:quantity"],
		props: {
			item: {
				type: Object,
				required: true,
			},
		},
		setup(props, { emit }) {
			const cartStore = useCartStore();
			const modal = useModal();

			const orderItem = ref(props.item);
			const showToast = ref(false);
			const toastMessage = ref(null);
			const updatingQuantity = ref(false);

			const getTotal = () => {
				let total = props.item.amount;

				if (props.item.add_options_total) {
					props.item.options.forEach((value) => {
						total += value.amount * props.item.quantity;
					});
				}

				return total;
			};

			const freeItemModal = async (freeItem) => {
				const modal = await modalController.create({
					component: FreeItemModal,
					canDismiss: true,
					backdropDismiss: true,
					cssClass: "gen-modal points",
					componentProps: {
						product: freeItem.get_product.name,
						minimum: freeItem.for_every_amount,
					},
				});

				return modal.present();
			};

			const updateQuantity = () => {
				updatingQuantity.value = true;

				cartStore.updateQuantity(
					orderItem.value.id,
					orderItem.value.new_quantity,
					(result) => {
						showToast.value = true;
						toastMessage.value = "Quantity has been updated.";

						const newFreeItems = cartStore.registerFreeItems(result.data.freeItems);

						newFreeItems.forEach((freeItem) => {
							freeItemModal(freeItem);
						});

						emit("new:quantity");
						updatingQuantity.value = false;
					},
					(error) => {
						updatingQuantity.value = false;
						modal.error(error.response.data.message);
					}
				);
			};

			return {
				updatingQuantity,
				orderItem,
				showToast,
				toastMessage,
				getTotal,
				updateQuantity,
			};
		},
	});
</script>
