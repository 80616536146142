<template>
	<side-menu :title="title" :is-visible="open" @close="open = false">
		<template #content>
			<template v-if="step === 1">
				<div>
					<item-link
						v-if="withWallet"
						@click="setMethod('RK Wallet')"
						title="RK Wallet"
						:description="cmsContent ? getCmsContent('methods_rkWalletDescription') : 'Pay using RK Wallet'"
					/>
					<!-- <item-link
                        @click="step++"
                        title="Debit/Credit"
                        :description="
                            cmsContent
                                ? getCmsContent('methods_debitCreditDescription')
                                : 'Pay using your Mastercard, Visa and more'
                        "
                    /> -->

					<!-- MAYA OTP -->
					<item-link
						v-if="paymentSettings.one_time_enable"
						@click="setMethod('oneTimeCheckout')"
						title="One-Time Payment (Debit/Credit)"
						:description="
							cmsContent
								? getCmsContent('methods_cardDescription')
								: 'Pay using your Debit/Credit Card. We do not save card details.'
						"
                        :disabled="toPay === 0"
					/>
					<!-- <item-link
						@click="setMethod('Gcash')"
						title="Gcash"
						:description="
							cmsContent !== null
								? getCmsContent('methods_gcashDescription')
								: 'Pay using your Gcash App'
						"
					/> -->
					<item-link
						v-if="paymentSettings.maya_enable"
						@click="setMethod('Maya')"
						title="E-Wallets"
						:description="cmsContent ? getCmsContent('methods_mayaDescription') : 'Pay using your Maya App'"
                        :disabled="toPay === 0"
					/>

					<!-- PAYMONGO -->
					<item-link
						v-if="paymentSettings.gcash_enable"
						@click="setMethod('GCash')"
						title="GCash"
						:description="
							cmsContent ? getCmsContent('methods_gcashDescription') : 'Pay using your GCash App'
						"
						:disabled="toPay < 100"
					/>
					<item-link
						v-if="paymentSettings.grabpay_enable"
						@click="setMethod('GrabPay')"
						title="GrabPay"
						:description="
							cmsContent ? getCmsContent('methods_grabpayDescription') : 'Pay using your Grab App'
						"
						:disabled="toPay < 100"
					/>
				</div>
			</template>
			<template v-if="step === 2">
				<div class="space-y-8">
					<div>
						<radio-large-card :options="cards" v-model="cardSelected"></radio-large-card>
					</div>
					<div class="space-y-4">
						<button-component expand="block" color="primary" fill="outline" @click="step++">
							<template #content> Add Card </template>
						</button-component>

						<button-component expand="block" color="primary" @click="selectCard" v-if="cardSelected">
							<template #content> Select Card </template>
						</button-component>
					</div>
				</div>
			</template>
			<template v-if="step === 3">
				<div class="space-y-8">
					<div class="space-y-2">
						<!-- <select-menu
							:options="cardType"
							placeholder="Select Card"
						/> -->

						<text-input placeholder="Card Number" v-model="cardDetail.number" />

						<div class="flex items-start gap-2">
							<text-input
								placeholder="Expiry Month"
								helper="MM"
								maxlength="2"
								v-model="cardDetail.expMonth"
							/>
							<text-input
								placeholder="Expiry Year"
								helper="YYYY"
								maxlength="4"
								v-model="cardDetail.expYear"
							/>
							<text-input placeholder="CVV" :rightIcon="informationCircle" v-model="cardDetail.cvc" />
						</div>

						<!-- <check-box label="Set as Default" id="set-default" /> -->
					</div>
					<button-component color="primary" expand="block" @click="selectCard">
						<template #content>{{ buttonText }}</template>
					</button-component>
				</div>
			</template>
		</template>
	</side-menu>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { informationCircle } from "ionicons/icons";
import SideMenu from "@/components/SideMenu.vue";
import ItemLink from "@/components/ItemLink.vue";
import RadioLargeCard from "@/components/RadioLargeCard.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import TextInput from "@/components/TextInput.vue";
// import CheckBox from "@/components/CheckBox.vue";
// import SelectMenu from "@/components/SelectMenu.vue";
import useCms from "@/composables/useCms";
import { modalController } from "@ionic/vue";
import Modal from "@/components/Modal.vue";
import { useApiStore } from "@/store/api.js";

export default defineComponent({
	components: {
		SideMenu,
		ItemLink,
		RadioLargeCard,
		ButtonComponent,
		TextInput,
		// CheckBox,
		// SelectMenu,
	},
	emits: ["close", "payment:set", "card:set"],
	props: {
		withWallet: {
			type: Boolean,
			default: true,
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
		toPay: {
			type: Number,
			default: 0,
		},
		buttonText: {
			type: String,
			default: "Top up now",
		},
	},
	setup(props, { emit }) {
		const cardSelected = ref(null);
		const open = ref(false);
		const step = ref(1);
		const { cms, contentSlug, getCms, getCmsContent } = useCms();
		const cmsContent = ref(null);
		const cardDetail = ref({
			number: null,
			expMonth: null,
			expYear: null,
			cvc: null,
		});
		const modalTitle = ref(null);
		const message = ref(null);
		const store = useApiStore();
		const paymentSettings = computed(() => store.settings.paymentSettings);

		const closeThis = () => {
			open.value = false;
		};

		const title = computed(() => {
			if (step.value === 1) {
				return "Choose Payment";
			} else if (step.value === 2) {
				return "Debit/Credit";
			} else {
				return "Input Card Details(Debit/Credit)";
			}
		});

		const cards = [
			{
				id: 1,
				cardName: "MasterCard",
				cardNumber: "4566",
				expiryDate: "04 / 2025",
			},
			{
				id: 2,
				cardName: "Visa",
				cardNumber: "1029",
				expiryDate: "09 / 2022",
			},
		];
		const cardType = [{ value: "MasterCard" }, { value: "Visa" }, { value: "Others" }];

		watch(
			() => props.isVisible,
			(bool) => (open.value = bool),
			{
				immediate: true,
			}
		);

		watch(
			() => open.value,
			(bool) => {
				if (!bool) {
					emit("close");
				} else {
					if (cardSelected.value && step.value != 2) {
						cardSelected.value = null;
					}

					step.value = 1;
				}
			}
		);

		const setMethod = (method) => {
			emit("payment:set", method);
			open.value = false;
		};

		const selectCard = () => {
			if (
				cardDetail.value.number &&
				cardDetail.value.expMonth &&
				cardDetail.value.expYear &&
				cardDetail.value.cvc
			) {
				if (cardDetail.value.number.length < 12 || cardDetail.value.number.length > 19) {
					showModalMessage("Warning!", "Please input valid 12-19 card number.");
					return false;
				}

				if (
					cardDetail.value.expMonth < 1 ||
					cardDetail.value.expMonth > 12 ||
					cardDetail.value.expMonth.length != 2
				) {
					showModalMessage("Warning!", "Please input valid month (MM).");
					return false;
				}

				if (cardDetail.value.expYear.length != 4) {
					showModalMessage("Warning!", "Please input valid year (YYYY).");
					return false;
				}

				if (cardDetail.value.cvc.length != 3) {
					showModalMessage("Warning!", "Please input 3-digit CVC.");
					return false;
				}

				emit("card:set", cardDetail.value);
				setMethod("Card");
			} else {
				showModalMessage("Warning!", "Please input all fields.");
			}
		};

		const showModalMessage = (title, msg) => {
			modalTitle.value = title;
			message.value = msg;
			responseModal();
		};

		const getContentSlug = () => {
			contentSlug.value = "payment_method_label";
			getCms(contentSlug.value);
			cmsContent.value = cms._rawValue.content;
		};

		const responseModal = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "gen-modal",
				componentProps: {
					title: modalTitle.value,
					description: message.value,
					imageLink: "/assets/icons/ic-warning.svg",
					imageWidth: "80",
					imageHeight: "78",
					buttonText: "Okay",
				},
			});

			return modal.present();
		};

		onMounted(() => {
			getContentSlug();
		});

		return {
			open,
			closeThis,
			cardSelected,
			cards,
			step,
			title,
			informationCircle,
			cardType,
			setMethod,
			selectCard,
			getCmsContent,
			cmsContent,
			cardDetail,
			paymentSettings,
		};
	},
});
</script>
