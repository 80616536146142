<template>
    <div class="flex justify-between py-3" v-if="method === 'GCash'">
        <div>
            <p class="text-sm font-semibold mb-2">GCash</p>
            <p class="text-sm text-primary-400">Pay using your GCash App</p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
    <div class="flex justify-between py-3" v-else-if="method === 'GrabPay'">
        <div>
            <p class="text-sm font-semibold mb-2">GrabPay</p>
            <p class="text-sm text-primary-400">Pay using your Grab App</p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
    <div
        class="flex justify-between py-3"
        v-else-if="method === 'Debit/Credit'"
    >
        <div>
            <p class="text-sm font-semibold mb-2">Debit/Credit</p>
            <p class="text-sm text-primary-400">
                Pay using your Mastercard, Visa and more
            </p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
    <div
        class="flex justify-between py-3"
        v-else-if="method === 'Card' || method === 'oneTimeCheckout'"
    >
        <div>
            <p class="text-sm font-semibold mb-2">One-time payment(Debit/Credit)</p>
            <p class="text-sm text-primary-400">
                Pay using your Debit/Credit Card. We do not save card details.
            </p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
    <div
        class="flex justify-between py-3"
        v-else-if="method === 'Maya'"
    >
        <div>
            <p class="text-sm font-semibold mb-2">E-Wallets</p>
            <p class="text-sm text-primary-400">
                Pay using your Maya App.
            </p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
    <div class="flex justify-between py-3" v-else-if="method === 'RK Wallet'">
        <div>
            <p class="text-sm font-semibold mb-2">RK Wallet</p>
            <p class="text-sm text-primary-400">Pay using your RK Wallet</p>
        </div>
        <ion-icon v-if="showForward" :src="chevronForward" class="w-4 h-4"></ion-icon>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";

export default defineComponent({
    name: "PaymentMethod",
    components: {
        IonIcon,
    },
    props: {
        method: {
            type: String,
            required: true,
        },
        showForward: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return {
            chevronForward,
        };
    },
});
</script>