<template>
	<div class="p-4 border rounded-lg">
		<div class="flex flex-col gap-2">
			<h4 class="font-bold text-sm m-0">Free {{ item.get_product.name }}!</h4>

			<p class="text-primary-300 text-sm m-0 flex flex-col items-start text-left">
				{{ Math.floor(cartStore.subtotal / item.for_every_amount) }}x
				{{ item.get_product.name }}
			</p>

			<p class="text-sm text-primary-300 mx-0">
				You successfully avail our promo for orders worth
				<render-price :value="item.for_every_amount" />!
			</p>
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";
import RenderPrice from "@/components/RenderPrice.vue";
import { useCartStore } from "@/store/cart";

export default defineComponent({
	components: {
		RenderPrice,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const cartStore = useCartStore();

		return {
			cartStore,
		};
	},
});
</script>
