<template>
	<div
		class="py-4"
		:class="{ 'border-b border-primary-50': withBorder }">
		<div class="space-y-2">
			<div class="text-xs text-primary-300 flex justify-between">
				<div class="text-xs">
					{{ description }}
				</div>
				<div class="text-xs">
					{{ time }}
				</div>
			</div>
			<div class="text-sm font-semibold text-primary-900 flex justify-between">
				<div class="text-xs">
					{{ title }}
				</div>
				<p
					v-if="type === 'added'"
					class="text-sm font-bold m-0 text-success">
					+
					<render-price
						v-if="!plainNumber"
						:value="amount" />
					<template v-else>{{ amount }}</template>
				</p>
				<p
					v-else-if="type === 'refunded'"
					class="text-sm font-bold m-0 text-success">
					+
					<render-price
						v-if="!plainNumber"
						:value="amount" />
					<template v-else>{{ amount }}</template>
				</p>
				<p
					v-else-if="type === 'deducted'"
					class="text-sm font-bold m-0 text-error">
					-
					<render-price
						v-if="!plainNumber"
						:value="amount" />
					<template v-else>{{ amount }}</template>
				</p>
				<p
					v-else-if="type === 'reset'"
					class="text-sm font-bold m-0 text-blue-400">
					<render-price
						v-if="!plainNumber"
						:value="amount" />
					<template v-else>{{ amount }}</template>
				</p>
			</div>
		</div>
	</div>
</template>
<script>
	import { defineComponent } from "vue";
	import RenderPrice from "@/components/RenderPrice.vue";

	export default defineComponent({
		components: {
			RenderPrice,
		},
		props: {
			title: {
				type: String,
			},
			description: {
				type: String,
			},
			time: {
				type: String,
			},
			amount: {
				type: [String, Number],
			},
			type: {
				type: String,
			},
			withBorder: {
				type: Boolean,
				default: false,
			},
			plainNumber: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>
