<template>
    <ion-page>
        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="page-offset">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        min-h-[calc(100vh-72px)]
                        lg:min-h-[calc(100vh-80px)]
                    "
                >
                    <div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
                        <div class="space-y-4">
                            <h3
                                class="
                                    font-bold
                                    text-[28px] text-primary-900 text-center
                                "
                            >
                                RK Points
                            </h3>
                            <p class="text-primary-400 text-center">
                                You have currently
                                {{ userStore.userData.rk_available_points }}
                                {{
                                    userStore.userData.rk_available_points === 1
                                        ? "point"
                                        : "points"
                                }}
                            </p>
                        </div>
                        <div class="mt-10">
                            <div class="relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    class="
                                        shadow-sm
                                        focus:ring-primary-500
                                        focus:border-primary-500
                                        block
                                        w-full
                                        sm:text-sm
                                        border-gray-300
                                        rounded-lg
                                        placeholder:text-primary-100
                                    "
                                    placeholder="Enter points"
                                    v-model="points"
                                />
                                <div
                                    class="
                                        cursor-pointer
                                        absolute
                                        inset-y-0
                                        right-0
                                        pr-3
                                        flex
                                        items-center
                                    "
                                    @click="applyPoints"
                                >
                                    <span class="font-bold text-sm">MAX</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-10">
                            <button-component
                                @click="router.push('checkout')"
                                expand="block"
                                color="primary"
                            >
                                <template #content> Use Points </template>
                            </button-component>
                        </div>
                    </div>
                </div>
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useUserStore } from "@/store/user";
import { useCartStore } from "@/store/cart";
import { useRouter } from "vue-router";
import { IonPage, IonContent } from "@ionic/vue";

export default defineComponent({
    components: {
        TopNavigation,
        BottomNavigation,
        ButtonComponent,
        IonPage,
        IonContent,
    },
    setup() {
        const router = useRouter();
        const userStore = useUserStore();
        const cartStore = useCartStore();
        const points = ref(cartStore.points);

        const applyPoints = () => {
            points.value =
                userStore.userData.rk_available_points > cartStore.subtotal
                    ? cartStore.subtotal
                    : userStore.userData.rk_available_points;
        };

        return {
            router,
            userStore,
            points,
            applyPoints,
        };
    },
});
</script>
