<template>
    <div>
        <ion-item lines="none" mode="ios" class="ion-no-padding">
            <ion-label class="text-sm font-semibold ion-no-margin">
                {{ title }}
            </ion-label>
            <ion-toggle
                slot="end"
                mode="ios"
                :checked="modelValue"
                class="ion-no-padding"
                @ionChange="$emit('update:modelValue', $event.target.checked)"
            />
        </ion-item>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { IonItem, IonLabel, IonToggle } from "@ionic/vue";

export default defineComponent({
    components: {
        IonItem,
        IonLabel,
        IonToggle,
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
    },
});
</script>
