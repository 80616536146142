<template>
    <select
        class="
            shadow-sm
            focus:ring-primary-500 focus:border-primary-500
            block
            w-full
            sm:text-sm
            border-gray-300
            rounded-lg
            placeholder:text-primary-100
        "
        @change="$emit('update:modelValue', $event.target.value)"
    >
        <option selected disabled value>{{ placeholder }}</option>
        <template v-for="(option, index) in options" :key="index">
            <option :value="option.value">{{ option.value }}</option>
        </template>
    </select>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        options: {
            type: [],
        },
        placeholder: {
            type: String,
        },
    },
});
</script>
