<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<template v-if="gift">
					<div class="flex items-center justify-center min-h-[calc(100dvh-72px)] lg:min-h-[calc(100dvh-80px)] px-4 py-10">
						<div class="w-full text-center">
							<img
								src="/assets/icon/ic-gift-sent.svg"
								alt="icon"
								class="w-[157px] h-[157px] mx-auto mb-9" />
							<h3 class="mb-4 font-semibold">Gift has been sent!</h3>
							<p class="text-sm text-primary-400 mb-4">Recipients will be informed via email that they’ve received a gift from you.</p>

							<p class="text-sm text-primary-400/[0.32]">Reference no: {{ gift.reference_number }}</p>
						</div>
					</div>
				</template>
				<div
					class="text-center py-10"
					v-else>
					<ion-spinner v-if="loading" />
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
	import { defineComponent, onMounted, ref } from "vue";
	import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
	import { useRoute, useRouter } from "vue-router";
	import { useApiStore } from "@/store/api";

	export default defineComponent({
		components: {
			IonContent,
			IonPage,
			IonSpinner,
		},
		setup() {
			const route = useRoute();
			const router = useRouter();
			const apiStore = useApiStore();

			const loading = ref(false);
			const gift = ref(null);

			const pollStatus = () => {
				loading.value = true;

				window.axios
					.post(
						apiStore.route("rkCreditsPollStatus", {
							id: route.query.gift,
						})
					)
					.then((response) => {
						if (response.data.status === "success") {
							gift.value = response.data.gift;
							loading.value = false;
						} else if (response.data.status === "failed") {
							router.replace("/mobile-rk-credit-failed");
						} else {
							setTimeout(() => {
								pollStatus();
							}, 3000);
						}
					})
					.catch(() => {
						router.replace("/mobile-rk-credit-failed");
					});
			};

			onMounted(() => {
				pollStatus();
			});

			return {
				loading,
				gift,
			};
		},
	});
</script>
