<template>
	<div>
		<slot></slot>
		<h3 class="text-primary-900 font-semibold text-2xl">Recipient</h3>
		<p class="text-sm text-primary-400">We need to know who will receive the order</p>
		<div class="max-h-80 overflow-auto space-y-6 scrollbar-style mt-6">
			<div class="space-y-4 p-1" v-if="!guestStore.isGuest">
				<radio-group :options="recipients" name="order-recipient" v-model="form.type" />
			</div>
			<div class="space-y-2 p-1" v-show="form.type === 'other'">
				<text-input type="text" placeholder="First Name" v-model="form.first_name" />
				<text-input type="text" placeholder="Last Name" v-model="form.last_name" />
				<mobile-number v-model="form.mobile_number" :error="error" />
				<text-input type="email" placeholder="Email" v-model="form.email" />
			</div>
		</div>
		<div class="absolute lg:relative bottom-0 w-full right-0 px-4 lg:px-1 lg:pb-0 pb-8 mt-6">
			<button-component
				@click="save"
				color="primary"
				expand="block"
				:disabled="form.type === 'other' && !Object.values(form).every((v) => v != null)"
			>
				<template #content> Continue </template>
			</button-component>
		</div>
	</div>
</template>
<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import TextInput from "@/components/TextInput.vue";
import RadioGroup from "@/components/RadioGroup.vue";
import { useCartStore } from "@/store/cart";
import { useUserStore } from "@/store/user";
import { useGuestStore } from "@/store/guest";
import ButtonComponent from "@/components/ButtonComponent.vue";
import MobileNumber from "@/components/MobileNumber.vue";
import useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
	components: {
		TextInput,
		RadioGroup,
		ButtonComponent,
		MobileNumber,
	},
	setup(props, { emit }) {
		const cartStore = useCartStore();
		const userStore = useUserStore();
		const guestStore = useGuestStore();
		const { localStorage } = useLocalStorage();

		const form = ref({
			type: "myself",
			first_name: null,
			last_name: null,
			mobile_number: null,
			email: null,
		});

		const error = ref(null);

		const save = () => {
			if (form.value.type === "other" && form.value.mobile_number && form.value.mobile_number.length != 10) {
				return (error.value = "Invalid recipient mobile number");
			}

			if (!guestStore.isGuest) {
				cartStore.recipient.type = form.value.type;
				cartStore.recipient.firstName = form.value.first_name;
				cartStore.recipient.lastName = form.value.last_name;
				cartStore.recipient.mobileNumber = form.value.mobile_number;
				cartStore.recipient.email = form.value.email;
			} else {
				guestStore.recipient.type = form.value.type;
				guestStore.recipient.firstName = form.value.first_name;
				guestStore.recipient.lastName = form.value.last_name;
				guestStore.recipient.mobileNumber = form.value.mobile_number;
				guestStore.recipient.email = form.value.email;

				localStorage.value.set("guestRecipient", guestStore.recipient);
			}

			emit("next");
		};

		const recipients = [
			{ id: "myself", label: "Order for myself", value: "myself" },
			{ id: "other", label: "Order for other", value: "other" },
		];

		onMounted(() => {
			localStorage.value.get("authToken").then((value) => {
				if (value) {
					localStorage.value.get("activeCart").then((value) => {
						if (value) {
							form.value.type = value.recipient_email === userStore.userData.email ? "myself" : "other";
							form.value.first_name = value.recipient_first_name;
							form.value.last_name = value.recipient_last_name;
							form.value.mobile_number = value.recipient_mobile_number;
							form.value.email = value.recipient_email;
						}
					});
				}
			});

			guestStore.checkIfGuest((bool) => {
				if (bool) {
					form.value.type = "other";
					form.value.first_name = guestStore.recipient.firstName;
					form.value.last_name = guestStore.recipient.lastName;
					form.value.mobile_number = guestStore.recipient.mobileNumber;
					form.value.email = guestStore.recipient.email;
				}
			});
		});

		onUnmounted(() => {
			form.value.type = "myself";
			form.value.first_name = "";
			form.value.last_name = "";
			form.value.mobile_number = "";
			form.value.email = "";
		});

		return {
			recipients,
			guestStore,
			form,
			save,
			error,
		};
	},
});
</script>
