<template>
	<div>
		<div class="max-h-[75vh] lg:max-h-full overflow-auto space-y-6 pr-2 lg:pr-0 scrollbar-style mt-6">
			<div class="p-1">
				<div class="relative">
					<text-input v-model="locationState.completeAddress" type="text" placeholder="Enter Address" @click="$emit('close')" />
					<div @click="$emit('close')" class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
						<XCircleIcon class="h-6 w-6 text-primary-200 bg-white" aria-hidden="true" />
					</div>
				</div>

				<div class="mt-8">
					<!-- MAP HERE -->
					<div id="locationMap" class="lg:w-[400px] h-[400px]"></div>
				</div>

				<div class="bg-red-100 rounded-lg p-4 mt-8" v-if="errorMessage">
					<div class="flex items-start justify-start gap-3">
						<p class="text-error text-sm w-72 m-0">
							{{ errorMessage }}
						</p>
					</div>
				</div>

				<div class="bg-primary-50 rounded-lg p-4 mt-8">
					<div class="flex items-start justify-start gap-3">
						<LocationMarkerIcon class="w-4 h-4 text-primary-500" />
						<p class="text-primary-500 text-sm line-clamp-2 w-72 m-0">
							{{ locationState.completeAddress }}
						</p>
					</div>
				</div>
				<div class="mt-8 space-y-2.5">
					<div>
						<text-input type="text" placeholder="Address Name" helper="e.g. home, office" v-model="form.name" />
					</div>
					<div>
						<text-input type="text" placeholder="Address Details" helper="e.g. blk number, floor number" v-model="form.details" />
					</div>
				</div>
				<div class="my-4">
					<check-box id="set-default" label="Set as default" v-model="form.is_default" />
				</div>
			</div>

			<div class="absolute lg:relative lg:border-t-0 bottom-5 border-t w-full right-0 pt-4 px-4 bg-white lg:px-1 lg:pb-0">
				<button-component
					@click="save"
					color="primary"
					expand="block"
					:disabled="!form.name || !form.details || !locationState.completeAddress">
					<template #content>
						<template v-if="!loading">Save & Continue</template>
						<ion-spinner v-else></ion-spinner>
					</template>
				</button-component>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { LocationMarkerIcon } from "@heroicons/vue/solid";
import TextInput from "@/components/TextInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { XCircleIcon } from "@heroicons/vue/solid";
import { useUserStore } from "@/store/user";
import { IonSpinner } from "@ionic/vue";
import useLocation from "@/composables/useLocation";
import { useAddressStore } from "@/store/address";
import { useGuestStore } from "@/store/guest";

export default defineComponent({
	components: {
		LocationMarkerIcon,
		TextInput,
		CheckBox,
		ButtonComponent,
		XCircleIcon,
		IonSpinner,
	},
	setup(props, { emit }) {
		const loading = ref(false);
		const userStore = useUserStore();
		const addressStore = useAddressStore();
		const guestStore = useGuestStore();
		const errorMessage = ref(null);

		const { locationState, setupMap, updateMapCenter, setCustomerLocation, updateMarkerCoordinates, submitLocation, clearValues } = useLocation();

		const form = ref({
			id: null,
			name: null,
			details: null,
			address: null,
			latitude: null,
			longitude: null,
			is_default: false,
		});

		const save = () => {
			loading.value = true;

			form.value.address = locationState.completeAddress;
			form.value.latitude = locationState.customerLat;
			form.value.longitude = locationState.customerLng;

			if (guestStore.isGuest) {
				guestStore.recipient.address = {};
				guestStore.recipient.address.latitude = form.value.latitude;
				guestStore.recipient.address.longitude = form.value.longitude;
				guestStore.recipient.address.fullAddress = locationState.completeAddress;
				guestStore.recipient.address.name = form.value.name;
				guestStore.recipient.address.details = form.value.details;
				guestStore.recipient.address.isDefault = form.value.is_default;

				loading.value = false;
				emit("address:saved", locationState.completeAddress);
			} else {
				submitLocation(form.value)
					.then((result) => {
						loading.value = false;

						if (addressStore.isAddressBook) {
							emit("address:saved");
						} else {
							userStore.userData.addresses.push(result.data.address);
							emit("address:saved", result.data.address);
						}

						resetValues();
						clearValues();
					})
					.catch((error) => {
						loading.value = false;
						errorMessage.value = error.response.data.message;
					});
			}
		};

		const initLocation = () => {
			if (document.querySelector("#locationMap").children.length <= 0) {
				setupMap("locationMap").then(() => {
					updateMapCenter(locationState.customerLat, locationState.customerLng);
					updateMarkerCoordinates("customer");
				});
			} else {
				updateMapCenter(locationState.customerLat, locationState.customerLng);
				updateMarkerCoordinates("customer");
			}
		};

		// For Address Book Only
		const setSelectedAddress = (address) => {
			form.value.id = address.id;
			form.value.name = address.name;
			form.value.details = address.details;
			form.value.address = address.address;
			form.value.latitude = address.latitude;
			form.value.longitude = address.longitude;
			form.value.is_default = address.is_default;
		};

		const resetValues = () => {
			form.value = {
				id: null,
				name: null,
				details: null,
				address: null,
				latitude: null,
				longitude: null,
				is_default: false,
			};

			addressStore.selectedAddress = {};
			addressStore.isAddressBook = false;
			addressStore.editAddress = false;
			locationState.customerLat = 0;
			locationState.customerLng = 0;
		};

		const getAddress = async () => {
			if (addressStore.isAddressBook && addressStore.hasSelectedAddress) {
				locationState.completeAddress = addressStore.selectedAddress.address;
				setSelectedAddress(addressStore.selectedAddress);
			} else {
				form.value.name = locationState.customerAddressName;
				form.value.details = locationState.customerAddressDetail;
				form.value.address = locationState.completeAddress;
				form.value.latitude = locationState.customerLat;
				form.value.longitude = locationState.customerLng;

				return true;
			}
		};

		watch(
			() => locationState.completeAddress,
			(value) => {
				form.value.address = value;
			}
		);

		onMounted(() => {
			getAddress().then(() => {
				setTimeout(() => {
					// Init map
					if (form.value.latitude && form.value.longitude) {
						locationState.customerLat = form.value.latitude;
						locationState.customerLng = form.value.longitude;
						initLocation();
					} else if (locationState.customerLat && locationState.customerLng) {
						initLocation();
					} else {
						setCustomerLocation().then(() => {
							initLocation();
						});
					}
				}, 500);
			});
		});

		onBeforeUnmount(() => {
			locationState.customerAddressName = form.value.name;
			locationState.customerAddressDetail = form.value.details;
		});

		return {
			loading,
			form,
			save,
			locationState,
			errorMessage,
		};
	},
});
</script>
