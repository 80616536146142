import { defineStore } from "pinia";
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useApiStore = defineStore({
	id: "api",
	state: () => {
		return {
			routes: [],
			cms: [],
			settings: [],
		};
	},
	getters: {
		route: (state) => {
			return (route, params = {}) => {
				if (state.routes[route] !== undefined) {
					let url = state.routes[route];

					for (let [key, value] of Object.entries(params)) {
						url = url.replace("::" + key + "::", value);
					}

					return url;
				} else {
					return "";
				}
			};
		},
	},
	actions: {
		async fetchConfigs() {
			const { localStorage } = useLocalStorage();

			await window.axios.post(process.env.VUE_APP_API_URL + "/api/config/fetch").then((result) => {
				this.routes = result.data.routes;
				this.cms = result.data.cms;
				this.settings = result.data.settings;
				localStorage.value.set("routes", result.data.routes);
				localStorage.value.set("cms", result.data.cms);
				localStorage.value.set("settings", result.data.settings);

				if (this.settings.maintenanceSettings?.enabled && window.location.pathname !== "/maintenance-page") {
					window.location.href = "/maintenance-page";

					return;
				} else if (!this.settings.maintenanceSettings?.enabled && window.location.pathname === "/maintenance-page") {
					window.location.href = "/";

					return;
				}
			});
		},
	},
});
