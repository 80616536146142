<template>
    <ion-toast
        :is-open="show"
        :message="message"
        :duration="duration"
        :position="position"
        :css-class="customClass"
        :buttons="[
            {
                side: 'end',
                icon: icon,
                role: 'cancel',
            },
        ]"
        :showCloseButton="true"
        @didDismiss="$emit('didDismiss')"
    />
</template>
<script>
import { IonToast } from "@ionic/vue";

export default {
    components: {
        IonToast,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: null,
        },
        duration: {
            type: Number,
            default: 2000,
        },
        position: {
            type: String,
            default: "top",
        },
        customClass: {
            type: String,
            default: "general-toast"
        },
        icon: {
            type: String,
            default: "/assets/icon/ic-close.svg"
        }
    },
};
</script>
<style lang="scss">
.general-toast {
    --border-radius: 40px;
    --background: #231815;
    --color: #ffffff;
    --max-width: 500px;
    &.top {
        margin-top: 48px;
    }
}
.success-toast {
    --border-radius: 40px;
    --background: #6BA353;
    --color: #ffffff;
    --max-width: 500px;
    &.top {
        margin-top: 48px;
    }
}
</style>
