import { computed } from 'vue';
import { Preferences } from "@capacitor/preferences";

export default function useLocalStorage() {
    const localStorage = computed(() => {
        return {
            async get(key) {
                const { value } = await Preferences.get({ key: key });
                return JSON.parse(value);
            },
            async set(key, value) {
                value = JSON.stringify(value);
                await Preferences.set({ key: key, value: value });
            },
            async delete(key) {
                await Preferences.remove({ key: key });
            },
        };
    })

    return {
        localStorage
    }
}
