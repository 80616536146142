<template>
    <div class="block w-full lg:relative absolute top-0 left-0 z-10 h-full bg-white lg:p-0 py-8 px-4 max-w-none lg:max-w-[1024px] mx-auto">
        <div class="w-full">
            <p class="m-0 text-xs text-primary-300 mb-2"> {{ item.created_at_formatted }} </p>
            <p class="m-0 font-semibold mb-1 text-primary-900"> {{ item.data?.title }} </p>
            <p class="m-0 text-primary-500"> {{ item.data?.message }} </p>
            <img
                v-if="item.data?.image"
                :src="item.data?.image"
                class="w-full h-full mt-4"
            />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
})
</script>
