<template>
	<div v-if="!showAddressForm">
		<div class="max-h-[500px] lg:max-h-80 overflow-auto space-y-6 pr-2 scrollbar-style mt-6">
			<div class="p-1">
				<div class="relative">
					<text-input v-model="addressSearch" type="text" placeholder="Enter Address" />
					<div @click="clearInput" class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
						<XCircleIcon class="h-6 w-6 text-primary-200 bg-white" aria-hidden="true" />
					</div>
				</div>

				<div class="mt-8">
					<div v-for="(addressCandidate, index) in locationState.addressCandidates" :key="index" class="space-y-2 mt-2">
						<address-search-card :address="addressCandidate.formattedAddress" @click="selectAddress(addressCandidate)" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<template v-else>
		<map-selection @close="showAddressForm = false" @address:saved="closeMapSelectionModal(true)"></map-selection>
	</template>
</template>
<script>
import { defineComponent, watch, ref, onMounted, inject, onUnmounted } from "vue";
import AddressSearchCard from "./AddressSearchCard.vue";
import TextInput from "@/components/TextInput.vue";
import { XCircleIcon } from "@heroicons/vue/solid";
import { useUserStore } from "@/store/user";
import { useAddressStore } from "@/store/address";
import MapSelection from "./MapSelection.vue";
import useLocation from "@/composables/useLocation";
import debounce from "lodash/debounce";

export default defineComponent({
	components: {
		AddressSearchCard,
		TextInput,
		XCircleIcon,
		MapSelection,
	},
	props: {
		preventSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const userStore = useUserStore();
		const addressStore = useAddressStore();
		const { locationState, setSelectedAddress, geocodePlace, googleSearch } = useLocation();
		const { closeMapSelectionModal } = inject("map-selection-modal");

		const addressSearch = ref(null);
		const showAddressForm = ref(false);

		watch(
			() => addressSearch.value,
			debounce((currentValue) => {
				if (currentValue) {
					googleSearch(currentValue);
				} else {
					locationState.addressCandidates = [];
				}

				if (!showAddressForm.value) {
					locationState.completeAddress = currentValue;
				}
			}, 700)
		);

		const selectAddress = (addressCandidate) => {
			geocodePlace(addressCandidate.placeId).then((response) => {
				setSelectedAddress(
					response.results[0].geometry.location.lat(),
					response.results[0].geometry.location.lng(),
					addressCandidate.formattedAddress
				);
				showAddressForm.value = true;
			});
		};

		const clearInput = () => {
			if (locationState.completeAddress === null) {
				showAddressForm.value = true;
			} else {
				locationState.completeAddress = null;
				addressSearch.value = null;
			}
		};

		onMounted(() => {
			showAddressForm.value = addressStore.editAddress;
			locationState.completeAddress = addressStore.selectedAddress?.address;
			addressSearch.value = locationState.completeAddress;
		});

		onUnmounted(() => {
			clearInput();
		});

		return {
			addressSearch,
			showAddressForm,
			locationState,
			selectAddress,
			userStore,
			closeMapSelectionModal,
			clearInput,
		};
	},
});
</script>
