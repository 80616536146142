import { defineStore } from "pinia";

export const useAddressStore = defineStore({
	id: "address",
	state: () => {
		return {
			// Address Book: Selected Address for Updating
			selectedAddress: {},
			isAddressBook: false,
			editAddress: false,
		};
	},
	getters: {
		hasSelectedAddress(state) {
			return Object.keys(state.selectedAddress).length > 0 ? true : false;
		},
	},
});
