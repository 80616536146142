import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./setup.js";

import useAuth from "./composables/useAuth";
import useGuest from "./composables/useGuest";

import { IonicVue } from "@ionic/vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

/* Custom CSS */
import "./assets/scss/app.scss";

import { useApiStore } from "@/store/api.js";
import { useGuestStore } from "@/store/guest.js";
import useLocalStorage from "@/composables/useLocalStorage";
import useModal from "@/composables/modal";

/* intialize axios */
import { reactive } from "vue";
let maximumAxiosAttempts = reactive(1);

window.axios_refresher = require("axios").default;
window.axios = require("axios").default;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.headers.common["X-Platform-Accessor"] = "web";
window.axios.defaults.headers.common["App-Key"] = process.env.VUE_APP_API_URL;
window.axios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		const { setToken, setRefreshToken, fetchUserData, clearStorage } = useAuth();
		const { localStorage } = useLocalStorage();
		const apiStore = useApiStore();
		const modal = useModal();

		if (originalConfig.url !== apiStore.route("login") && err.response) {
			if (err.response.status === 401 && !originalConfig._retry && maximumAxiosAttempts !== 0) {
				originalConfig._retry = true;
				maximumAxiosAttempts--;

				if (err.response.data.relogin === true && modal.modalState.open === false) {
					modal.modalState.open = true;

					modal.error(err.response.data.message, () => {
						// Redirect to the home page
						clearStorage();
						window.location.href = "/";
					});

					return;
				}

				try {
					// get refresh token
					const token = await localStorage.value.get("authRefreshToken");

					if (token) {
						// renew access token by using refresh token
						const result = await window.axios_refresher.post(
							apiStore.route("authRefresh"),
							{
								token: token,
							},
							{
								headers: {
									Authorization: "Bearer " + token,
								},
							}
						);

						if (!result.data.error) {
							// set the new header for this request
							err.config.headers["Authorization"] = `Bearer ${result.data.access_token}`;

							// update token in the localStorage
							setToken("Bearer " + result.data.access_token, result.data.user);
							setRefreshToken(result.data.refresh_token);
							fetchUserData();

							return new Promise((resolve, reject) => {
								window.axios
									.request(originalConfig)
									.then((response) => {
										resolve(response);
									})
									.catch((err) => {
										reject(err);
									});
							});
						} else {
							return Promise.reject(result.data.error);
						}
					}
				} catch (_error) {
					return Promise.reject(_error);
				}
			}
		}

		return Promise.reject(err);
	}
);

/* Entity holding state */
const pinia = createPinia();

const app = createApp(App).use(pinia).use(IonicVue).use(store);

Sentry.init({
	app,
	dsn: "https://ef34a4bf32b847d2b34c15cf9d02f261@sentry.praxxys.ph/42",
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: [process.env.VUE_APP_API_URL, /^\//],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.25,
});

app.use(router);

const { assignToken, assignUser, fetchUserData } = useAuth();
const { fetchGuestData } = useGuest();

Promise.allSettled([assignToken(), assignUser(), router.isReady()]).then(() => {
	const apiStore = useApiStore();
	const guestStore = useGuestStore();

	apiStore.fetchConfigs().then(() => {
		guestStore.checkIfGuest((bool) => {
			if (!bool) {
				fetchUserData().then(() => app.mount("#app"));
			} else {
				fetchGuestData().then(() => app.mount("#app"));
			}
		});
	});
});
