<template>
	<TransitionRoot
		as="template"
		:show="open">
		<Dialog
			as="div"
			class="relative z-10">
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed z-10 inset-0 overflow-y-auto">
				<div class="flex items-end sm:items-center justify-center min-h-full p-0 lg:p-4 text-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-0 lg:my-8 w-[500px]">
							<div class="h-full overflow-auto">
								<ArrowLeftIcon
									@click="closeThis"
									class="cursor-pointer w-5 h-5 absolute left-6 top-6" />
								<div
									class="h-80 lg:h-auto"
									v-if="product.image_path">
									<img
										:src="product.image_path"
										class="w-auto h-[calc(100%-20px)] lg:w-full lg:h-auto lg:max-w-[500px] lg:max-h-[250px] mx-auto object-cover" />
								</div>
								<div class="py-6 px-4 lg:px-12 rounded-t-3xl lg:rounded-t-none bg-white -mt-5 lg:mt-0 relative z-10">
									<ArrowLeftIcon
										@click="closeThis"
										class="cursor-pointer w-5 h-5 mt-5"
										v-if="!product.image_path" />
									<div
										class="bg-error/10 p-3 rounded-lg mt-2"
										v-if="product.remarks">
										<div
											class="text-error text-xs font-semibold product-modal-remarks v-html"
											v-html="product.remarks"></div>
									</div>
									<div class="space-y-2 mt-6">
										<h3 class="text-primary-900 text-2xl font-bold">
											{{ product.name }}
										</h3>
										<p class="text-sm text-primary-200">{{ product.description }}</p>

										<!-- Free Items -->
										<div
											v-if="product.free_items.length > 0 || product.applied_free_items.length > 0"
											class="mt-3">
											<!-- Product Specific -->
											<template
												v-for="freeItem in product.free_items"
												:key="freeItem">
												<template v-if="freeItem.get_product?.inventories[0]">
													<p
														class="text-xs font-semibold text-error"
														v-if="freeItem.get_product.inventories[0].stock > 0">
														*Free {{ freeItem.get_product.name }}
													</p>
												</template>
											</template>

											<!-- Category Specific & ALL -->
											<template
												v-for="freeItem in product.applied_free_items"
												:key="freeItem">
												<template v-if="freeItem.get_product?.inventories[0]">
													<p
														class="text-xs font-semibold text-error"
														v-if="freeItem.get_product.inventories[0].stock > 0">
														*Free {{ freeItem.get_product.name }}
													</p>
												</template>
											</template>
										</div>
									</div>
									<div class="flex items-center justify-between py-4 border-b">
										<h4 class="flex-1 text-primary-900 text-2xl font-bold m-0">
											<render-price
												:value="productPrice(product)"
												:slashed="product.applied_item_discounts.length > 0 && product.applied_item_discounts.find((obj) => obj !== undefined) !== null"
												:old-price="product.price" />
										</h4>
										<div class="flex-1 flex items-center gap-2 justify-end">
											<quantity-selector
												:value="form.quantity"
												:max="product.inventories[0].stock"
												@update:modelValue="(value) => (form.quantity = value)" />
										</div>
									</div>

									<!-- Add ons -->
									<div
										class="py-6 border-b"
										v-for="group in product.applied_options"
										:key="group">
										<p class="text-base text-primary-900 font-bold mb-4">
											{{ group.name }}
											<span class="ml-1 font-normal text-primary-300 text-xs">
												{{ groupMinMax(group) }}
											</span>
										</p>
										<div
											class="mb-3"
											v-for="(option, index) in group.options"
											:key="option">
											<template v-if="form.options[group.id] !== undefined">
												<template v-if="group.is_single && group.is_required">
													<add-on
														:index="index"
														:group="group"
														:product="option"
														:option="option"
														v-model="form.options[group.id]"
														@update:modelValue="
															(value) => {
																form.options[group.id] = value;
																isDisabled(group);
															}
														"
														@update:price="(price) => (addonPrices[group.id] = price)"
														:disabled="group.is_disabled" />
												</template>
												<template v-else>
													<add-on
														:index="index"
														:group="group"
														:product="option"
														:option="option"
														v-model="form.options[group.id][option.id]"
														@update:modelValue="
															(value) => {
																form.options[group.id][option.id] = value;
																isDisabled(group);
															}
														"
														@update:price="(price) => (addonPrices[group.id][option.id] = price)"
														:disabled="group.is_disabled" />
												</template>
											</template>
										</div>
									</div>

									<div class="py-6 sm:border-b mb-20 sm:mb-0">
										<div class="space-y-2 my-2">
											<p class="text-base text-primary-900 font-bold">Special Instructions</p>
											<p class="text-xs text-primary-900">Please take note that some requests may not be accomodated.</p>
										</div>
										<textarea
											v-model="form.special_instructions"
											rows="4"
											class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg placeholder:text-primary-100 min-h-[75px] max-h-[250px]"
											placeholder="Do you have any request or allergies that need to avoid?" />
									</div>
									<div class="sm:pt-6 flex items-center justify-between p-4 bg-white fixed bottom-0 sm:relative w-full left-0 border-t sm:border-0">
										<div class="space-y-2">
											<p class="text-primary-100 text-xs font-bold">Grand Total</p>
											<p class="text-base text-primary-900 font-bold">
												<render-price :value="subtotal" />
											</p>
										</div>
										<button-component
											@click="addItem"
											color="primary"
											:disabled="!allowToAdd"
											class="w-7/12">
											<template #content>Add to Order</template>
										</button-component>
									</div>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
<script>
	import { defineComponent, computed, ref, watch } from "vue";
	import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
	import { ArrowLeftIcon } from "@heroicons/vue/outline";
	import RenderPrice from "@/components/RenderPrice.vue";
	import QuantitySelector from "@/components/QuantitySelector.vue";
	import AddOn from "@/components/AddOn.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import { useCartStore } from "@/store/cart";

	export default defineComponent({
		components: {
			Dialog,
			DialogPanel,
			TransitionChild,
			TransitionRoot,
			ArrowLeftIcon,
			ButtonComponent,
			RenderPrice,
			QuantitySelector,
			AddOn,
		},
		emits: ["close", "add:order"],
		props: {
			isVisible: {
				type: Boolean,
				default: false,
			},
			product: {
				type: Object,
				required: true,
			},
		},
		setup(props, { emit }) {
			const cartStore = useCartStore();
			const open = ref(false);
			const subtotal = ref(0);
			const form = ref({
				product: props.product.id,
				quantity: 0,
				special_instructions: null,
				options: {},
			});

			const addonPrices = ref({});

			const allowToAdd = computed(() => {
				let allowed = true;

				props.product.applied_options.map((group) => {
					if (group.is_single && group.is_required) {
						if (form.value.options[group.id] === 0 || form.value.options[group.id] === null) {
							allowed = false;
						}
					} else {
						if (group.is_required) {
							const sum = Object.values(form.value.options[group.id]).reduce((accumulator, value) => {
								return accumulator + value;
							}, 0);

							if (sum < group.min) {
								allowed = false;
							}
						}
					}
				});

				return form.value.quantity != 0 && allowed;
			});

			watch(
				() => subtotal.value,
				() => computeSubtotal(addonPrices.value)
			);

			watch(
				() => addonPrices.value,
				(items) => computeSubtotal(items),
				{
					deep: true,
				}
			);

			const computeSubtotal = (addons) => {
				subtotal.value = cartStore.productPrice(props.product) * form.value.quantity;

				Object.values(addons).filter((value) => {
					if (typeof value === "object") {
						Object.values(value).filter((price) => {
							subtotal.value += price * form.value.quantity;
						});
					} else {
						subtotal.value += value * form.value.quantity;
					}
				});
			};

			const closeThis = () => {
				open.value = false;
			};

			const addItem = () => {
				emit("add:order", form.value);
				closeThis();
			};

			watch(
				() => props.isVisible,
				(bool) => {
					open.value = bool;
				},
				{
					immediate: true,
				}
			);

			watch(
				() => open.value,
				(bool) => {
					if (!bool) {
						emit("close");
					} else {
						form.value.quantity = 0;
						form.value.special_instructions = null;
						form.value.options = {};

						props.product.applied_options.map((group) => {
							group.is_disabled = false;

							form.value.options[group.id] = {};
							addonPrices.value[group.id] = {};

							group.options.map((option, index) => {
								if (group.is_single && group.is_required) {
									if (index === 0) {
										form.value.options[group.id] = option.id;
										addonPrices.value[group.id] = option.price;
									}
								} else {
									form.value.options[group.id][option.id] = 0;
									addonPrices.value[group.id][option.id] = 0;
								}
							});
						});
					}
				},
				{
					immediate: true,
				}
			);

			watch(
				() => form.value.quantity,
				(value) => {
					subtotal.value = cartStore.productPrice(props.product) * value;
				}
			);

			const isDisabled = (group) => {
				if (typeof form.value.options[group.id] === "object") {
					group.is_disabled = Object.values(form.value.options[group.id]).reduce((a, b) => a + b) === (group.is_single ? 1 : group.max);
				} else {
					group.is_disabled = false;
				}
			};

			const groupMinMax = (group) => {
				let text = "(" + (group.is_required ? "Required" : "Optional") + ", ";

				if (group.min) text += "Min: " + group.min + ", ";
				text += "Max: " + (group.is_single ? 1 : group.max);
				text += ")";

				return text;
			};

			return {
				open,
				closeThis,
				addItem,
				subtotal,
				form,
				allowToAdd,
				isDisabled,
				addonPrices,
				productPrice: cartStore.productPrice,
				groupMinMax,
			};
		},
	});
</script>
