<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="mbr-frame1 bg-black">
					<div class="frm-wrapper px-6 lg:px-0 lg:max-w-[870px] pt-[121px] pb-[89px] m-auto">
						<div class="mbr-frame1__wrapper flex items-center justify-between relative">
							<div class="mbr-frame1__left max-w-none md:max-w-[395px]">
								<div class="mbr-frame1__header max-w-[266px] mb-4">
									<template v-if="cmsContent">
										<h2 class="text-white font-semibold leading-tight">
											{{ getCmsContent("frame1_title") }}
										</h2>
									</template>
									<template v-else>
										<h2 class="text-white font-semibold leading-tight">RK Prime Membership</h2>
									</template>
								</div>

								<template v-if="cmsContent">
									<div v-html="getCmsContent('frame1_content')" class="html-box dark-bg"></div>
								</template>
								<template v-else>
									<div class="mbr-frame1__desc mb-4">
										<p class="text-sm md:text-base text-primary-100">
											RK Prime membership is an exclusive online offering of Ramen Kuroda for
											those who want to get more perks and privileges from Ramen Kuroda.
										</p>
										<p class="mt-3 text-sm md:text-base text-primary-100">
											For only PHP 100/month you can enjoy the following perks everytime you visit
											any Ramen Kuroda stores.
										</p>
									</div>
								</template>

								<anchor-link @click="showPaymentHistory = !showPaymentHistory">
									<template #content>
										<div class="flex items-center gap-4">
											<span class="text-white font-bold text-sm">Payment History</span>
											<ion-icon :src="chevronForward" class="w-6 h-6 text-white"></ion-icon>
										</div>
									</template>
								</anchor-link>
							</div>

							<div class="mbr-frame1__right w-full max-w-none md:max-w-[397px] bg-white rounded-xl h-fit">
								<div class="mbr-card__wrapper p-6">
									<div class="mbr-card__header mb-4 flex gap-2 w-fit">
										<template v-if="isMember == true">
											<img src="/assets/icon/facebook.svg" class="w-6 h-6" />
											<p class="font-semibold leading-tight">RK Facebook Group</p>
										</template>
										<template v-else>
											<h3 class="font-semibold leading-tight">P 100.00/mo.</h3>
										</template>
									</div>
									<div class="mbr-card__desc mb-4">
										<template v-if="isMember == true">
											<p class="text-xs text-primary-400 leading-normal">
												Get exclusive information about Ramen Kuroda by joining our Facebook
												group
											</p>
										</template>
										<template v-else>
											<p class="text-xs text-primary-400 leading-normal">
												This will automatically deducted monthly. You can cancel anytime!
											</p>
										</template>
									</div>
									<div class="mbr-btn">
										<template v-if="isMember == true">
											<button-component
												color="secondary"
												expand="block"
												@click="redirectToGroup(getCmsContent('frame1_fblink'))"
											>
												<template #content>
													<ion-spinner v-if="isLoading" />
													<template v-else>Visit our group</template>
												</template>
											</button-component>
										</template>
										<template v-else>
											<button-component
												@click="activateMembership"
												color="primary"
												expand="block"
											>
												<template #content>
													<ion-spinner v-if="isLoading" />
													<template v-else>Activate Membership</template>
												</template>
											</button-component>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mbr-frame2">
					<div
						class="
							frm-wrapper
							max-w-none
							px-6
							lg:px-0 lg:max-w-[870px]
							m-auto
							pt-[125px]
							md:pt-[47px]
							pb-[46px]
							md:pb-[70px]
						"
					>
						<div class="mbr-frame2__wrapper mb-[32px] md:mb-[44px]">
							<div class="mbr-frame2__header mb-6">
								<template v-if="cmsContent">
									<h3 class="font-semibold">
										{{ getCmsContent("frame2_title") }}
									</h3>
								</template>
								<template v-else>
									<h3 class="font-semibold">Perks & Benefits</h3>
								</template>
							</div>
							<div class="mbr-frame2__list flex flex-col gap-[10px] md:gap-4">
								<template v-if="cmsContent">
									<template v-for="(item, index) in getCmsContent('frame2_perks')" :key="index">
										<div class="mbr-frame2__list-item flex gap-2 w-fit items-center">
											<ion-icon :src="checkmarkCircle" class="w-5 h-5"></ion-icon>
											<p class="text-primary-400">
												{{ item.perks }}
											</p>
										</div>
									</template>
								</template>
								<template v-else>
									<div class="mbr-frame2__list-item flex gap-2 w-fit items-center">
										<ion-icon :src="checkmarkCircle" class="w-5 h-5"></ion-icon>
										<p class="text-primary-400">Free 1 extra noodles for every visit</p>
									</div>
									<div class="mbr-frame2__list-item flex gap-2 w-fit items-center">
										<ion-icon :src="checkmarkCircle" class="w-5 h-5"></ion-icon>
										<p class="text-primary-400">Free 1 iced tea for every visit</p>
									</div>
									<div class="mbr-frame2__list-item flex gap-2 w-fit items-center">
										<ion-icon :src="checkmarkCircle" class="w-5 h-5"></ion-icon>
										<p class="text-primary-400">
											Get exclusive information about Ramen Kuroda by joining our Facebook Group
										</p>
									</div>
								</template>
							</div>
						</div>
						<div class="hwto-earn">
							<template v-if="cmsContent">
								<div v-html="getCmsContent('frame2_content')" class="html-box"></div>
							</template>
							<template v-else>
								<div class="hwto-earn__header mb-4">
									<h3 class="font-bold">Subscription Guide</h3>
								</div>
								<div class="hwt-earn__desc">
									<h6 class="text-primary-400 mt-2 mb-20">
										RK Prime Membership facebook group is a closed community group for real fans of
										Ramen Kuroda. Joining is recommended to those who are interested to know more
										about Japan Culture specially about food. Real and informative knowledge is
										posted by Ramen Kuroda Management. We would also like to hear your suggestions
										and feedback as well to help us improve our services even more as one of the top
										food industries. Be a member and enjoy exclusive treats from Ramen Kuroda now!
									</h6>
									<h4 class="font-bold mt-4">Monthly Payment</h4>
									<h6 class="text-primary-400 mt-2">
										You will be billed P100 monthly for the subscription fees. All subscriptions are
										recurring and will automatically renew after the end of each paid subscription
										period. Membership fees are billed at the beginning of each period and may takea
										few days after the billing date to appear on your account.
									</h6>
									<h4 class="font-bold mt-4">Cancellation</h4>
									<h6 class="text-primary-400 mt-2">
										User acknowledges that all subscription fees are charged automatically on a
										recurring basis until the user cancels their subscription. Cancel before the
										billing date to avoid being charged for the next period.
									</h6>
								</div>
							</template>
						</div>

						<template v-if="isMember == true">
							<!-- <div class="pt-6 pb-4 border-y mt-8">
                                <div class="flex items-start justify-between">
                                    <p
                                        class="
                                            text-base text-primary-900
                                            font-bold
                                            m-0
                                        "
                                    >
                                        Payment
                                    </p>
                                    <anchor-link
                                        @click="
                                            showPaymentMethod =
                                                !showPaymentMethod
                                        "
                                    >
                                        <template #content>
                                            <div
                                                class="flex items-center gap-4"
                                            >
                                                <span
                                                    class="
                                                        text-primary-900
                                                        font-bold
                                                        text-sm
                                                    "
                                                    >Change</span
                                                >
                                                <ion-icon
                                                    :src="chevronForward"
                                                    class="
                                                        w-4
                                                        h-4
                                                        text-primary-900
                                                    "
                                                ></ion-icon>
                                            </div>
                                        </template>
                                    </anchor-link>
                                </div>
                                <item-list
                                    title="Debit/Credit"
                                    description="Pay using your MasterCard, Visa and More"
                                ></item-list>
                            </div> -->

							<div class="mt-8 flex justify-end">
								<button-component
									@click="showCancelModal = !showCancelModal"
									custom-class="w-full md:w-auto"
									color="red"
								>
									<template #content>
										<ion-spinner v-if="isLoading" />
										<template v-else>Cancel Subscription</template>
									</template>
								</button-component>
							</div>
						</template>
					</div>
				</div>
			</div>
			<bottom-navigation></bottom-navigation>
		</ion-content>

		<!------------
            MODALS
        ------------->

		<!-- Payment History -->
		<side-menu title="Payment History" :is-visible="showPaymentHistory" @close="showPaymentHistory = false">
			<template #content>
				<div class="h-[70vh] overflow-y-auto pr-4 scrollbar-style">
					<template v-if="!historyLoading">
						<template v-if="memberships.length === 0">
							<p class="text-sm text-primary-900 text-center">No data to display.</p>
						</template>
						<template v-else>
							<item-list
								v-for="(membership, index) in memberships"
								:key="index"
								:title="'Subscription'"
								:description="membership.created_at_formatted"
								:amount="membership.price"
								type="deducted"
								:withBorder="true"
							></item-list>
						</template>
					</template>
					<ion-spinner v-else />
				</div>
			</template>
		</side-menu>

		<!-- Payment Method -->
		<!-- <payment-method
            :is-visible="showPaymentMethod"
            @close="showPaymentMethod = false"
        ></payment-method> -->

		<!-- Subscription Payment -->
		<subscription-payment :is-visible="showPaymentMethod" @close="showPaymentMethod = false"></subscription-payment>

		<!-- Membership Success Modal -->
		<generic-modal :is-visible="showMembershipSuccess" @close="showMembershipSuccess = false" :noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-6 lg:pb-8 w-full lg:w-[400px] h-auto">
					<div class="space-y-8">
						<div class="text-center">
							<ion-icon :src="checkmarkCircle" class="w-8 h-8"></ion-icon>
						</div>
						<div class="text-center space-y-2">
							<p class="font-bold text-primary-900 m-0">Membership Activated</p>
							<p class="font-normal text-primary-400 m-0">
								You successfully activated your RK Prime Membership.
							</p>
						</div>
						<div class="text-center">
							<anchor-link @click="showMembershipSuccess = false">
								<template #content>
									<span class="font-bold text-primary-500">Okay</span>
								</template>
							</anchor-link>
						</div>
					</div>
				</div>
			</template>
		</generic-modal>

		<!-- Cancel Subscription Modal -->
		<generic-modal :is-visible="showCancelModal" @close="showCancelModal = false" :noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-6 lg:pb-6 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold">Are your sure?</p>
						<p class="text-sm text-primary-400 font-normal">
							This will cancel your current subscription. This action is irreversible.
						</p>
					</div>
					<div class="flex items-center justify-end gap-2 mt-8">
						<button-component @click="showCancelModal = false" color="secondary">
							<template #content> Cancel </template>
						</button-component>
						<button-component color="red" @click="cancelMembership">
							<template #content> Cancel Subscription </template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</ion-page>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { IonPage, IonContent, IonIcon, IonSpinner, onIonViewWillEnter } from "@ionic/vue";
import { useRouter } from "vue-router";
import { chevronForward, checkmarkCircle } from "ionicons/icons";

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import SideMenu from "@/components/SideMenu.vue";
import ItemList from "@/components/ItemList.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
// import PaymentMethod from "@/components/PaymentMethod.vue";
import GenericModal from "@/components/GenericModal.vue";
import useCms from "@/composables/useCms";
import SubscriptionPayment from "./Components/SubscriptionPayment.vue";
import { useApiStore } from "@/store/api";

export default defineComponent({
	name: "OrderHistory",
	components: {
		TopNavigation,
		BottomNavigation,
		IonContent,
		IonPage,
		IonIcon,
		IonSpinner,
		SideMenu,
		ItemList,
		AnchorLink,
		ButtonComponent,
		// PaymentMethod,
		GenericModal,
		SubscriptionPayment,
	},
	setup() {
		const historyLoading = ref(true);
		const isLoading = ref(false);
		const showPaymentHistory = ref(false);
		const showPaymentMethod = ref(false);
		const showCancelModal = ref(false);
		const showMembershipSuccess = ref(false);
		const isMember = ref(null);
		const router = useRouter();
		const memberships = ref([]);
		const { cms, contentSlug, getCms, getCmsContent } = useCms();
		const userSubscriptionId = ref(null);
		const apiStore = useApiStore();
		const cmsContent = ref(null);

		const activateMembership = () => {
			showPaymentMethod.value = true;
		};

		const cancelMembership = () => {
			showCancelModal.value = false;
			isLoading.value = true;
			const data = {
				subscription_id: userSubscriptionId.value,
			};

			window.axios
				.post(process.env.VUE_APP_API_URL + "/api/cancel-subscription", data)
				.then(() => {
					fetchUser();
					isLoading.value = false;
				})
				.catch(() => {
					isLoading.value = false;
				});
		};

		const redirectToGroup = (link) => {
			window.open(link, "_blank");
		};

		const getContentSlug = () => {
			contentSlug.value = "rk_membership";
			getCms(contentSlug.value);
			cmsContent.value = cms._rawValue.content;
		};

		const fetchUser = async () => {
			isLoading.value = true;
			window.axios.get(apiStore.route("customer")).then((result) => {
				userSubscriptionId.value = result.data.user.subscription_id;
				result.data.user.subscription_status == 1 ? (isMember.value = true) : (isMember.value = false);
				isLoading.value = false;
			});
		};

		const fetchSubscription = () => {
			window.axios.get(process.env.VUE_APP_API_URL + "/api/membership/fetch").then((result) => {
				historyLoading.value = false;
				memberships.value = result.data.memberships;
			});
		};

		onMounted(() => {
			fetchSubscription();
			getContentSlug();
			fetchUser();
		});

		onIonViewWillEnter(() => {
			fetchSubscription();
			fetchUser();
		});

		return {
			historyLoading,
			isLoading,
			chevronForward,
			checkmarkCircle,
			router,
			showPaymentHistory,
			showCancelModal,
			showPaymentMethod,
			isMember,
			cancelMembership,
			activateMembership,
			redirectToGroup,
			showMembershipSuccess,
			memberships,
			getCmsContent,
			cmsContent,
		};
	},
});
</script>
