<template>
	<ion-page>
		<top-navigation @showTimeslot="showBranchTimeslot = true" />

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="px-4 lg:px-40 mt-4 lg:mt-10 mb-10 lg:mb-48">
					<h2 class="text-2xl font-bold mb-10">Checkout</h2>

					<div class="flex flex-col lg:flex-row items-start justify-between">
						<div class="flex-initial w-full lg:w-[450px]">
							<div
								class="bg-primary-50 w-full p-3 rounded-lg mb-6"
								v-if="!usedRKWallet">
								<p class="text-primary-900 text-xs">
									Points to be earned:
									<span class="font-bold">
										{{ cartStore.pointsToEarn }}
										{{ cartStore.pointsToEarn === 1 ? "Point" : "Points" }}
									</span>
								</p>
							</div>

							<div class="pb-4 lg:p-0">
								<fulfillment-information
									class="flex lg:hidden cursor-pointer mt-4"
									@showTimeslot="showBranchTimeslot = true" />
							</div>

							<template v-if="cartStore.recipient?.address">
								<div v-if="cartStore.fulfillmentMethod === 'Delivery'">
									<p class="text-base font-bold text-primary-900">Deliver To</p>
									<div class="space-y-2 mb-8">
										<address-card
											:title="cartStore.recipient.address.name"
											:address="cartStore.recipient.address.address"
											:withRightArrow="true"
											@click="
												() => {
													showOrderModal = true;
													openChooseAddress = true;
												}
											" />

										<ion-spinner
											class="mt-2"
											v-if="loadingNewRecipient" />
										<address-card
											v-else
											:title="cartStore.recipientName"
											:address="cartStore.recipient.mobileNumber"
											:withRightArrow="true"
											@click="
												() => {
													showOrderModal = true;
													openChooseRecipient = true;
												}
											" />
									</div>
								</div>

								<div
									class="mt-6"
									v-else-if="cartStore.fulfillmentMethod === 'Pick-up'">
									<p class="text-base font-bold text-primary-900">Pick-up Near</p>
									<div class="space-y-2 mt-2">
										<address-card
											:title="cartStore.recipient.address.name"
											:address="cartStore.recipient.address.address"
											:withRightArrow="true"
											@click="
												() => {
													showOrderModal = true;
													openChooseAddress = true;
												}
											" />
									</div>
								</div>
							</template>

							<!-- Orders: For Mobile Responsive -->
							<div class="mt-6 block lg:hidden">
								<div>
									<div class="flex items-center justify-between py-6 border-t">
										<p class="text-base font-bold text-primary-900 m-0">Orders</p>
										<ion-button
											@click="router.push('branch-view')"
											class="btn-no-padding btn-link">
											<span class="text-sm text-primary-900">Add More</span>
											<ion-icon
												slot="end"
												src="/assets/icon/ic-chevron-right.svg"
												class="ml-4"></ion-icon>
										</ion-button>
									</div>
								</div>
								<div class="space-y-2">
									<order-item-card
										v-for="item in cartStore.items"
										:key="item"
										:item="item"
										@update:quantity="(value) => updateItemQuantity(item, value)"
										@new:quantity="newItemQuantity()" />

									<free-item-card
										v-for="freeItem in cartStore.freeItems"
										:key="freeItem"
										:item="freeItem" />
								</div>
							</div>

							<div
								class="py-3 hidden border-y lg:block pt-3 pb-6"
								v-if="userStore.userData.is_member">
								<toggle-switch
									v-model="availFreebies"
									:title="`RK Prime Freebies`" />

								<p class="text-sm text-primary-900">1x Extra Noodles</p>
								<p class="text-sm text-primary-900">1x Iced Tea</p>
							</div>

							<div class="border-b hidden lg:block">
								<div class="flex items-center justify-between py-6">
									<p class="text-base font-bold text-primary-900 m-0">Voucher</p>
									<ion-button
										@click="showVoucherModal = true"
										class="btn-no-padding btn-link">
										<span class="text-sm text-primary-900">
											<template v-if="cartStore.voucher.code">Change</template>
											<template v-else>Add</template>
										</span>
										<ion-icon
											slot="end"
											src="/assets/icon/ic-chevron-right.svg"
											class="ml-4"></ion-icon>
									</ion-button>
								</div>
								<div
									class="rounded-lg border border-primary-50 j p-4 mb-4 flex items-center justify-between"
									v-if="cartStore.voucher.code">
									<p class="text-sm font-semibold m-0">
										{{ cartStore.voucher.code }}
									</p>
									<button
										class="flex items-center"
										@click="clearVoucher()">
										<ion-icon
											:src="closeOutline"
											class="w-6 h-6" />
									</button>
								</div>
							</div>

							<div
								class="py-3 hidden border-b lg:block"
								v-if="userStore.userData.rk_available_points > 0">
								<toggle-switch
									v-model="usePoints"
									:title="`RK Points (${userStore.userData.rk_available_points})`" />

								<item-link
									v-if="usePoints"
									:border="true"
									:title="cartStore.points"
									@click="showPointsMenu = true" />
								<p
									v-if="usePoints"
									class="italic text-xs font-medium text-primary-200 mt-1">
									Max RK Points: 80% of order subtotal
								</p>
							</div>

							<div class="mt-6 hidden lg:block">
								<div class="flex items-center justify-between">
									<div class="flex items-center gap-2">
										<CreditCardIcon class="w-5 h-5 text-primary-900" />
										<p class="text-base font-bold text-primary-900 m-0">Payment Method</p>
									</div>
									<ion-button
										@click="showPaymentMenu = true"
										class="btn-no-padding btn-link">
										<span class="text-sm text-primary-900">
											<template v-if="cartStore.paymentMethod">Change</template>
											<template v-else>Choose</template>
										</span>
										<ion-icon
											slot="end"
											src="/assets/icon/ic-chevron-right.svg"
											class="ml-4"
											name="cart"></ion-icon>
									</ion-button>
								</div>

								<order-payment-method
									v-if="cartStore.paymentMethod"
									:method="cartStore.paymentMethod"
									:show-forward="false" />
							</div>
						</div>
						<div class="flex-initial w-full lg:w-[450px] lg:pl-6">
							<div class="space-y-2 hidden lg:block">
								<p class="text-base font-bold text-primary-900">Orders</p>
								<div class="space-y-2">
									<order-item-card
										v-for="item in cartStore.items"
										:key="item"
										:item="item"
										@update:quantity="(value) => updateItemQuantity(item, value)"
										@new:quantity="newItemQuantity()" />

									<free-item-card
										v-for="freeItem in cartStore.freeItems"
										:key="freeItem"
										:item="freeItem" />
								</div>
							</div>
							<div class="mt-6">
								<div class="pb-6 border-b space-y-2">
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">Subtotal</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.subtotal" />
										</p>
									</div>
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">VATable Sale</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.vatableSale" />
										</p>
									</div>
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-400 m-0">VAT (12%)</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.vat" />
										</p>
									</div>
									<!-- Delivery Fee -->
									<template v-if="cartStore.fulfillmentMethod === 'Delivery'">
										<div class="flex items-center justify-between">
											<p class="text-sm text-primary-400 m-0">Delivery Fee</p>
											<p class="text-sm text-primary-900 font-bold m-0">
												<ion-spinner v-if="deliveryFeeLoading" />
												<render-price
													:value="cartStore.deliveryFee"
													v-else />
											</p>
										</div>
										<p class="text-xs text-primary-900 font-bold">Disclaimer:</p>
										<p
											class="text-xs text-primary-900 m-0"
											v-if="cmsContent">
											<template v-if="apiStore.settings?.defaultDeliveryProvider === 'Lalamove'">
												{{ getCmsContent("lalamove_disclaimer_description") || defaultDisclaimer.description }}
												<br />
												Learn more from
												<a
													:href="getCmsContent('lalamove_disclaimer_url') || defaultDisclaimer.url"
													target="_blank"
													class="font-bold">
													Lalamove Matrix
												</a>
											</template>
											<template v-else-if="apiStore.settings?.defaultDeliveryProvider === 'Grab'">
												{{ getCmsContent("grab_disclaimer_description") || defaultDisclaimer.description }}
												<br />
												Learn more from
												<a
													:href="getCmsContent('grab_disclaimer_url') || defaultDisclaimer.url"
													target="_blank"
													class="font-bold">
													Grab Matrix
												</a>
											</template>
										</p>
									</template>
									<div
										class="flex items-center justify-between"
										v-if="cartStore.serviceCharge > 0">
										<p class="text-sm text-primary-400 m-0">Service</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.serviceCharge" />
										</p>
									</div>
									<div
										class="flex items-center justify-between"
										v-if="cartStore.voucher.value > 0">
										<p class="text-sm text-primary-400 m-0">Voucher Discount</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="-cartStore.voucher.value" />
										</p>
									</div>
									<div
										class="flex items-center justify-between"
										v-if="cartStore.points > 0">
										<p class="text-sm text-primary-400 m-0">Points</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="-cartStore.points" />
										</p>
									</div>
								</div>
								<div class="pt-6 hidden lg:block">
									<div class="flex items-center justify-between">
										<p class="text-sm text-primary-900 font-bold m-0">Grand Total</p>
										<p class="text-sm text-primary-900 font-bold m-0">
											<render-price :value="cartStore.grandTotal" />
										</p>
									</div>
								</div>
								<div class="mt-6 hidden lg:block">
									<button-component
										@click="placeOrder"
										expand="block"
										color="primary"
										v-if="cartStore.items.length > 0"
										:disabled="loading || deliveryFeeLoading || unconfirmedQuantity">
										<template #content>
											<ion-spinner v-if="loading || deliveryFeeLoading" />
											<template v-else>Place Order</template>
										</template>
									</button-component>
									<p class="mt-1 text-xs text-primary-900 mx-auto font-bold flex text-center justify-center">Please note that once the order is confirmed you can no longer cancel it.</p>
								</div>
							</div>

							<!-- RK Prime Freebies: For Mobile Responsive -->
							<div
								class="block lg:hidden"
								v-if="userStore.userData.is_member">
								<div class="pt-4 pb-6 border-b">
									<toggle-switch
										v-model="availFreebies"
										:title="`RK Prime Freebies`" />

									<p class="text-sm text-primary-900">1x Extra Noodles</p>
									<p class="text-sm text-primary-900">1x Iced Tea</p>
								</div>
							</div>

							<!-- Vouchers: For Mobile Responsive -->
							<div class="block border-b lg:hidden">
								<div class="flex items-center justify-between py-6">
									<p class="text-base font-bold text-primary-900 m-0">Voucher</p>
									<ion-button
										@click="showVoucherModal = true"
										class="btn-no-padding btn-link">
										<span class="text-sm text-primary-900">
											<template v-if="cartStore.voucher.code">Change</template>
											<template v-else>Add</template>
										</span>
										<ion-icon
											slot="end"
											src="/assets/icon/ic-chevron-right.svg"
											class="ml-4"></ion-icon>
									</ion-button>
								</div>
								<div
									class="rounded-lg border border-primary-50 j p-4 mb-6 flex items-center justify-between"
									v-if="cartStore.voucher.code">
									<p class="text-sm font-semibold m-0">
										{{ cartStore.voucher.code }}
									</p>
									<button
										class="flex items-center"
										@click="clearVoucher()">
										<ion-icon
											:src="closeOutline"
											class="w-6 h-6" />
									</button>
								</div>
							</div>

							<!-- RK Points: For Mobile Responsive -->
							<div
								class="block lg:hidden"
								v-if="userStore.userData.rk_available_points">
								<div class="py-6 border-b">
									<toggle-switch
										v-model="usePoints"
										:title="`RK Points (${userStore.userData.rk_available_points})`" />

									<item-link
										v-if="usePoints"
										:border="true"
										:title="cartStore.points"
										@click="showPointsMenu = true" />
									<p
										v-if="usePoints"
										class="italic text-xs font-medium text-primary-200 mt-1">
										Max RK Points: 80% of order subtotal
									</p>
								</div>
							</div>

							<!-- Payment Method: For Mobile Responsive -->
							<div class="mt-8 block lg:hidden">
								<div class="flex items-center justify-between">
									<div class="flex items-center gap-2">
										<CreditCardIcon class="w-5 h-5 text-primary-900" />
										<p class="text-base font-bold text-primary-900 m-0">Payment Method</p>
									</div>
									<ion-button
										@click="showPaymentMenu = true"
										class="btn-no-padding btn-link">
										<span class="text-sm text-primary-900">
											<template v-if="cartStore.paymentMethod">Change</template>
											<template v-else>Choose</template>
										</span>
										<ion-icon
											slot="end"
											src="/assets/icon/ic-chevron-right.svg"
											class="ml-4"
											name="cart"></ion-icon>
									</ion-button>
								</div>

								<order-payment-method
									v-if="cartStore.paymentMethod"
									:method="cartStore.paymentMethod"
									:show-forward="false" />
							</div>
						</div>
					</div>
				</div>

				<!-- Place Order Button: For Mobile Responsive -->
				<div class="bg-white fixed bottom-0 w-full p-4 border-t z-10 block lg:hidden">
					<div class="flex items-center justify-between">
						<div class="space-y-2">
							<p class="text-primary-100 text-xs font-bold">Grand Total</p>
							<p class="text-base text-primary-900 font-bold">
								<render-price :value="cartStore.grandTotal" />
							</p>
						</div>

						<button-component
							@click="placeOrder"
							expand="block"
							color="primary"
							class="w-7/12"
							v-if="cartStore.items.length > 0"
							:disabled="loading || deliveryFeeLoading || unconfirmedQuantity">
							<template #content>
								<ion-spinner v-if="loading || deliveryFeeLoading" />
								<template v-else>Place Order</template>
							</template>
						</button-component>
					</div>
				</div>
			</div>

			<generic-modal
				:is-visible="showVoucherModal"
				@close="showVoucherModal = false"
				:mobile-full-screen="true">
				<template #content>
					<div class="relative h-full">
						<div class="w-full h-full lg:h-auto relative md:w-[500px] px-4 py-6 lg:p-12">
							<div class="space-y-2">
								<h2 class="font-bold text-[28px] text-primary-900">Voucher</h2>
							</div>
							<div class="mt-8">
								<text-input
									type="text"
									placeholder="Enter Voucher"
									v-model="voucher"
									helper="Code is case-sensitive"
									errors="sss" />
							</div>
						</div>
						<div class="mt-0 lg:mt-8 absolute lg:relative bottom-0 pb-12 lg:pb-12 w-full px-4 lg:px-12">
							<ion-button
								@click="applyVoucher"
								size="large"
								class="btn-primary"
								expand="block">
								<ion-spinner v-if="loadingVoucher" />
								<template v-else>Apply Voucher</template>
							</ion-button>
						</div>
					</div>
				</template>
			</generic-modal>

			<bottom-navigation />
		</ion-content>

		<!--------- 
            Modals
        ---------->

		<!-- BRANCH TIMESLOTS -->
		<branch-timeslots
			:branch="cartStore.branch"
			:is-visible="showBranchTimeslot"
			@close="showBranchTimeslot = false" />

		<!-- VOUCHER MODAL -->
		<generic-modal
			:is-visible="showVoucherModal"
			@close="showVoucherModal = false"
			:mobile-full-screen="true">
			<template #content>
				<div class="relative h-full">
					<div class="w-full h-full lg:h-auto relative md:w-[500px] px-4 py-6 lg:p-12">
						<div class="space-y-2">
							<h2 class="font-bold text-[28px] text-primary-900">Voucher</h2>
						</div>
						<div class="mt-8">
							<text-input
								type="text"
								placeholder="Enter Voucher"
								v-model="voucher"
								helper="Code is case-sensitive"
								:error="voucherError" />
						</div>
					</div>
					<div class="mt-0 lg:mt-8 absolute lg:relative bottom-0 pb-12 lg:pb-12 w-full px-4 lg:px-12">
						<ion-button
							@click="applyVoucher"
							size="large"
							class="btn-primary"
							expand="block">
							<ion-spinner v-if="loadingVoucher" />
							<template v-else>Apply Voucher</template>
						</ion-button>
					</div>
				</div>
			</template>
		</generic-modal>

		<!-- PAYMENT METHOD -->
		<payment-method
			:is-visible="showPaymentMenu"
			@close="showPaymentMenu = false"
			:to-pay="cartStore.grandTotal"
			@payment:set="(method) => (cartStore.paymentMethod = method)"
			@card:set="(card) => (cartStore.cardSelected = card)"
			button-text="Checkout" />

		<!-- APPLY POINTS -->
		<side-menu
			title="RK Points"
			:description="`You have currently ${userStore.userData.rk_available_points} ${userStore.userData.rk_available_points === 1 ? `point` : `points`}`"
			:is-visible="showPointsMenu"
			@close="showPointsMenu = false">
			<template #content>
				<div class="px-2">
					<div class="relative rounded-md shadow-sm">
						<input
							type="number"
							class="appearance-none shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg placeholder:text-primary-100"
							placeholder="Enter points"
							v-model="points"
							:min="0"
							:max="maxPoints" />
						<div
							class="bg-white m-1 cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center"
							@click="applyPoints">
							<span class="font-bold text-sm">MAX</span>
						</div>
					</div>
				</div>
				<div class="mt-10 px-2">
					<button-component
						@click="updateCartPoints"
						expand="block"
						color="primary">
						<template #content>
							<ion-spinner v-if="loadingPoints" />
							<template v-else>Use Points</template>
						</template>
					</button-component>
				</div>
			</template>
		</side-menu>

		<!-- ORDER MODAL -->
		<generic-modal
			:is-visible="showOrderModal"
			@close="showOrderModal = false"
			:mobile-full-screen="true"
			:noCloseIcon="true">
			<template #content>
				<div class="absolute w-full top-6 z-50 lg:top-12">
					<div class="flex items-center justify-end">
						<XIcon
							@click="showOrderModal = !showOrderModal"
							class="cursor-pointer w-5 h-5 text-primary-500 absolute right-4 lg:right-12" />
					</div>
				</div>
				<div class="relative h-full">
					<div class="h-full w-full lg:w-[500px]">
						<div class="p-4 lg:p-12">
							<recipient-index
								v-show="openChooseRecipient"
								@next="updateRecipient" />
							<order-address
								v-show="openChooseAddress"
								@next="
									() => {
										showOrderModal = false;
										router.push('/branches');
									}
								"
								:preventSaving="true" />
						</div>
					</div>
				</div>
			</template>
		</generic-modal>

		<toast-message
			:show="showToast"
			:message="toastMessage"
			@didDismiss="showToast = false" />
	</ion-page>
</template>
<script>
	import { computed, defineComponent, ref, watch } from "vue";
	import { CreditCardIcon } from "@heroicons/vue/solid";
	import { XIcon } from "@heroicons/vue/outline";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import AddressCard from "@/components/AddressCard.vue";
	import ToggleSwitch from "@/components/ToggleSwitch.vue";
	import OrderItemCard from "@/components/OrderItemCard.vue";
	import FreeItemCard from "@/components/FreeItemCard.vue";
	import GenericModal from "@/components/GenericModal.vue";
	import RecipientIndex from "@/views/home/Components/RecipientIndex.vue";
	import OrderAddress from "@/views/home/Components/OrderAddress.vue";
	import PaymentMethod from "@/components/PaymentMethod.vue";
	import SideMenu from "@/components/SideMenu.vue";
	import TextInput from "@/components/TextInput.vue";
	import OrderPaymentMethod from "@/components/OrderPaymentMethod.vue";
	import { useCartStore } from "@/store/cart";
	import { useApiStore } from "@/store/api";
	import { useUserStore } from "@/store/user";
	import { IonPage, IonContent, IonButton, IonSpinner, IonIcon, modalController, onIonViewWillEnter } from "@ionic/vue";
	import PointsModal from "@/components/PointsModal.vue";
	import { closeOutline } from "ionicons/icons";
	import useModal from "@/composables/modal";
	import ToastMessage from "@/components/ToastMessage.vue";
	import RenderPrice from "@/components/RenderPrice.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import ItemLink from "@/components/ItemLink.vue";
	import { useRouter } from "vue-router";
	import useLocalStorage from "@/composables/useLocalStorage.js";
	import useCms from "@/composables/useCms";
	import FulfillmentInformation from "@/components/FulfillmentInformation.vue";
	import BranchTimeslots from "@/views/branches/Components/BranchTimeslots.vue";

	export default defineComponent({
		components: {
			IonPage,
			IonContent,
			IonButton,
			XIcon,
			IonSpinner,
			IonIcon,
			TopNavigation,
			BottomNavigation,
			AddressCard,
			ToggleSwitch,
			CreditCardIcon,
			OrderItemCard,
			FreeItemCard,
			GenericModal,
			RecipientIndex,
			OrderAddress,
			PaymentMethod,
			SideMenu,
			TextInput,
			OrderPaymentMethod,
			ToastMessage,
			RenderPrice,
			ButtonComponent,
			ItemLink,
			FulfillmentInformation,
			BranchTimeslots,
		},
		setup() {
			const loading = ref(false);
			const loadingPoints = ref(false);
			const loadingVoucher = ref(false);
			const router = useRouter();
			const apiStore = useApiStore();
			const cartStore = useCartStore();
			const userStore = useUserStore();
			const modal = useModal();
			const { localStorage } = useLocalStorage();

			const showOrderModal = ref(false);
			const loadingNewRecipient = ref(false);
			const openChooseRecipient = ref(false);
			const openChooseAddress = ref(false);
			const unconfirmedQuantity = ref(false);
			const showBranchTimeslot = ref(false);
			const voucherError = ref(null);
			const defaultDisclaimer = ref({
				description: "",
				url: apiStore.settings?.defaultDeliveryMatrixUrl,
			});
			const { cms, contentSlug, getCms, getCmsContent } = useCms();
			const cmsContent = ref(null);

			watch(
				() => showOrderModal.value,
				(bool) => {
					if (!bool) {
						openChooseRecipient.value = false;
						openChooseAddress.value = false;
					}
				}
			);

			const showVoucherModal = ref(false);
			const showPaymentMenu = ref(false);
			const showPointsMenu = ref(false);
			const deliveryFeeLoading = ref(false);

			const showToast = ref(false);
			const toastMessage = ref(null);

			const availFreebies = ref(false);
			const usePoints = ref(cartStore.points > 0);
			const points = ref(cartStore.points);

			const pointsEarned = ref(0);
			const availablePoints = ref(0);

			const voucher = ref(null);
			const usedRKWallet = ref(false);

			const maxPoints = computed(() => {
				// get the 80% of the subtotal (with voucher deduction)
				// if RK available points is higher than the 80% portion, the max will be the 80% portion
				// else, the max will be the customer's RK available points
				const portion = cartStore.discountedAmount * 0.8;
				const customerPoints = userStore.userData.rk_available_points;

				return customerPoints > portion ? portion : customerPoints;
			});

			watch(
				() => cartStore.paymentMethod,
				(val) => {
					usedRKWallet.value = val == "RK Wallet" ? true : false;
				}
			);

			watch(
				() => cartStore.voucher.value,
				() => {
					if (cartStore.points > 0 && cartStore.points > maxPoints.value) {
						cartStore.points = maxPoints.value;
						points.value = maxPoints.value;
					}
				}
			);

			const getContentSlug = () => {
				contentSlug.value = "lalamove_disclaimer";
				getCms(contentSlug.value);
				cmsContent.value = cms._rawValue.content;
			};

			const successModal = async () => {
				if (usedRKWallet.value || pointsEarned.value === "0.00") {
					router.replace("/order-success");
				} else {
					const modal = await modalController.create({
						component: PointsModal,
						canDismiss: true,
						backdropDismiss: false,
						cssClass: "gen-modal points",
						componentProps: {
							buttonLink: "/order-success",
							points: pointsEarned.value,
							availablePoints: availablePoints.value,
						},
					});

					modal.onDidDismiss(() => {
						router.replace("/order-success");
					});

					return modal.present();
				}
			};

			const clearVoucher = () => {
				cartStore.resetVoucher();
			};

			const applyVoucher = () => {
				if (cartStore.grandTotal > 0) {
					loadingVoucher.value = true;

					window.axios
						.post(apiStore.route("useVoucher"), {
							voucher: voucher.value,
							method: cartStore.fulfillmentMethod,
							branch: cartStore.branch?.id,
						})
						.then((result) => {
							loadingVoucher.value = false;

							cartStore.voucher.code = result.data.code;

							switch (result.data.voucher.discount_type) {
								case "Percent Discount":
									cartStore.voucher.value = cartStore.subtotal * (result.data.voucher.discount_value / 100);
									break;
								case "Fixed Discount":
									cartStore.voucher.value = result.data.voucher.discount_value > cartStore.subtotal ? cartStore.subtotal : result.data.voucher.discount_value;
									break;
							}

							showVoucherModal.value = false;
							voucherError.value = null;

							// clear payment method every change in voucher
							cartStore.paymentMethod = null;
							cartStore.cardSelected = null;

							// recheck applied RK
							updateCartPoints();
						})
						.catch((error) => {
							loadingVoucher.value = false;
							voucherError.value = error.response.data.message;
						});
				} else {
					voucherError.value = "Failed to apply voucher anymore. The total amount of the cart is 0.";
				}
			};

			watch(
				() => cartStore.id,
				(value) => {
					if (value) {
						if (cartStore.fulfillmentMethod === "Delivery") {
							getDeliveryFee();
						}
					}
				},
				{
					deep: true,
				}
			);

			watch(
				() => usePoints.value,
				() => {
					cartStore.points = 0;
				}
			);

			const updateRecipient = () => {
				showOrderModal.value = false;
				loadingNewRecipient.value = true;

				window.axios
					.patch(apiStore.route("cartUpdateRecipient", { id: cartStore.id }), {
						recipient: cartStore.recipient,
					})
					.then((result) => {
						cartStore.fetchCart(result.data.cart);
						localStorage.value.set("activeCart", result.data.cart);
						localStorage.value.set("setRecipient", cartStore.recipient);

						showToast.value = true;
						toastMessage.value = result.data.message;

						loadingNewRecipient.value = false;
					})
					.catch((error) => {
						loadingNewRecipient.value = false;
						modal.error(error.response.data.message);
					});
			};

			watch(
				() => points.value,
				(value) => {
					if (value > maxPoints.value) {
						points.value = maxPoints.value;
					}

					if (value > cartStore.discountedAmount) {
						points.value = cartStore.discountedAmount;
					}

					points.value = parseFloat(points.value);
				}
			);

			const applyPoints = () => {
				const maxSubtotal = cartStore.discountedAmount;

				points.value = maxPoints.value > maxSubtotal ? maxSubtotal : maxPoints.value;
			};

			const updateCartPoints = () => {
				loadingPoints.value = true;

				if (points.value > cartStore.discountedAmount) {
					points.value = cartStore.discountedAmount;
				}

				if (points.value > 0) {
					window.axios
						.post(apiStore.route("usePoints"), {
							points: points.value.toFixed(2),
							subtotal: cartStore.discountedAmount,
						})
						.then((result) => {
							loadingPoints.value = false;

							cartStore.points = parseFloat(points.value).toFixed(2);
							points.value = points.value.toFixed(2);

							showPointsMenu.value = false;

							showToast.value = true;
							toastMessage.value = result.data.message;
						})
						.catch((error) => {
							loadingPoints.value = false;
							modal.error(error.response.data.message);
						});
				} else {
					loadingPoints.value = false;
					cartStore.points = 0;
				}
			};

			const placeOrder = async () => {
				loading.value = true;

				window.axios
					.post(
						apiStore.route("checkout", {
							id: cartStore.id,
						}),
						{
							points: cartStore.points,
							payment_method: cartStore.paymentMethod,
							voucher: cartStore.voucher.code,
							card: cartStore.cardSelected,
							freebies: availFreebies.value,
						}
					)
					.then((result) => {
						pointsEarned.value = result.data.points_to_earn;
						availablePoints.value = result.data.available_points;
						loading.value = false;

						// set to user a pending points to earn (to show on success page)
						localStorage.value.set("pointsToEarn", pointsEarned.value);
						localStorage.value.set("availablePoints", availablePoints.value);

						// remove to user carts
						const cartIndex = userStore.carts.findIndex((obj) => obj.id === result.data.cart.id);
						userStore.carts.splice(cartIndex, 1);

						// remove local storage values
						localStorage.value.delete("product");
						localStorage.value.delete("cartFulfillment");
						localStorage.value.delete("dineinTable");
						localStorage.value.delete("setRecipient");
						localStorage.value.delete("activeCart");
						cartStore.$reset();

						if (result.data.redirect) {
							// redirect
							window.location.href = result.data.redirect;
						} else {
							successModal();
						}
					})
					.catch((error) => {
						loading.value = false;
						modal.error(error.response.data.message);
					});
			};

			const getDeliveryFee = () => {
				deliveryFeeLoading.value = true;

				window.axios
					.get(
						apiStore.route("getDeliveryFee", {
							id: cartStore.id,
						})
					)
					.then((result) => {
						deliveryFeeLoading.value = false;

						cartStore.deliveryFee = result.data.amount;
					})
					.catch((error) => {
						deliveryFeeLoading.value = false;
						cartStore.deliveryFee = 0;

						modal.error(error.response.data.message);
					});
			};

			const updateItemQuantity = (item, newQuantity) => {
				item.new_quantity = newQuantity;

				unconfirmedQuantity.value = cartStore.items.some((item) => {
					if (typeof item.new_quantity !== "undefined") {
						return item.quantity != item.new_quantity;
					}

					return false;
				});
			};

			const newItemQuantity = () => {
				points.value = 0;
				voucher.value = null;
				cartStore.resetVoucher();

				unconfirmedQuantity.value = cartStore.items.some((item) => {
					if (typeof item.new_quantity !== "undefined") {
						return item.quantity != item.new_quantity;
					}

					return false;
				});
			};

			onIonViewWillEnter(() => {
				// Fetch for any changes to customer (ex: available points)
				window.axios.get(apiStore.route("customer")).then(({ data }) => {
					userStore.setUser(data.user);

					return true;
				});

				if (cartStore.fulfillmentMethod === "Delivery" && cartStore.id) {
					getDeliveryFee();
				}

				voucher.value = null;
				getContentSlug();
			});

			return {
				apiStore,
				showOrderModal,
				loadingNewRecipient,
				openChooseRecipient,
				openChooseAddress,
				unconfirmedQuantity,
				showBranchTimeslot,
				closeOutline,
				loading,
				loadingPoints,
				loadingVoucher,
				voucher,
				clearVoucher,
				router,
				cartStore,
				userStore,
				showVoucherModal,
				applyVoucher,
				showPaymentMenu,
				showPointsMenu,
				deliveryFeeLoading,
				updateRecipient,
				showToast,
				toastMessage,
				placeOrder,
				points,
				applyPoints,
				updateCartPoints,
				availFreebies,
				usePoints,
				usedRKWallet,
				maxPoints,
				voucherError,
				getCmsContent,
				cmsContent,
				defaultDisclaimer,
				updateItemQuantity,
				newItemQuantity,
			};
		},
	});
</script>
