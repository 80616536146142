<template>
    <RadioGroup v-model="selectedOption">
        <div class="grid grid-cols-4 gap-3">
            <RadioGroupOption
                as="template"
                v-for="option in options"
                :key="option.amount"
                :value="option.value"
                :disabled="!option.disabled"
                v-slot="{ active, checked }"
            >
                <div
                    :class="[
                        option.disabled
                            ? 'cursor-pointer focus:outline-none'
                            : 'cursor-not-allowed bg-primary-50',
                        active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                        checked
                            ? 'bg-primary-600 border-transparent text-white'
                            : 'border-gray-200 text-primary-400',
                        'border rounded-md py-3 px-3 flex items-center justify-center text-xs font-medium uppercase sm:flex-1',
                    ]"
                >
                    <RadioGroupLabel as="span" class="text-xs font-bold">
                        {{ option.amount }}
                    </RadioGroupLabel>
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>
</template>
<script>
import { defineComponent, watch, ref } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";

export default defineComponent({
    components: {
        RadioGroup,
        RadioGroupLabel,
        RadioGroupOption,
    },
    props: {
        options: {
            type: Array
        }
    },
    setup(props, { emit }) {
        const selectedOption = ref(null);

        watch(
            () => selectedOption.value,
            (value) => {
                emit("update:modelValue", value);
            }
        );

        return {
            selectedOption,
        };
    },
});
</script>
