<template>
	<ion-page>
		<ion-content :fullscreen="true" class="ion-padding safe-area-bottom">
			<div class="h-full w-full flex flex-col justify-center lg:w-[320px] mx-auto">
				<div class="w-full text-center pb-20">
					<img
						:src="maintenanceSettings?.icon ?? `/assets/image/maintenance.svg`"
						alt="maintenance"
						class="-translate-x-[12px] mx-auto w-[190px] mb-6" />
					<h4 class="font-semibold text-primary-900 mb-4">Oops.. undergoing maintenance</h4>
					<p class="text-sm text-primary-400">{{ maintenanceSettings?.message }}</p>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
	import { IonPage, IonContent } from "@ionic/vue";
	import { useApiStore } from "@/store/api.js";
	import { computed } from "vue";

	export default {
		name: "MaintenancePage",
		components: {
			IonContent,
			IonPage,
		},

		setup() {
			const apiStore = useApiStore();
			const maintenanceSettings = computed(() => apiStore.settings?.maintenanceSettings);

			return {
				maintenanceSettings,
			};
		},
	};
</script>
