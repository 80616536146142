<template>
	<ion-page>
		<top-navigation :class="{ 'bg-white shadow-md': isScrolled }" :isScrolled="isScrolled"></top-navigation>
		<ion-content :fullscreen="true" :scroll-events="true" @ion-scroll="logScrolling($event)">
			<div class="min-h-screen">
				<!-- Frame 1 -->
				<section class="h-screen lg:h-full relative overflow-hidden">
					<img src="/assets/image/frame1-image.png" class="w-full h-full object-cover" />
					<div class="absolute z-10 inset-0 m-4 lg:m-20 flex items-center justify-start">
						<div class="space-y-12 lg:space-y-16">
							<h1
								class="
									text-5xl
									lg:text-6xl
									text-center
									lg:text-left
									text-white
									font-bold
									w-full
									lg:w-5/12
									m-0
								"
							>
								Make Real Ramen part of your everyday
							</h1>
							<location-bar />
						</div>
					</div>
				</section>

				<banner-modal 
					:is-visible="showPopup && !isHomeBannerClosed"
					@close="showPopup = false"
                    :popup="renderCmsImage(popup)"
				/>

				<!-- Frame 2 -->
				<section class="px-4 lg:px-12 py-12 lg:py-24">
                    <swiper
                        v-if="banners"
                        :modules="modules"
                        :pagination="true"
                        :loop="true"
                    >
                        <template v-for="item in banners" :key="item">
                            <swiper-slide>
                                <div class="rounded-lg relative bg-primary-50 flex lg:flex-row flex-col justify-between overflow-hidden">
                                    <div class="w-full lg:w-auto flex items-center flex-col p-6 lg:px-28 md:py-12 justify-center lg:order-1 order-2">
                                        <div class="w-full">
                                            <h2 class="text-primary-900 text-xl lg:text-[32px] font-semibold leading-tight">
                                                {{ item.title }}
                                            </h2>
                                            <p class="text-primary-400 text-sm mt-4">{{ item.description }}</p>
                                            <location-bar 
                                                :locationBar="false"
                                                :contents="item"
                                            ></location-bar>
                                        </div>
                                    </div>
                                    <div class="w-full lg:w-[49.5%] flex-shrink-0 lg:order-2 order-1">
                                        <img
                                            :src="`${item.image !== '' ? renderCmsImage(item.image) : '/assets/image/banner_img1.png'}`"
                                            class="object-cover block mx-auto h-full w-full"
                                            alt="banner"
                                        />
                                    </div>
                                </div>
                            </swiper-slide>
                        </template>
                    </swiper>
				</section>

				<!-- Old Frame 2 -->
				<!-- <section class="px-4 lg:px-12 py-12 lg:py-24">
					<div class="rounded-lg relative bg-primary-50 p-6 lg:p-28 overflow-hidden">
						<div>
							<div class="w-4/5">
								<h2 class="text-primary-900 text-3xl font-semibold">
									More good things for you in our app!
								</h2>
								<p class="text-primary-400 text-sm mt-4">Now available on Google PlayStore.</p>
							</div>
						</div>
						<img
							src="/assets/image/frame2-img-1.png"
							class="hidden lg:block absolute bottom-0 right-[19rem] w-auto h-[20rem]"
						/>
						<img
							src="/assets/image/frame2-img-2.png"
							class="hidden lg:block absolute top-0 right-0 w-auto h-full"
						/>
					</div>
				</section> -->

				<!-- Frame 3 -->
				<section class="px-4 lg:px-12 pb-24">
					<div class="space-y-2 lg:space-y-4">
						<h3 class="text-xl lg:text-3xl text-primary-900 font-semibold">Promos for you</h3>
						<p class="text-primary-400 text-sm">Some promos does not apply to other stores.</p>
					</div>
					<div class="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-12 mt-4 lg:mt-12">
						<template v-for="(item, index) in promos" :key="index">
							<promo-card :item="item" @click="viewPromo(item)"></promo-card>
						</template>
					</div>
				</section>
			</div>

			<bottom-navigation />
		</ion-content>

		<!-- Promo Modal -->
		<generic-modal :is-visible="showPromoModal" @close="showPromoModal = false" :mobile-full-screen="true">
			<template #content>
				<promo-view :item="promoView" />
			</template>
		</generic-modal>
	</ion-page>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import PromoCard from "./Components/PromoCard.vue";
import LocationBar from "./Components/LocationBar.vue";
import GenericModal from "@/components/GenericModal.vue";
import PromoView from "@/components/PromoView.vue";
import BannerModal from "./Components/BannerModal.vue";
import { useGuestStore } from "@/store/guest";
import { useUserStore } from "@/store/user";
import useCms from "@/composables/useCms";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import '@ionic/vue/css/ionic-swiper.css';

export default defineComponent({
	name: "HomePage",
	components: {
		IonPage,
		IonContent,
		// IonButton,
		TopNavigation,
		BottomNavigation,
		PromoCard,
		LocationBar,
		GenericModal,
		PromoView,
		BannerModal,
        Swiper,
        SwiperSlide,
	},
	setup() {
        /*--------------*
         * IMPORTS
         *--------------*/
        const { cms, contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();
		const router = useRouter();
        const route = useRoute()
		const guestStore = useGuestStore();
        const userStore = useUserStore();

        /*--------------*
         * REFS
         *--------------*/
		const isScrolled = ref(false);
		const showPromoModal = ref(false);
		const promos = ref([]);
		const promoView = ref(null);
        const banners = ref(null);
        const popup = ref(null);
		const showPopup = ref(false);
        const cmsContent = ref(null);

		const logScrolling = (e) => {
			if (e.detail.scrollTop > 150) {
				isScrolled.value = true;
			} else if (e.detail.scrollTop < 154) {
				isScrolled.value = false;
			}
		};

        const isHomeBannerClosed = computed(() => userStore.isHomeBannerClosed ?? false);

		const fetchPromos = () => {
			window.axios.get(process.env.VUE_APP_API_URL + "/api/promos/fetch").then((result) => {
				promos.value = result.data.promos;
			});
		};

		const viewPromo = (promo) => {
			promoView.value = promo;
			showPromoModal.value = true;
		};

        const fetchCmsSlug = async () => {
            contentSlug.value = "web_homepage";
            getCms(contentSlug.value);
            
            cmsContent.value = cms._rawValue.content;
            banners.value = getCmsContent('frame1_banners')
            popup.value = getCmsContent("frame2_popup") || null;

            return true;
        };

		onIonViewWillEnter(() => {
			guestStore.checkIfGuest();
            userStore.getHomeBannerState();
            fetchPromos();
            fetchCmsSlug()

            setTimeout(() => {
                if (popup.value && route.name === "Home") {
                    showPopup.value = true;
                }
            }, 1500);
		});

		return {
			isScrolled,
			logScrolling,
			showPromoModal,
			router,
			promos,
			viewPromo,
			promoView,
			showPopup,
            popup,
            userStore,
            getCmsImage,
            renderCmsImage,
            banners,
            modules: [Pagination],
            isHomeBannerClosed,
		};
	},
});
</script>