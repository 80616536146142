<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="flex items-center justify-center h-full my-6 first-letter:lg:my-20">
					<div class="px-4 lg:px-0 w-full lg:w-[400px] mx-auto">
						<div class="relative">
							<div @click="() => router.push('/login-option')" class="relative pb-8 block lg:hidden">
								<ArrowLeftIcon class="cursor-pointer w-5 h-5 text-primary-500 absolute left-0" />
							</div>
						</div>
						<div>
							<h2 class="text-primary-900 font-semibold">Create Account</h2>
							<p class="text-primary-900 text-sm">Looks like you are not registered yet. Please fill out the field to continue.</p>
						</div>
						<div class="mt-8 space-y-4">
							<div class="space-y-2">
								<p class="text-primary-400 text-sm">Personal Information:</p>
								<text-input
									type="text"
									placeholder="First Name"
									v-model="form.first_name"
									:error="errors?.first_name ? errors.first_name[0] : null" />
								<text-input
									type="text"
									placeholder="Last Name"
									v-model="form.last_name"
									:error="errors?.last_name ? errors.last_name[0] : null" />

								<SelectMenu v-model="form.gender" name="gender" id="gender" placeholder="Gender" :options="gender" />
								<p class="text-[10px] text-error mt-1" v-if="errors?.gender">
									{{ errors.gender[0] }}
								</p>
							</div>
							<div class="space-y-2">
								<p class="text-primary-400 text-sm">Date of Birth:</p>
								<div class="flex items-start flex-col">
									<text-input
										type="date"
										placeholder="Birth Date"
										v-model="form.birthdate"
										:error="errors?.birthdate ? errors.birthdate[0] : null" />
								</div>
							</div>
							<div class="space-y-2">
								<p class="text-primary-400 text-sm">Account Details:</p>

								<mobile-number
									class="mt-2"
									v-model="form.mobile_number"
									:error="errors?.mobile_number ? errors.mobile_number[0] : null"
									:disabled="form.validator === form.mobile_number" />
								<text-input
									type="email"
									placeholder="Email"
									v-model="form.email"
									:error="errors?.email ? errors.email[0] : null"
									:disabled="form.validator === form.email" />
								<div class="relative">
									<text-input :type="showPassword ? 'text' : 'password'" placeholder="Password" v-model="form.password" />
									<div
										@click="showPassword = !showPassword"
										class="cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center">
										<template v-if="!showPassword">
											<EyeIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
										</template>
										<template v-else>
											<EyeOffIcon class="h-5 w-5 text-primary-500" aria-hidden="true" />
										</template>
									</div>
								</div>
								<p class="text-[10px] text-error mt-1" v-if="errors?.password">
									{{ errors.password[0] }}
								</p>
							</div>
						</div>
						<div class="mt-3 text-right w-full">
							<button-component @click="submit" color="primary" class="w-full lg:w-auto">
								<template #content>
									<template v-if="!loading">Create Account</template>
									<ion-spinner v-else></ion-spinner>
								</template>
							</button-component>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>
	</ion-page>
</template>
<script>
	import { defineComponent, ref } from "vue";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
	import { ArrowLeftIcon } from "@heroicons/vue/outline";
	import Modal from "@/components/Modal.vue";
	import useLocalStorage from "@/composables/useLocalStorage.js";
	import SelectMenu from "@/components/SelectMenu.vue";
	import TextInput from "@/components/TextInput.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import MobileNumber from "@/components/MobileNumber.vue";
	import { IonPage, IonContent, IonSpinner, modalController, onIonViewWillEnter } from "@ionic/vue";
	import { useRouter } from "vue-router";
	import { useUserStore } from "@/store/user";
	import { useApiStore } from "@/store/api";

	export default defineComponent({
		name: "LoginAccount",
		components: {
			IonPage,
			IonContent,
			IonSpinner,
			EyeIcon,
			EyeOffIcon,
			TopNavigation,
			BottomNavigation,
			ArrowLeftIcon,
			ButtonComponent,
			MobileNumber,
			SelectMenu,
			TextInput,
		},
		setup() {
			const router = useRouter();
			const { localStorage } = useLocalStorage();
			const userStore = useUserStore();
			const apiStore = useApiStore();

			const loading = ref(false);
			const errors = ref(null);
			const showPassword = ref(false);

			const numberonly = (e) => {
				e = e ? e : window.event;
				let charCode = e.which ? e.which : e.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57)) {
					e.preventDefault();
				} else {
					return true;
				}
			};

			const form = ref({
				validator: null,
				first_name: null,
				last_name: null,
				email: null,
				birthdate: null,
				mobile_number: null,
				password: null,
				gender: null,
				errors: {},
			});

			const gender = ref([
				{ id: 1, value: "Female" },
				{ id: 2, value: "Male" },
			]);

			const errorModal = async (message) => {
				loading.value = false;

				const modal = await modalController.create({
					component: Modal,
					cssClass: "gen-modal",
					componentProps: {
						title: "Warning!",
						description: message,
						imageLink: "/assets/icons/ic-warning.svg",
						imageWidth: "80",
						imageHeight: "78",
					},
				});

				return modal.present();
			};

			const submit = () => {
				loading.value = true;

				window.axios
					.post(apiStore.route("validateRegister"), form.value)
					.then((result) => {
						loading.value = false;

						localStorage.value.set("pendingRegisterDetails", result.data.data);
						router.replace("/otp-verification");
					})
					.catch((error) => {
						errors.value = error.response.data.errors;
						errorModal(error.response.data.error);
					});
			};

			const changeBirthdate = (value) => {
				let date = new Date(value.detail.value);
				form.value.birthdate = date instanceof Date && !isNaN(date) ? date.toISOString().split("T")[0] : null;
			};

			onIonViewWillEnter(() => {
				userStore.getUserValidation().then(() => {
					form.value.mobile_number = !isNaN(parseInt(userStore.userValidation)) ? userStore.userValidation : null;
					form.value.validator = userStore.userValidation;
				});

				userStore.getUserEmail().then(() => {
					form.value.email = isNaN(parseInt(userStore.userValidation)) ? userStore.userValidation : null;
					form.value.validator = userStore.userValidation;
					userStore.setUserEmail(form.value.validator);
				});
			});

			return {
				gender,
				showPassword,
				numberonly,
				router,
				form,
				errors,
				loading,
				submit,
				userStore,
				changeBirthdate,
			};
		},
	});
</script>
