<template>
	<template v-if="slashed">
		<span class="text-error line-through">{{
			render(oldPrice)
		}}</span>
	</template>
	{{ render() }}
</template>

<script>
export default {
	props: {
		value: {
			default: 0,
		},
		slashed: {
			type: Boolean,
			default: false,
		},
		oldPrice: {
			default: 0,
		},
	},
	setup(props) {
		function render(amount = null) {
			let value = amount ?? props.value;

			if (isNaN(value)) {
				value = 0;
			}

			return Intl.NumberFormat("fil-PH", {
				style: "currency",
				currency: "PHP",
			}).format(value);
		}

		return {
			render,
		};
	},
};
</script>
