<template>
    <div class="flex item-center relative">
        <span
            class="
                absolute
                left-3
                top-2.5
                pr-2
                border-r
                text-gray-500 text-sm
                m-0
            "
        >
            +63
        </span>
        <input
            @keypress="numberonly"
            type="tel"
            class="
                pl-14
                shadow-sm
                focus:ring-primary-500 focus:border-primary-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-lg
                placeholder:text-primary-100
            "
            :placeholder="placeholder"
            maxlength="10"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :disabled="disabled"
        />
    </div>
    <p class="text-[10px] text-error mt-1" v-if="error">
        {{ error }}
    </p>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MobileNumber",
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: "Mobile Number",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const numberonly = (e) => {
            e = e ? e : window.event;
            let charCode = e.which ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                e.preventDefault();
            } else {
                return true;
            }
        };

        return {
            numberonly,
        };
    },
});
</script>