<template>
    
    <RadioGroup v-model="selectedOption">
        <div class="mt-4 grid grid-cols-1 gap-y-2 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="option in options" :key="option.id" :value="option" v-slot="{ checked, active }">
                <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'border-primary-500 ring-2 ring-primary-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                    <span class="flex-1 flex">
                        <span class="flex flex-col items-start m-0">
                            <RadioGroupLabel as="span" class="block text-sm font-bold text-primary-500 m-0">
                                {{ option.cardName }}
                            </RadioGroupLabel>
                            <RadioGroupDescription as="span" class="mt-1 flex items-center font-bold text-sm text-primary-500 m-0">
                                **** **** **** {{ option.cardNumber }}
                            </RadioGroupDescription>
                            <RadioGroupDescription as="span" class="mt-2 text-sm font-normal text-primary-400 m-0">
                                {{ option.expiryDate }}
                            </RadioGroupDescription>
                        </span>
                    </span>
                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-900']" aria-hidden="true" />
                    <span :class="[active ? 'border' : 'border-2', checked ? 'border-primary-900' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>

</template>
<script>
import { defineComponent, ref } from 'vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'

export default defineComponent({
    components: {
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        CheckCircleIcon
    },
    props: {
        options: {
            type: Array
        }
    },
    setup() {
        const selectedOption = ref(null)

        return {
            selectedOption
        }
    },
})
</script>
