<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true">
			<div class="page-offset min-h-screen">
				<div class="max-w-none md:max-w-[504px] mx-auto pt-10">
					<div class="px-4 md:px-12">
						<div class="mb-8">
							<h2 class="hidden md:block font-semibold font--28 mb-10">RK Wallet</h2>
							<div class="w-full rounded-xl border border-primary-50 p-6">
								<p class="text-sm text-primary-300">RK Wallet</p>
								<h2 class="text-[28px] md:text-[32px]">
									<render-price :value="userStore.userData.rk_wallet_balance" />
								</h2>
								<div class="text-right">
									<button-component
										color="primary"
										@click="showSideMenu = true">
										<template #content>Top-Up Now</template>
									</button-component>
								</div>
							</div>
						</div>
						<div class="w-full pb-24">
							<div class="flex items-center justify-between mb-6">
								<p class="font-semibold m-0 text-2xl md:text-base">Wallet History</p>
								<anchor-link @click="redirect('wallet-view')">
									<template #content>
										<span class="font-semibold text-sm text-primary-900">View All</span>
									</template>
								</anchor-link>
							</div>
							<div class="max-h-[50vh] overflow-y-auto">
								<template v-if="!loading">
									<item-list
										v-for="log in logs"
										:class="{ 'cursor-pointer': log.source_name === 'Order' }"
										:key="log"
										:title="generateLabel(log)"
										:description="generateDescription(log)"
										:time="log.log_date_formatted"
										:amount="log.amount"
										:type="log.transaction_type"
										:withBorder="true"
										@click="log.source_name === 'Order' ? redirectToOrder(log.source_id) : ''" />
								</template>
								<ion-spinner v-else />
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom-navigation></bottom-navigation>
		</ion-content>

		<!------------
			MODALS
		------------->

		<!-- Top Up -->
		<side-menu
			title="Amount"
			:is-visible="showSideMenu"
			@close="showSideMenu = false">
			<template #content>
				<div class="space-y-8 px-2">
					<div>
						<div class="flex items-center gap-2 mb-1.5">
							<p class="text-3xl font-bold m-0 mr-2">&#8369;</p>
							<input
								type="number"
								placeholder="Enter Amount"
								class="focus:ring-0 block w-full border-0 placeholder:text-primary-50 font-bold text-3xl p-0 appearance-none"
								:step="0.01"
								:min="100"
								:max="30000"
								v-model="form.amount" />
						</div>
						<p class="text-xs text-primary-200">
							from
							<render-price :value="100" />
							to
							<render-price :value="30000" />
						</p>
					</div>
					<div class="border-b pb-8">
						<radio-small-card
							v-model="selectedOption"
							:options="defaultAmount" />
					</div>
					<div>
						<div class="flex items-center justify-between">
							<p class="text-base font-semibold m-0">Payment</p>
							<button
								@click="showPaymentMenu = true"
								type="button"
								class="flex items-center gap-3.5">
								<span class="text-sm font-semibold mx-1">
									<template v-if="form.payment_method">Change</template>
									<template v-else>Choose</template>
								</span>
								<ion-icon
									:src="chevronForward"
									class="w-4 h-4"></ion-icon>
							</button>
						</div>
						<div v-if="form.payment_method">
							<order-payment-method
								:method="form.payment_method"
								:show-forward="false" />
						</div>
					</div>
				</div>
			</template>
			<template #button>
				<div class="flex items-start justify-between pt-6 border-t">
					<div>
						<p class="m-0 text-xs font-bold text-primary-200">Top up Amount</p>
						<p class="m-0 text-xl font-bold text-primary-900">
							<render-price :value="form.amount" />
						</p>
					</div>
					<button-component
						color="primary"
						@click="showConfirmationModal = true"
						:disabled="form.amount === 0 || !form.payment_method || form.amount < 100 || form.amount > 30000">
						<template #content>
							<template v-if="!topupLoading">Pay Now</template>
							<ion-spinner v-else />
						</template>
					</button-component>
				</div>
			</template>
		</side-menu>

		<generic-modal
			:is-visible="showConfirmationModal"
			@close="showConfirmationModal = false"
			:noCloseIcon="true">
			<template #content>
				<div class="px-4 pb-4 lg:px-6 lg:pb-6 w-full lg:w-[400px] h-auto">
					<div class="space-y-2">
						<p class="text-base text-primary-900 font-bold">Confirm Top-Up</p>
						<p class="text-sm text-primary-400 font-normal">
							RK Wallet can only be used solely to purchase Ramen Kuroda products in the application.
							<br />
							<br />
							PWD, Senior Citizen's, and other discount privileges are not yet applicable.
						</p>
					</div>
					<div class="flex items-center justify-end gap-2 mt-8">
						<button-component
							@click="showConfirmationModal = false"
							color="secondary">
							<template #content>Cancel</template>
						</button-component>
						<button-component
							@click="topup"
							color="red">
							<template #content>Proceed</template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>

		<payment-method
			:with-wallet="false"
			:is-visible="showPaymentMenu"
			@close="showPaymentMenu = false"
			@payment:set="(method) => (form.payment_method = method)"
			@card:set="(card) => (form.card = card)"
			:toPay="form.amount"></payment-method>
	</ion-page>
</template>

<script type="text/javascript">
	import { defineComponent, ref, watch } from "vue";
	import { chevronForward } from "ionicons/icons";
	import { IonPage, IonContent, IonIcon, IonSpinner, modalController, onIonViewWillEnter } from "@ionic/vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import ItemList from "@/components/ItemList.vue";
	import AnchorLink from "@/components/AnchorLink.vue";
	import SideMenu from "@/components/SideMenu.vue";
	import RadioSmallCard from "@/components/RadioSmallCard.vue";
	import PaymentMethod from "@/components/PaymentMethod.vue";
	import RenderPrice from "@/components/RenderPrice.vue";
	import _ from "lodash";
	import { useApiStore } from "@/store/api";
	import { useUserStore } from "@/store/user";
	import OrderPaymentMethod from "@/components/OrderPaymentMethod.vue";
	import useModal from "@/composables/modal";
	import PointsModal from "@/components/PointsModal.vue";
	import useLocalStorage from "@/composables/useLocalStorage.js";
	import { useRouter } from "vue-router";
	import GenericModal from "@/components/GenericModal.vue";

	export default defineComponent({
		amount: "WalletPage",
		components: {
			IonPage,
			IonContent,
			IonIcon,
			IonSpinner,
			TopNavigation,
			BottomNavigation,
			ButtonComponent,
			ItemList,
			AnchorLink,
			SideMenu,
			RadioSmallCard,
			PaymentMethod,
			RenderPrice,
			OrderPaymentMethod,
			GenericModal,
		},
		setup() {
			const apiStore = useApiStore();
			const userStore = useUserStore();
			const modal = useModal();
			const router = useRouter();
			const showSideMenu = ref(false);
			const showPaymentMenu = ref(false);
			const showConfirmationModal = ref(false);
			const { localStorage } = useLocalStorage();

			const loading = ref(false);
			const topupLoading = ref(false);
			const logs = ref([]);
			const pointsEarned = ref(null);
			const availablePoints = ref(null);

			const form = ref({
				amount: null,
				payment_method: null,
				card: null,
			});

			watch(
				() => form.value.amount,
				(value) => {
					if (value > 30000) {
						form.value.amount = 30000;
					} else {
						form.value.amount = value ? parseFloat(value) : "";
					}
				}
			);

			const selectedOption = ref(null);
			const defaultAmount = [
				{ amount: "200.00", value: 200, disabled: true },
				{ amount: "400.00", value: 400, disabled: true },
				{ amount: "600.00", value: 600, disabled: true },
				{ amount: "800.00", value: 800, disabled: true },
				{ amount: "1,200.00", value: 1200, disabled: true },
				{ amount: "1,400.00", value: 1400, disabled: true },
				{ amount: "1,600.00", value: 1600, disabled: true },
				{ amount: "1,800.00", value: 1800, disabled: true },
			];

			watch(
				() => selectedOption.value,
				(value) => {
					form.value.amount = value;
				}
			);

			const openModal = async () => {
				const modal = await modalController.create({
					component: PointsModal,
					canDismiss: true,
					cssClass: "gen-modal points",
					componentProps: {
						points: pointsEarned.value,
						availablePoints: availablePoints.value,
						buttonText: "Okay",
						buttonLink: "/home",
					},
				});

				return modal.present();
			};

			const generateDescription = (log) => {
				if (log.transaction_type === "added") {
					if (log.source_name === "Gift") {
						return "Received gift from";
					} else {
						if (log.source?.payment) {
							return "Top up (" + identifyPaymentMethod(log.source?.payment?.method) + ") ";
						} else {
							if (_.startCase(log.source_type.split("\\").pop()) === "Order") {
								return "Order Refund ";
							} else {
								return "Top up ";
							}
						}
					}
				} else {
					return _.startCase(log.source_type.split("\\").pop()) + " Payment ";
				}
			};

			const generateLabel = (log) => {
				if (log.source_name === "Gift") {
					return log.source?.sender?.name;
				} else {
					return log.source_name + " #" + log.source_id;
				}
			};

			const identifyPaymentMethod = (payment) => {
				return payment == "Card" ? "Debit/Credit" : payment;
			};

			const topup = () => {
				showConfirmationModal.value = false;
				topupLoading.value = true;

				window.axios
					.post(apiStore.route("walletTopup"), form.value)
					.then((result) => {
						topupLoading.value = false;
						pointsEarned.value = result.data.points;
						availablePoints.value = result.data.available_points;

						// set to user a pending points to earn (to show on success page)
						localStorage.value.set("pointsToEarn", pointsEarned.value);
						localStorage.value.set("availablePoints", availablePoints.value);

						form.value.amount = 0;
						form.value.payment_method = null;
						form.value.card = null;

						if (result.data.redirect) {
							// redirect
							window.location.href = result.data.redirect;
						} else {
							openModal();
						}
					})
					.catch((error) => {
						topupLoading.value = false;
						modal.error(error.response.data.message);
					});
			};

			const redirectToOrder = (id) => {
				userStore.viewOrder = id;
				router.push("/order-history");
			};

			const closeThis = () => {
				open.value = false;
			};

			const redirect = (link) => {
				closeThis();
				router.push(link);
			};

			onIonViewWillEnter(() => {
				loading.value = true;

				window.axios.get(apiStore.route("walletLogs")).then((result) => {
					loading.value = false;
					logs.value = result.data.logs.splice(0, 5);
				});
			});

			return {
				userStore,
				showSideMenu,
				showPaymentMenu,
				showConfirmationModal,
				loading,
				topupLoading,
				topup,
				logs,
				form,
				selectedOption,
				defaultAmount,
				chevronForward,
				generateDescription,
				generateLabel,
				openModal,
				redirectToOrder,
				closeThis,
				redirect,
			};
		},
	});
</script>
