<template>
	<ion-page>
		<top-navigation></top-navigation>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="pnts-frame1 bg-black">
					<div class="frm-wrapper px-6 lg:px-0 lg:max-w-[870px] pt-[121px] pb-[79px] md:pb-[89px] m-auto">
						<div class="pnts-frame1__wrapper flex justify-between">
							<ion-spinner
								class="text-white"
								v-if="loading" />

							<template v-else>
								<div class="pnts-frame1__left text-center md:text-left">
									<div class="points-header mb-[10px]">
										<h1 class="text-5xl md:text-6xl font-bold text-white">
											{{ customer.rk_total_points }}
										</h1>
									</div>
									<div class="point-subtext mb-[30px]">
										<h6 class="text--primary--100">Total Points</h6>
									</div>
									<div class="current-point__cntnr mb-[33px]">
										<div class="flex gap-3 w-fit bg--primary--400 px-[20px] py-[8px] rounded-3xl m-auto md:m-0">
											<img
												v-if="tierLevel.icon"
												class="w-[24px] h-[24px] object-cover"
												:src="tierLevel.icon"
												alt="Tier Level Icon" />
											<h6 class="text-white font-semibold">
												{{ tierLevel.tier }}
											</h6>
										</div>
									</div>
									<anchor-link
										@click="showTransactionHistory = !showTransactionHistory"
										class="hidden md:block">
										<template #content>
											<div class="flex items-center gap-4">
												<span class="text-white font-bold text-sm">Transaction History</span>
												<ion-icon
													:src="chevronForward"
													class="w-6 h-6 text-white" />
											</div>
										</template>
									</anchor-link>
								</div>
								<div class="pnts-frame1__right w-full max-w-none md:max-w-[408px] relative">
									<div class="pnts-frame1__progress mb-[20px] md:mb-4">
										<ion-progress-bar
											class="mb-[10px]"
											color="warning"
											:value="progress" />
										<div class="flex w-full justify-between">
											<p class="font--12 text--primary--200 m-0">
												{{ currentTier }}
											</p>
											<p class="font--12 text--primary--200 m-0">
												{{ nextTier }}
											</p>
										</div>
									</div>
									<div class="pnts-frame1__desc mb-4 md:mb-6">
										<template v-if="earnPoints === 'max'">
											<h6 class="text-white">You reached the maximum tier level.</h6>
										</template>
										<template v-else>
											<h6 class="text-white">Earn {{ parseFloat(earnPoints).toFixed(2) }} more points to reach {{ nextTier }}.</h6>
										</template>
									</div>
									<anchor-link
										@click="showTransactionHistory = !showTransactionHistory"
										class="md:hidden">
										<template #content>
											<div class="flex items-center gap-4">
												<span class="text-white font-bold text-sm">Transaction History</span>
												<ion-icon
													:src="chevronForward"
													class="w-6 h-6 text-white" />
											</div>
										</template>
									</anchor-link>
									<div class="card-points bg--primary--50 w-full rounded-xl absolute mt-4 md:mt-0">
										<div class="card-ponts__wrapper pt-8 pb-7 px-0 md:p-12 flex">
											<div class="avail-pnts border-r border-white w-1/2">
												<div class="w-fit text-center m-auto md:m-0">
													<h3 class="text-xl md:text-2xl font-semibold mb-1">
														{{ customer.rk_available_points }}
													</h3>
													<p class="font--12">Available Points</p>
												</div>
											</div>
											<div class="annual-pnts w-1/2 flex justify-end">
												<div class="w-fit text-center m-auto md:m-0">
													<h3 class="text-xl md:text-2xl font-semibold mb-1">
														{{ customer.rk_annual_points }}
													</h3>
													<p class="font--12">Annual Points</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
				<div class="pnts-frame2">
					<div class="frm-wrapper px-6 max-w-none lg:px-0 lg:max-w-[870px] m-auto pt-[69px] pb-[44px] md:pb-[109px]">
						<div class="pnts-frame2__wrapper hidden md:grid gap-[22px] grid-cols-2 mb-[43px]">
							<div
								class="relative flex items-center space-x-6 border border-primary-50 rounded-xl p-6"
								:class="{
									'bg-primary-50': customer.tier_level === 'Diamond',
								}">
								<div class="flex-shrink-0">
									<ion-icon
										src="/assets/image/diamond.svg"
										class="w-14 h-14" />
								</div>
								<div class="flex-1 min-w-0">
									<ion-icon
										v-if="customer.tier_level === 'Diamond'"
										:src="checkmarkCircle"
										class="w-5 h-5 absolute top-3 right-3" />
									<p class="text-sm font-semibold mb-2">Diamond Bowl</p>
									<p class="text-xs text-primary-200">Earn 1000 points to unlock</p>
								</div>
							</div>

							<div
								class="relative flex items-center space-x-6 border border-primary-50 rounded-xl p-6"
								:class="{
									'bg-primary-50': customer.tier_level === 'Platinum',
								}">
								<div class="flex-shrink-0">
									<ion-icon
										src="/assets/image/plat.svg"
										class="w-14 h-14" />
								</div>
								<div class="flex-1 min-w-0">
									<ion-icon
										v-if="customer.tier_level === 'Platinum'"
										:src="checkmarkCircle"
										class="w-5 h-5 absolute top-3 right-3" />
									<p class="text-sm font-semibold mb-2">Platinum Bowl</p>
									<p class="text-xs text-primary-200">Earn 500 points to unlock</p>
								</div>
							</div>

							<div
								class="relative flex items-center space-x-6 border border-primary-50 rounded-xl p-6"
								:class="{
									'bg-primary-50': customer.tier_level === 'Gold',
								}">
								<div class="flex-shrink-0">
									<ion-icon
										src="/assets/image/gold.svg"
										class="w-14 h-14" />
								</div>
								<div class="flex-1 min-w-0">
									<ion-icon
										v-if="customer.tier_level === 'Gold'"
										:src="checkmarkCircle"
										class="w-5 h-5 absolute top-3 right-3" />
									<p class="text-sm font-semibold mb-2">Golden Bowl</p>
									<p class="text-xs text-primary-200">Earn 150 points to unlock</p>
								</div>
							</div>

							<div
								class="relative flex items-center space-x-6 border border-primary-50 rounded-xl p-6"
								:class="{
									'bg-primary-50': customer.tier_level === 'Silver',
								}">
								<div class="flex-shrink-0">
									<ion-icon
										src="/assets/image/silver.svg"
										class="w-14 h-14" />
								</div>
								<div class="flex-1 min-w-0">
									<ion-icon
										v-if="customer.tier_level === 'Silver'"
										:src="checkmarkCircle"
										class="w-5 h-5 absolute top-3 right-3" />
									<p class="text-sm font-semibold mb-2">Silver Bowl</p>
									<p class="text-xs text-primary-200">Earn 50 points to unlock</p>
								</div>
							</div>
						</div>
						<div class="hwto-earn">
							<div class="hwto-earn__header mb-4">
								<template v-if="cmsContent">
									<h3 class="text-xl md:text-2xl font-bold">
										{{ getCmsContent("mechanics_title") || "How to earn points?" }}
									</h3>
								</template>
								<template v-else>
									<h3 class="text-xl md:text-2xl font-bold">How to earn points?</h3>
								</template>
							</div>
							<div class="hwt-earn__desc">
								<template v-if="cmsContent">
									<div
										v-html="getCmsContent('mechanics_content')"
										class="html-box"></div>
								</template>
								<template v-else>
									<h5 class="text--primary--400">
										You earn points if you will top-up the RK Wallet. Every 10 pesos top-up is equivalent to 1 point (point will increase depending on tier)
										<br />
										<br />
										Purchasing products in the app not using RK Wallet ( Pay using Cash, Debit Card/ GrabPay/Gcash). Every 100 pesos worth of worth of product = 1 point (point depending on tier)
										<br />
										<br />
										Points earned will be based on the total amount of food ordered (VAT, service charge and delivery fee is not included)
										<br />
										<br />
									</h5>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<bottom-navigation />
		</ion-content>

		<!----------- 
            MODALS
        ------------->

		<!-- Transaction History -->
		<side-menu
			title="Transaction History"
			:is-visible="showTransactionHistory"
			@close="showTransactionHistory = false">
			<template #content>
				<div class="h-[70vh] overflow-y-auto pr-4 scrollbar-style">
					<template v-if="!loading">
						<item-list
							v-for="(point, index) in customer.point_logs"
							:class="{ 'cursor-pointer': point.source_name === 'Order' }"
							:key="index"
							:title="`${point.source_name} #${point.source_id}`"
							:description="generateDescription(point)"
							:time="point.created_at_formatted"
							:amount="point.points"
							:type="point.transaction_type"
							:withBorder="true"
                            :plainNumber="true"
							@click="point.source_name === 'Order' ? redirectToOrder(point.source_id) : ''" />
					</template>
					<ion-spinner v-else />
				</div>
			</template>
		</side-menu>
	</ion-page>
</template>

<script>
	import { computed, defineComponent, ref } from "vue";
	import { IonPage, IonContent, IonProgressBar, IonIcon, IonSpinner, onIonViewWillEnter } from "@ionic/vue";
	import { chevronForward, checkmarkCircle } from "ionicons/icons";
	import { useRouter } from "vue-router";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import SideMenu from "@/components/SideMenu.vue";
	import ItemList from "@/components/ItemList.vue";
	import AnchorLink from "@/components/AnchorLink.vue";
	import useCms from "@/composables/useCms";
	import { useApiStore } from "@/store/api";
	import { useUserStore } from "@/store/user";

	export default defineComponent({
		name: "OrderHistory",
		components: {
			TopNavigation,
			BottomNavigation,
			IonContent,
			IonPage,
			IonProgressBar,
			IonIcon,
			IonSpinner,
			ItemList,
			SideMenu,
			AnchorLink,
		},
		setup() {
			const userStore = useUserStore();
			const apiStore = useApiStore();
			const router = useRouter();
			const { cms, contentSlug, getCms, getCmsContent } = useCms();

			const showTransactionHistory = ref(false);
			const loading = ref(true);

			const customer = ref([]);
			const progress = ref(0);
			const currentTier = ref(null);
			const nextTier = ref(null);
			const earnPoints = ref(null);
			const cmsContent = ref(null);

			const fetchLogs = () => {
				window.axios.get(apiStore.route("points")).then((result) => {
					loading.value = false;
					customer.value = result.data.customer;

					progressBar(customer.value.rk_annual_points);
				});
			};

			const generateDescription = (log) => {
				switch (log.transaction_type) {
					case "added":
						return `Points Earned`;
					case "refunded":
						return `Points Refunded`;
					case "deducted":
						return `Points Deducted`;
					case "reset":
						return `Points has been reset`;
					default:
						return `Points Transaction`;
				}
			};

			const tierLevel = computed(() => {
				let icon = null,
					tier = "Regular";

				if (customer.value) {
					if (customer.value.tier_level === "Silver") {
						icon = "/assets/image/silver.svg";
						tier = "Silver Bowl";
					} else if (customer.value.tier_level === "Gold") {
						icon = "/assets/image/gold.svg";
						tier = "Golden Bowl";
					} else if (customer.value.tier_level === "Platinum") {
						icon = "/assets/image/plat.svg";
						tier = "Platinum Bowl";
					} else if (customer.value.tier_level === "Diamond") {
						icon = "/assets/image/diamond.svg";
						tier = "Diamond Bowl";
					}
				}

				return { icon, tier };
			});

			const progressBar = (points) => {
				// Diamond tier customers doesn't reset their rank, so they will always have the maximum regardless of their annual points
				if (customer.value.tier_level === "Diamond" || points >= 1000) {
					progress.value = points;
					earnPoints.value = "max";
					currentTier.value = "";
					nextTier.value = "Diamond";
				} else {
					if (points < 50) {
						progress.value = points / 50;
						earnPoints.value = 50 - points;
						currentTier.value = "Regular";
						nextTier.value = "Silver";
					} else if (points >= 50 && points <= 149) {
						progress.value = points / 150;
						earnPoints.value = 150 - points;
						currentTier.value = "Silver";
						nextTier.value = "Gold";
					} else if (points >= 150 && points <= 499) {
						progress.value = points / 500;
						earnPoints.value = 500 - points;
						currentTier.value = "Gold";
						nextTier.value = "Platinum";
					} else if (points >= 500 && points <= 999) {
						progress.value = points / 1000;
						earnPoints.value = 1000 - points;
						currentTier.value = "Platinum";
						nextTier.value = "Diamond";
					}
				}
			};

			const getContentSlug = () => {
				contentSlug.value = "rk_points";
				getCms(contentSlug.value);
				cmsContent.value = cms._rawValue.content;
			};

			const redirectToOrder = (id) => {
				userStore.viewOrder = id;
				router.push("/order-history");
			};

			onIonViewWillEnter(() => {
				loading.value = true;
				fetchLogs();
				getContentSlug();
			});

			return {
				chevronForward,
				checkmarkCircle,
				router,
				showTransactionHistory,
				customer,
				loading,
				tierLevel,
				generateDescription,
				progress,
				currentTier,
				nextTier,
				earnPoints,
				getCmsContent,
				cmsContent,
				redirectToOrder,
			};
		},
	});
</script>
