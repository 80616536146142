import { Geolocation } from "@capacitor/geolocation";
import { Loader } from "@googlemaps/js-api-loader";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { reactive } from "vue";
import { useApiStore } from "@/store/api";

// Declarations for map
const locationState = reactive({
	// User coordinates
	customerLat: 0,
	customerLng: 0,
	customerAddressName: null,
	customerAddressDetail: null,
	// Search variables
	completeAddress: null,
	addressCandidates: [],
});

export default function useLocation() {
	const apiStore = useApiStore();

	let map;
	let iconExtender;
	let customerIcon;
	let customerMarker;

	const clearValues = () => {
		locationState.customerLat = 0;
		locationState.customerLng = 0;
		locationState.customerAddressName = null;
		locationState.customerAddressDetail = null;
		locationState.completeAddress = null;
		locationState.addressCandidates = [];
	};

	const googleLoader = new Loader({
		apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
		version: "weekly",
		libraries: ["places"],
	});

	// Add Marker
	const addMarker = (userType = "customer", draggable = false) => {
		iconExtender = L.Icon.extend({
			iconSize: [35, 95],
		});
		switch (userType) {
			case "customer":
				(customerIcon = new iconExtender({
					iconUrl: "/assets/icon/ic-map-pin.svg",
				})),
					// Map marker
					(customerMarker = L.marker([parseFloat(locationState.customerLat), parseFloat(locationState.customerLng)], {
						icon: customerIcon,
						draggable: draggable,
					}).addTo(map));
				break;
		}
	};

	// Remove Marker
	const removeMarker = (userType = "customer") => {
		switch (userType) {
			case "customer":
				map.removeLayer(customerMarker);
				break;
		}
	};

	const createBounds = (lat1, lng1, lat2, lng2, lat3, lng3) => {
		const bounds = [
			[lat1, lng1],
			[lat2, lng2],
		];

		if (lat3 && lng3) {
			bounds.push([lat3, lng3]);
		}

		map.fitBounds(bounds, {
			padding: [40, 50],
		});
	};

	const mapCenteredMarker = (userType = "customer") => {
		map.on("move", function () {
			switch (userType) {
				case "customer":
					customerMarker.setLatLng(map.getCenter());
					locationState.customerLat = customerMarker.getLatLng().lat;
					locationState.customerLng = customerMarker.getLatLng().lng;
					break;
			}
		});
	};

	// Update rider marker position
	const updateMarkerCoordinates = (userType = "customer") => {
		switch (userType) {
			case "customer":
				customerMarker.setLatLng([locationState.customerLat, locationState.customerLng]);
				break;
		}
	};

	const draggableMarker = () => {
		customerMarker.on("dragend", function () {
			locationState.customerLat = customerMarker.getLatLng().lat;
			locationState.customerLng = customerMarker.getLatLng().lng;
		});
	};

	// Instantiate map
	const setupMap = (mapId = "locationMap") => {
		// Set map and center
		map = L.map(mapId);
		// Set tiles
		L.tileLayer(
			"https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFtZW5rdXJvZGEiLCJhIjoiY2w1N3FtczExMXQ0MDNqb2M2bGM4eTdvdSJ9.M38_1aVPB_ScOWCXf-EyoA",
			{
				// attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 18,
				id: "mapbox/streets-v11",
				tileSize: 512,
				zoomOffset: -1,
				accessToken: "pk.eyJ1IjoicmFtZW5rdXJvZGEiLCJhIjoiY2w1N3FtczExMXQ0MDNqb2M2bGM4eTdvdSJ9.M38_1aVPB_ScOWCXf-EyoA",
			}
		).addTo(map);

		// Remove zoom control
		map.removeControl(map.zoomControl);

		// Add a marker on the map for the customer
		addMarker("customer");

		// Fix marker position with map's center
		mapCenteredMarker();

		// Set map move end event
		mapMoveEndEvent();

		return new Promise((resolve) => {
			resolve(true);
		});
	};

	// Update map center position
	const updateMapCenter = (lat, lng) => {
		map.setView([lat, lng], 20);
	};

	const mapMoveEndEvent = (userType = "customer") => {
		map.on("moveend", function () {
			switch (userType) {
				case "customer":
					locationState.customerLat = customerMarker.getLatLng().lat;
					locationState.customerLng = customerMarker.getLatLng().lng;

					searchCoordinates(locationState.customerLat, locationState.customerLng).then((responses) => {
						locationState.completeAddress = responses.results[0].formatted_address;
					});

					break;
			}
		});
	};

	const geocodePlace = async (placeId) => {
		const google = await googleLoader.load();
		const geocoder = new google.maps.Geocoder();

		return geocoder.geocode(
			{
				placeId: placeId,
			},
			(responses, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					return {
						lat: responses[0].geometry.location.lat(),
						lng: responses[0].geometry.location.lng(),
					};
				}
			}
		);
	};

	const searchCoordinates = async (latitude, longitude) => {
		const google = await googleLoader.load();
		const geocoder = new google.maps.Geocoder();

		return geocoder.geocode(
			{
				location: {
					lat: latitude,
					lng: longitude,
				},
			},
			(responses, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					return responses;
				}
			}
		);
	};

	// Search feature
	const googleSearch = async (currentValue = null, callback = null) => {
		if (currentValue?.length > 3) {
			const google = await googleLoader.load();
			const service = new google.maps.places.AutocompleteService();

			const center = {
				lat: locationState.customerLat,
				lng: locationState.customerLng,
			};

			const displaySuggestions = (predictions, status) => {
				if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
					return;
				}

				predictions.forEach((prediction) => {
					// check if place id is already in the candidates
					const index = locationState.addressCandidates.findIndex((address) => address.placeId === prediction.place_id);

					if (index === -1) {
						locationState.addressCandidates.push({
							placeId: prediction.place_id,
							formattedAddress: prediction.description,
						});
					}
				});

				if (typeof callback === "function") {
					callback();
				}
			};

			service.getPlacePredictions(
				{
					input: currentValue,
					bounds: {
						north: center.lat + 0.1,
						south: center.lat - 0.1,
						east: center.lng + 0.1,
						west: center.lng - 0.1,
					},
					componentRestrictions: { country: "ph" },
					region: "ph",
				},
				displaySuggestions
			);
		} else {
			locationState.addressCandidates = [];
		}
	};

	const setSelectedAddress = (lat, lng, formattedAddress) => {
		locationState.customerLat = lat;
		locationState.customerLng = lng;

		if (formattedAddress) {
			locationState.completeAddress = formattedAddress;
		}

		if (map) {
			updateMapCenter(locationState.customerLat, locationState.customerLng);
			updateMarkerCoordinates("customer");
		}
	};

	// Set current customer location
	const setCustomerLocation = async () => {
		if (!locationState.customerLat || !locationState.customerLng) {
			return Geolocation.getCurrentPosition({
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			}).then((result) => {
				if (result) {
					locationState.customerLat = result.coords.latitude;
					locationState.customerLng = result.coords.longitude;
				}
			});
		} else {
			return true;
		}
	};

	// Submit location form to the back-end
	const submitLocation = (payload) => {
		let request = null;

		if (payload.id) {
			request = window.axios.patch(apiStore.route("updateAddress", { id: payload.id }), payload);
		} else {
			request = window.axios.post(apiStore.route("storeAddress"), payload);
		}
		return request;
	};

	return {
		setupMap,
		clearValues,
		addMarker,
		removeMarker,
		createBounds,
		updateMapCenter,
		updateMarkerCoordinates,
		draggableMarker,
		geocodePlace,
		searchCoordinates,
		googleSearch,
		setCustomerLocation,
		setSelectedAddress,
		submitLocation,
		locationState,
		map,
	};
}
