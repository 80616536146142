<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="flex items-center justify-center min-h-[calc(100vh-72px)] lg:min-h-[calc(100vh-80px)]">
					<div class="w-full lg:w-[400px] px-4 lg:px-0 mx-auto">
						<div class="space-y-4">
							<h3 class="font-bold text-[28px] text-primary-900 text-center">Order Failed!</h3>
							<p class="text-primary-400 text-center">
								This is to confirm your order failed. We are very sorry for the inconvenience. We will
								process the refund as soon as possible. Your RK point earned in this transaction will
								also be deducted to your account.
							</p>
						</div>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import { defineComponent } from "vue";
// import ButtonComponent from "@/components/ButtonComponent.vue";
import { useRouter } from "vue-router";

export default defineComponent({
	components: {
		// ButtonComponent,
	},
	setup() {
		const router = useRouter();

		return {
			router,
		};
	},
});
</script>
