<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">
            <div class="page-offset">
                <div
                    class="
                        ordr-hstry__cntnr
                        px-4
                        relative
                        lg:px-12
                        py-[52px]
                        lg:py-10
                    "
                >
                    <a href="/order-history">
                        <ion-icon
                            :src="arrowBack"
                            class="absolute top-4 left-4 w-6 h-6 text-black"
                        ></ion-icon>
                    </a>
                    <div class="h-full items-start flex flex-col lg:flex-row">
                        <div
                            class="
                                ordr-hstry__tab
                                w-full
                                lg:w-7/12
                                border-0
                                lg:border-l
                            "
                        >
                            <div
                                class="
                                    ordr-hstry__tab--wrapper
                                    max-w-none
                                    lg:max-w-[408px]
                                    ml-0
                                    lg:ml-[10%]
                                "
                            >
                                <div class="rounded-lg p-4 bg-primary-800 mb-4">
                                    <div
                                        class="
                                            flex
                                            items-center
                                            justify-between
                                            mb-2
                                        "
                                    >
                                        <p class="m-0 text-sm text-success">
                                            Completed
                                        </p>
                                        <p class="m-0 text-sm text-primary-50">
                                            #10023
                                        </p>
                                    </div>
                                    <h3 class="text-white font-semibold">
                                        0 minutes
                                    </h3>
                                    <p class="text-primary-50 text-xs">
                                        Estimated Delivery Time
                                    </p>
                                </div>

                                <div
                                    class="
                                        flex
                                        items-center
                                        justify-between
                                        mb-3
                                    "
                                >
                                    <p class="m-0 font-semibold">Deliver to</p>
                                    <p class="m-0 text-xs text-primary-200">
                                        Feb 22, 2022 12:00 PM
                                    </p>
                                </div>

                                <div
                                    class="
                                        border border-primary-50
                                        rounded-xl
                                        p-4
                                        mb-2
                                    "
                                >
                                    <p
                                        class="
                                            text-sm
                                            font-semibold
                                            text-primary-500
                                            mb-1
                                        "
                                    >
                                        Abby’s Home
                                    </p>
                                    <p class="text-xs text-primary-400">
                                        774-B Juan Luna Avenue 1000, Manila
                                        City, Metro Manila, NCR
                                    </p>
                                </div>
                                <div
                                    class="
                                        border border-primary-50
                                        rounded-xl
                                        p-4
                                        mb-2
                                    "
                                >
                                    <p
                                        class="
                                            text-sm
                                            font-semibold
                                            text-primary-500
                                            mb-1
                                        "
                                    >
                                        Abby Tolentino (Other)
                                    </p>
                                    <p class="text-xs text-primary-400">
                                        +63 912 345 6789
                                    </p>
                                </div>

                                <div class="mt-4 mb-2">
                                    <p class="m-0 font-semibold">Orders</p>
                                </div>
                                <div
                                    class="
                                        border border-primary-50
                                        rounded-xl
                                        mb-2
                                    "
                                    v-for="i in 1"
                                    :key="i"
                                >
                                    <div class="p-4 border-b border-primary-50">
                                        <div
                                            class="
                                                relative
                                                flex
                                                items-center
                                                space-x-3
                                            "
                                        >
                                            <div class="flex-shrink-0">
                                                <img
                                                    class="
                                                        h-[94px]
                                                        w-[94px]
                                                        rounded-xl
                                                        object-cover
                                                    "
                                                    src="/assets/image/product-image.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="flex-1 min-w-0">
                                                <p
                                                    class="
                                                        text-sm
                                                        font-semibold
                                                        text-primary-900
                                                        truncate
                                                        mb-1
                                                    "
                                                >
                                                    Aka Ramen
                                                </p>
                                                <p
                                                    class="
                                                        text-xs text-primary-400
                                                    "
                                                >
                                                    1x Half Boiled Egg
                                                </p>
                                                <p
                                                    class="
                                                        text-xs text-primary-400
                                                    "
                                                >
                                                    1x Leeks
                                                </p>
                                                <p
                                                    class="
                                                        text-xs text-primary-400
                                                    "
                                                >
                                                    1x Chasu
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            flex
                                            items-center
                                            justify-between
                                            p-4
                                        "
                                    >
                                        <p class="text-sm font-semibold m-0">
                                            P 230.00
                                        </p>
                                        <button
                                            class="
                                                bg-primary-500
                                                rounded-lg
                                                text-white text-center
                                                flex
                                                items-center
                                                py-1
                                                px-4
                                            "
                                        >
                                            <span
                                                class="
                                                    text-sm
                                                    font-semibold
                                                    mx-1
                                                "
                                                >1</span
                                            >
                                        </button>
                                    </div>
                                </div>

                                <div class="mt-6">
                                    <div class="flex justify-between mb-2">
                                        <p class="text-primary-400 m-0">
                                            Subtotal
                                        </p>
                                        <p
                                            class="
                                                text-primary-900
                                                font-bold
                                                m-0
                                            "
                                        >
                                            P 360.00
                                        </p>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <p class="text-primary-400 m-0">
                                            VATable Sale
                                        </p>
                                        <p
                                            class="
                                                text-primary-900
                                                font-bold
                                                m-0
                                            "
                                        >
                                            P 89.29
                                        </p>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <p class="text-primary-400 m-0">
                                            VAT (12%)
                                        </p>
                                        <p
                                            class="
                                                text-primary-900
                                                font-bold
                                                m-0
                                            "
                                        >
                                            P 10.71
                                        </p>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <p class="text-primary-400 m-0">
                                            Total
                                        </p>
                                        <p
                                            class="
                                                text-primary-900
                                                font-bold
                                                m-0
                                            "
                                        >
                                            P 234.25
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="border-t border-primary-50 pt-4 mt-4"
                                >
                                    <p class="font-semibold">Payment</p>
                                    <p class="text-sm font-semibold">Gcash</p>
                                </div>
                                <div
                                    class="mt-4 pt-4 flex justify-end border-t"
                                >
                                    <ion-button
                                        size="large"
                                        class="btn-primary w-full"
                                        expand="block"
                                        >Reorder</ion-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bottom-navigation />
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import { chevronForward, arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";
export default {
    name: "OrderHistory",
    components: {
        TopNavigation,
        BottomNavigation,
        IonContent,
        IonPage,
        IonButton,
    },
    setup() {
        const router = useRouter();

        return {
            chevronForward,
            router,
            arrowBack,
        };
    },
};
</script>