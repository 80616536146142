import { defineStore } from "pinia";
import { useUserStore } from "./user";
import { useApiStore } from "./api";
import useModal from "@/composables/modal";
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useCartStore = defineStore({
	id: "cart",
	state: () => {
		return {
			id: null,
			fulfillmentMethod: null,
			recipient: {
				type: "myself", // myself | other
				firstName: null,
				lastName: null,
				mobileNumber: null,
				email: null,
				address: null, // address model
			},
			branch: null, // branch model
			timeslot: null, // timeslot model
			branchTableOption: null, // branch table option model
			headCount: 0,
			items: [], // array of cart item model with options
			freeItems: [], // array of free item model with the product to get
			voucher: {
				code: null,
				value: 0,
			},
			points: 0,
			deliveryFee: 0,
			paymentMethod: "RK Wallet", // default payment method
			cardSelected: null,
		};
	},
	getters: {
		recipientName(state) {
			if (state.recipient.firstName && state.recipient.lastName) {
				let name = state.recipient.firstName + " " + state.recipient.lastName;

				if (state.recipient.type === "other") {
					name += " (Other)";
				}

				return name;
			} else {
				const userStore = useUserStore();
				return userStore.userData ? userStore.userData.first_name + " " + userStore.userData.last_name : "";
			}
		},
		subtotal(state) {
			let total = 0;

			state.items.forEach((value) => {
				total += parseFloat(value.amount);

				if (value.add_options_total) {
					value.options.forEach((option) => {
						total += parseFloat(option.amount) * value.quantity;
					});
				}
			});

			return total;
		},
		serviceCharge(state) {
			if (state.fulfillmentMethod === "Dine-in" && state.branch) {
				return this.vatableSale * (state.branch.service_charge / 100);
			} else {
				return 0;
			}
		},
		vatableSale() {
			return this.subtotal / 1.12;
		},
		vat() {
			return this.vatableSale * 0.12;
		},
		discountedAmount(state) {
			let total = 0;

			total += this.subtotal;

			if (state.voucher.value > 0) {
				total -= state.voucher.value > total ? total : state.voucher.value;
			}

			return total;
		},
		grandTotal(state) {
			let total = 0;

			total += this.discountedAmount;
			total += this.serviceCharge;
			total -= state.points > 0 ? state.points : 0;
			total += state.fulfillmentMethod === "Delivery" ? parseFloat(state.deliveryFee) : 0; // not included to voucher and points application

			return total;
		},
		itemsPerProduct: (state) => {
			return (productId, productType) => {
				return state.items.reduce((val, item) => {
					const itemType = item.product_type.split("\\").slice(-1).pop();

					return item.product_id === parseInt(productId) && itemType === productType ? val + item.quantity : val + 0;
				}, 0);
			};
		},
		pointsToEarn() {
			const userStore = useUserStore();

			if (userStore.userData) {
				const multiplier = this.vatableSale / 100;
				let points = 0;

				switch (userStore.userData.tier_level) {
					case "Silver":
						points = multiplier * 1.5;
						break;
					case "Gold":
						points = multiplier * 2;
						break;
					case "Platinum":
						points = multiplier * 3;
						break;
					case "Diamond":
						points = multiplier * 5;
						break;
					default:
						points = multiplier * 1;
				}

				return points.toFixed(2);
			} else {
				return 0;
			}
		},
		productPrice: () => {
			return (product) => {
				let price = product.price;

				if (product.applied_item_discounts.length > 0) {
					const promo = product.applied_item_discounts.find((obj) => obj !== undefined);

					if (promo) {
						switch (promo.discount_type) {
							case "Fixed Discount":
								price = parseFloat(product.price - promo.discount_value);
								break;
							case "Percent Discount":
								price = parseFloat(product.price - product.price * (promo.discount_value / 100));
								break;
							case "New Price":
								price = parseFloat(promo.discount_value);
								break;
						}

						if (price <= 0) {
							price = 0;
						}
					}
				}

				return price;
			};
		},
	},
	actions: {
		async fetchCarts() {
			const { localStorage } = useLocalStorage();

			if (await localStorage.value.get("authToken")) {
				const userStore = useUserStore();
				const apiStore = useApiStore();

				return window.axios.get(apiStore.route("cartsPending")).then((result) => {
					userStore.carts = result.data.carts;

					return true;
				});
			}
		},
		async fetchCartRecipient() {
			const { localStorage } = useLocalStorage();
			await localStorage.value.get("cartFulfillment").then((value) => (this.fulfillmentMethod = value));
			await localStorage.value.get("setRecipient").then((value) => {
				if (value) {
					this.recipient = value;
				} else {
					this.recipient = {};
				}
			});
		},
		async fetchActiveCart() {
			const modal = useModal();
			const { localStorage } = useLocalStorage();

			// get updated details
			await localStorage.value.get("activeCart").then((value) => {
				if (value) {
					window.axios.get(process.env.VUE_APP_API_URL + "/api/cart/" + value.id + "/show").then((result) => {
						localStorage.value.set("activeCart", result.data.cart);
						this.fetchCart(result.data.cart);

						this.freeItems = result.data.freeItems;

						if (result.data.updated) {
							modal.error("Your cart has been updated automatically based on added item's availability.");
						}
					});
				}
			});
		},
		fetchCart(cart) {
			const userStore = useUserStore();

			this.id = cart.id;
			this.fulfillmentMethod = cart.fulfillment_method;
			this.recipient.type = cart.recipient_email === userStore.userData.email ? "myself" : "other";
			this.recipient.firstName = cart.recipient_first_name;
			this.recipient.lastName = cart.recipient_last_name;
			this.recipient.mobileNumber = cart.recipient_mobile_number;
			this.recipient.email = cart.recipient_email;
			this.recipient.address = cart.address;
			this.branch = cart.branch;
			this.branch.distance = cart.distance;
			this.timeslot = cart.timeslot;
			this.items = cart.items.sort((a, b) => a.product.name.localeCompare(b.product.name));
			this.voucher.code = cart.voucher ? cart.voucher.code : null;
			this.voucher.value = cart.voucher_applied;
			this.points = cart.points_applied;
			this.deliveryFee = cart.delivery_fee ? parseFloat(cart.delivery_fee) : 0;
		},
		updateQuantity(itemId, quantity, success, failed) {
			const apiStore = useApiStore();

			window.axios
				.patch(
					apiStore.route("cartItemUpdateQuantity", {
						id: this.id,
						item: itemId,
					}),
					{
						quantity: quantity,
					}
				)
				.then((result) => {
					if (result.data.item) {
						const index = this.items.findIndex((obj) => obj.id == result.data.item.id);

						this.items[index].quantity = result.data.item.quantity;
						this.items[index].amount = result.data.item.amount;
					} else {
						const index = this.items.findIndex((obj) => obj.id == itemId);

						this.items.splice(index, 1);
					}

					// check if there are points applied
					if (this.points > this.subtotal) {
						this.points = this.subtotal;
					}

					success(result);
				})
				.catch((error) => {
					failed(error);
				});
		},
		registerFreeItems(freeItems) {
			const newFreeItems = [];

			if (freeItems.length > 0) {
				freeItems.forEach((freeItem) => {
					if (this.freeItems.filter((e) => e.id === freeItem.id).length === 0) {
						if (freeItem.get_product.inventories[0].stock > 0) {
							this.freeItems.push(freeItem);
							newFreeItems.push(freeItem);
						}
					}
				});
			} else {
				this.freeItems = [];
			}

			return newFreeItems;
		},
		resetVoucher() {
			this.voucher = {
				code: null,
				value: 0,
			};
		},
		updateUserCart(cart) {
			const userStore = useUserStore();
			const cartIndex = userStore.carts.findIndex((obj) => obj.id === cart.id);
			userStore.carts[cartIndex] = cart;
		},
		async edit(id) {
			const { localStorage } = useLocalStorage();
			const userStore = useUserStore();

			const cart = userStore.carts.find((obj) => obj.id === id);

			await localStorage.value.set("cartFulfillment", cart.fulfillment_method);
			await localStorage.value.set("setRecipient", {
				type: cart.recipient_email === userStore.userData.email ? "myself" : "other",
				firstName: cart.recipient_first_name,
				lastName: cart.recipient_last_name,
				mobileNumber: cart.recipient_mobile_number,
				email: cart.recipient_email,
				address: cart.address, // address model
			});

			await localStorage.value.set("activeCart", {
				id: cart.id,
			});

			await this.fetchActiveCart();
		},
		async delete(id, success, failed) {
			const { localStorage } = useLocalStorage();
			const userStore = useUserStore();
			const apiStore = useApiStore();

			window.axios
				.delete(apiStore.route("cartDelete", { id: id }))
				.then(() => {
					const cartIndex = userStore.carts.findIndex((obj) => obj.id === id);

					if (cartIndex !== -1) {
						userStore.carts.splice(cartIndex, 1);
					}

					localStorage.value.get("activeCart").then((value) => {
						if (value?.id === id) {
							localStorage.value.delete("activeCart");
							localStorage.value.delete("cartFulfillment");
							localStorage.value.delete("setRecipient");
						}

						success();
					});
				})
				.catch((error) => {
					failed(error);
				});
		},
	},
});
