<template>
	<ion-page>
		<top-navigation></top-navigation>

		<ion-content :fullscreen="true">
			<div class="page-offset">
				<div class="min-h-screen px-4 py-6 lg:px-12 lg:pb-12">

					<fulfillment-information class="flex lg:hidden" />

					<div class="mt-6 space-y-2">
						<h3 class="text-2xl text-primary-900 font-bold">Branches</h3>
						<p class="text-sm text-primary-400">
							{{ branches.length }} RK
							{{ branches.length === 1 ? "store" : "stores" }}
							near your area.
						</p>

					</div>
					<div class="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-6 mt-10">
						<ion-spinner v-if="loading"></ion-spinner>
						<template v-else v-for="branch in branches" :key="branch">
							<branch-card
								@click="selectBranch(branch)"
								:branch="branch"
                                :disabled="!isBranchAvailable(branch)"
                            ></branch-card>
						</template>
					</div>
				</div>
			</div>

			<bottom-navigation />
		</ion-content>

		<branch-timeslots
			v-if="selectedBranch"
			:branch="selectedBranch"
			:is-visible="showSideMenu"
			@close="
				{
					showSideMenu = false;
					selectedBranch = null;
				}
			" />
		

		<generic-modal :no-close-icon="true" :is-visible="unavailableModal" @close="unavailableModal = false">
			<template #content>
				<div class="px-4 pb-4 lg:px-12 lg:pb-12 w-full lg:w-[407px] h-auto">
					<img
						src="/assets/icon/ic-sad.svg"
						alt="sad_icon"
						class="w-8 h-8 object-contain mx-auto mb-3"
					>
					<div class="space-y-2 text-center">
						<p class="text-xl text-primary-900 font-semibold">Sorry</p>
						<p class="text-sm text-primary-400">This branch is not available at the moment.</p>
					</div>
					<div class="w-full mt-8">
						<button-component @click="unavailableModal = false" color="primary" expand="block">
							<template #content> Okay </template>
						</button-component>
					</div>
				</div>
			</template>
		</generic-modal>
	</ion-page>
</template>
<script>
	import { defineComponent, ref, computed } from "vue";
	import TopNavigation from "@/partials/TopNavigation.vue";
	import BottomNavigation from "@/partials/BottomNavigation.vue";
	import BranchCard from "./Components/BranchCard.vue";
	import BranchTimeslots from "./Components/BranchTimeslots.vue";
	import FulfillmentInformation from "@/components/FulfillmentInformation.vue";
	import GenericModal from "@/components/GenericModal.vue";
	import ButtonComponent from "@/components/ButtonComponent.vue";
	import { useApiStore } from "@/store/api";
	import { useCartStore } from "@/store/cart";
	import { useGuestStore } from "@/store/guest";
	import { IonPage, IonContent, IonSpinner, onIonViewWillEnter } from "@ionic/vue";
	import useModal from "@/composables/modal";

	export default defineComponent({
		components: {
			TopNavigation,
			BottomNavigation,
			IonPage,
			IonContent,
			IonSpinner,
			BranchCard,
			BranchTimeslots,
			FulfillmentInformation,
			GenericModal,
			ButtonComponent,
		},
		setup() {
			const apiStore = useApiStore();
			const cartStore = useCartStore();
			const guestStore = useGuestStore();
			const modal = useModal();
			const unavailableModal = ref(false);

			const showSideMenu = ref(false);
			const loading = ref(true);
			const branches = ref([]);

			const selectedBranch = ref(null);

            const fulfillmentMethod = computed(() => {
                return guestStore.isGuest ? guestStore.fulfillmentMethod : cartStore.fulfillmentMethod;
            });
            
            const selectBranch = (branch) => {
                if (!isBranchAvailable(branch)) {
                    unavailableModal.value = true;
                    return;
                }

                selectedBranch.value = branch;
                showSideMenu.value = !showSideMenu.value;
            }

            const isBranchAvailable = (branch) => {
                let isAvailable = false;

                switch (fulfillmentMethod.value) {
                    case "Dine-in":
                        isAvailable = branch?.for_dine_in || false;
                        break;
                    case "Pick-up":
                        isAvailable = branch?.for_pick_up || false;
                        break;
                    case "Delivery":
                        isAvailable = branch?.for_delivery || false;
                        break;
                }

                if (branch.timeslots.length === 0) {
                    isAvailable = false;
                }

                return isAvailable;
            };

			onIonViewWillEnter(() => {
				loading.value = true;

				window.axios
					.get(apiStore.route("nearestBranch"), {
						params: {
							latitude: guestStore.isGuest ? guestStore.recipient.address?.latitude : cartStore.recipient.address?.latitude,
							longitude: guestStore.isGuest ? guestStore.recipient.address?.longitude : cartStore.recipient.address?.longitude,
							fulfillment: guestStore.isGuest ? guestStore.fulfillmentMethod : cartStore.fulfillmentMethod,
						},
					})
					.then((result) => {
						loading.value = false;
						branches.value = result.data.branches;
					})
					.catch((error) => {
						modal.error(error.response.data.message);
					});
			});

			return {
				showSideMenu,
				loading,
				branches,
                selectBranch,
				selectedBranch,
				unavailableModal,
                isBranchAvailable
			};
		},
	});
</script>
